import { Form, FormikProvider, useFormik } from "formik";
import React from "react";
import { useState } from "react";
import * as Yup from "yup";
import Alert from "../../components/common/Alert";
import LoadingButton from "../../components/common/LoadingButton";
import { useNavigate } from "react-router-dom";

function Signup() {
  const [step1, setstep1] = useState(true);
  const [step2, setstep2] = useState(false);
  const [step3, setstep3] = useState(false);
  const [step1Touched, setstep1Touched] = useState(true);
  const [step2Touched, setstep2Touched] = useState(false);
  const [step3Touched, setstep3Touched] = useState(false);

  const navigate = useNavigate();

  const RegisterSchema = Yup.object().shape({
    workEmail: Yup.string().email("Enter valid email").required("Email is required"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),

    companyName: Yup.string().required("Company Name is required"),
    noOfEmployees: Yup.string().required("No of employees is required"),
    companyPhone: Yup.string().required("Company phone is required"),
    industry: Yup.string().required("Industry is required"),

    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Password doesn’t match"),
  });

  const formik = useFormik({
    initialValues: {
      workEmail: "",
      firstName: "",
      lastName: "",

      companyName: "",
      noOfEmployees: "",
      companyPhone: "",
      industry: "",

      password: "",
      confirmPassword: "",
    },
    validationSchema: RegisterSchema,
    validateOnChange: true,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log(values);
      setSubmitting(true);
      //   await dispatch(loginUser({ ...values, setErrors }));
      setSubmitting(false);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      <div className="page-body-widthout-sidebar">
        <div className="container">
          <div style={{ minHeight: "70vh" }} className="d-flex align-items-center justify-content-center flex-column">
            <p className="semi-bold-weight mb-1" style={{ fontSize: "24px" }}>
              Get Started with Ignite
            </p>
            <p className="body1"></p>
            <div>
              <p className="text-center mb-3">Let’s quickly get to know you!</p>
            </div>
            <div className="mb-3">
              <div className="hstack gap-2">
                {step1Touched && <div className="bg-primary" style={{ minHeight: "0.2rem", minWidth: "5rem" }}></div>}
                {step2Touched && <div className="bg-primary" style={{ minHeight: "0.2rem", minWidth: "5rem" }}></div>}
                {step3Touched && <div className="bg-primary" style={{ minHeight: "0.2rem", minWidth: "5rem" }}></div>}

                {!step1Touched && <div className="bg-secondary" style={{ minHeight: "0.2rem", minWidth: "5rem" }}></div>}
                {!step2Touched && <div className="bg-secondary" style={{ minHeight: "0.2rem", minWidth: "5rem" }}></div>}
                {!step3Touched && <div className="bg-secondary" style={{ minHeight: "0.2rem", minWidth: "5rem" }}></div>}
              </div>
            </div>
            <div>
              <div className="bg-white border p-4 mb-3" style={{ minWidth: "24rem" }}>
                {/* Step 1 */}
                <div>
                  <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      {step1 && (
                        <>
                          <p className="body1 semi-bold-weight text-center mb-3">Basic Information</p>

                          {/* First Name */}
                          <div className="mb-4">
                            <label className="form-label">First Name</label>
                            <input {...getFieldProps("firstName")} className="form-control form-control-lg" placeholder="First Name" />
                            <span className="invalid-feedback" style={{ display: Boolean(touched.firstName && errors.firstName) ? "block" : "none" }}>
                              {errors.firstName}
                            </span>
                          </div>

                          {/* Last Name */}
                          <div className="mb-4">
                            <label className="form-label">Last Name</label>
                            <input {...getFieldProps("lastName")} className="form-control form-control-lg" placeholder="Last Name" />
                            <span className="invalid-feedback" style={{ display: Boolean(touched.lastName && errors.lastName) ? "block" : "none" }}>
                              {errors.lastName}
                            </span>
                          </div>

                          {/* Work Email */}
                          <div className="mb-4">
                            <label className="form-label">Work Email</label>
                            <input {...getFieldProps("workEmail")} className="form-control form-control-lg" placeholder="Work Email" />
                            <span className="invalid-feedback" style={{ display: Boolean(touched.workEmail && errors.workEmail) ? "block" : "none" }}>
                              {errors.workEmail}
                            </span>
                          </div>

                          {/* Next Button */}
                          <div className="d-flex justify-content-end mt-3">
                            <button
                              className="btn btn-lg btn-primary"
                              onClick={() => {
                                setstep1(false);
                                setstep2(true);
                                setstep2Touched(true);
                              }}
                              disabled={
                                !Boolean(touched.firstName) ||
                                !Boolean(touched.lastName) ||
                                !Boolean(touched.workEmail) ||
                                Boolean(errors.firstName) ||
                                Boolean(errors.lastName) ||
                                Boolean(errors.workEmail)
                              }
                            >
                              Next
                            </button>
                          </div>
                        </>
                      )}

                      {step2 && (
                        <>
                          <p className="body1 semi-bold-weight text-center mb-3">Company Details</p>

                          {/* Company Name */}
                          <div className="mb-4">
                            <label className="form-label">Company Name</label>
                            <input {...getFieldProps("companyName")} className="form-control form-control-lg" placeholder="Company Name" />
                            <span
                              className="invalid-feedback"
                              style={{ display: Boolean(touched.companyName && errors.companyName) ? "block" : "none" }}
                            >
                              {errors.companyName}
                            </span>
                          </div>

                          {/* No of employees */}
                          <div className="mb-4">
                            <label className="form-label">No of employees</label>
                            <select
                              {...getFieldProps("noOfEmployees")}
                              className="form-select"
                              onChange={(e) => {
                                formik.setFieldValue("noOfEmployees", e.target.value);
                              }}
                            >
                              <option value="">No. of employees</option>
                              <option value="Just Me">Just Me</option>
                              <option value="2-10">2-10</option>
                              <option value="11-50">11-50</option>
                              <option value="51-100">51-100</option>
                              <option value="101-500">101-500</option>
                              <option value="501-1000">501-1000</option>
                              <option value="1000+">1000+</option>
                            </select>
                            <span
                              className="invalid-feedback"
                              style={{ display: Boolean(touched.noOfEmployees && errors.noOfEmployees) ? "block" : "none" }}
                            >
                              {errors.noOfEmployees}
                            </span>
                          </div>

                          {/* Company Phone */}
                          <div className="mb-4">
                            <label className="form-label">Company Phone</label>
                            <input {...getFieldProps("companyPhone")} className="form-control form-control-lg" placeholder="Company Phone" />
                            <span
                              className="invalid-feedback"
                              style={{ display: Boolean(touched.companyPhone && errors.companyPhone) ? "block" : "none" }}
                            >
                              {errors.companyPhone}
                            </span>
                          </div>

                          {/* Industry */}
                          <div className="mb-4">
                            <label className="form-label">Select Industry</label>
                            <select
                              {...getFieldProps("industry")}
                              className="form-select"
                              onChange={(e) => {
                                formik.setFieldValue("industry", e.target.value);
                              }}
                            >
                              <option value="">Select Industry</option>
                              <option value="Agency">Agency</option>
                              <option value="Software">Software</option>
                              <option value="Ecommerce">Ecommerce</option>
                              <option value="Dropshipping">Dropshipping</option>
                              <option value="Freelance">Freelance</option>
                              <option value="Other">Other</option>
                            </select>
                            <span className="invalid-feedback" style={{ display: Boolean(touched.industry && errors.industry) ? "block" : "none" }}>
                              {errors.industry}
                            </span>
                          </div>

                          {/* Next Button */}
                          <div className="d-flex justify-content-end mt-3">
                            <button
                              className="btn btn-text text-secondary"
                              onClick={() => {
                                setstep1(true);
                                setstep2(false);
                              }}
                            >
                              ← back
                            </button>
                            <button
                              className="btn btn-lg btn-primary"
                              onClick={() => {
                                setstep3Touched(true);
                                setstep2(false);
                                setstep3(true);
                              }}
                              disabled={
                                !Boolean(touched.companyName) ||
                                !Boolean(touched.noOfEmployees) ||
                                !Boolean(touched.companyPhone) ||
                                !Boolean(touched.industry) ||
                                Boolean(errors.companyName) ||
                                Boolean(errors.noOfEmployees) ||
                                Boolean(errors.companyPhone) ||
                                Boolean(errors.industry)
                              }
                            >
                              Next
                            </button>
                          </div>
                        </>
                      )}

                      {step3 && (
                        <>
                          <p className="body1 semi-bold-weight text-center mb-3">Set Password</p>

                          {/* Set Password */}
                          <div className="mb-4">
                            <label className="form-label">Set Password</label>
                            <input {...getFieldProps("password")} className="form-control form-control-lg" placeholder="Set Password" />
                            <span className="invalid-feedback" style={{ display: Boolean(touched.password && errors.password) ? "block" : "none" }}>
                              {errors.password}
                            </span>
                            <p className="mt-2 mb-1">Set a strong password</p>

                            <p className="mb-1">
                              <span style={{ marginRight: "0.3rem" }}>
                                {formik.values.password?.length >= 8 && <img src="/static/icons/passwordCheckStrong.svg" />}
                                {formik.values.password?.length < 8 && <img src="/static/icons/passwordCheck.svg" />}
                              </span>
                              Use 8 or more characters.
                            </p>
                            <p className="mb-1">
                              <span style={{ marginRight: "0.3rem" }}>
                                {/^(?=.*[a-z])/.test(formik.values.password) && <img src="/static/icons/passwordCheckStrong.svg" />}
                                {!/^(?=.*[a-z])/.test(formik.values.password) && <img src="/static/icons/passwordCheck.svg" />}
                              </span>
                              Use a minimum of one letter.
                            </p>
                            <p className="mb-1">
                              <span style={{ marginRight: "0.3rem" }}>
                                {/^(?=.*[0-9])/.test(formik.values.password) && <img src="/static/icons/passwordCheckStrong.svg" />}
                                {!/^(?=.*[0-9])/.test(formik.values.password) && <img src="/static/icons/passwordCheck.svg" />}
                              </span>
                              Use a minimum of one number.
                            </p>
                          </div>

                          {/* Confirm Password */}
                          <div className="mb-4">
                            <label className="form-label">Confirm Password</label>
                            <input {...getFieldProps("confirmPassword")} className="form-control form-control-lg" placeholder="Confirm Password" />
                            <span
                              className="invalid-feedback"
                              style={{ display: Boolean(touched.confirmPassword && errors.confirmPassword) ? "block" : "none" }}
                            >
                              {errors.confirmPassword}
                            </span>
                          </div>

                          {/* Errors from server */}
                          {errors.afterSubmit && <Alert value={errors.afterSubmit} variant="danger" />}

                          {/* Next Button */}
                          <div className="d-flex justify-content-end mt-3">
                            <button
                              className="btn btn-text text-secondary"
                              onClick={() => {
                                setstep2(true);
                                setstep3(false);
                              }}
                            >
                              ← back
                            </button>
                            <LoadingButton
                              type="submit"
                              loading={isSubmitting}
                              className="btn btn-lg btn-primary"
                              disabled={
                                !Boolean(touched.password) ||
                                !Boolean(touched.confirmPassword) ||
                                Boolean(errors.password) ||
                                Boolean(errors.confirmPassword)
                              }
                            >
                              Submit
                            </LoadingButton>
                          </div>
                        </>
                      )}
                    </Form>
                  </FormikProvider>
                </div>
              </div>

              <div>
                <p
                  className="body1 text-danger text-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Cancel
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;

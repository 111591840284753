import React from "react";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { sendLearningBackToTests } from "../../../redux/slices/projectSlice";

function SendBackToTestsDialog() {
  const dispatch = useDispatch();
  const params = useParams();
  const projectId = params.projectId;
  const closeDialogRef = useRef();
  const navigate = useNavigate();

  const closeDialog = () => {
    closeDialogRef.current.click();
  };

  return (
    <>
      <div>
        <div className="modal fade" id="sendBackToIdeaModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div style={{ marginBottom: "24px" }}>
                  <h2>Send Test Back To Test</h2>
                  <p>Sending back a learning to test will erase the conclusion and result data permanently, are you sure?</p>
                </div>

                <div className="hstack gap-2 d-flex justify-content-end">
                  <button type="button" class="btn btn-lg btn-outline-danger" data-bs-dismiss="modal" ref={closeDialogRef}>
                    Close
                  </button>
                  <button
                    type="submit"
                    class="btn btn-lg btn-primary"
                    onClick={() => {
                      dispatch(sendLearningBackToTests({ projectId, closeDialog, navigate }));
                    }}
                  >
                    Send Back To Test
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SendBackToTestsDialog;

import React, { useEffect, useState } from "react";
import CompareModelDialog from "./CompareModelDialog";
import CreateNewModelDialog from "./CreateNewModelDialog";
import DeleteModelDialog from "./DeleteModelDialog";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteModel, getAllModels, selectmodels, updateModels, updateselectedModel } from "../../redux/slices/modelSlice";
import { formatTime } from "../../utils/formatTime";
import { backendServerBaseURL } from "../../utils/backendServerBaseURL";
import { isTypeOwner, isRoleAdmin, isRoleMember, hasPermission_add_models } from "../../utils/permissions";


function Models() {
  const models = useSelector(selectmodels);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true); // Loading state flag

  useEffect(() => {
    dispatch(getAllModels());
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); 
  }, []);

  return (
    <div className="page-body-widthout-sidebar">
      <div className="d-flex">
        <div>
          <h1 className="mb-1">Models</h1>
          <p className="text-secondary">{models?.length} Models</p>
        </div>

        <div className="flex-fill d-flex flex-row-reverse">
          <div className="hstack gap-3" style={{ padding: 0 }}>
            {/* <button type="button" className="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#compareModelDialog">
              Compare Models
            </button> */}

            <button
              onClick={() => {
                dispatch(updateselectedModel(null));
              }}
              type="button"
              className="btn btn-primary"
              style={{ minWidth: "10rem" }}
              data-bs-toggle="modal"
              data-bs-target="#createNewModel"
              disabled={hasPermission_add_models() ? false : true}
            >
              + New Model
            </button>
          </div>
        </div>
      </div>

    

{models?.length === 0 && !isLoading ? (  
     <div className="d-flex align-items-center justify-content-center mt-5">
     <div className="vstack gap-3 text-center">
       <img
         src="/static/illustrations/no-projects-found.svg"
         alt=""
         height="200px"
       />
       <h2 className="body1 regular-weight">No models created yet</h2>

       <div>
         <button
           onClick={() => {
             dispatch(updateselectedModel(null));
           }}
           type="button"
           className="btn btn-primary"
           style={{ minWidth: "10rem" }}
           data-bs-toggle="modal"
           data-bs-target="#createNewModel"
           disabled={hasPermission_add_models() ? false : true}
         >
           Create My First Model
         </button>
       </div>
     </div>
   </div>
    )
   : (models?.length !== 0 ? 
     (
      <>
     <table className="table table-borderless mt-2 custom-table-sm" style={{cursor: "pointer"}}>
          <thead className="border-none">
            <tr>
              <td scope="col" className="text-secondary body3 regular-weight">
                Name
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Created
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Owner
              </td>
              <td
                scope="col"
                className="text-secondary body3 regular-weight"
              ></td>
            </tr>
          </thead>
          <tbody>
            {models.map((model) => {
              return (
                <tr
                  className="border bg-white custom-hover-effect"
                  onClick={() => {
                    navigate(`/models/${model._id}`);
                  }}
                >
                  <td className="body3 regular-weight align-middle">
                    {model.name}
                  </td>
                  <td className="body3 regular-weight align-middle">
                    {formatTime(model.createdAt)}
                  </td>
                  <td className="body3 regular-weight align-middle">
                    <img
                      src={`${backendServerBaseURL}/${model.creator.avatar}`}
                      alt=""
                      className="avatar3"
                      style={{ marginRight: "0.3rem" }}
                    />
                    {model.creator.firstName} {model.creator.lastName}
                  </td>
                  <td className="body3 regular-weight align-middle">
                    <div
                      class="dropdown"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      <div data-bs-toggle="dropdown">
                        <i class="bi bi-three-dots-vertical cp custom-more-icon-hover-effect" style={{ padding: "0.5rem" }}></i>
                      </div>

                      <ul className="dropdown-menu">
                        <li>
                          <a
                            className="dropdown-item body3 regular-weight"
                            onClick={() => {
                              navigate(`/models/${model._id}`);
                            }}
                          >
                            View Model
                          </a>
                        </li>
                        {/* <li>
                          <a className="dropdown-item body3 regular-weight" data-bs-toggle="modal" data-bs-target="#compareModelDialog">
                            Compare Model
                          </a>
                        </li> */}
                        {hasPermission_add_models() ? (
                          <li>
                            <a
                              className="dropdown-item body3 regular-weight"
                              data-bs-toggle="modal"
                              data-bs-target="#deleteModelDialog"
                              onClick={() => {
                                dispatch(updateselectedModel(model));
                              }}
                            >
                              Delete Model
                            </a>
                          </li>
                        ) : (
                          <li></li>
                        )}
                      </ul>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
    </>
    )
  : (
    // Optional: You can return a loading spinner or fallback UI in case the conditions above are not met.
     (
     <div>
      <table className="table table-borderless mt-2 custom-table-sm">
     <thead className="border-none">
     <div style={{ display: "flex", justifyContent: "space-between" }}>

    <tr style={{width: "100%"}}>
      <td className="text-secondary body3 regular-weight" style={{width: "90px",
                  maxWidth: "20%",}}>
        Name
      </td>
      </tr>
      <tr style={{width: "100%"}}>
      <td className="text-secondary body3 regular-weight" style={{  width: "100px",
                  maxWidth: "20%",}}>
        Created
      </td>
      </tr>
      <tr style={{width: "100%"}}>
      <td className="text-secondary body3 regular-weight" style={{ width: "100px",
                  maxWidth: "20%",}}>
        Owner
      </td>
      </tr>
    </div>
  </thead>
    <tbody style={{width: "100%"}}>
      <tr 
        style={{
          cursor: "pointer",
          marginTop: "10px",
          borderRadius: "4px",
          border: "1px solid var(--black-400, #D6D6D6)",
          background: "var(--color-gray-gray-100, #F3F4F6)"
        }}

      >
        <div className="skeleton-placeholder">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <td
                data-bs-placement="bottom"
                className="body3 regular-weight align-middle"
                style={{
                  width: "90px",
                  maxWidth: "20%",
                  borderRadius: "4px",
                  background: "#E5E7EB",  
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  borderRadius: "4px",
                  background: "#E5E7EB",  
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  borderRadius: "4px",
                  background: "#E5E7EB",                   
                  height: "5px !important",
                }}
              >
              </td>
            </div>           
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",                         
                  borderRadius: "4px",
                  background: "#E5E7EB",  
                  height: "5px !important",
                }}
              >
              </td>
            </div>
          </div>
        </div>
      </tr>
      <tr 
        style={{
          cursor: "pointer",
          marginTop: "10px",
          borderRadius: "4px",
          border: "1px solid var(--black-400, #D6D6D6)",
          background: "var(--color-gray-gray-100, #F3F4F6)"
        }}

      >
        <div className="skeleton-placeholder">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <td
                data-bs-placement="bottom"
                className="body3 regular-weight align-middle"
                style={{
                  width: "90px",
                  maxWidth: "20%",
                  borderRadius: "4px",
                  background: "#E5E7EB",  
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  borderRadius: "4px",
                  background: "#E5E7EB",  
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  borderRadius: "4px",
                  background: "#E5E7EB",                   
                  height: "5px !important",
                }}
              >
              </td>
            </div>           
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",                         
                  borderRadius: "4px",
                  background: "#E5E7EB",  
                  height: "5px !important",
                }}
              >
              </td>
            </div>
          </div>
        </div>
      </tr>
      <tr 
        style={{
          cursor: "pointer",
          marginTop: "10px",
          borderRadius: "4px",
          border: "1px solid var(--black-400, #D6D6D6)",
          background: "var(--color-gray-gray-100, #F3F4F6)"
        }}

      >
        <div className="skeleton-placeholder">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <td
                data-bs-placement="bottom"
                className="body3 regular-weight align-middle"
                style={{
                  width: "90px",
                  maxWidth: "20%",
                  borderRadius: "4px",
                  background: "#E5E7EB",  
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  borderRadius: "4px",
                  background: "#E5E7EB",  
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  borderRadius: "4px",
                  background: "#E5E7EB",                   
                  height: "5px !important",
                }}
              >
              </td>
            </div>           
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",                         
                  borderRadius: "4px",
                  background: "#E5E7EB",  
                  height: "5px !important",
                }}
              >
              </td>
            </div>
          </div>
        </div>
      </tr>
      <tr 
        style={{
          cursor: "pointer",
          marginTop: "10px",
          borderRadius: "4px",
          border: "1px solid var(--black-400, #D6D6D6)",
          background: "var(--color-gray-gray-100, #F3F4F6)"
        }}

      >
        <div className="skeleton-placeholder">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <td
                data-bs-placement="bottom"
                className="body3 regular-weight align-middle"
                style={{
                  width: "90px",
                  maxWidth: "20%",
                  borderRadius: "4px",
                  background: "#E5E7EB",  
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  borderRadius: "4px",
                  background: "#E5E7EB",  
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  borderRadius: "4px",
                  background: "#E5E7EB",                   
                  height: "5px !important",
                }}
              >
              </td>
            </div>           
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",                         
                  borderRadius: "4px",
                  background: "#E5E7EB",  
                  height: "5px !important",
                }}
              >
              </td>
            </div>
          </div>
        </div>
      </tr>
      <tr 
        style={{
          cursor: "pointer",
          marginTop: "10px",
          borderRadius: "4px",
          border: "1px solid var(--black-400, #D6D6D6)",
          background: "var(--color-gray-gray-100, #F3F4F6)"
        }}

      >
        <div className="skeleton-placeholder">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <td
                data-bs-placement="bottom"
                className="body3 regular-weight align-middle"
                style={{
                  width: "90px",
                  maxWidth: "20%",
                  borderRadius: "4px",
                  background: "#E5E7EB",  
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  borderRadius: "4px",
                  background: "#E5E7EB",  
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  borderRadius: "4px",
                  background: "#E5E7EB",                   
                  height: "5px !important",
                }}
              >
              </td>
            </div>           
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",                         
                  borderRadius: "4px",
                  background: "#E5E7EB",  
                  height: "5px !important",
                }}
              >
              </td>
            </div>
          </div>
        </div>
      </tr>
      <tr 
        style={{
          cursor: "pointer",
          marginTop: "10px",
          borderRadius: "4px",
          border: "1px solid var(--black-400, #D6D6D6)",
          background: "var(--color-gray-gray-100, #F3F4F6)"
        }}

      >
        <div className="skeleton-placeholder">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <td
                data-bs-placement="bottom"
                className="body3 regular-weight align-middle"
                style={{
                  width: "90px",
                  maxWidth: "20%",
                  borderRadius: "4px",
                  background: "#E5E7EB",  
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  borderRadius: "4px",
                  background: "#E5E7EB",  
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  borderRadius: "4px",
                  background: "#E5E7EB",                   
                  height: "5px !important",
                }}
              >
              </td>
            </div>           
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",                         
                  borderRadius: "4px",
                  background: "#E5E7EB",  
                  height: "5px !important",
                }}
              >
              </td>
            </div>
          </div>
        </div>
      </tr>
      <tr 
        style={{
          cursor: "pointer",
          marginTop: "10px",
          borderRadius: "4px",
          border: "1px solid var(--black-400, #D6D6D6)",
          background: "var(--color-gray-gray-100, #F3F4F6)"
        }}

      >
        <div className="skeleton-placeholder">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <td
                data-bs-placement="bottom"
                className="body3 regular-weight align-middle"
                style={{
                  width: "90px",
                  maxWidth: "20%",
                  borderRadius: "4px",
                  background: "#E5E7EB",  
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  borderRadius: "4px",
                  background: "#E5E7EB",  
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  borderRadius: "4px",
                  background: "#E5E7EB",                   
                  height: "5px !important",
                }}
              >
              </td>
            </div>           
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",                         
                  borderRadius: "4px",
                  background: "#E5E7EB",  
                  height: "5px !important",
                }}
              >
              </td>
            </div>
          </div>
        </div>
      </tr>
      <tr 
        style={{
          cursor: "pointer",
          marginTop: "10px",
          borderRadius: "4px",
          border: "1px solid var(--black-400, #D6D6D6)",
          background: "var(--color-gray-gray-100, #F3F4F6)"
        }}

      >
        <div className="skeleton-placeholder">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <td
                data-bs-placement="bottom"
                className="body3 regular-weight align-middle"
                style={{
                  width: "90px",
                  maxWidth: "20%",
                  borderRadius: "4px",
                  background: "#E5E7EB",  
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  borderRadius: "4px",
                  background: "#E5E7EB",  
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  borderRadius: "4px",
                  background: "#E5E7EB",                   
                  height: "5px !important",
                }}
              >
              </td>
            </div>           
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",                         
                  borderRadius: "4px",
                  background: "#E5E7EB",  
                  height: "5px !important",
                }}
              >
              </td>
            </div>
          </div>
        </div>
      </tr>
      
    </tbody>
  </table>
  </div>)
  )
  )}

      <CreateNewModelDialog />
      <DeleteModelDialog />
      <CompareModelDialog />
    </div>
  );
}

export default Models;

// <<<<<<< HEAD
import React, { useEffect,  useState } from "react";
import "../../App.css";
// import { useDispatch } from "react-redux";
import moment from "moment-timezone";
import { countries, currencies } from "country-data";
import {
  updateCompany,
  uploadFevicon,
  uploadLogo,
  deleteFevicon,
  deleteLogo,
} from "../../redux/slices/settingSlice";
// =======
// import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { updateCompany, updateProfilePicture, selectAllUsers, getAllUsers } from "../../redux/slices/settingSlice";
// >>>>>>> origin/stable
import UpdatePasswordDialog from "./UpdatePasswordDialog";
import ImageComponent from "../../components/common/ImageComponent";
import { backendServerBaseURL } from "../../utils/backendServerBaseURL";



function Company() {
  const profileData = JSON.parse(localStorage.getItem("user", ""));
  console.log('profileData :>> ', profileData);
  const [profileEditing, setprofileEditing] = useState(false);
  const [fevicon, setFevicon] = useState("");
  const [logo, setLogo] = useState("");
  const [isSwitchOn, setIsSwitchOn] = useState(false);
   const [showAlert, setShowAlert] = useState(false);
   const [showModal, setShowModal] = useState(false);

  const timezones = moment.tz.names();
  const options = timezones.map((timezone) => {
    return (
      <option value={timezone}>
        {timezone.replace("_", " ")} - {moment().tz(timezone).format("h:mm A")}
      </option>
    );
  });

  const toggleSwitch = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  const [company, setCompany] = useState("");
  const [timezone, setTimezone] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState("");
  const [domain, setDomain] = useState(``);
  const currencyValue = localStorage.getItem("currency", "");

  // const [currencyVal, setcurrencyVal] = useState("");
  const dispatch = useDispatch();

  // const team = useSelector(selectAllUsers);

  // useEffect(() => {
  //   dispatch(getAllUsers());
  // }, []);

  const handleUpdateCompany = () => {
    dispatch(
      updateCompany({
        company,
        timezone,
        address,
        address2,
        city,
        state,
        country,
        zip,
        currency,
      })
    );
    setprofileEditing(false);
  };

  useEffect(() => {
    if (profileData) {
      setCompany(profileData.company);
      setTimezone(profileData.timezone);
      setAddress(profileData.address);
      setAddress2(profileData.address2);
      setZip(profileData.zip);
      setCountry(profileData.country);
      setState(profileData.state);
      // setCurrency(profileData.currency);
      setCity(profileData.city);
      
    }
  }, []);

  let currencyVal;

  const setCurrencyOnChange = (e) => {
    localStorage.setItem("currency", e.target.value);
    const newCurrency = e.target.value;
    setCurrency(newCurrency);

  }

  const countryOptions = countries.all.map((c) => {
    return <option value={c.alpha2}>{c.name}</option>;
  });

  const currencyOptions = Object.keys(currencies).map((code) => {
    return (
      <option value={code.code}>{`${code} - ${currencies[code].name}`}</option>
    );
  });

  const handleDomain = () => {
    dispatch(updateCompany({ domain }));
 
    setIsSwitchOn(!isSwitchOn);
       setShowAlert(true);
       setTimeout(() => {
         setShowAlert(false);
         setShowModal(true);
       }, 3000);
  };

  const handleFevicon = (file) => {
    dispatch(
      uploadFevicon({
        file: file,
      })
    );
  
  };

  const handleLogo = (file) => {
    dispatch(uploadLogo({ file: file }));
    console.log('file :>> ', file);
  };

  const handleDeleteFevicon = () => {
    dispatch(deleteFevicon());
  };
  const handleDeleteLogo = () => {
    dispatch(deleteLogo());
  };

  useEffect(() => {
    if(profileData){
    setFevicon(
    
      profileData?.fevicon
        ? `${backendServerBaseURL}/${profileData?.fevicon}`
        : "/static/icons/logo.svg"
    );
    setLogo(
     
      profileData?.logo
        ? `${backendServerBaseURL}/${profileData?.logo}`
        : "/static/icons/logo.svg"
    );
    }

    console.log('logo1 :>> ', `${backendServerBaseURL}/${profileData?.logo}`);
  }, [profileData]);

  console.log('logo2 :>> ',`${backendServerBaseURL}/${profileData?.logo}`);


 function handleModalClose() {

   setShowModal(false);
   localStorage.clear()
   window.open(`http://${profileData.domain}.scalez.in`, "_self");
 }

  return (
    <div>
      {/* <div style={{fontSize: "100px"}}>Coming Soon</div> */}
      <div style={{ textAlign: "center" }}>
        {showAlert && (
          <span className="alert alert-success mt-3 text-center" role="alert">
            Subdomain updated successfully!
          </span>
        )}
      </div>

      <div className="d-flex">
        <div>
          <div>
            <div>
              <h1 className="mb-1" id="scroll">
                Company
              </h1>
              <p className="text-secondary">Company Details</p>
            </div>
          </div>
          <div>
            <h1 className="mb-1">Company Information</h1>
            <div className="d-flex" style={{ gap: "3rem" }}>
              <div>
                <p className="text-secondary">Favicon</p>
                <ImageComponent
                  image={fevicon}
                  handleImage={handleFevicon}
                  handleDelete={handleDeleteFevicon}
                />
              </div>
              <div>
                <p className="text-secondary">Logo(Icon + Text)</p>
                <ImageComponent
                  image={logo}
                  handleImage={handleLogo}
                  handleDelete={handleDeleteLogo}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex-fill d-flex flex-row-reverse">
          <div className="hstack gap-3" style={{ padding: 0 }}>
            {/* <button type="button" className="btn btn-primary" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
              + New Goal
            </button> */}
          </div>
        </div>
      </div>

      <div style={{ maxWidth: "100%" }}>
        <div className="row">
          <div class="mt-3 col-6">
            <div>
              <label className="form-label">Company Name</label>
              <input
                onChange={(e) => setCompany(e.target.value)}
                type="text"
                className="form-control"
                placeholder="Company Name"

                defaultValue={profileData?.company}

                disabled={!profileEditing}
              />
            </div>
          </div>
          <div class="mt-3 col-6">
            <div>
              <label className="form-label">Timezone</label>
        
              <select
                className="form-select"
             
                disabled={!profileEditing}
                onChange={(e) => setTimezone(e.target.value)}
                defaultValue={profileData?.timezone}
              >
                <option value="">Select Timezone</option>
                {options}
              </select>
            </div>
          </div>

          <div class="mt-3 col-6">
            <div>
              <label className="form-label">Last Name</label>
              <input
                onChange={(e) => setAddress(e.target.value)}
                type="text"
                className="form-control"
                placeholder="Address Line"
                defaultValue={profileData?.address}
                disabled={!profileEditing}
              />
            </div>
          </div>

          <div class="mt-3 col-6">
            <div>
              <label className="form-label">Email</label>
              <input
                onChange={(e) => setAddress2(e.target.value)}
                type="text"
                className="form-control"
                placeholder="Address Line 2 (Optional)"
                defaultValue={profileData?.address2}
                disabled={!profileEditing}
              />
            </div>
          </div>

          <div class="mt-3 col-6">
            <div>
              <label className="form-label">Address</label>
              <input
                // ref={addressRef}
                type="text"
                className="form-control"
                placeholder="Address"
                defaultValue={profileData?.address}
                disabled={!profileEditing}
              />
            </div>
          </div>

          <div class="mt-3 col-6">
            <div>
              <label className="form-label">Address Line 2 (Optional)</label>
              <input
                // ref={addressLine2Ref}
                type="text"
                className="form-control"
                placeholder="Address Line 2 (Optional)"
                defaultValue={profileData?.address2}
                disabled={!profileEditing}
              />
            </div>
          </div>

          <div class="mt-3 col-3">
            <div>
              <label className="form-label">City</label>
              <input
                type="text"
                onChange={(e) => setCity(e.target.value)}
                className="form-control"
                placeholder="City"
                defaultValue={profileData?.city}
                disabled={!profileEditing}
              />
            </div>
          </div>
          <div class="mt-3 col-3">
            <div>
              <label className="form-label">State</label>
              <input
                onChange={(e) => setState(e.target.value)}
                type="text"
                className="form-control"
                placeholder="State"
                defaultValue={profileData?.state}
                disabled={!profileEditing}
              />
            </div>
          </div>
          <div class="mt-3 col-3">
            <div>
              <label className="form-label">Country</label>

              <select
                className="form-select"
                name=""
                id="countries"
                disabled={!profileEditing}
                onChange={(e) => setCountry(e.target.value)}
                defaultValue={profileData?.country}
              >
                <option value="">Select Country</option>
                {countryOptions}
              </select>
            </div>
          </div>
          <div class="mt-3 col-3">
            <div>
              <label className="form-label">Zip Code</label>
              <input
                onChange={(e) => setZip(e.target.value)}
                type="text"
                className="form-control"
                placeholder="Zip Code"
                defaultValue={profileData?.zip}
                disabled={!profileEditing}
              />
            </div>
          </div>

          <div class="mt-3 col-6">
            <div>
              <label className="form-label">Set Currency</label>
      
              <select
                className="form-select"
                name=""
                id="currencies"
                disabled={!profileEditing}
                onChange={(e) => setCurrencyOnChange(e)}
                value={currencyValue} // Bind the selected value to the state
                >
                <option value="">Select Currency</option>
                {currencyOptions}
              </select>
            </div>
          </div>

          <div class="col-6" style={{ marginTop: "40px" }}>
            <div className="hstack gap-2">
              {profileEditing && (
                <>
                  <button
                    className="btn btn-primary"
                    onClick={handleUpdateCompany}
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => {
                      setprofileEditing(false);
                    }}
                  >
                    Cancel
                  </button>
                </>
              )}

              {!profileEditing && (
                <>
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => {
                      setprofileEditing(true);
                    }}
                  >
                    Edit Details
                  </button>
                </>
              )}
            </div>
          </div>

          <UpdatePasswordDialog />
        </div>
      </div>
      <hr className="line3" />

      <div style={{opacity: "50%"}}>
        <div>
          <p className="whitelabel">Customization (Coming Soon!)</p>
        </div>
        <div className="domainbox d-flex-column">
          <div>
            <p className="formlabel">Custom domain</p>
          </div>
          <div className="d-flex justify-content-between  align-items-center centerContent">
            <div>
              <p className="labelpara mb-0">
                To enable mapping of a custom domain on your portal first add
                your domain in the field & then use the values in your DNS panel
              </p>
            </div>
            <div>
              <div>
                <div>
                  <div className="form-check form-switch">
                    <input
                      style={{ width: "35px", height: "20px" }}
                      className="form-check-input"
                      type="checkbox"
                      id="customSwitch"
                      checked={isSwitchOn}
                      onChange={toggleSwitch}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2" style={{opacity: "50%"}}>
        <label className="form-label">Brand Name</label>
      </div>
      <div style={{opacity: "50%"}}>
      <div className="d-flex" style={{ gap: "10px" }}>
        <input
          className="border rounded-1 p-1"
          style={{width:"35%"}}
          type="text"
          placeholder="Enter your brand name"
          // defaultValue={profileData?.domain}
          onChange={(e) => setDomain(e.target.value)}
          disabled={!isSwitchOn}
        />
        <button
          disabled={!isSwitchOn || domain === ""}
          className="border rounded-1 btn btn-primary"
          style={{ padding: "0px 10px" }}
          onClick={handleDomain}
        >
          Add
        </button>
      </div>
      <div className="mt-1">
        <p>
          ex. Your domain will be set to{" "}
          <strong>
            {" "}
            {/* <u>brand.scalez.in</u>{" "} */}
          </strong>
        </p>
      </div>
      <div>
        <div className="d-flex mt-2">
          <label className="form-label">Type : </label>
          <p style={{ marginLeft: "5px" }}>CNAME</p>
        </div>
        <div className="d-flex">
          <label style={{ marginTop: "-10px" }} className="form-label">
            Name :{" "}
          </label>
          <p style={{ marginLeft: "5px", marginTop: "-10px" }}>
           {"-"} {/* {profileData?.domain?.split(".")[0] || "-"} */}
          </p>
        </div>
        <div className="d-flex">
          <label style={{ marginTop: "-10px" }} className="form-label">
            Target :{" "}
          </label>
          <p style={{ marginLeft: "5px", marginTop: "-10px" }}>scalez.in</p>
        </div>
      </div>
      </div>
      {showModal && (
        <div className="modal d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <h2 className="modal-title mb-2">Domain Updated</h2>
                <p>
                  Your domain updated to
                  <span style={{ color: "blue" }}> {profileData.domain}.scalez.in. </span>
                  Proceed to the new Domain.
                </p>
                <div className="d-flex justify-content-end">
                  <button
                    onClick={handleModalClose}
                    type="button"
                    className="btn btn-primary mt-3 mb-3"
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Company;

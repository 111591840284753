import React from "react";
import { useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import TourModal from "../pages/Projects/Tour/TourModal";
import {
  selectselectedTest,
  selectselectedLearning,
} from "../redux/slices/projectSlice";
import {  useSelector } from "react-redux";

function ProjectSidebar() {
  const [selectedMenu, setselectedMenu] = useState("");
  const params = useParams();
  
  const selectedTest = useSelector(selectselectedTest);
  const selectedLearning = useSelector(selectselectedLearning);

  const menus = [
    { name: "Goals", icon: "goals.svg", link: `/projects/${params.projectId}/goals` },
    { name: "Ideas", icon: "ideas.svg", link: `/projects/${params.projectId}/ideas` },
    { name: "Tests", icon: "tests.svg", link: `/projects/${params.projectId}/tests` },
    { name: "Learning", icon: "learnings.svg", link: `/projects/${params.projectId}/learnings` },
    { name: "Insights", icon: "insights.svg", link: `/projects/${params.projectId}/insights` },
  ];

  const onSelectedMenu = (name) =>{
    console.log('name :>> ', name);
    setselectedMenu(name);
    // if(name === 'Learning'){
    //   selectedTest = null;
    // }
  }

  // const appsMenus = [{ name: "Integrations", icon: "goals.svg", link: `/projects/${params.projectId}/integrations` }];

  return (
    <div className="project-sidebar border-end p-2">
      <div className="d-flex align-items-center">
        <div className="flex-fill">
          <h3 className="p-2 border-bottom pt-3 mb-2 regular-weight">Process</h3>
        </div>

        <div style={{ marginRight: "0.4rem", cursor: "pointer" }}>
          <img src="/static/images/tour/tour_icon.svg" alt="" data-bs-toggle="modal" data-bs-target="#tourModal" />
        </div>
      </div>

      {menus.map((menu) => {
        return (
          <Link
            to={menu.link}
            onClick={() => {
              onSelectedMenu(menu.name);
            }}
            // onChange={(e) => {onSelectedMenu(e.target.value)}}
            className={
              "d-flex aling-items-center p-2 border-start cp " +
              (window.location.pathname.includes(menu.name?.toLowerCase()) ? "border-primary border-5" : "border-white border-5 onhover-menu-items")
            }
            style={{ textDecoration: "none", backgroundColor: selectedMenu === menu.name ? "var(--bs-primary-light)" : "" }}
          >
            <img src={`/static/icons/${menu.icon}`} style={{ marginRight: "0.4rem", marginLeft: "0.4rem" }} />
            <p className={"m-0 " + (selectedMenu === menu.name ? "text-dark" : "text-secondary")}>{menu.name}</p>
          </Link>
        );
      })}

      {/* <h3 className="p-2 border-bottom pt-3 mb-2 regular-weight">APPS</h3> */}

      {/* {appsMenus.map((menu) => {
        return (
          <Link
            to={menu.link}
            onClick={() => {
              setselectedMenu(menu.name);
            }}
            className={
              "d-flex aling-items-center p-2 border-start cp " + (selectedMenu === menu.name ? "border-primary border-5" : "border-white border-5")
            }
            style={{ textDecoration: "none", backgroundColor: selectedMenu === menu.name ? "var(--bs-primary-light)" : "" }}
          >
            <img src={`/static/icons/${menu.icon}`} style={{ marginRight: "0.4rem", marginLeft: "0.4rem" }} />
            <p className={"m-0 " + (selectedMenu === menu.name ? "text-dark" : "text-secondary")}>{menu.name}</p>
          </Link>
        );
      })} */}
    </div>
  );
}

export default ProjectSidebar;

import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";

import { Form, FormikProvider } from "formik";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createcategory, editcategory, selectselectedCategory } from "../../redux/slices/actionPlanSlice";

function CreateNewCategoryDialog() {
  const dispatch = useDispatch();
  const closeModalRef = useRef();
  const selectedCategory = useSelector(selectselectedCategory);

  const NewProjectSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  const closeModal = () => {
    document.elementFromPoint(100, 100).click();
    closeModalRef.current.click();
  };

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validateOnBlur: true,
    validationSchema: NewProjectSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      console.log(values);
      setSubmitting(true);
      if (selectedCategory) {
        dispatch(editcategory({ ...values, closeModal }));
      } else {
        dispatch(createcategory({ ...values, closeModal }));
      }
      setSubmitting(false);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, resetForm } = formik;

  useEffect(() => {
    if (selectedCategory) {
      formik.setValues({
        name: selectedCategory.name,
      });
    } else {
      formik.setValues({
        name: "",
      });
    }
  }, [selectedCategory]);

  return (
    <>
      <div className="modal fade" id="CreateNewCategoryDialog" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered" style={{ minWidth: "45rem" }}>
          <div className="modal-content">
            <div className="modal-body">
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <h2>{selectedCategory ? "Edit category" : "Add a category"}</h2>

                  <div className="form-field">
                    <label className="form-label">Category Name</label>
                    <input type={"text"} {...getFieldProps("name")} className="form-control form-control-lg" placeholder="Enter category name" />
                    <span className="invalid-feedback" style={{ display: Boolean(touched.name && errors.name) ? "block" : "none" }}>
                      {errors.name}
                    </span>
                  </div>

                  <div className="hstack gap-2 d-flex justify-content-end">
                    <button
                      type="button"
                      class="btn btn-lg btn-outline-danger"
                      data-bs-dismiss="modal"
                      ref={closeModalRef}
                      onClick={() => {
                        document.elementFromPoint(100, 100).click();
                      }}
                    >
                      Close
                    </button>
                    <button type="submit" class="btn btn-lg btn-primary">
                      {selectedCategory ? "Update Category" : "Create Category"}
                    </button>
                  </div>
                </Form>
              </FormikProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateNewCategoryDialog;

import moment from "moment";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Mention, MentionsInput } from "react-mentions";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import AvatarGroup from "../../../components/common/AvatarGroup";
import {
  addGoalComment,
  deleteGoalComment,
  deleteKeyMetricValue,
  getProjectCollaborators,
  getProjectUsers,
  readSingleGoal,
  selectProjectCollaboratos,
  selectProjectUsers,
  selectSingleGoalInfo,
  updateGoalComment,
  updateKeyMetricStatus,
  updateSelectedGoal,
  updateSelectedIdea,
  updateSelectedKeyMetric,
  getAllIdeas,
} from "../../../redux/slices/projectSlice";
import { getAllkeyMetrics } from "../../../redux/slices/settingSlice";
import { backendServerBaseURL } from "../../../utils/backendServerBaseURL";
import { formatDate2, formatTime } from "../../../utils/formatTime";
import {
  hasPermission_create_goals,
  isTypeOwner,
  isRoleAdmin,
  isRoleMember,
  hasPermission_create_ideas,
  hasPermission_create_comments,
  hasPermission_mention_everyone,
} from "../../../utils/permissions";
import { getUsersFromTags, swapTags } from "../../../utils/tag.js";
import InviteCollaboratorsDialog from "../../Settings/InviteCollaboratorsDialog";
import CreateNewIdeaDialog from "../Ideas/CreateNewIdeaDialog";
import CreateNewGoalDialog from "./CreateNewGoalDialog";
import EditMetricValueDialog from "./EditMetricValueDialog";
import UpdateMetricDialog from "./UpdateMetricDialog";
import Spinner from "../../../components/common/Spinner";
import LoadingButton from "../../../components/common/LoadingButton";

function GoalInfo() {
  const [ideasForWeeklySales, setideasForWeeklySales] = useState([1, 2, 3]);
  const [selectedMenu, setselectedMenu] = useState("");
  const params = useParams();
  const projectId = params.projectId;
  const goalId = params.goalId;
  const dispatch = useDispatch();
  const singleGoalInfo = useSelector(selectSingleGoalInfo);
  console.log('singleGoalInfo :>> ', singleGoalInfo);
  const [ProjectsMenus, setprojectsMenus] = useState([]);
  let [selectedKeyMetric, setselectedKeyMetric] = useState(null);
  // console.log('selectedKeyMetric :>> ', selectedKeyMetric);
  const me = JSON.parse(localStorage.getItem("user", ""));
  const [comment, setcomment] = useState("");
  const [comment2, setcomment2] = useState("");
  const projectUsers = useSelector(selectProjectUsers);
  const projectCollaborators = useSelector(selectProjectCollaboratos);
  const [editingComment, seteditingComment] = useState(0);
  const openedProject = JSON.parse(localStorage.getItem("openedProject", ""));
  const [selectedTab, setselectedTab] = useState("About Goal");
  const navigate = useNavigate();
  let [selectedKeyMetricIndex, setselectedKeyMetricIndex] = useState(0);

  let options = {
    elements: {
      line: {
        tension: 0, // disables bezier curves
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          color: "#97a4af",
          usePointStyle: true,
        },
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
    scales: {
      yAxes: {
        gridLines: {
          color: "#e7eaf3",
          drawBorder: false,
          zeroLineColor: "#e7eaf3",
        },
        ticks: {
          beginAtZero: true,
          stepSize: 10,
          fontSize: 12,
          color: "#97a4af",
          fontFamily: "Open Sans, sans-serif",
          padding: 10,
          postfix: "k",
        },
      },
      xAxes: {
        grid: {
          display: false,
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          fontSize: 12,
          color: "#97a4af",
          fontFamily: "Open Sans, sans-serif",
          padding: 5,
        },
      },
    },
    tooltips: {
      mode: "index",
      intersect: false,
    },
    hover: {
      mode: "index",
      intersect: false,
    },
  };

  const [showLoader, setShowLoader] = useState(true);
  const [isSubmitting, setisSubmitting] = useState(false);

  // useEffect(() => {
  //   if (singleGoalInfo) {
  //     // setShowLoader(false); 
  //   } else {
  //     // setShowLoader(true); 
  //   }
  // }, [singleGoalInfo]);

  useEffect(() => {
    dispatch(readSingleGoal({ goalId: params.goalId }));
    console.log('params.goalId :>> ', params.goalId);
    dispatch(getProjectUsers({ projectId }));
    dispatch(getAllkeyMetrics());
    dispatch(getProjectCollaborators({ projectId }));
    setTimeout(() => {
      setShowLoader(false); 
    }, 3000); 
  }, []);

  useEffect(() => {
    if (singleGoalInfo) {
      setprojectsMenus(
        singleGoalInfo?.keymetric?.map((k) => {
          return { name: k.name, id: k._id };
        })
      );

      setselectedKeyMetric(singleGoalInfo.keymetric[selectedKeyMetricIndex]);
      let tempMenu = singleGoalInfo.keymetric[selectedKeyMetricIndex];
      setselectedMenu({ name: tempMenu?.name, id: tempMenu?._id });

      // console.log('selectedKeyMetricIndex :>> ', selectedKeyMetricIndex);
      //       console.log('setselectedKeyMetric(singleGoalInfo.keymetric[selectedKeyMetricIndex', setselectedKeyMetric(singleGoalInfo.keymetric[selectedKeyMetricIndex])
      // );
      // console.log('singleGoalInfo 11:>> ', singleGoalInfo);
      // console.log('selectedKeyMetric 11:>> ', selectedKeyMetric);
      // if(selectedKeyMetric){
      //   setselectedKeyMetric(singleGoalInfo.keymetric.filter((m) => m._id === selectedKeyMetric?._id)[0]);
      // }
      if (selectedMenu?.id === ProjectsMenus[selectedKeyMetricIndex]?.id) {
        setselectedKeyMetricIndex(0);
      }
    }
  }, [singleGoalInfo, selectedKeyMetric]);

  useEffect(() => {
    if (selectedMenu == "" && ProjectsMenus.length !== 0) {
      setselectedMenu(ProjectsMenus[0]);
    }
  }, [ProjectsMenus]);

  useEffect(() => {
    // console.log('selectedMenu ---:>> ', selectedMenu);
    if (singleGoalInfo && singleGoalInfo?.keymetric.length !== 0) {
      setselectedKeyMetric(singleGoalInfo.keymetric.filter((k) => k._id === selectedMenu.id)[0]);
    }
  }, [selectedMenu]);

  const keymetricProgressPercent = () => {
    const a = selectedKeyMetric?.metrics?.length === 0 ? 0 : selectedKeyMetric?.metrics[selectedKeyMetric?.metrics?.length - 1].value;
    const b = selectedKeyMetric?.targetValue;
    return Math.round((a / b) * 100);
  };

  return (
    <div>
      <div className="d-flex">
        {showLoader && <div
          style={{
            width: "100%",
            height: "100%",
            zIndex: "999",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            paddingRight: "300px",
            paddingBottom: "200px",
            background: "#FBFBFB"
          }}>
          <Spinner />
        </div>}
        <div className="flex-fill" style={{ marginRight: "5rem" }}>
          <div className="mb-3">
            <p className="body3 text-secondary">
              {openedProject?.name} / Goals
            </p>
            <h2>{singleGoalInfo?.name}</h2>
          </div>

          <div className="mb-3">
            <p className="body3 mb-2 text-secondary">Description</p>
            <p>{singleGoalInfo?.description}</p>
          </div>

          <hr />
          {/* Created On and Confidence meter */}
          <div className="row mt-3">
            <div className="col-6">
              <div className="border-bottom h-100">
                <p className="body3 mb-2 text-secondary">Created On</p>
                <p>{formatTime(singleGoalInfo?.createdAt)}</p>
              </div>
            </div>

            <div className="col-6 border-bottom">
              <div className="border-bottom">
                <p className="body3 mb-2 text-secondary">Confidence Meter</p>
                <div>
                  <p
                    className="rounded p-1 text-center"
                    style={{
                      maxWidth: "8rem",
                      backgroundColor: "var(--bs-success)",
                      color: "var(--bs-green)",
                    }}
                  >
                    {singleGoalInfo?.confidence}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Metric Goals */}

          <div className="row mt-3">
            <p className="body3 mb-2 text-secondary">Metric Goals</p>

            <div className="border-bottom mt-3 mb-3">
              <div className="flex-fill d-flex align-items-center">
                {ProjectsMenus.map((menu, index) => {
                  return (
                    <div
                      style={{
                        textDecoration: "none",
                        border: "solid",
                        borderTopWidth: "0px",
                        borderLeftWidth: "0px",
                        borderBottomWidth: "0px",
                        borderRightWidth: "1px",
                        borderColor: "#ebebeb",
                      }}
                      className="text-dark body3 regular-weight cp"
                      onClick={() => {
                        setselectedMenu(menu);
                        setselectedKeyMetricIndex(index);
                      }}
                    >
                      <div
                        className={
                          selectedMenu.id === menu.id
                            ? "text-center border-bottom border-primary border-3"
                            : "text-center pb-1"
                        }
                        style={{ minWidth: "7rem", padding: "0px 12px" }}
                      >
                        <p className="mb-1">{menu.name}</p>
                      </div>
                    </div>
                  );
                })}

                <div className="flex-fill ml-auto"></div>

                {/* Add a key metric */}
                <div
                  style={{ textDecoration: "none" }}
                  className="text-dark body3 regular-weight cp"
                >
                  {hasPermission_create_goals() ? (
                    <div
                      className="text-center pb-1"
                      style={{ minWidth: "7rem" }}
                    >
                      <p
                        className="mb-1 text-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#createNewGoalDialog"
                        onClick={() => {
                          setselectedTab("Key Metrics");
                          dispatch(updateSelectedGoal(singleGoalInfo));
                        }}
                      >
                        + Add a Key Metric
                      </p>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>

            {ProjectsMenus.length !== 0 && (
              <div className="row p-0 m-0">
                <div className="col-6">
                  <div className="card p-5">
                    <div>
                      <Bar
                        data={{
                          labels: selectedKeyMetric?.metrics?.map((m) =>
                            formatDate2(m.date)
                          ),
                          datasets: [
                            {
                              data: selectedKeyMetric?.metrics?.map(
                                (m) => m.value
                              ),
                              label: "Users",
                              barThickness: 30,
                              backgroundColor: "#19b351",
                              borderColor: "#19b351",
                              borderWidth: 2,
                              lineTension: 0.4,
                              pointRadius: 0,
                              hoverBorderColor: "#19b351",
                              pointBackgroundColor: "#19b351",
                              pointBorderColor: "#19b351",
                              pointHoverRadius: 0,
                            },
                          ],
                        }}
                        options={options}
                      />

                      <div>
                        <p className="mb-1 body3">
                          {keymetricProgressPercent()}%
                        </p>

                        <div
                          className="progress"
                          style={{ height: "8px", borderRadius: "12px" }}
                        >
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: `${keymetricProgressPercent()}%`,
                              backgroundColor: "#19b351",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <p className="body3 mb-1 text-secondary">Current Value</p>
                      <h2>
                        {selectedKeyMetric?.metrics?.length === 0
                          ? 0
                          : selectedKeyMetric?.metrics[
                              selectedKeyMetric?.metrics?.length - 1
                            ].value}
                      </h2>
                      <p className="body3 regular-weight text-secondary mb-3">
                        as of {formatTime(singleGoalInfo?.startDate)}
                      </p>

                      <button
                        className="btn btn-outline-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#updateMetricModal"
                        onClick={() => {
                          dispatch(updateSelectedKeyMetric(selectedKeyMetric));
                        }}
                        disabled={hasPermission_create_goals() ? false : true}
                      >
                        Update Value
                      </button>
                    </div>

                    <div className="col-6">
                      <div className="d-flex">
                        <div className="flex-fill">
                          <p className="body3 mb-1 text-secondary">
                            Target Value
                          </p>
                        </div>
                      </div>

                      <h2>{selectedKeyMetric?.targetValue}</h2>
                      <p className="body3 regular-weight text-secondary mb-3">
                        as of {formatTime(singleGoalInfo?.endDate)}
                      </p>

                      <label className="form-label">Status</label>
                      <div>
                        <select
                          class="form-select  form-control-sm"
                          style={{ maxWidth: "10rem" }}
                          value={selectedKeyMetric?.status}
                          onChange={(e) => {
                            if (hasPermission_create_goals()) {
                              dispatch(
                                updateKeyMetricStatus({
                                  status: e.target.value,
                                  keymetricId: selectedKeyMetric?._id,
                                  goalId: params.goalId,
                                })
                              );
                            }
                          }}
                        >
                          <option value="On-Track">On-Track</option>
                          <option value="Off-Track">Off-Track</option>
                          <option value="At-Risk">At-Risk</option>
                          <option value="Achieved">Achieved</option>
                        </select>
                      </div>
                    </div>

                    {singleGoalInfo.keymetric[selectedKeyMetricIndex] &&
                      selectedKeyMetric?.metrics.length !== 0 && (
                        <div className="col-12 flex-fill mt-3">
                          <table className="table table-borderless mb-0 pb-0">
                            <thead className="border-none">
                              <tr>
                                <td
                                  scope="col"
                                  className="text-secondary body3 regular-weight"
                                >
                                  Value
                                </td>
                                <td
                                  scope="col"
                                  className="text-secondary body3 regular-weight"
                                >
                                  Updated
                                </td>
                                <td
                                  scope="col"
                                  className="text-secondary body3 regular-weight"
                                >
                                  Member
                                </td>
                                <td
                                  scope="col"
                                  className="text-secondary body3 regular-weight"
                                ></td>
                              </tr>
                            </thead>

                            <tbody>
                              {singleGoalInfo.keymetric[
                                selectedKeyMetricIndex
                              ]?.metrics?.map((metricUpdate) => {
                                return (
                                  <tr className="border bg-white align-items-center"
                                  style={{verticalAlign: "text-bottom"}}
                                  >
                                    <td
                                      scope="col"
                                      className="body3 regular-weight"
                                    >
                                      {metricUpdate?.value}
                                    </td>
                                    <td
                                      scope="col"
                                      className="body3 regular-weight"
                                      // style={{ paddingLeft: "50px" }}
                                    >
                                      {formatTime(metricUpdate?.updatedAt)}
                                    </td>
                                    <td
                                      scope="col"
                                      className="body3 regular-weight"
                                      // style={{ paddingLeft: "40px" }}
                                    >
                                      <img
                                        className="avatar2"
                                        src={`${backendServerBaseURL}/${metricUpdate?.createdBy.avatar}`}
                                        alt=""
                                        style={{ marginRight: "0.3rem" }}
                                      />
                                      {metricUpdate
                                        ? [
                                            metricUpdate?.createdBy.firstName,
                                            metricUpdate?.createdBy.lastName,
                                          ].join(" ")
                                        : "-"}
                                      {/* newChange TBD */}
                                    </td>
                                    <td className="body3 regular-weight hstack gap-3">
                                      <i
                                        class="bi bi-pencil-fill text-primary cp"
                                        data-bs-toggle="modal"
                                        data-bs-target="#EditMetricValueDialog"
                                        onClick={() => {
                                          dispatch(
                                            updateSelectedKeyMetric(
                                              metricUpdate
                                            )
                                          );
                                        }}
                                      ></i>
                                      <i
                                        class="bi bi-trash2-fill text-primary cp"
                                        onClick={() => {
                                          dispatch(
                                            deleteKeyMetricValue({
                                              keymetricId: metricUpdate?._id,
                                              goalId: goalId,
                                            })
                                          );
                                        }}
                                      ></i>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>

                          <div
                            className="customScrollBarLight"
                            style={{ maxHeight: "10rem", overflowY: "scroll" }}
                          >
                            <table className="table table-borderless">
                              {/* <tbody>
                              {singleGoalInfo.keymetric[selectedKeyMetricIndex]?.metrics?.map((metricUpdate) => {
                                return (
                                  <tr className="border bg-white">
                                    <td scope="col" className="body3 regular-weight">
                                      {metricUpdate?.value}
                                    </td>
                                    <td scope="col" className="body3 regular-weight" style={{ paddingLeft: "50px" }}>
                                      {formatTime(metricUpdate?.updatedAt)}
                                    </td>
                                    <td scope="col" className="body3 regular-weight" style={{ paddingLeft: "40px" }}>
                                      <img
                                        className="avatar2"
                                        src={`${backendServerBaseURL}/${metricUpdate?.createdBy.avatar}`}
                                        alt=""
                                        style={{ marginRight: "0.3rem" }}
                                      />
                                      {metricUpdate ? [metricUpdate?.createdBy.firstName, metricUpdate?.createdBy.lastName].join(" ") : "-"}
                                      {/* newChange TBD */}
                              {/* </td> */}
                              {/* <td className="body3 regular-weight hstack gap-3">
                                      <i
                                        class="bi bi-pencil-fill text-primary cp"
                                        data-bs-toggle="modal"
                                        data-bs-target="#EditMetricValueDialog"
                                        onClick={() => {
                                          dispatch(updateSelectedKeyMetric(metricUpdate));
                                        }}
                                      ></i>
                                      <i
                                        class="bi bi-trash2-fill text-primary cp"
                                        onClick={() => {
                                          dispatch(
                                            deleteKeyMetricValue({
                                              keymetricId: metricUpdate?._id,
                                              goalId: goalId,
                                            })
                                          );
                                        }}
                                      ></i>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody> */}
                            </table>

                            {/* <div className="customScrollBarLight" style={{ maxHeight: "10rem", overflowY: "scroll" }}>
                            <table className="table table-borderless">
                              <tbody>
                                {singleGoalInfo.keymetric[selectedKeyMetricIndex]?.metrics?.map((metricUpdate) => {
                                  return (
                                    <tr className="border bg-white">
                                      <td className="body3 regular-weight">{metricUpdate?.value}</td>
                                      <td className="body3 regular-weight">{formatTime(metricUpdate?.updatedAt)}</td>
                                      <td className="body3 regular-weight">
                                        <img src="/static/icons/Atom=avatar, Name=aryan, Size=small.svg" alt="" style={{ marginRight: "0.3rem" }} />
                                      </td>
                                      <td className="body3 regular-weight hstack gap-3">
                                        <i
                                          class="bi bi-pencil-fill text-primary cp"
                                          data-bs-toggle="modal"
                                          data-bs-target="#EditMetricValueDialog"
                                          onClick={() => {
                                            dispatch(updateSelectedKeyMetric(metricUpdate));
                                          }}
                                        ></i>
                                        <i
                                          class="bi bi-trash2-fill text-primary cp"
                                          onClick={() => {
                                            dispatch(
                                              deleteKeyMetricValue({
                                                keymetricId: metricUpdate?._id,
                                                goalId: goalId,
                                              })
                                            );
                                          }}
                                        ></i>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div> */}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Ideas for weekly sales */}
          <div className="row mt-3" style={{marginLeft: "1px"}}>
            <p className="body3 mb-2 text-secondary">Ideas</p>
            <table className="table table-borderless mt-2">
              <thead className="border-none">
                <tr>
                  <td
                    scope="col"
                    className="text-secondary body3 regular-weight"
                  >
                    Name
                  </td>
                  <td
                    scope="col"
                    className="text-secondary body3 regular-weight"
                  >
                    Lever
                  </td>
                  <td
                    scope="col"
                    className="text-secondary body3 regular-weight"
                  >
                    Created By
                  </td>
                  <td
                    scope="col"
                    className="text-secondary body3 regular-weight"
                  >
                    Date
                  </td>
                  <td
                    scope="col"
                    className="text-secondary body3 regular-weight"
                  >
                    I.C.E Score
                  </td>
                </tr>
              </thead>
              <tbody>
                {singleGoalInfo?.ideas?.map((idea) => {
                  return (
                    <tr
                      className="border bg-white custom-hover-effect"
                      style={{
                        cursor: "pointer",
                        verticalAlign: "text-bottom",
                      }}
                      // style={{ verticalAlign: "text-bottom" }}
                      onClick={() => {
                        dispatch(updateSelectedIdea(idea));
                        navigate(`/projects/${projectId}/ideas/${idea._id}`);
                      }}
                    >
                      <td
                        className="body3 regular-weight"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title={idea?.name}
                        style={{ verticalAlign: "bottom" }}
                      >
                        <span
                          style={{
                            width: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "inline-block",
                          }}
                        >
                          {idea?.name}
                        </span>
                      </td>
                      <td
                        className="body3 regular-weight"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title={idea?.lever}
                        style={{ verticalAlign: "bottom" }}
                      >
                        <span
                          style={{
                            width: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "inline-block",
                          }}
                        >
                          {idea?.lever}
                        </span>
                      </td>
                      <td className="body3 regular-weight">
                        <img
                          src={`${backendServerBaseURL}/${idea.createdBy?.avatar}`}
                          className="avatar2"
                          alt=""
                          style={{ marginRight: "0.3rem" }}
                        />
                        {idea.createdBy?.firstName} {idea.createdBy?.lasstName}
                      </td>
                      <td className="body3 regular-weight">
                        {formatTime(idea?.createdAt)}
                      </td>

                      <td className="body3 regular-weight">{idea?.score}</td>
                    </tr>
                  );
                })}

                {hasPermission_create_ideas() ? (
                  <p
                    className="text-primary mt-2"
                    style={{ cursor: "pointer" }}
                    data-bs-toggle="modal"
                    data-bs-target="#createNewIdeaDialog"
                    onClick={() => {
                      dispatch(updateSelectedIdea(null));
                      dispatch(readSingleGoal({ goalId: params.goalId }));
                    }}
                  >
                    + Suggest an idea
                  </p>
                ) : (
                  <p></p>
                )}
              </tbody>
            </table>
          </div>

          {/* Tests for daily active user */}
          <div className="row mb-3" style={{marginLeft: "1px"}}>
            <p className="body3 mb-2 text-secondary">Tests</p>
            <table className="table table-borderless mt-2">
              <thead className="border-none">
                <tr>
                  <td
                    scope="col"
                    className="text-secondary body3 regular-weight"
                  >
                    Name
                  </td>
                  <td
                    scope="col"
                    className="text-secondary body3 regular-weight"
                  >
                    Tasks
                  </td>
                  <td
                    scope="col"
                    className="text-secondary body3 regular-weight"
                  >
                    Lever
                  </td>
                  <td
                    scope="col"
                    className="text-secondary body3 regular-weight"
                  >
                    Created & Assigned
                  </td>
                  <td
                    scope="col"
                    className="text-secondary body3 regular-weight"
                  >
                    Date
                  </td>
                  <td
                    scope="col"
                    className="text-secondary body3 regular-weight"
                  >
                    I.C.E Score
                  </td>
                </tr>
              </thead>
              <tbody>
                {singleGoalInfo?.tests?.map((test) => {
                  // console.log("singleGoalInfo --", singleGoalInfo )
                  return (
                    <tr
                      className="border bg-white custom-hover-effect"
                      style={{
                        cursor: "pointer",
                        verticalAlign: "middle",
                      }}
                      onClick={() => {
                        navigate(`/projects/${projectId}/tests/${test._id}`);
                      }}
                    >
                      <td
                        className="body3 regular-weight"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title={test.name}
                      >
                        <span
                          style={{
                            width: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "inline-block",
                          }}
                        >
                          {test.name}
                        </span>
                      </td>
                      <td className="body3 regular-weight">
                        {test.tasks.filter((t) => t.status === true).length} /{" "}
                        {test.tasks.length}
                      </td>
                      <td
                        className="body3 regular-weight"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title={test.lever}
                      >
                        <span
                          style={{
                            width: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "inline-block",
                          }}
                        >
                          {test.lever}
                        </span>
                      </td>
                      <td
                        className="body3 regular-weight d-flex align-items-center"
                        // style={{ paddingLeft: "50px" }}
                      >
                        {/* console.log("") */}
                        <img
                          src={`${backendServerBaseURL}/${test.createdBy.avatar}`}
                          className="avatar2"
                          alt=""
                          // style={{ marginRight: "0.3rem" }}
                        />
                        <AvatarGroup
                          listOfUrls={test.assignedTo.map(
                            (mem) => `${backendServerBaseURL}/${mem?.avatar}`
                          )}
                          userName={test.assignedTo.map((t) => [
                            t?.firstName,
                            `${backendServerBaseURL}/${t?.avatar}`,
                            t?.lastName,
                          ])}
                          show={3}
                          total={test.assignedTo.length}
                        />
                      </td>
                      <td className="body3 regular-weight">
                        {formatTime(test.createdAt)}
                      </td>
                      {/* <td className="body3 regular-weight">{formatTime(test.createdAt)}</td> */}

                      <td className="body3 regular-weight">{test.score}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="row">
            <hr />

            {hasPermission_create_comments() && 
            <div className="border rounded ps-3 pe-3 pb-2 bg-white mb-2" style={{marginLeft: "12px"}}>
              <div className="hstack gap-3">
                <img
                  src={`${backendServerBaseURL}/${me.avatar}`}
                  className="avatar2"
                  alt=""
                />

                <MentionsInput
                  className="mentions w-100"
                  value={comment}
                  placeholder="Comment Here"
                  onChange={(e) => {
                    // console.log("A");
                    // console.log(e.target.value);
                    // console.log(getUsersFromTags(e.target.value));
                    setcomment(e.target.value);
                  }}
                >
                  <Mention
                    className="mentions__mention"
                    markup="@{{__type__||__id__||__display__}}"
                    trigger="@"
                    renderSuggestion={(
                      entry,
                      search,
                      highlightedDisplay,
                      index,
                      focused
                    ) => {
                      return (
                        <div className="p-1 border-bottom d-flex align-items-center">
                          <img
                            src={`${backendServerBaseURL}/${projectUsers[index].avatar}`}
                            className="avatar2"
                            alt=""
                            style={{ marginRight: "0.3rem" }}
                          />
                          <p className="mb-0">
                            {projectUsers[index].firstName}{" "}
                            {projectUsers[index].lastName}
                          </p>
                        </div>
                      );
                    }}
                    data={projectUsers?.map((pu) => {
                      return {
                        id: pu._id,
                        display: `@${pu.firstName} ${pu.lastName}`,
                      };
                    })}
                  />
                </MentionsInput>
              </div>

              <div className="d-flex justify-content-end">
                <LoadingButton
                  loading={isSubmitting}
                  className="btn btn-lg btn-primary"
                  type="submit"   
                  onClick={async () => {
                    setisSubmitting(true);
                   await dispatch(addGoalComment({ goalId, comment }));
                    setcomment("");
                    setTimeout(() => {
                      setisSubmitting(false);
                    }, 200); 
                  }}
                  // TODO: Permission
                  // style={comment.length > 0 ? {cursor: "pointer"} : {cursor: "not-allowed"}}
                  disabled={
                    comment.length > 0 && hasPermission_create_comments()
                      ? false
                      : true
                  }
                >
                  Comment
                </LoadingButton>
              </div>
            </div>}

            {/* Saved Comments */}
            {hasPermission_create_comments() && 
             <div className="bg-white border rounded">
              {singleGoalInfo?.comments?.map((c) => {
                return (
                  <div className="hstack gap-2 p-3 border-bottom">
                    <div>
                      <img
                        src={`${backendServerBaseURL}/${c.createdBy.avatar}`}
                        className="avatar2"
                        alt=""
                        style={{ marginRight: "0.3rem" }}
                      />
                    </div>
                    {editingComment !== c._id && (
                      <div className="w-100">
                        <div className="d-flex">
                          <p className="body2 mb-0">{`${c.createdBy.firstName} ${c.createdBy.lastName}`}</p>
                          <div className="flex-fill"></div>
                          <p className="mb-0 pe-2 text-secondary">
                            {moment(new Date(c.createdAt)).fromNow()}
                          </p>
                          {hasPermission_create_comments() ? (
                            <div class="dropdown">
                              <div
                                data-bs-toggle="dropdown"
                                style={{ cursor: "pointer" }}
                              >
                                <i class="bi bi-three-dots-vertical cp text-secondary custom-more-icon-hover-effect" style={{ padding: "0.5rem" }}></i>
                              </div>
                              <ul className="dropdown-menu">
                                <li className="border-bottom">
                                  <a
                                    className="dropdown-item body3 regular-weight"
                                    onClick={() => {
                                      seteditingComment(c._id);
                                      setcomment2(c.comment);
                                    }}
                                  >
                                    Edit Comment
                                  </a>
                                </li>
                                <li className="border-bottom">
                                  <a
                                    className="dropdown-item body3 regular-weight"
                                    onClick={() => {
                                      dispatch(
                                        deleteGoalComment({
                                          goalId,
                                          commentId: c._id,
                                        })
                                      );
                                    }}
                                  >
                                    Delete Comment
                                  </a>
                                </li>
                              </ul>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>

                        <div className="d-flex">
                          {c.comment && swapTags(c.comment)}
                        </div>
                      </div>
                    )}

                    {editingComment === c._id && (
                      <div className="w-100">
                        <MentionsInput
                          className="mentions w-100"
                          value={comment2}
                          placeholder="Comment Here"
                          onChange={(e) => {
                            // console.log(e.target.value);
                            setcomment2(e.target.value);
                          }}
                        >
                          <Mention
                            className="mentions__mention"
                            markup="@{{__type__||__id__||__display__}}"
                            trigger="@"
                            renderSuggestion={(
                              entry,
                              search,
                              highlightedDisplay,
                              index,
                              focused
                            ) => {
                              return (
                                <div className="p-1 border-bottom d-flex align-items-center">
                                  <img
                                    src={`${backendServerBaseURL}/${projectUsers[index].avatar}`}
                                    className="avatar2"
                                    alt=""
                                    style={{ marginRight: "0.3rem" }}
                                  />
                                  <p className="mb-0">
                                    {projectUsers[index].firstName}{" "}
                                    {projectUsers[index].lastName}
                                  </p>
                                </div>
                              );
                            }}
                            data={projectUsers?.map((pu) => {
                              return {
                                id: pu._id,
                                display: `@${pu.firstName} ${pu.lastName}`,
                              };
                            })}
                          />
                        </MentionsInput>

                        <div className="d-flex justify-content-end">
                          <button
                            className="btn btn-outline-danger"
                            style={{ marginRight: "0.3rem" }}
                            onClick={() => {
                              seteditingComment(0);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              const commentData = c;
                              dispatch(
                                updateGoalComment({
                                  commentId: commentData._id,
                                  comment: comment2,
                                  goalId,
                                })
                              );
                              seteditingComment(0);
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>}
          </div>
        </div>

        {/* Sidebar */}
        <div
          className="border-start p-3"
          style={{ minWidth: "15rem", maxHeight: "85vh" }}
        >
          {hasPermission_create_goals() ? (
            <div className="mb-4">
              <button
                className="btn btn-outline-primary w-100"
                data-bs-toggle="modal"
                data-bs-target="#createNewGoalDialog"
                onClick={() => {
                  setselectedTab("About Goal");
                  dispatch(updateSelectedGoal(singleGoalInfo));
                }}
                disabled={hasPermission_create_goals() ? false : true}
              >
                <i
                  class="bi bi-pencil-fill text-primary"
                  style={{ marginRight: "0.5rem" }}
                ></i>{" "}
                Edit Goal
              </button>
            </div>
          ) : (
            <div></div>
          )}

          <div className="mb-4">
            <div className="d-flex">
              <div className="flex-fill">
                <p>MEMBERS</p>
              </div>
            </div>

            {projectUsers?.length === 0 && (
              <div className="d-flex align-items-center justify-content-center flex-column pt-3 pb-3">
                <img src="/static/illustrations/invite-users.svg" alt="" />
                <p className="body1 text-center regular-weight text-secondary mt-3">
                  Invite users, clients
                  <br /> to get feedback
                </p>
              </div>
            )}

            {singleGoalInfo?.length !== 0 &&
              singleGoalInfo?.members?.map((member) => {
                return (
                  <div className="border-bottom d-flex align-items-center p-1">
                    <img
                      src={`${backendServerBaseURL}/${member.avatar}`}
                      className="avatar2"
                      alt=""
                      style={{ marginRight: "0.5rem" }}
                    />

                    <div>
                      <p className="mb-0">
                        {member.firstName} {member.lastName}
                      </p>
                      <p className="mb-0">{member?.role?.name}</p>
                    </div>
                  </div>
                );
              })}
          </div>

          {/* <div className="mb-4">
            <div className="d-flex">
              <div className="flex-fill">
                <p>COLLABORATORS</p>
              </div>

              <div data-bs-toggle="modal" data-bs-target="#inviteCollaborators" style={{ cursor: "pointer" }}>
                <p>+</p>
              </div>
            </div>

            {projectCollaborators?.filter((member) => member.status !== "invited").length === 0 && (
              <div className="d-flex align-items-center justify-content-center flex-column pt-3 pb-3">
                <img src="/static/illustrations/invite-users.svg" alt="" />
                <p className="body1 text-center regular-weight text-secondary mt-3">
                  Invite users, clients
                  <br /> to get feedback
                </p>
              </div>
            )}

            {projectCollaborators?.length !== 0 &&
              projectCollaborators
                ?.filter((member) => member.status !== "invited")
                ?.map((member) => {
                  return (
                    <div className="border-bottom d-flex align-items-center p-1">
                      <img src={`${backendServerBaseURL}/${member.avatar}`} className="avatar2" alt="" style={{ marginRight: "0.5rem" }} />

                      <div>
                        <p className="mb-0">
                          {member.firstName} {member.lastName}
                        </p>
                        <p className="mb-0">{member?.role?.name}</p>
                      </div>
                    </div>
                  );
                })}
          </div> */}
        </div>
      </div>

      {/* Update Metric Dialog */}
      <UpdateMetricDialog />
      <CreateNewGoalDialog
        openRequestIdeaDialog={() => {}}
        selectedTab={selectedTab}
      />
      <InviteCollaboratorsDialog />
      <CreateNewIdeaDialog selectedGoal={singleGoalInfo} />
      <EditMetricValueDialog />
    </div>
  );
}

export default GoalInfo;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getAllCollaborators, getAllUsers, selectAllCollaborators, selectAllUsers, updateNotifications } from "../../redux/slices/settingSlice";

function Notifications() {
  const [selectedMenu, setselectedMenu] = useState("Team");
  const ideas = [1, 2, 3];
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params.projectId;
  const collaborators = useSelector(selectAllCollaborators);
  const team = useSelector(selectAllUsers);
  const dispatch = useDispatch();

  const ProjectsMenus = [];

  const RightProjectsMenus = [];

  const [allChecked, setallChecked] = useState(true);

  const [acceptInvitation, setacceptInvitation] = useState(true);
  const [invitation_2days, setinvitation_2days] = useState(true);
  const [team_activated, setteam_activated] = useState(true);
  const [workspace_created, setworkspace_created] = useState(true);

  const [goal_created, setgoal_created] = useState(true);

  const [idea_created, setidea_created] = useState(true);
  const [idea_updated, setidea_updated] = useState(true);
  const [idea_deleted, setidea_deleted] = useState(true);
  const [idea_sent_to_test, setidea_sent_to_test] = useState(true);
  const [idea_nominated, setidea_nominated] = useState(true);

  const [test_sent_to_idea, settest_sent_to_idea] = useState(true);
  const [test_ready, settest_ready] = useState(true);
  const [test_sent_to_learning, settest_sent_to_learning] = useState(true);

  const [learning_created, setlearning_created] = useState(true);
  const [learning_sent_to_test, setlearning_sent_to_test] = useState(true);

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getAllCollaborators());
  }, [selectedMenu]);

  const callUpdateNotifications = () => {
    setTimeout(() => {
      dispatch(
        updateNotifications({
          acceptInvitation,
          invitation_2days,
          team_activated,
          workspace_created,

          goal_created,

          idea_created,
          idea_updated,
          idea_deleted,
          idea_sent_to_test,
          idea_nominated,

          test_sent_to_idea,
          test_ready,
          test_sent_to_learning,

          learning_created,
          learning_sent_to_test,
        })
      );
    }, 500);
  };

  return (
    <div>
      <div className="d-flex">
        <div>
          <h1 className="mb-1">Notifications</h1>
          <p className="text-secondary">Manage your notifications</p>
        </div>

        <div className="flex-fill d-flex flex-row-reverse">
          <div className="hstack gap-3" style={{ padding: 0 }}></div>
        </div>
      </div>

      <div className="border-bottom mt-3">
        <div className="flex-fill d-flex align-items-center">
          {ProjectsMenus.map((menu) => {
            return (
              <div
                style={{ textDecoration: "none" }}
                className="text-dark body3 regular-weight cp"
                onClick={() => {
                  setselectedMenu(menu.name);
                }}
              >
                <div
                  className={selectedMenu === menu.name ? "text-center border-bottom border-primary border-3" : "text-center pb-1"}
                  style={{ minWidth: "7rem" }}
                >
                  <p className="mb-1">{menu.name}</p>
                </div>
              </div>
            );
          })}

          <div className="flex-fill ml-auto"></div>

          {RightProjectsMenus.map((menu) => {
            return (
              <div
                style={{ textDecoration: "none" }}
                className="text-dark body3 regular-weight cp"
                onClick={() => {
                  setselectedMenu(menu.name);
                }}
              >
                <div
                  className={selectedMenu === menu.name ? "text-center border-bottom border-primary border-3" : "text-center pb-1"}
                  style={{ minWidth: "7rem" }}
                >
                  <p className="mb-1">{menu.name}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* All Notifications */}
      <div className="border  d-flex align-items-center p-2 ps-3 pe-3 bg-white">
        <div className="flex-fill">
          <p className="mb-1 body1">All notifications</p>
          <p className="mb-0 text-secondary">Enable/Disable all notifications</p>
        </div>

        <div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              checked={allChecked}
              onChange={(e) => {
                setacceptInvitation(e.target.checked);
                setinvitation_2days(e.target.checked);
                setteam_activated(e.target.checked);
                setworkspace_created(e.target.checked);

                setgoal_created(e.target.checked);

                setidea_created(e.target.checked);
                setidea_updated(e.target.checked);
                setidea_deleted(e.target.checked);
                setidea_sent_to_test(e.target.checked);
                setidea_nominated(e.target.checked);

                settest_sent_to_idea(e.target.checked);
                settest_ready(e.target.checked);
                settest_sent_to_learning(e.target.checked);

                setlearning_created(e.target.checked);
                setlearning_sent_to_test(e.target.checked);

                setallChecked(e.target.checked);
                callUpdateNotifications();
              }}
              style={{ transform: "scale(1.5)" }}
            />
          </div>
        </div>
      </div>

      {/* General */}
      <div className="pt-3 pb-2">
        <label htmlFor="">General</label>
      </div>

      <div className="border  d-flex align-items-center p-2 ps-3 pe-3 bg-white">
        <div className="flex-fill">
          <p className="mb-1 body2">When someone accepts my invitaion</p>
        </div>

        <div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              checked={acceptInvitation}
              onChange={(e) => {
                setacceptInvitation(e.target.checked);
                callUpdateNotifications();
              }}
              style={{ transform: "scale(1.5)" }}
            />
          </div>
        </div>
      </div>

      <div className="border  d-flex align-items-center p-2 ps-3 pe-3 bg-white">
        <div className="flex-fill">
          <p className="mb-1 body2">If someone doesnt accept invitation within 2 days</p>
        </div>

        <div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              checked={invitation_2days}
              onChange={(e) => {
                setinvitation_2days(e.target.checked);
                callUpdateNotifications();
              }}
              style={{ transform: "scale(1.5)" }}
            />
          </div>
        </div>
      </div>

      <div className="border  d-flex align-items-center p-2 ps-3 pe-3 bg-white">
        <div className="flex-fill">
          <p className="mb-1 body2">When a team member is activated/disabled</p>
        </div>

        <div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              checked={team_activated}
              onChange={(e) => {
                setteam_activated(e.target.checked);
                callUpdateNotifications();
              }}
              style={{ transform: "scale(1.5)" }}
            />
          </div>
        </div>
      </div>

      <div className="border  d-flex align-items-center p-2 ps-3 pe-3 bg-white">
        <div className="flex-fill">
          <p className="mb-1 body2">When a new workspace is created</p>
        </div>

        <div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              checked={workspace_created}
              onChange={(e) => {
                setworkspace_created(e.target.checked);
                callUpdateNotifications();
              }}
              style={{ transform: "scale(1.5)" }}
            />
          </div>
        </div>
      </div>

      {/* Goals */}
      <div className="pt-3 pb-2">
        <label htmlFor="">Goals</label>
      </div>

      <div className="border  d-flex align-items-center p-2 ps-3 pe-3 bg-white">
        <div className="flex-fill">
          <p className="mb-1 body2">When a goal is created</p>
        </div>

        <div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              checked={goal_created}
              onChange={(e) => {
                setgoal_created(e.target.checked);
                callUpdateNotifications();
              }}
              style={{ transform: "scale(1.5)" }}
            />
          </div>
        </div>
      </div>

      {/* Ideas */}
      <div className="pt-3 pb-2">
        <label htmlFor="">Ideas</label>
      </div>

      <div className="border  d-flex align-items-center p-2 ps-3 pe-3 bg-white">
        <div className="flex-fill">
          <p className="mb-1 body2">When a team member adds an idea</p>
        </div>

        <div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              checked={idea_created}
              onChange={(e) => {
                setidea_created(e.target.checked);
                callUpdateNotifications();
              }}
              style={{ transform: "scale(1.5)" }}
            />
          </div>
        </div>
      </div>

      <div className="border  d-flex align-items-center p-2 ps-3 pe-3 bg-white">
        <div className="flex-fill">
          <p className="mb-1 body2">When an idea is updated</p>
        </div>

        <div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              checked={idea_updated}
              onChange={(e) => {
                setidea_updated(e.target.checked);
                callUpdateNotifications();
              }}
              style={{ transform: "scale(1.5)" }}
            />
          </div>
        </div>
      </div>

      <div className="border  d-flex align-items-center p-2 ps-3 pe-3 bg-white">
        <div className="flex-fill">
          <p className="mb-1 body2">When an idea is deleted</p>
        </div>

        <div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              checked={idea_deleted}
              onChange={(e) => {
                setidea_deleted(e.target.checked);
                callUpdateNotifications();
              }}
              style={{ transform: "scale(1.5)" }}
            />
          </div>
        </div>
      </div>

      <div className="border  d-flex align-items-center p-2 ps-3 pe-3 bg-white">
        <div className="flex-fill">
          <p className="mb-1 body2">When an idea is sent to test</p>
        </div>

        <div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              checked={idea_sent_to_test}
              onChange={(e) => {
                setidea_sent_to_test(e.target.checked);
                callUpdateNotifications();
              }}
              style={{ transform: "scale(1.5)" }}
            />
          </div>
        </div>
      </div>

      <div className="border  d-flex align-items-center p-2 ps-3 pe-3 bg-white">
        <div className="flex-fill">
          <p className="mb-1 body2">When my idea is nominated by someone</p>
        </div>

        <div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              checked={idea_nominated}
              onChange={(e) => {
                setidea_nominated(e.target.checked);
                callUpdateNotifications();
              }}
              style={{ transform: "scale(1.5)" }}
            />
          </div>
        </div>
      </div>

      {/* Tests */}
      <div className="pt-3 pb-2">
        <label htmlFor="">Tests</label>
      </div>

      <div className="border  d-flex align-items-center p-2 ps-3 pe-3 bg-white">
        <div className="flex-fill">
          <p className="mb-1 body2">When a test is sent back to ideas</p>
        </div>

        <div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              checked={test_sent_to_idea}
              onChange={(e) => {
                settest_sent_to_idea(e.target.checked);
                callUpdateNotifications();
              }}
              style={{ transform: "scale(1.5)" }}
            />
          </div>
        </div>
      </div>

      <div className="border  d-flex align-items-center p-2 ps-3 pe-3 bg-white">
        <div className="flex-fill">
          <p className="mb-1 body2">When a test is ready to analyze</p>
        </div>

        <div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              checked={test_ready}
              onChange={(e) => {
                settest_ready(e.target.checked);
                callUpdateNotifications();
              }}
              style={{ transform: "scale(1.5)" }}
            />
          </div>
        </div>
      </div>

      <div className="border  d-flex align-items-center p-2 ps-3 pe-3 bg-white">
        <div className="flex-fill">
          <p className="mb-1 body2">When a test is sent to learnings</p>
        </div>

        <div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              checked={test_sent_to_learning}
              onChange={(e) => {
                settest_sent_to_learning(e.target.checked);
                callUpdateNotifications();
              }}
              style={{ transform: "scale(1.5)" }}
            />
          </div>
        </div>
      </div>

      {/* Learnings */}
      <div className="pt-3 pb-2">
        <label htmlFor="">Learnings</label>
      </div>

      <div className="border  d-flex align-items-center p-2 ps-3 pe-3 bg-white">
        <div className="flex-fill">
          <p className="mb-1 body2">When a learning is created</p>
        </div>

        <div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              checked={learning_created}
              onChange={(e) => {
                setlearning_created(e.target.checked);
                callUpdateNotifications();
              }}
              style={{ transform: "scale(1.5)" }}
            />
          </div>
        </div>
      </div>

      <div className="border  d-flex align-items-center p-2 ps-3 pe-3 bg-white">
        <div className="flex-fill">
          <p className="mb-1 body2">When a learning is sent back to tests</p>
        </div>

        <div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              checked={learning_sent_to_test}
              onChange={(e) => {
                setlearning_sent_to_test(e.target.checked);
                callUpdateNotifications();
              }}
              style={{ transform: "scale(1.5)" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notifications;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  appendNotification,
  markRead,
  readNotifications,
  selectnotifications,
} from "../redux/slices/dashboardSlice";
import {
  getAllProjects,
  getAllUsers,
  selectProjects,
} from "../redux/slices/projectSlice";
import { selectAllUsers } from "../redux/slices/settingSlice";
import { backendServerBaseURL } from "../utils/backendServerBaseURL";
import { formatTime } from "../utils/formatTime";

function Toolbar({ socket }) {
  const [selectedMenu, setselectedMenu] = useState("");
  const profileData = JSON.parse(localStorage.getItem("user", ""));
  const [icon, setIcon] = useState(
    profileData?.logo
      ? `${backendServerBaseURL}/${profileData?.logo}`
      : "/static/icons/logo.svg"
  );
  const navigate = useNavigate();
  const me = JSON.parse(localStorage.getItem("user", ""));
  const dispatch = useDispatch();
  const notifications = useSelector(selectnotifications);
  const location = useLocation();

  const projects = useSelector(selectProjects);
  const team = useSelector(selectAllUsers);
  // console.log('profileData TB :>> ', profileData);
  // console.log('team TB:>> ', team);
  //  alert(me._id);

  if (me?._id && me?.role != "owner" && team.length != 0) {
    let res = team.filter((x) => x._id == me._id && x.role != "owner");
    // console.log('res :>> ', res);

    // let res2 = team.filter(x => x._id == me._id && x.role != "owner");

    if (res.length == 0) {
      localStorage.clear();
      navigate("/");
    }
  }

  useEffect(() => {
    dispatch(getAllProjects());
    dispatch(getAllUsers());
  }, []);

  const completedQuickStartGuide = () => {
    return (
      (projects?.length != 0 ? 1 : 0) +
      (team?.length != 0 ? 1 : 0) +
      (!profileData?.firstName && !profileData?.lastName && !profileData?.email
        ? 0
        : 1)
    );
  };

  const menus = [
    {
      name: "Dashboard",
      link: "/dashboard",
    },
    {
      name: "Project",
      link: "/projects",
    },
    {
      name: "Models",
      link: "/models",
    },
    {
      name: "Funnels",
      link: "/funnel/0",
    },
    {
      name: "Analytics",
      link: "/analytics",
    },
  ];
  const ProjectsMenus = [
    {
      name: "All Notifications",
    },
    {
      name: "Mentioned",
    },
    {
      name: "Assigned",
    },
  ];

  useEffect(() => {
    dispatch(readNotifications());

    socket?.on("notification", (payload) => {
      console.log("Received notification");
      dispatch(appendNotification(payload));
    });

    return () => {
      socket?.off("notification");
    };
  }, []);

  useEffect(() => {
    if (location.pathname.includes("dashboard")) {
      setselectedMenu("Dashboard");
    }

    if (location.pathname.includes("projects")) {
      setselectedMenu("Project");
    }

    if (location.pathname.includes("models")) {
      setselectedMenu("Models");
    }

    if (location.pathname.includes("analytics")) {
      setselectedMenu("Analytics");
    }
  }, [location.pathname]);

  useEffect(() => {
    const profileData = JSON.parse(localStorage.getItem("user", ""));
    if (profileData.logo) {
      setIcon(
        profileData?.logo
          ? `${backendServerBaseURL}/${profileData?.logo}`
          : "/static/icons/logo.svg"
      );
    }
  }, []);
  // console.log(icon)
  return (
    <div
      className="toolbar border-bottom d-flex align-items-center"
      style={{ zIndex: 999 }}
    >
      <img
        className="rounded"
        src={icon}
        width="35px"
        alt=""
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/dashboard");
        }}
      />

      <div className="flex-fill d-flex justify-content-center align-items-center">
        {menus.map((menu) => {
          return (
            <Link
              to={menu.link}
              style={{ textDecoration: "none" }}
              className="text-dark body2"
              onClick={() => {
                setselectedMenu(menu.name);
              }}
            >
              <div
                className={
                  selectedMenu === menu.name
                    ? "text-center border-bottom border-primary border-3 d-flex align-items-center justify-content-center"
                    : "text-center pb-1 d-flex align-items-center justify-content-center on-hover-header-menu-items"
                }
                style={{ minWidth: "7rem", minHeight: "62px" }}
              >
                <p className="mb-1">{menu.name}</p>
              </div>
            </Link>
          );
        })}
      </div>

      <div className="hstack">
        {projects && completedQuickStartGuide() != 3 ? (
          <>
            {completedQuickStartGuide() == 1 && (
              <img
                // src="/static/p20.svg"
                alt=""
                style={{ marginRight: "2rem", cursor: "pointer" }}
                href="#offcanvasExample"
                onClick={() => {
                  navigate("/quick-start");
                  setselectedMenu("quick-start");
                }}
              />
            )}

            {completedQuickStartGuide() == 2 && (
              <img
                // src="/static/p60.svg"
                alt=""
                style={{ marginRight: "2rem", cursor: "pointer" }}
                href="#offcanvasExample"
                onClick={() => {
                  navigate("/quick-start");
                  setselectedMenu("quick-start");
                }}
              />
            )}

            {completedQuickStartGuide() == 3 && (
              <img
                src="/static/p100.svg"
                alt=""
                style={{ marginRight: "2rem", cursor: "pointer" }}
                href="#offcanvasExample"
                onClick={() => {
                  navigate("/quick-start");
                  setselectedMenu("quick-start");
                }}
              />
            )}
          </>
        ) : (
          <img
            // src="/static/p20.svg"
            alt=""
            style={{ marginRight: "2rem", cursor: "pointer" }}
            href="#offcanvasExample"
            onClick={() => {
              navigate("/quick-start");
              setselectedMenu("quick-start");
            }}
          />
        )}

        {notifications &&
        notifications?.filter((noti) => {
          return noti?.audience?.includes(me?.id);
        }).length !== 0 ? (
          <img
            src="/static/icons/highlighted_bell.svg"
            alt=""
            style={{ marginRight: "2rem" }}
            data-bs-toggle="offcanvas"
            href="#offcanvasExample"
            role="button"
            aria-controls="offcanvasExample"
          />
        ) : (
          <img
            src="/static/icons/bell.svg"
            alt=""
            style={{ marginRight: "2rem" }}
            data-bs-toggle="offcanvas"
            href="#offcanvasExample"
            role="button"
            aria-controls="offcanvasExample"
          />
        )}

        <div class="dropdown">
          <img
            src={`${backendServerBaseURL}/${me?.avatar}`}
            alt=""
            className="avatar2"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />

          <ul
            class="dropdown-menu"
            aria-labelledby="dropdownMenuButton1"
            style={{ minWidth: "15rem" }}
          >
            <div
              className="d-flex"
              style={{ padding: "0.5rem", cursor: "pointer" }}
              onClick={() => {
                navigate("/settings");
              }}
            >
              <div style={{ marginRight: "0.45rem" }}>
                <img
                  src={`${backendServerBaseURL}/${me?.avatar}`}
                  alt=""
                  style={{
                    maxWidth: "2.5rem",
                    mimWidth: "2.5rem",
                    minHeight: "2.5rem",
                    maxHeight: "2.5rem",
                    borderRadius: "50%",
                  }}
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                />
              </div>

              <div>
                <h6 className="body2">
                  {me?.firstName} {me?.lastName}
                </h6>
                <p className="text-secondary body3">{me?.email}</p>
              </div>
            </div>
            <li className="border p-0 m-2 rounded">
              <a
                class="dropdown-item text-center"
                onClick={() => {
                  navigate("/settings");
                }}
              >
                Settings
              </a>
            </li>
            <li className="border p-0 m-2 rounded">
              <a
                class="dropdown-item  text-center"
                onClick={() => {
                  navigate("/action-plans");
                }}
              >
                Action Plans
              </a>
            </li>{" "}
            <li className="border p-0 m-2 rounded">
              <a
                class="dropdown-item text-danger text-center"
                onClick={() => {
                  localStorage.clear();
                  navigate("/");
                }}
              >
                Log Out
              </a>
            </li>
          </ul>
        </div>

        {/* Notifications */}
        <div>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasExample"
          >
            <div className="offcanvas-header">
              <div className="d-flex align-items-center mt-3">
                <img
                  data-bs-dismiss="offcanvas"
                  src="/static/icons/back_arrow.svg"
                  alt=""
                  style={{
                    marginRight: "1rem",
                    transform: "rotate(180deg)",
                    cursor: "pointer",
                  }}
                />
                <h3>Notifications</h3>
              </div>
            </div>

            <div className="offcanvas-body">
              <div className="mt-3">
                <div className="flex-fill d-flex align-items-center">
                  {ProjectsMenus.map((menu) => {
                    return (
                      <div
                        style={{ textDecoration: "none" }}
                        className="text-dark body3 regular-weight cp"
                        onClick={() => {
                          setselectedMenu(menu.name);
                        }}
                      >
                        <div
                          className={
                            selectedMenu === menu.name
                              ? "text-center border-bottom border-primary border-3"
                              : "text-center pb-1"
                          }
                          style={{ marginRight: "1rem", marginLeft: "1rem" }}
                        >
                          <p className="mb-1">{menu.name}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              {notifications?.length !== 0 &&
                notifications
                  .filter((noti) => {
                    if (selectedMenu == "Mentioned")
                      return noti.type === "Mentioned";
                    if (selectedMenu == "Assigned")
                      return noti.type === "Assigned";
                    return true;
                  })
                  .map((notification) => {
                    return notification?.audience?.includes(me?.id) ? (
                      <div className="border-bottom d-flex align-items-center p-2 mt-2">
                        <div>
                          <img
                            src={`${backendServerBaseURL}/${me.avatar}`}
                            className="avatar2"
                            alt=""
                            style={{ marginRight: "0.5rem" }}
                          />
                        </div>

                        <div className="flex-fill">
                          <p className="mb-0">{notification.message}</p>

                          <div className="d-flex justify-content-between mt-2">
                            <p className="mb-0 text-secondary body3 regular-weight">
                              {formatTime(notification.createdAt)}
                            </p>
                            <img
                              src="/static/icons/notification_tik.svg"
                              alt=""
                              style={{
                                minWidth: "1.2rem",
                                minHeight: "1.2rem",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                dispatch(
                                  markRead({ notificationId: notification._id })
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    );
                  })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Toolbar;

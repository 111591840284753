import React, { useEffect, useState } from "react";
import "../../App.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAllCollaborators,
  getAllUsers,
  makeAdmin,
  makeUser,
  selectAllCollaborators,
  selectAllUsers,
  selectUserLimit,
} from "../../redux/slices/settingSlice";
import InviteCollaboratorsDialog2 from "./InviteCollaboratorsDialog2";
import InviteTeamMemberDialog from "./InviteTeamMemberDialog";
import { useDispatch, useSelector } from "react-redux";
import { formatTime } from "../../utils/formatTime";
import { getAllProjects, selectUsers } from "../../redux/slices/projectSlice";
import { useRef } from "react";
import ChangeRoleDialog from "./ChangeRoleDialog";
import RemoveUserDialog from "./RemoveUserDialog";
import {
  isTypeOwner,
  isRoleAdmin,
  hasPermission_add_teammates,
  hasPermission_add_roles,
  hasPermission_remove_teammates,
} from "../../utils/permissions";

import { frontURL } from "../../utils/backendServerBaseURL";

function Users() {
  const [selectedMenu, setselectedMenu] = useState("Team");
  const ideas = [1, 2, 3];
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params.projectId;
  const collaborators = useSelector(selectAllCollaborators);
  const dispatch = useDispatch();
  const addMemberDialogRef = useRef();
  const removeMemberDialogRef = useRef();
  let team = useSelector(selectAllUsers);
  let limit = useSelector(selectUserLimit); //newChange TBD

  if (addMemberDialogRef) {
    // console.log('team :>> ', team);
    // getAllUsers();
  }
  const [selectedUser, setselectedUser] = useState(null);
  // console.log('selectedUser :>> ', selectedUser);

  let team2 = useSelector(selectUsers);
  let arrCopy = [];
  if (selectedUser) {
    team = Object.assign(team2);
    const index = team.findIndex((x) => x._id === selectedUser._id);

    // 👇️ create a shallow copy of the array
    if (removeMemberDialogRef) {
      arrCopy = [...team];
      arrCopy.splice(index, 1);
      getAllUsers();
    }
  }

  const ProjectsMenus = [
    {
      name: "Team",
    },
    // {
    //   name: "Collaborators",
    // },
  ];

  const RightProjectsMenus = [];

  let userIdLocal = localStorage.getItem("user");
  let objData = JSON.parse(userIdLocal);
  let storedUserId = objData.id;
  // console.log('objData :>> ', objData);

  // }
  // if(removeMemberDialogRef && selectedUser) {
  //     // console.log('team.findIndex(a => a._id === selectedUser._id) :>> ', team.findIndex(a => a._id === selectedUser._id));
  //   // console.log('team remove:>> ', result);
  // }
  // console.log(' {team?.map((teamMember) => {team :>> ',  team?.filter((teamMember) => teamMember.invitedBy.firstName === objData.firstName));

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getAllCollaborators());
    dispatch(getAllProjects());
  }, [selectedMenu]);

  useEffect(() => {
    if (localStorage.getItem("openAddMemberDialog", null) === "1") {
      addMemberDialogRef.current.click();
      localStorage.setItem("openAddMemberDialog", "0");
    }
  }, []);

  const handleCopyLink = (index) => {
    const tempInput = document.createElement("input");
    tempInput.value = `${frontURL}/complete-profile/${index}`;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    // Display an alert indicating that the link has been copied
    const alertElement = document.createElement("div");
    alertElement.className = "alert alert-success mt-3";
    alertElement.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-check-circle-fill me-2" viewBox="0 0 16 16">
        <path d="M7.5 1C3.91 1 1 3.91 1 7.5S3.91 14 7.5 14 14 11.09 14 7.5 11.09 1 7.5 1zm2.516 5.97a.53.53 0 0 1-.77 0L6.47 9.47l-1.47-1.47a.53.53 0 1 1 .76-.76L7 8.24l2.24-2.23a.53.53 0 1 1 .76.76l-2.23 2.23 2.23 2.23a.53.53 0 0 1 0 .77z"/>
      </svg>
      Link Copied!
    `;
    // alertElement.style.cssText = `width:fit-content;margin:auto;z-index:1000; `;
    document.getElementById("alert-container").appendChild(alertElement); // Append the alert element to a container div
    setTimeout(() => {
      alertElement.remove();
    }, 3000);
  };

  return (
    <div>
      <div className="d-flex">
        <div>
          <h1 className="mb-1">Users</h1>
          <p className="text-secondary">Manage teammates</p>
        </div>

        <div className="flex-fill d-flex flex-row-reverse">
          <div className="hstack gap-3" style={{ padding: 0 }}>
            {
              <button
                type="button"
                ref={addMemberDialogRef}
                className="btn btn-primary"
                style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                data-bs-toggle="modal"
                data-bs-target="#inviteUsers"
                disabled={hasPermission_add_teammates() ? false : true}
              >
                Add Member ({team?.length}/{limit})
              </button>
            }

            {/* {selectedMenu === "Collaborators" && (
              <button
                type="button"
                className="btn btn-primary"
                style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                data-bs-toggle="modal"
                data-bs-target="#InviteCollaboratorsDialog2"
              >
                + Invite Collaborators
              </button>
            )} */}
          </div>
        </div>
      </div>

      <div className="border-bottom mt-3">
        <div className="flex-fill d-flex align-items-center">
          {ProjectsMenus.map((menu) => {
            return (
              <div
                style={{ textDecoration: "none" }}
                className="text-dark body3 regular-weight cp"
                onClick={() => {
                  setselectedMenu(menu.name);
                }}
              >
                <div
                  className={selectedMenu === menu.name ? "text-center border-bottom border-primary border-3" : "text-center pb-1"}
                  style={{ minWidth: "7rem" }}
                >
                  <p className="mb-1">{menu.name}</p>
                </div>
              </div>
            );
          })}

          <div className="flex-fill ml-auto"></div>

          {RightProjectsMenus.map((menu) => {
            return (
              <div
                style={{ textDecoration: "none" }}
                className="text-dark body3 regular-weight cp"
                onClick={() => {
                  setselectedMenu(menu.name);
                }}
              >
                <div
                  className={selectedMenu === menu.name ? "text-center border-bottom border-primary border-3" : "text-center pb-1"}
                  style={{ minWidth: "7rem" }}
                >
                  <p className="mb-1">{menu.name}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {selectedMenu === "Team" && team?.length === 0 && (
        <div className="d-flex align-items-center justify-content-center mt-5">
          <div className="vstack gap-3 text-center">
            <img src="/static/illustrations/empty-list.svg" alt="" height="200px" />
            <h2 className="body1">List is empty!</h2>
            <p>
              Collaborators are added directly <br /> from projects section!
            </p>
          </div>
        </div>
      )}

      {selectedMenu === "Collaborators" && collaborators?.length === 0 && (
        <div className="d-flex align-items-center justify-content-center mt-5">
          <div className="vstack gap-3 text-center">
            <img src="/static/illustrations/empty-list.svg" alt="" height="200px" />
            <h2 className="body1">List is empty!</h2>
            <p>
              Collaborators are added directly <br /> from projects section!
            </p>
          </div>
        </div>
      )}

      {team?.length !== 0 && selectedMenu === "Team" && (
        <table className="table table-borderless mt-2 custom-table-sm">
          <thead className="border-none">
            <tr>
              <td scope="col" className="text-secondary body3 regular-weight">
                Name
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Joined
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Role
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Email
              </td>

              <td scope="col" className="text-secondary body3 regular-weight">
                Invited by
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Last Active
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Token
              </td>

              <td scope="col" className="text-secondary body3 regular-weight"></td>
              <td scope="col" className="text-secondary body3 regular-weight"></td>
            </tr>
          </thead>
          <tbody>
            {team?.map((teamMember, index) => {
              return (
                <tr className="border bg-white custom-hover-effect">
                  {/* <td>{teamMember.invitedBy.limit}</td> */}
                  <td className="body3 regular-weight">
                    {teamMember.firstName} {teamMember.lastName}
                  </td>
                  <td className="body3 regular-weight">{formatTime(teamMember.createdAt)}</td>
                  <td className="body3 regular-weight">{teamMember?.role?.name}</td>
                  <td className="body3 regular-weight">{teamMember?.email}</td>

                  <td className="body3 regular-weight">
                    {teamMember?.invitedBy?.firstName} {teamMember?.invitedBy?.lastName}
                  </td>
                  <td className="body3 regular-weight">{teamMember.lastLogin ? formatTime(teamMember.lastLogin) : "-"}</td>
                  {teamMember.token ? (
                    <td
                      style={{
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      className="body3 regular-weight"
                      onClick={() => handleCopyLink(teamMember.token)}
                    >
                      Copy Link
                    </td>
                  ) : (
                    <td className="body3 regular-weight">-</td>
                  )}
                  <td className="body3 regular-weight">
                    {teamMember.token !== "" && (
                      <>
                        <span className="gray-chip">Waiting</span>
                      </>
                    )}
                  </td>
                  {/* <td className="body3 regular-weight">
                  <div class="dropdown">
                        <div
                          className=" d-flex justify-content-between align-items-center rounded"
                          data-bs-toggle="dropdown"
                          style={{ cursor: "pointer" }}
                        >
                          <i class="bi bi-three-dots-vertical cp"></i>
                        </div>

                        <ul className="dropdown-menu">
                          <li className="border-bottom">
                            <a
                              className="dropdown-item body3 regular-weight"
                              ref={removeMemberDialogRef}
                              data-bs-toggle="modal"
                              data-bs-target="#removeUserDialog"
                              onClick={() => {
                                setselectedUser(teamMember);
                              }}
                            >
                              Remove User
                            </a>
                          </li>
                        </ul>
                      </div>
                      </td> */}

                  <td className="body3 regular-weight">
                    {teamMember.role?.name?.toLowerCase() === "owner" ||
                    // isRoleAdmin() || // TODO: Permission Doubt
                    hasPermission_add_teammates() ? (
                      <div class="dropdown">
                        <div
                          className=" d-flex justify-content-between align-items-center rounded"
                          data-bs-toggle="dropdown"
                          style={{ cursor: "pointer" }}
                        >
                          <i class="bi bi-three-dots-vertical cp custom-more-icon-hover-effect" style={{ padding: "0.5rem" }}></i>
                        </div>

                        <ul className="dropdown-menu">
                          {hasPermission_add_roles() ? (
                            <li className="border-bottom">
                              <a
                                className="dropdown-item body3 regular-weight"
                                data-bs-toggle="modal"
                                data-bs-target="#changeRoleDialog"
                                onClick={() => {
                                  setselectedUser(teamMember);
                                }}
                              >
                                Change Role
                              </a>
                            </li>
                          ) : (
                            <li></li>
                          )}
                          {hasPermission_remove_teammates() ? (
                            <li className="border-bottom">
                              <a
                                className="dropdown-item body3 regular-weight"
                                ref={removeMemberDialogRef}
                                data-bs-toggle="modal"
                                data-bs-target="#removeUserDialog"
                                onClick={() => {
                                  setselectedUser(teamMember);
                                }}
                              >
                                Remove User
                              </a>
                            </li>
                          ) : (
                            <li></li>
                          )}
                        </ul>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <div id="alert-container" className="position-fixed top-0 start-50 translate-middle-x" style={{ zIndex: "1000" }}></div>

      {collaborators?.length !== 0 && selectedMenu === "Collaborators" && (
        <table className="table table-borderless mt-2">
          <thead className="border-none">
            <tr>
              <td scope="col" className="text-secondary body3 regular-weight">
                Name
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Joined
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Organization
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Email
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Project
              </td>
              <td scope="col" className="text-secondary body3 regular-weight"></td>
              <td scope="col" className="text-secondary body3 regular-weight"></td>
            </tr>
          </thead>
          <tbody>
            {collaborators?.map((teamMember) => {
              return (
                <tr className="border bg-white">
                  <td className="body3 regular-weight">
                    {teamMember.firstName} {teamMember.lastName}
                  </td>
                  <td className="body3 regular-weight">{formatTime(teamMember.createdAt)}</td>
                  <td className="body3 regular-weight">{teamMember.owner?.organization}</td>
                  <td className="body3 regular-weight">{teamMember.email}</td>
                  <td className="body3 regular-weight">{teamMember.project?.name}</td>
                  <td className="body3 regular-weight">
                    {teamMember.token !== "" && (
                      <>
                        <span className="gray-chip">Waiting</span>
                      </>
                    )}
                  </td>
                  <td className="body3 regular-weight">
                    <div class="dropdown">
                      {/* <div
                        className=" d-flex justify-content-between align-items-center rounded"
                        data-bs-toggle="dropdown"
                        style={{ cursor: "pointer" }}
                      >
                        <i class="bi bi-three-dots-vertical cp"></i>
                      </div> */}

                      <ul className="dropdown-menu">
                        <li className="border-bottom">
                          <a className="dropdown-item body3 regular-weight" href="#">
                            Disable Member
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      <InviteTeamMemberDialog />
      <InviteCollaboratorsDialog2 />
      <ChangeRoleDialog selectedUser={selectedUser} />
      <RemoveUserDialog selectedUser={selectedUser} />
    </div>
  );
}

export default Users;

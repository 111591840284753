import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAnayticsData, selectanayticsData, updatespan, ideaTestData } from "../../redux/slices/anayticsSlice";
import { formatDate2, formatTime } from "../../utils/formatTime";
import { CSVLink, CSVDownload } from "react-csv";
import { saveAs } from "file-saver";
import "../../App.css";
import { Doughnut, Line } from "react-chartjs-2";
import { Chart, LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, Legend } from "chart.js";
import { backendServerBaseURL } from "../../utils/backendServerBaseURL";
import Spinner from "../../components/common/Spinner";

Chart.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, Legend);
function Analytics() {
  const [selectedMenu, setselectedMenu] = useState("All");
  const dispatch = useDispatch();
  const ProjectsMenus = [];
  const RightProjectsMenus = [];
  const analyticsData = useSelector(selectanayticsData);
  const ideaTest = useSelector(ideaTestData)
 console.log("ideaTest",ideaTest)
// console.log("analystc - -",analyticsData)
  const [csvData, setcsvData] = useState([]);

  const [showDropdown, setShowDropdown] = useState(false);
  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  const countAllGoals = () => {
    let totalGoals = 0;
    analyticsData.forEach((project) => {
      totalGoals += project.goals;
    });
    return totalGoals;
  };

  function saveCanvas1() {
    //save to png
    const canvasSave = document.getElementById("graph1");
    canvasSave.toBlob(function (blob) {
      saveAs(blob, "Ideas Created & Tests Started.png");
    });
  }

  function saveCanvas2() {
    //save to png
    const canvasSave = document.getElementById("graph2");
    canvasSave.toBlob(function (blob) {
      saveAs(blob, "Learnings Acquired.png");
    });
  }

  function saveCanvas3() {
    //save to png
    const canvasSave = document.getElementById("graph3");
    canvasSave.toBlob(function (blob) {
      saveAs(blob, "Learnings by Growth lever.png");
    });
  }

  function saveCanvas4() {
    //save to png
    const canvasSave = document.getElementById("graph4");
    canvasSave.toBlob(function (blob) {
      saveAs(blob, "Weekly Team Participation.png");
    });
  }

  let options = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: "#97a4af",
          usePointStyle: true,
        },
      },
    },
    scales: {
      yAxes: {
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        },
        ticks: {
          beginAtZero: true,
          stepSize: 10,
          fontSize: 12,
          color: "#97a4af",
          fontFamily: "Open Sans, sans-serif",
          padding: 10,
          postfix: "k",
        },
      },
      xAxes: {
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        },
        ticks: {
          beginAtZero: true,
          fontSize: 12,
          color: "#97a4af",
          fontFamily: "Open Sans, sans-serif",
          padding: 5,
        },
      },
    },
  };

  let DoughnutOptions = {
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    cutout: 0,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: "#97a4af",
          usePointStyle: true,
        },
      },
    },
  };

  const countAllWorkedLearnings = () => {
    let totalWorkedLearnings = 0;
    analyticsData.forEach((project) => {
      totalWorkedLearnings += project.workedLearnings;
    });
    return totalWorkedLearnings;
  };

  const countAllDidntWorkedLearnings = () => {
    let totalDidntWorkedLearnings = 0;
    analyticsData.forEach((project) => {
      totalDidntWorkedLearnings += project.didntWorkedLearnings;
    });
    return totalDidntWorkedLearnings;
  };

  const inconclusiveLearningsCount = () => {
    const total = analyticsData.reduce((acc, project) => {
      return acc + project.inconclusiveLearnings;
    }, 0);
    return total;
  };

  const learingAcquired = () => {
    let total = countAllDidntWorkedLearnings() + countAllWorkedLearnings() + inconclusiveLearningsCount();
    return total;
  };

  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    dispatch(getAnayticsData());
    setTimeout(() => {
      setShowLoader(false); 
    }, 2000);
  }, [selectedMenu]);

  useEffect(() => {
    let tempCSVData = [
      [
        "Project",
        "Status",
        "Created",
        "Updated",
        "Goals",
        "Ideas",
        "Tests",
        "Successful",
        "Unsuccessful",
        "Inconclusive",
        "Total",
        "Users",
        "	W.A.U",
        "Active %",
      ],
    ];
    analyticsData.map((singleProject) => {
      tempCSVData.push([
        singleProject?.name,
        singleProject?.status,
        formatTime(singleProject.createdAt),
        formatTime(singleProject.updatedAt),
        singleProject?.goals,
        singleProject?.ideas,
        singleProject?.tests,

        singleProject?.tests,
        singleProject?.tests,
        singleProject?.tests,
        singleProject?.learnings,

        singleProject?.tests,
        singleProject?.tests,
        singleProject?.tests,
      ]);
    });

    setcsvData(tempCSVData);
  }, [analyticsData]);
  // console.log(csvData);

  return (
    <div className="page-body-widthout-sidebar">
      <div className="d-flex">
      {showLoader && <div
          style={{
            width: "100%",
            height: "100%",
            zIndex: "999",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            paddingRight: "100px",
            paddingBottom: "200px",
            background: "#FBFBFB"
          }}>
          <Spinner />
        </div>}
        <div>
          <h1 className="mb-1">Analytics</h1>
        </div>

        <div className="flex-fill d-flex flex-row-reverse">
          <div className="hstack gap-3" style={{ padding: 0 }}>
            <select
              className="form-select"
              onChange={(e) => {
                dispatch(updatespan(e.target.value));
                dispatch(getAnayticsData());
              }}
            >
              <option value="1week">1 Week</option>
              <option value="2weeks">2 Weeks</option>
              <option value="1month">1 Month</option>
              <option value="3months">3 Months</option>
              <option value="6months">6 Months</option>
              <option value="12months">12 Months</option>
            </select>
            <CSVLink data={csvData} filename={"Analytics.csv"}>
              <button type="button" className="btn btn-outline-primary" style={{ minWidth: "10rem" }}>
                Export as CSV
              </button>
            </CSVLink>
          </div>
        </div>
      </div>
      <div className="analytics d-flex gap-3 mt-3">
        <div>
          <p>Active Projects</p>
          <h3>{`${analyticsData?.length}`}</h3>
        </div>
        <div>
          <p>Active Goals</p>
          <h3>{countAllGoals()}</h3>
        </div>
        {/* <div>
          <p>Complete Goals</p>
          <h3>0</h3>
        </div> */}
        <div>
          <span className="d-flex gap-3 pt-0 px-0 m-0 align-items-center">
            <span className="mb-0">Learnings Acquired</span>
            <div className="dropdown pb-0 pt-0" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ width: "max-content" }}>
              <span
                className="mb-0 p-0"
                // type="button"
                id="user-dropdown"
                aria-expanded={showDropdown}
                style={{ width: "20px", margin: "auto" }}
              >
                <img
                  // type="button"
                  // id="user-dropdown"
                  // expanded={showDropdown}
                  src="/static/icons/info.svg"
                  alt="info"
                />
              </span>
            </div>
            <ul className={`dropdown-menu ${showDropdown ? "show" : ""}`}>
              <li className="dropdown-item disabled m=0 pt-1 pb-1">Worked : {countAllWorkedLearnings()}</li>
              <li className="dropdown-item disabled m=0 pt-1 pb-1">Didn't Work : {countAllDidntWorkedLearnings()}</li>
              <li className="dropdown-item disabled m=0 pt-1 pb-1">Inconclusive : {inconclusiveLearningsCount()}</li>
            </ul>
          </span>

          <h3 className="mt-3">{learingAcquired()}</h3>
        </div>
      </div>

      <div className="border-bottom mt-3">
        <div className="flex-fill d-flex align-items-center">
          {ProjectsMenus.map((menu) => {
            return (
              <div
                style={{ textDecoration: "none" }}
                className="text-dark body3 semi-bold-weight cp"
                onClick={() => {
                  setselectedMenu(menu.name);
                }}
              >
                <div
                  className={selectedMenu === menu.name ? "text-center border-bottom border-primary border-3" : "text-center pb-1"}
                  style={{ minWidth: "7rem" }}
                >
                  <p className="mb-1">{menu.name}</p>
                </div>
              </div>
            );
          })}

          <div className="flex-fill ml-auto"></div>

          {RightProjectsMenus.map((menu) => {
            return (
              <div
                style={{ textDecoration: "none" }}
                className="text-dark body3 semi-bold-weight cp"
                onClick={() => {
                  setselectedMenu(menu.name);
                }}
              >
                <div
                  className={selectedMenu === menu.name ? "text-center border-bottom border-primary border-3" : "text-center pb-1"}
                  style={{ minWidth: "7rem" }}
                >
                  <p className="mb-1">{menu.name}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="mt-3 d-flex flex-column gap-2 text-center">
        <div className="d-flex gap-3 justify-content-around" style={{ backgroundColor: "white" }}>
          <div className="border w-50 p-3">
            <div className="d-flex justify-content-between  gap-2">
              <p className="fw-bold w-75 m-auto">Ideas Created and Tests Started</p>
              <div
                style={{ cursor: "pointer", width: "50px" }}
                onClick={(e) => {
                  saveCanvas1();
                }}
              >
                <img src="/static/icons/chartDownloadBtn.svg" alt="" />
              </div>
            </div>

            <div style={{ padding: "30px" }}>
              <Line
                id="graph1"
                data={{
                  labels: ideaTest?.idea?.map((el) => formatDate2(el._id)),
                  datasets: [
                    {
                      label: "Idea Created",
                      backgroundColor: "#D77631",
                      borderColor: "#D77631",
                      data: ideaTest?.idea?.map((el) => el.amount),
                    },
                    {
                      label: "Test Started",
                      backgroundColor: "#DDC238",
                      borderColor: "#DDC238",
                      data: ideaTest?.test?.map((el) => el.amount),
                    },
                  ],
                }}
                options={options}
              />
            </div>
          </div>
          <div className="border w-50 p-3">
            <div className="d-flex justify-content-between  gap-2">
              <p className="fw-bold w-75 m-auto">Learings Acquired</p>
              <div
                style={{ cursor: "pointer", width: "50px" }}
                onClick={(e) => {
                  saveCanvas2();
                }}
              >
                <img src="/static/icons/chartDownloadBtn.svg" alt="" />
              </div>
            </div>
            <div style={{ padding: "30px" }}>
              <Line
                id="graph2"
                data={{
                  labels: ideaTest?.learning?.map((el) => formatDate2(el._id)),
                  datasets: [
                    {
                      label: "Cards Analyzed",
                      backgroundColor: "#19B351",
                      borderColor: "#19B351",
                      data: ideaTest?.learning?.map((el) => el.amount),
                    },
                  ],
                }}
                options={options}
              />
            </div>
          </div>
        </div>
        <div className="d-flex gap-3 justify-content-between" style={{ backgroundColor: "white" }}>
          <div className="border w-50 p-3">
            <div className="d-flex justify-content-between  gap-2">
              <p className="fw-bold w-75 m-auto">Learnings by Growth lever</p>
              <div
                style={{ cursor: "pointer", width: "50px" }}
                onClick={(e) => {
                  saveCanvas3();
                }}
              >
                <img src="/static/icons/chartDownloadBtn.svg" alt="" />
              </div>
            </div>
            <div style={{ padding: "30px" }}>
              <Doughnut
                id="graph3"
                style={{ maxHeight: "20rem" }}
                data={{
                  labels: ["Acquisition", "Activation", "Referral", "Retention", "Revenue"],

                  datasets: [
                    {
                      data: [ideaTest?.acquisition, ideaTest?.activation, ideaTest?.referral, ideaTest?.retention, ideaTest?.revenue],
                      backgroundColor: ["#7093F2", "#F2DD70", "#D77631", "#E95050", "#31D76E"],
                    },
                  ],
                }}
                options={DoughnutOptions}
              />
            </div>
          </div>
          <div className="border w-50 p-3">
            <div className="d-flex justify-content-between  gap-2">
              <p className="fw-bold w-75 m-auto">Weekly Team Participation</p>
              <div
                style={{ cursor: "pointer", width: "50px" }}
                onClick={(e) => {
                  saveCanvas4();
                }}
              >
                <img src="/static/icons/chartDownloadBtn.svg" alt="" />
              </div>
            </div>
            <div style={{ padding: "30px" }}>
              <Line
                id="graph4"
                data={{
                  labels: ideaTest?.labels,
                  datasets: [
                    {
                      data: ideaTest?.data,
                      label: "Members",
                      backgroundColor: "transparent",
                      borderColor: "#3864DD",
                      borderWidth: 2,
                      pointRadius: 5,
                      hoverBorderColor: "#3864DD",
                      pointBackgroundColor: "#3864DD",
                      pointBorderColor: "#fff",
                      pointHoverRadius: 0,
                    },
                  ],
                }}
                options={options}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3">
        <h3 className="mb-2">Team Performance</h3>
        <div className="card pe-3 ps-3 pt-1 pb-1 customScrollBarLight" style={{ maxHeight: "12rem", overflowY: "scroll" }}>
          <table
            className="table table-borderless mt-2"
            // style={{ backgroundColor: "white" }}
          >
            <thead className="border-none">
              <tr className="fixed-header">
                <th scope="col" className="text-secondary body3 regular-weight">
                  Name
                </th>
                <th scope="col" className="text-secondary body3 regular-weight">
                  Idea Added
                </th>
                <th scope="col" className="text-secondary body3 regular-weight">
                  Nominations Received
                </th>
              </tr>
            </thead>
            <tbody>
              {ideaTest?.userData?.map((el) => {
                return (
                  <tr className="border-top bg-white">
                    <td className="body3 regular-weight">
                      <img
                        src={`${backendServerBaseURL}/${el.user.avatar}`}
                        alt=""
                        style={{ marginRight: "0.3rem", borderRadius: "50%" }}
                        width={18}
                        height={18}
                      />
                      {el.user.firstName} {el.user.lastName}
                    </td>

                    <td className="body3 regular-weight">
                      {el.user.ideaCount}
                    </td>
                    <td className="body3 regular-weight">
                      {el.user.ideaNominate}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="mt-3">
        <h3>Project Based Analytics</h3>
        <table className="table table-borderless mt-2 custom-table-sm">
          <thead className="border-none">
            <tr style={{ backgroundColor: "#F3F3F3" }}>
              <td scope="col" colSpan={7} className="text-secondary body semi-bold-weight border">
                Workspace
              </td>
              <td scope="col" colSpan={4} className="text-secondary body3 semi-bold-weight border">
                Learnings
              </td>
              <td scope="col" colSpan={3} className="text-secondary body3 semi-bold-weight border">
                Activity
              </td>
            </tr>
          </thead>
          <tbody>
            <tr className="border" style={{ backgroundColor: "#F3F3F3" }}>
              <td className="body3 semi-bold-weight align-middle border">Project</td>
              <td className="body3 semi-bold-weight align-middle border">Status</td>
              <td className="body3 semi-bold-weight align-middle border">Created</td>
              <td className="body3 semi-bold-weight align-middle border">Updated</td>
              <td className="body3 semi-bold-weight align-middle border">Goals</td>
              <td className="body3 semi-bold-weight align-middle border">Ideas</td>
              <td className="body3 semi-bold-weight align-middle border">Tests</td>

              <td className="body3 semi-bold-weight align-middle border">Worked</td>
              <td className="body3 semi-bold-weight align-middle border">Didn’t work</td>
              <td className="body3 semi-bold-weight align-middle border">Inconclusive</td>
              <td className="body3 semi-bold-weight align-middle border">Total</td>

              <td className="body3 semi-bold-weight align-middle border">Users</td>
              {/* <td className="body3 semi-bold-weight align-middle border">W.A.U</td>
              <td className="body3 semi-bold-weight align-middle border">Active %</td> */}
            </tr>

            {analyticsData.map((singleProject) => {
              return (
                <tr className="border bg-white">
                  <td className="body3 regular-weight align-middle border">{singleProject?.name}</td>
                  <td className="body3 regular-weight align-middle border">
                    <div
                      className={`d-flex justify-content-between align-items-center rounded ${
                        singleProject.status === "Not Defined" && "blue-chip"
                      } ${singleProject.status === "Completed" && "green-chip"} ${singleProject.status === "Active" && "green-chip"} ${
                        singleProject.status === "On Hold" && "yellow-chip"
                      }`}
                      style={{ maxWidth: "6.3rem" }}
                    >
                      <div className="w-100 text-center">
                        <p className="m-0">{singleProject.status}</p>
                      </div>
                    </div>
                  </td>
                  <td className="body3 regular-weight align-middle border">
                    {formatTime(singleProject.createdAt)}
                  </td>
                  <td className="body3 regular-weight align-middle border">
                    {formatTime(singleProject.updatedAt)}
                  </td>
                  <td className="body3 regular-weight align-middle border">
                    {singleProject.goals}
                  </td>
                  <td className="body3 regular-weight align-middle border">
                    {singleProject.ideaCount}
                  </td>
                  <td className="body3 regular-weight align-middle border">
                    {singleProject.ideaTest}
                  </td>
                  {/* TODO: Update backend to get following data */}
                  <td className="body3 regular-weight align-middle border">
                    {singleProject.ideaSuccessful}
                  </td>
                  <td className="body3 regular-weight align-middle border">
                    {singleProject.ideaUnsuccessful}
                  </td>
                  <td className="body3 regular-weight align-middle border">
                    {singleProject.ideaInconclusive}
                  </td>
                  <td className="body3 regular-weight align-middle border">
                    {singleProject.learnings}
                  </td>

                  <td className="body3 regular-weight align-middle border">
                    {singleProject.team?.length + 1}
                  </td>
                  {/* <td className="body3 regular-weight align-middle border">
                    {"N/A"}
                  </td>
                  <td className="body3 regular-weight align-middle border">
                    {"N/A"}
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Analytics;

import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";

import { Form, FormikProvider } from "formik";

function DeleteLearningDialog() {
  return (
    <>
      {/* Create new Project Dialog */}
      <div>
        <div className="modal fade" id="deleteLearningDialog" tabIndex={-1} aria-labelledby="deleteLearningDialogLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h2 style={{ marginBottom: "16px" }}>Delete Learning</h2>

                <div className="form-field">
                  <span>asdasdasdaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</span>
                </div>

                <div className="hstack gap-2 d-flex justify-content-end">
                  <button type="button" class="btn btn-lg btn-outline-primary" data-bs-dismiss="modal">
                    Close
                  </button>
                  <button type="submit" class="btn btn-lg btn-danger">
                    Delete Learning
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeleteLearningDialog;

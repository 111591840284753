import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import ReactTooltip from "react-tooltip";
import {
  getGrowthData,
  getIdeasAndTestChartData,
  getlearningsAcquiredGraphData,
  getlearningsByGrowthLeverGraphData,
  getTeamPartitionGraphData,
  selectgrowthData,
  selectgrowthSpan,
  selectideasCreatedAndTestStartedGraphData,
  selectinsightsSpan,
  selectlearningsAcquiredGraphData,
  selectlearningsByGrowthLeverGraphData,
  selectWeeklyTeamPartcipationGraphData,
  updategrowthSpan,
  updateinsightsSpan,
} from "../../../redux/slices/projectSlice";
import { useDispatch, useSelector } from "react-redux";
import { backendServerBaseURL } from "../../../utils/backendServerBaseURL";
import { formatDate2, formatDate4 } from "../../../utils/formatTime";
import moment from "moment";
import TourModal from "../Tour/TourModal";
import { saveAs } from "file-saver";
import Spinner from "../../../components/common/Spinner";

function Insights() {
  const insights = [1, 2];
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const projectId = params.projectId;
  const insightsSpan = useSelector(selectinsightsSpan);
  const teamContributions = [1, 2, 3, 4, 5, 6, 7];
  const openedProject = JSON.parse(localStorage.getItem("openedProject", ""));
  let options = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: "#97a4af",
          usePointStyle: true,
        },
      },
    },
    scales: {
      yAxes: {
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        },
        ticks: {
          beginAtZero: true,
          stepSize: 10,
          fontSize: 12,
          color: "#97a4af",
          fontFamily: "Open Sans, sans-serif",
          padding: 10,
          postfix: "k",
        },
      },
      xAxes: {
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        },
        ticks: {
          beginAtZero: true,
          fontSize: 12,
          color: "#97a4af",
          fontFamily: "Open Sans, sans-serif",
          padding: 5,
        },
      },
    },
  };

  let DoughnutOptions = {
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    cutout: 0,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: "#97a4af",
          usePointStyle: true,
        },
      },
    },
  };
  const ideasCreatedAndTestStartedGraphData = useSelector(selectideasCreatedAndTestStartedGraphData);
  const learningsAcquiredGraphData = useSelector(selectlearningsAcquiredGraphData);
  console.log('learningsAcquiredGraphData :>> ', learningsAcquiredGraphData);
  const learningsByGrowthLeverGraphData = useSelector(selectlearningsByGrowthLeverGraphData);
  const WeeklyTeamPartcipationGraphData = useSelector(selectWeeklyTeamPartcipationGraphData);
  console.log('WeeklyTeamPartcipationGraphData :>> ', WeeklyTeamPartcipationGraphData);
  const growthData = useSelector(selectgrowthData);
  const growthSpan = useSelector(selectgrowthSpan);
  const [showLoader, setShowLoader] = useState(true);

console.log("growthdata",growthData)
  useEffect(() => {
    dispatch(getIdeasAndTestChartData({ projectId }));
    dispatch(getlearningsAcquiredGraphData({ projectId }));
    dispatch(getlearningsByGrowthLeverGraphData({ projectId }));
    dispatch(getTeamPartitionGraphData({ projectId }));
    dispatch(getGrowthData({ projectId }));
    setTimeout(() => {
      setShowLoader(false); 
    }, 2000);
  }, [insightsSpan, growthSpan]);

  function saveCanvas1() {
    //save to png
    const canvasSave = document.getElementById("graph1");
    canvasSave.toBlob(function (blob) {
      saveAs(blob, "Ideas Created & Tests Started.png");
    });
  }

  function saveCanvas2() {
    //save to png
    const canvasSave = document.getElementById("graph2");
    canvasSave.toBlob(function (blob) {
      saveAs(blob, "Learnings Acquired.png");
    });
  }

  function saveCanvas3() {
    //save to png
    const canvasSave = document.getElementById("graph3");
    canvasSave.toBlob(function (blob) {
      saveAs(blob, "Learnings by Growth lever.png");
    });
  }

  function saveCanvas4() {
    //save to png
    const canvasSave = document.getElementById("graph4");
    canvasSave.toBlob(function (blob) {
      saveAs(blob, "Weekly Team Participation.png");
    });
  }


   const countAllIdeas = () => {
     let totalIdeas = 0;
     growthData?.projectCount?.forEach((project) => {
       totalIdeas += project.countIdea;
     });
     return totalIdeas;
   };
   const countAllTest = () => {
     let totalTest = 0;
     growthData?.projectCount?.forEach((project) => {
       totalTest += project.countTest;
     });
     return totalTest;
   };

  return (
    <div>
      <div className="d-flex">
      {showLoader && <div
          style={{
            width: "100%",
            height: "100%",
            zIndex: "999",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            paddingRight: "300px",
            paddingBottom: "200px",
            background: "#FBFBFB"
          }}>
          <Spinner />
        </div>}
        <div>
          <p className="text-secondary mb-1">{openedProject?.name}</p>
          <h1 className="mb-2">Insights</h1>

          <p className="text-secondary">
            {insightsSpan !== "all" ? formatDate4(moment.duration(new Date()).subtract(insightsSpan, "week")) : "Created On"} -{" "}
            {formatDate4(new Date())}
          </p>
        </div>

        <div className="flex-fill d-flex flex-row-reverse">
          <div className="hstack gap-3 align-items-start" style={{ padding: 0 }}>
            <select
              class="form-select"
              onChange={(e) => {
                dispatch(updateinsightsSpan(e.target.value));
              }}
            >
              <option value="4" selected>
                Past 4 Weeks
              </option>
              <option value="8">Past 8 Weeks</option>
              <option value="12">Past 12 Weeks</option>
              <option value="all">All</option>
            </select>
          </div>
        </div>
      </div>

      {insights.length === 0 && (
        <div className="d-flex align-items-center justify-content-center mt-5">
          <div className="vstack gap-3 text-center">
            <img src="/static/illustrations/no-projects-found.svg" alt="" height="200px" />
            <h2 className="body1 regular-weight">Insights not available</h2>
          </div>
        </div>
      )}

      {insights.length !== 0 && (
        <>
          <div className="row g-3">
            {/* Ideas Created & Tests Started */}
            <div className="col-6">
              <div className="card" style={{ paddingRight: "4rem", paddingLeft: "4rem", paddingTop: "2rem", paddingBottom: "2rem" }}>
                <div className="d-flex align-items-center">
                  <div className="flex-fill">
                    <h3 className="text-center mb-2">Ideas Created & Tests Started</h3>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      // TODO: Download Chart
                      saveCanvas1();
                    }}
                  >
                    <img src="/static/icons/chartDownloadBtn.svg" alt="" />
                  </div>
                </div>

                <Line
                  id="graph1"
                  data={{
                    labels: ideasCreatedAndTestStartedGraphData?.ideasData.labels,
                    datasets: [
                      {
                        data: ideasCreatedAndTestStartedGraphData?.ideasData.data,
                        label: "Ideas Created",
                        backgroundColor: "transparent",
                        borderColor: "#D77631",
                        borderWidth: 2,
                        pointRadius: 5,
                        hoverBorderColor: "#D77631",
                        pointBackgroundColor: "#D77631",
                        pointBorderColor: "#fff",
                        pointHoverRadius: 0,
                      },
                      {
                        data: ideasCreatedAndTestStartedGraphData?.testsData.data,
                        label: "Tests Started",
                        backgroundColor: "transparent",
                        borderColor: "#DDC238",
                        borderWidth: 2,
                        pointRadius: 5,
                        hoverBorderColor: "#DDC238",
                        pointBackgroundColor: "#DDC238",
                        pointBorderColor: "#fff",
                        pointHoverRadius: 0,
                      },
                    ],
                  }}
                  options={options}
                />
              </div>
            </div>

            {/* Learnings Acquired */}
            <div className="col-6">
              <div className="card" style={{ paddingRight: "4rem", paddingLeft: "4rem", paddingTop: "2rem", paddingBottom: "2rem" }}>
                <div className="d-flex align-items-center">
                  <div className="flex-fill">
                    <h3 className="text-center mb-2">Learnings Acquired</h3>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      // TODO: Download Chart
                      saveCanvas2();
                    }}
                  >
                    <img src="/static/icons/chartDownloadBtn.svg" alt="" />
                  </div>
                </div>

                <Line
                  id="graph2"
                  data={{
                    labels: learningsAcquiredGraphData?.labels,
                    datasets: [
                      {
                        data: learningsAcquiredGraphData?.data,
                        label: "Learnings",
                        backgroundColor: "transparent",
                        borderColor: "#3864DD",
                        borderWidth: 2,
                        pointRadius: 5,
                        hoverBorderColor: "#3864DD",
                        pointBackgroundColor: "#3864DD",
                        pointBorderColor: "#fff",
                        pointHoverRadius: 0,
                      },
                    ],
                  }}
                  options={options}
                />
              </div>
            </div>

            {/* Learnings by Growth lever */}
            <div className="col-6">
              <div className="card" style={{ paddingRight: "4rem", paddingLeft: "4rem", paddingTop: "2rem", paddingBottom: "2rem" }}>
                <div className="d-flex align-items-center">
                  <div className="flex-fill">
                    <h3 className="text-center mb-2">Learnings by Growth lever</h3>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      // TODO: Download Chart
                      saveCanvas3();
                    }}
                  >
                    <img src="/static/icons/chartDownloadBtn.svg" alt="" />
                  </div>
                </div>

                <Doughnut
                  id="graph3"
                  style={{ maxHeight: "20rem" }}
                  data={{
                    labels: ["Acquisition", "Activation", "Referral", "Retention", "Revenue"],

                    datasets: [
                      {
                        data: [
                          learningsByGrowthLeverGraphData?.Acquisition,
                          learningsByGrowthLeverGraphData?.Activation,
                          learningsByGrowthLeverGraphData?.Referral,
                          learningsByGrowthLeverGraphData?.Retention,
                          learningsByGrowthLeverGraphData?.Revenue,
                        ],
                        backgroundColor: ["#7093F2", "#F2DD70", "#D77631", "#E95050", "#31D76E"],
                      },
                    ],
                  }}
                  options={DoughnutOptions}
                />
              </div>
            </div>

            {/* Weekly Team Participation */}
            <div className="col-6">
              <div className="card" style={{ paddingRight: "4rem", paddingLeft: "4rem", paddingTop: "2rem", paddingBottom: "2rem" }}>
                <div className="d-flex align-items-center">
                  <div className="flex-fill">
                    <h3 className="text-center mb-2">Weekly Team Participation</h3>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      // TODO: Download Chart
                      saveCanvas4();
                    }}
                  >
                    <img src="/static/icons/chartDownloadBtn.svg" alt="" />
                  </div>
                </div>

                <Line
                  id="graph4"
                  data={{
                    labels: WeeklyTeamPartcipationGraphData?.labels,
                    datasets: [
                      {
                        data: WeeklyTeamPartcipationGraphData?.data,
                        label: "Members",
                        backgroundColor: "transparent",
                        borderColor: "#3864DD",
                        borderWidth: 2,
                        pointRadius: 5,
                        hoverBorderColor: "#3864DD",
                        pointBackgroundColor: "#3864DD",
                        pointBorderColor: "#fff",
                        pointHoverRadius: 0,
                      },
                    ],
                  }}
                  options={options}
                />
              </div>
            </div>
          </div>

          <hr className="mt-4 mb-4" />

          <div className="d-flex">
            <div>
              <div>
                <p className="text-secondary mb-1">{openedProject?.name}</p>
                <h1 className="mb-2">Growth Health</h1>

                <p className="text-secondary">
                  {formatDate4(moment.duration(new Date()).subtract(growthSpan, "week"))} - {formatDate4(new Date())}
                </p>
              </div>

              <div className="hstack gap-2">
                <div className="bg-white border p-3 rounded">
                  <p>Ideas Created</p>
                  <h2>{countAllIdeas()}</h2>
                </div>

                <div className="bg-white border p-3 rounded">
                  <p>Tests Started</p>
                  <h2>{countAllTest()}</h2>
                </div>

                <div className="bg-white border p-3 rounded">
                  <p>
                    Learnings Acquired{" "}
                    <span data-tip data-for="learningAcquiredInfoIcon">
                      <img src="/static/icons/info.svg" alt="" style={{ paddingBottom: "0.2rem", paddingLeft: "0.2rem" }} />
                    </span>
                    <ReactTooltip id="learningAcquiredInfoIcon" effect="float" delayShow={500}>
                      <p className="mb-1">Successful: {growthData?.userData[0]?.workedLearnings}</p>
                      <p className="mb-1">Unsuccessful: {growthData?.userData[0]?.didntWorkedLearnings}</p>
                      <p className="mb-1">Inconclusive: {growthData?.userData[0]?.inconclusiveLearnings}</p>
                    </ReactTooltip>
                  </p>
                  <h2>{growthData?.learningsCreated}</h2>
                </div>
              </div>
            </div>

            <div className="flex-fill d-flex flex-row-reverse">
              <div className="hstack gap-3 align-items-start" style={{ padding: 0 }}>
                <select
                  class="form-select"
                  onChange={(e) => {
                    dispatch(updategrowthSpan(e.target.value));
                  }}
                >
                  <option value="1" selected>
                    Past Weeks
                  </option>
                  <option value="2">Past 2 Weeks</option>
                  <option value="4">Past 4 Weeks</option>
                  <option value="6">Past 6 Weeks</option>
                  <option value="8">Past 8 Weeks</option>
                </select>
              </div>
            </div>
          </div>

          <p className="mt-3">Team Contribution</p>
          <div className="card pe-3 ps-3 pt-1 pb-1 customScrollBarLight" style={{ maxHeight: "12rem", overflowY: "scroll" }}>
            <table className="table table-borderless mt-2">
              <thead className="border-none">
                <tr>
                  <td scope="col" className="text-secondary body3 regular-weight">
                    Name
                  </td>
                  <td scope="col" className="text-secondary body3 regular-weight">
                    Ideas Created
                  </td>
                  <td scope="col" className="text-secondary body3 regular-weight">
                    Nominations Recieved
                  </td>
                  <td scope="col" className="text-secondary body3 regular-weight">
                    Ideas Tested
                  </td>
                </tr>
              </thead>
              <tbody>
                {growthData?.projectCount?.map((user) => {
                  return (
                    <tr className="border-top bg-white">
                      <td className="body3 regular-weight">
                        <img
                          src={`${backendServerBaseURL}/${user.user.avatar}`}
                          alt=""
                          style={{ marginRight: "0.3rem", borderRadius: "50%" }}
                          width={18}
                          height={18}
                        />
                        {user.user.firstName} {user.user.lastName}
                      </td>

                      <td className="body3 regular-weight">{user.countIdea}</td>
                      <td className="body3 regular-weight">{user.countNominate}</td>
                      <td className="body3 regular-weight">{user.countTest}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}

      <TourModal />
    </div>
  );
}

export default Insights;

import { Form, FormikProvider, useFormik } from "formik";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { createFeedback } from "../../../redux/slices/projectSlice";

function SupportModal() {
  const dispatch = useDispatch();
  const closeDialogRef = useRef();
  const [selectedMenu, setselectedMenu] = useState("Tutorials");
  const ProjectsMenus = [
    {
      name: "Tutorials",
    },
    {
      name: "Feedback",
    },
  ];

  const RightProjectsMenus = [];
  const closeDialog = () => {
    closeDialogRef.current.click();
  };

  const NewProjectSchema = Yup.object().shape({
    category: Yup.string().required("Category is required"),
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
  });

  const formik = useFormik({
    initialValues: {
      category: "",
      title: "",
      description: "",
    },
    validateOnBlur: true,
    validationSchema: NewProjectSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      console.log(values);
      setSubmitting(true);
      await dispatch(createFeedback({ ...values, closeModal: closeDialog }));
      setSubmitting(false);
    },
  });

  const Note = (note) => {
    return (
      <div className="border p-2 rounded mb-2" style={{ backgroundColor: "#F5F8FF" }}>
        <span style={{ marginRight: "0.45rem", position: "relative", top: "-3px" }}>
          <img src="/static/images/tour/star.svg" alt="" />
        </span>
        <span className="body3 semi-bold-weight">{note}</span>
      </div>
    );
  };

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, resetForm } = formik;

  return (
    <>
      <div className="modal fade" id="SupportModal" tabIndex={-1} aria-labelledby="deleteProjectDialogLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" style={{ minWidth: "55rem" }}>
          <div className="modal-content">
            <div className="modal-body">
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <div className="border-bottom mt-2">
                    <div className="flex-fill d-flex align-items-center">
                      {ProjectsMenus.map((menu) => {
                        return (
                          <div
                            style={{ textDecoration: "none" }}
                            className="text-dark body3 regular-weight cp"
                            onClick={() => {
                              setselectedMenu(menu.name);
                            }}
                          >
                            <div
                              className={selectedMenu === menu.name ? "text-center border-bottom border-primary border-3" : "text-center pb-1"}
                              style={{ minWidth: "7rem" }}
                            >
                              <p className="mb-1">{menu.name}</p>
                            </div>
                          </div>
                        );
                      })}

                      <div className="flex-fill ml-auto"></div>

                      {RightProjectsMenus.map((menu) => {
                        return (
                          <div
                            style={{ textDecoration: "none" }}
                            className="text-dark body3 regular-weight cp"
                            onClick={() => {
                              setselectedMenu(menu.name);
                            }}
                          >
                            <div
                              className={selectedMenu === menu.name ? "text-center border-bottom border-primary border-3" : "text-center pb-1"}
                              style={{ minWidth: "7rem" }}
                            >
                              <p className="mb-1">{menu.name}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="mt-2">
                    {selectedMenu == "Tutorials" && (
                      <>
                        <div className="row">
                          <div className="col-7">
                           <video
                          controls
                          src="/static/images/Scalez_Platform-Introduction.mp4"
                          // poster="https://peach.blender.org/wp-content/uploads/title_anouncement.jpg?x11217"
                          width="460" style={{margin: "65px 0px"}}>
                          Sorry, your browser doesn't support embedded videos, but don't worry, you can
                          <a href="https://archive.org/details/BigBuckBunny_124">download it</a>
                          and watch it with your favorite video player!
                           </video>
                            {/* <img src="/static/images/tour/goals.svg" alt="" style={{ maxWidth: "100%", marginBottom: "1rem" }} /> */}
                            {/* <h3>Welcome to Pulse</h3>
                            <p className="text-secondary">Karan Karamchandani, Founder</p> */}
                          </div>
                          <div className="col-5">
                            <p className="mb-0 semi-bold-weight mb-2">8 Timestamps (8 mins)</p>

                            <div className="border d-flex align-items-center p-2 rounded mb-2" style={{ backgroundColor: "#F5F8FF" }}>
                              <span style={{ marginRight: "0.45rem", position: "relative", top: "-3px" }}>
                                <img src="/static/images/tour/star.svg" alt="" />
                              </span>
                              <span className="body3 semi-bold-weight flex-fill">Dashboard</span>
                              <span className="body3 semi-bold-weight">00:29</span>
                            </div>

                            <div className="border d-flex align-items-center p-2 rounded mb-2" style={{ backgroundColor: "#F5F8FF" }}>
                              <span style={{ marginRight: "0.45rem", position: "relative", top: "-3px" }}>
                                <img src="/static/images/tour/star.svg" alt="" />
                              </span>
                              <span className="body3 semi-bold-weight flex-fill">Process</span>
                              <span className="body3 semi-bold-weight">00:40</span>
                            </div>

                            <div className="border d-flex align-items-center p-2 rounded mb-2" style={{ backgroundColor: "#F5F8FF" }}>
                              <span style={{ marginRight: "0.45rem", position: "relative", top: "-3px" }}>
                                <img src="/static/images/tour/star.svg" alt="" />
                              </span>
                              <span className="body3 semi-bold-weight flex-fill">Projects</span>
                              <span className="body3 semi-bold-weight">1:05</span>
                            </div>

                            <div className="border d-flex align-items-center p-2 rounded mb-2" style={{ backgroundColor: "#F5F8FF" }}>
                              <span style={{ marginRight: "0.45rem", position: "relative", top: "-3px" }}>
                                <img src="/static/images/tour/star.svg" alt="" />
                              </span>
                              <span className="body3 semi-bold-weight flex-fill">Goals</span>
                              <span className="body3 semi-bold-weight">1:41</span>
                            </div>
                            <div className="border d-flex align-items-center p-2 rounded mb-2" style={{ backgroundColor: "#F5F8FF" }}>
                              <span style={{ marginRight: "0.45rem", position: "relative", top: "-3px" }}>
                                <img src="/static/images/tour/star.svg" alt="" />
                              </span>
                              <span className="body3 semi-bold-weight flex-fill">Ideas</span>
                              <span className="body3 semi-bold-weight">3:54</span>
                            </div>
                            <div className="border d-flex align-items-center p-2 rounded mb-2" style={{ backgroundColor: "#F5F8FF" }}>
                              <span style={{ marginRight: "0.45rem", position: "relative", top: "-3px" }}>
                                <img src="/static/images/tour/star.svg" alt="" />
                              </span>
                              <span className="body3 semi-bold-weight flex-fill">Tests</span>
                              <span className="body3 semi-bold-weight">6:13</span>
                            </div>
                            <div className="border d-flex align-items-center p-2 rounded mb-2" style={{ backgroundColor: "#F5F8FF" }}>
                              <span style={{ marginRight: "0.45rem", position: "relative", top: "-3px" }}>
                                <img src="/static/images/tour/star.svg" alt="" />
                              </span>
                              <span className="body3 semi-bold-weight flex-fill">Learning</span>
                              <span className="body3 semi-bold-weight">6:58</span>
                            </div>
                            <div className="border d-flex align-items-center p-2 rounded mb-2" style={{ backgroundColor: "#F5F8FF" }}>
                              <span style={{ marginRight: "0.45rem", position: "relative", top: "-3px" }}>
                                <img src="/static/images/tour/star.svg" alt="" />
                              </span>
                              <span className="body3 semi-bold-weight flex-fill">Insights</span>
                              <span className="body3 semi-bold-weight">7:14</span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {selectedMenu == "Feedback" && (
                      <>
                        <div className="form-field mt-4">
                          <label className="form-label">Category</label>
                          <select {...getFieldProps("category")} className="form-select">
                            <option value="">Select Category</option>
                            <option value="Issue">Issue</option>
                            <option value="Suggestion">Suggestion</option>
                            <option value="Other">Other</option>
                          </select>
                          <span className="invalid-feedback" style={{ display: Boolean(touched.category && errors.category) ? "block" : "none" }}>
                            {errors.category}
                          </span>
                        </div>

                        <div className="form-field mt-4">
                          <label className="form-label">Title</label>
                          <input type={"text"} {...getFieldProps("title")} className="form-control form-control-lg" placeholder="Feeback Title" />
                          <span className="invalid-feedback" style={{ display: Boolean(touched.title && errors.title) ? "block" : "none" }}>
                            {errors.title}
                          </span>
                        </div>

                        <div className="form-field mt-4">
                          <label className="form-label">Description</label>
                          <textarea
                            rows={5}
                            type={"text"}
                            {...getFieldProps("description")}
                            className="form-control form-control-lg"
                            placeholder="Feeback Description"
                          />
                          <span
                            className="invalid-feedback"
                            style={{ display: Boolean(touched.description && errors.description) ? "block" : "none" }}
                          >
                            {errors.description}
                          </span>
                        </div>

                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            class="btn btn-lg btn-outline-danger"
                            ref={closeDialogRef}
                            data-bs-dismiss="modal"
                            style={{ marginRight: "0.3rem" }}
                          >
                            Cancel
                          </button>
                          <button type="submit" class="btn btn-lg btn-primary">
                            Submit
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </Form>
              </FormikProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SupportModal;

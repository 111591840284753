import { Form, FormikProvider, useFormik } from "formik";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Alert from "../../components/common/Alert";
import LoadingButton from "../../components/common/LoadingButton";
import { backendServerBaseURL } from "../../utils/backendServerBaseURL";

function ResetPassword() {
  const navigate = useNavigate();
  const [passwordResetSuccessfully, setpasswordResetSuccessfully] = useState(false);

  const RegisterSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Password doesn’t match"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: RegisterSchema,
    validateOnChange: true,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log(values);
      setSubmitting(true);
      //   await dispatch(loginUser({ ...values, setErrors }));
      setSubmitting(false);
    },
  });

  const onResetPassword = (e) => {

    let resettoken = window.location.pathname.split('/').reverse()[0];
    console.log("resettoken", resettoken)


    let newPasswd = {...getFieldProps("password")};
    let conFirmPasswd = {...getFieldProps("confirmPassword")};

    console.log('newPasswd :>> ', newPasswd);
    console.log('conFirmPasswd :>> ', conFirmPasswd);
    const payload = {
      newPassword: newPasswd.value,
      resetPasswordToken: resettoken
    };
    console.log("payload", payload);
    const value = resetPassword(payload);
      console.log(value);
  
  };

  const resetPassword = (data) => {
     fetch(`${backendServerBaseURL}/api/v1/auth/reset-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
          },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
      .then(data => {
        console.log('data :>> ', data);
        navigate("/login");
        
      })  

      .catch((error) => {
        throw error
      })
  }  


  const { errors, touched, isSubmitting, getFieldProps } = formik;

  return (
    <>
      <div className="page-body-widthout-sidebar">
        <div className="container">
          <div style={{ minHeight: "70vh" }} className="d-flex align-items-center justify-content-center flex-column">
            <p className="semi-bold-weight mb-1" style={{ fontSize: "24px" }}>
              Reset Password
            </p>
            <p className="body1"></p>
            <div>
              <p className="text-center mb-3">Fill in the following information</p>
            </div>

            <div>
              {!passwordResetSuccessfully && (
                <div className="bg-white border p-4 mb-3" style={{ minWidth: "24rem" }}>
                  {/* Step 1 */}
                  <div>
                    <FormikProvider value={formik}>
                      <Form autoComplete="off" noValidate onSubmit={onResetPassword}>
                        <>
                          {/* Set Password */}
                          <div className="mb-4">
                            <label className="form-label">New Password</label>
                            <input {...getFieldProps("password")} className="form-control form-control-lg" placeholder="New Password" />
                            <span className="invalid-feedback" style={{ display: Boolean(touched.password && errors.password) ? "block" : "none" }}>
                              {errors.password}
                            </span>
                            <p className="mt-2 mb-1">Set a strong password</p>

                            <p className="mb-1">
                              <span style={{ marginRight: "0.3rem" }}>
                                {formik.values.password?.length >= 8 && <img src="/static/icons/passwordCheckStrong.svg" alt="" />}
                                {formik.values.password?.length < 8 && <img src="/static/icons/passwordCheck.svg" alt="" />}
                              </span>
                              Use 8 or more characters.
                            </p>
                            <p className="mb-1">
                              <span style={{ marginRight: "0.3rem" }}>
                                {/^(?=.*[a-z])/.test(formik.values.password) && <img src="/static/icons/passwordCheckStrong.svg" alt="" />}
                                {!/^(?=.*[a-z])/.test(formik.values.password) && <img src="/static/icons/passwordCheck.svg" alt=""/>}
                              </span>
                              Use a minimum of one letter.
                            </p>
                            <p className="mb-1">
                              <span style={{ marginRight: "0.3rem" }}>
                                {/^(?=.*[0-9])/.test(formik.values.password) && <img src="/static/icons/passwordCheckStrong.svg" alt="" />}
                                {!/^(?=.*[0-9])/.test(formik.values.password) && <img src="/static/icons/passwordCheck.svg" alt="" />}
                              </span>
                              Use a minimum of one number.
                            </p>
                          </div>

                          {/* Confirm Password */}
                          <div className="mb-4">
                            <label className="form-label">Confirm Password</label>
                            <input {...getFieldProps("confirmPassword")} className="form-control form-control-lg" placeholder="Confirm Password" />
                            <span
                              className="invalid-feedback"
                              style={{ display: Boolean(touched.confirmPassword && errors.confirmPassword) ? "block" : "none" }}
                            >
                              {errors.confirmPassword}
                            </span>
                          </div>

                          {/* Errors from server */}
                          {errors.afterSubmit && <Alert value={errors.afterSubmit} variant="danger" />}

                          {/* Next Button */}
                          <div className="d-flex justify-content-end mt-3">
                            <LoadingButton
                              type="submit"
                              loading={isSubmitting}
                              className="btn btn-lg btn-primary w-100"
                              disabled={
                                !Boolean(touched.password) ||
                                !Boolean(touched.confirmPassword) ||
                                Boolean(errors.password) ||
                                Boolean(errors.confirmPassword)
                              }
                            >
                              Reset Password
                            </LoadingButton>
                          </div>
                        </>
                      </Form>
                    </FormikProvider>
                  </div>
                </div>
              )}

              {passwordResetSuccessfully && (
                <>
                  <div className="bg-white d-flex flex-column justify-content-center align-items-center border rounded p-4">
                    <img src="/static/illustrations/forgotPasswordLinkSentSuccessfully.svg" alt="" style={{ marginBottom: "1.5rem" }} />

                    <h3 className="mb-2">Password Successfully Reset</h3>
                    <p>Please continue to log in!</p>

                    <button
                      className="btn btn-primary w-100"
                      onClick={() => {
                        navigate("/login");
                      }}
                    >
                      Back to Log In
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;

import React from "react";
import { useState } from "react";
import { ReactSortable } from "react-sortablejs";
import MoveToLearningDialog from "./MoveToLearningDialog";
import { useNavigate, useParams } from "react-router-dom";
import AvatarGroup from "../../../components/common/AvatarGroup";
import { useEffect } from "react";
import { getAllTests, moveToLearning, selecttests, updateselectedTest, updateTestStatus } from "../../../redux/slices/projectSlice";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { formatTime } from "../../../utils/formatTime";
import { backendServerBaseURL } from "../../../utils/backendServerBaseURL";
import TourModal from "../Tour/TourModal";
import { isTypeOwner, isRoleAdmin, isRoleMember } from "../../../utils/permissions";
import { selectallGrowthLevers } from "../../../redux/slices/settingSlice";

function Tests() {
  const allTests = useSelector(selecttests);
  console.log('allTests', allTests)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const projectId = params.projectId;
  console.log('projectId Tests :>> ', projectId);
  const [columnsData, setColumns] = useState({});
  const openedProject = JSON.parse(localStorage.getItem("openedProject", ""));
  const me = JSON.parse(localStorage.getItem("user", ""));
  const allGrowthLevers = useSelector(selectallGrowthLevers);
  console.log('allGrowthLevers Ideas :>> ', allGrowthLevers);
  let leversData = allGrowthLevers.map((x) => {
  return ({
   name:  x.name,
   color: x.color}
  )
});
  // console.log("alltests --",allTests)

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }

    // Update test status
    console.log(result);
    console.log(columns);
    console.log(setColumns);
    dispatch(updateTestStatus({ testId: result.draggableId, projectId, status: columnsData[result.destination.droppableId].name }));
    console.log(result.draggableId);
  };

  const [isLoading, setIsLoading] = useState(true); // Loading state flag

  useEffect(() => {
    dispatch(getAllTests({ projectId }));
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); 
  }, []);

  useEffect(() => {
    setColumns({
      [uuidv4()]: {
        name: "Up Next",
        items: allTests?.filter((test) => test.status === "Up Next"),
      },
      [uuidv4()]: {
        name: "In Progress",
        items: allTests?.filter((test) => test.status === "In Progress"),
      },
      [uuidv4()]: {
        name: "Ready to analyze",
        items: allTests?.filter((test) => test.status === "Ready to analyze"),
      },
    });
  }, [allTests]);

  const TaskCard = (test, provided) => {
    return (
      allTests.length !== 0 && !isLoading ? (
      <div
        onClick={() => {
          navigate(`/projects/${projectId}/tests/${test._id}`);
        }}
        key={`${test._id}`}
  /* Add your hover styles here */
        className="vstack gap-3 border rounded mb-2 p-3 noselect bg-white custom-hover-effect-cards"
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <div className="d-flex justify-content-between">
          
        {leversData.map((lever) => {
                      if (test.lever === lever.name) {
                        let chipColor;
                        switch (lever.color) {
                          case "Blue":
                            chipColor = "blue-chip";
                            break;
                          case "Orange":
                            chipColor = "orange-chip";
                            break;
                          case "Green":
                            chipColor = "green-chip";
                            break;
                          case "Red":
                            chipColor = "red-chip";
                            break;
                          case "Yellow":
                            chipColor = "yellow-chip";
                            break;
                          default:
                            chipColor = "blue-chip";
                            break;
                        }
                        return (
                          <span className={chipColor} key={lever.name}>
                            {test.lever}
                          </span>
                        );
                      }
                      return null;
                    })}
          <p className="m-0 green-chip">{test.score}</p>
        </div>

        <div className="d-flex justify-content-between">
          <p className="m-0"> <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title={test.name}
                      style={{
                        width: "150px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "inline-block",
                      }}
                    >
                      {test.name}
                    </span></p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="m-0">
            <span>
              <img
                src="/static/icons/u_check-square.svg"
                alt=""
                style={{ position: "relative", top: "-1px" }}
              />
            </span>{" "}
            {test.tasks.filter((task) => task.status === true).length}/
            {test.tasks.length}
          </p>
          <p className="m-0">{formatTime(test.dueDate)}</p>
        </div>
        <div className="d-flex justify-content-between">
          <div className="hstack gap-3">
            <img
              src={`${backendServerBaseURL}/${me.avatar}`}
              className="avatar2"
              alt=""
            />

            <AvatarGroup
              listOfUrls={test.assignedTo?.map(
                (member) => `${backendServerBaseURL}/${member.avatar}`
              )}
              show={3}
              total={test.assignedTo.length}
              userName={test.assignedTo?.map((t) => [
                t?.firstName,
                `${backendServerBaseURL}/${t?.avatar}`,
                t?.lastName,
              ])}
            />
          </div>

          <div className="hstack gap-2">
            <p className="m-0">{test.comments.length}</p>
            <i class="bi bi-chat"></i>
          </div>
        </div>

        {test.status === "Ready to analyze" && (
          <button
            className="btn btn-outline-primary"
            data-bs-toggle="modal"
            data-bs-target="#moveToLearningModal"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              dispatch(updateselectedTest(test));
            }}
          >
            Move to learning
          </button>
        )}
      </div>)
    : (isLoading && 
    <div style={{marginTop: "10px"}}>
      <div style={{ borderRadius: "4px",
                  border: "1px solid var(--black-400, #D6D6D6)",
                  background: "var(--color-gray-gray-100, #F3F4F6)",
                  height: "170px"}}>
      <span style={{fontSize: "50px"}}>
        <div className="tests-skeleton-placeholder1"></div>
        <div className="tests-skeleton-placeholder2"></div>
        <div className="tests-skeleton-placeholder2"></div>
        <div className="tests-skeleton-placeholder2"></div>
      </span>
      </div>
      <div>

      </div>
    </div>));
  };

  return (
    <div>
      {/* Header */}
      <div className="d-flex">
        <div>
          <h1 className="mb-1">{openedProject?.name}</h1>
          <p className="text-secondary">
            {allTests?.length == 1
              ? `${allTests?.length} Test`
              : `${allTests?.length} Tests`}
          </p>
        </div>

        <div className="flex-fill d-flex flex-row-reverse"></div>
      </div>

      <div className="row">
        {/* // TODO: Permission Doubt */}
        <DragDropContext
          onDragEnd={(result) =>
             onDragEnd(result, columnsData, setColumns)
              
          }
        >
          {Object.entries(columnsData).map(([columnId, column], index) => {
            return (
              <div className="col-4" key={columnId}>
                <div className="border-bottom mb-3">
                  <p className="mb-2 body3">
                    {column.name} ({column.items?.length})
                  </p>
                </div>

                <div style={{ margin: 8 }}>
                  <Droppable droppableId={columnId}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          style={{
                            backgroundColor: snapshot.isDraggingOver
                              ? "#F3F4F6"
                              : "transparent",
                            height: "100%",
                            padding: "7px",
                            borderRadius: snapshot.isDraggingOver ? "4px" : "",
                            border: snapshot.isDraggingOver ? "1px solid #D1D5DB" : ""
                          }}
                        >
                          {column.items?.map((item, index) => {
                            return (
                              <Draggable
                                key={item._id}
                                draggableId={item._id}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  return TaskCard(item, provided);
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </div>
            );
          })}
        </DragDropContext>
      </div>

      <MoveToLearningDialog />
      <TourModal />
    </div>
  );
}

export default Tests;

import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

import { Form, FormikProvider } from "formik";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createProject,
  editProject,
  selectselectedActionPlan,
} from "../../redux/slices/projectSlice";
import {
  createActionPlan,
  editActionPlan,
  selectSelectedActionPlan,
} from "../../redux/slices/actionPlanSlice";

function CreateNewActionPlanDialog() {
  const dispatch = useDispatch();
  const closeModalRef = useRef();
  const selectedActionPlan = useSelector(selectSelectedActionPlan);

  const NewProjectSchema = Yup.object().shape({
    name: Yup.string().required("Plan Name is required"),
  });

  const closeModal = () => {
    document.elementFromPoint(100, 100).click();
    closeModalRef.current.click();
  };

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validateOnBlur: true,
    validationSchema: NewProjectSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      console.log(values);
      setSubmitting(true);
      if (selectedActionPlan) {
        dispatch(editActionPlan({ ...values, closeModal }));
      } else {
        dispatch(createActionPlan({ ...values, closeModal }));
      }
      setSubmitting(false);
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
    resetForm,
  } = formik;

  useEffect(() => {
    if (selectedActionPlan) {
      formik.setValues({
        name: selectedActionPlan.name,
      });
    } else {
      formik.setValues({
        name: "",
      });
    }
  }, [selectedActionPlan]);

  return (
    <>
      <div className="modal fade" id="createActionPlanModal" tabIndex={-1}>
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ minWidth: "45rem" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <h2>{selectedActionPlan ? "Edit" : "New"} Action Plan</h2>
                  <p
                    style={{ marginBottom: "24px" }}
                    className="text-secondary"
                  >
                    Create an doc
                  </p>

                  <div className="form-field">
                    <label className="form-label">Action Plan Name</label>
                    <input
                      type={"text"}
                      {...getFieldProps("name")}
                      className="form-control form-control-lg"
                      placeholder="Ex. Service based, product, etc."
                    />
                    <span
                      className="invalid-feedback"
                      style={{
                        display: Boolean(touched.name && errors.name)
                          ? "block"
                          : "none",
                      }}
                    >
                      {errors.name}
                    </span>
                  </div>

                  <div className="hstack gap-2 d-flex justify-content-end">
                    <button
                      type="button"
                      class="btn btn-lg btn-outline-danger"
                      data-bs-dismiss="modal"
                      ref={closeModalRef}
                      onClick={() => {
                        document.elementFromPoint(100, 100).click();
                      }}
                    >
                      Close
                    </button>
                    <button type="submit" class="btn btn-lg btn-primary">
                      {selectedActionPlan
                        ? "Update Action Plan"
                        : "Create Action Plan"}
                    </button>
                  </div>
                </Form>
              </FormikProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateNewActionPlanDialog;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  readCheckins,
  readGoals,
  readIdeas,
  readLearnings,
  readTasks,
  readTests,
  selectcheckins,
  selectgoalsData,
  selectideasData,
  selectlearningsData,
  selecttasksAssigned,
  selecttasksCompleted,
  selecttestsData,
} from "../../redux/slices/dashboardSlice";
import { NavLink, useNavigate } from "react-router-dom";
import {
  getAllProjects,
  selectProjects,
  updateTestTaskStatus,
  selectGoals,
} from "../../redux/slices/projectSlice";
import AddWidgetDialog from "./AddWidgetDialog";
import { formatDate2, formatTime } from "../../utils/formatTime";
import { backendServerBaseURL } from "../../utils/backendServerBaseURL";
import AvatarGroup from "../../components/common/AvatarGroup";
import { getAllUsers } from "../../redux/slices/settingSlice";
import { getMe } from "../../redux/slices/generalSlice";

import { Bar } from "react-chartjs-2";
import Spinner from "../../components/common/Spinner";
let options = {
  elements: {
    line: {
      tension: 0, // disables bezier curves
    },
  },
  plugins: {
    legend: {
      display: false,
      position: "bottom",
      labels: {
        color: "#97a4af",
        usePointStyle: true,
      },
    },
  },
  interaction: {
    intersect: false,
    mode: "index",
  },
  scales: {
    yAxes: {
      gridLines: {
        color: "#e7eaf3",
        drawBorder: false,
        zeroLineColor: "#e7eaf3",
      },
      ticks: {
        beginAtZero: true,
        stepSize: 10,
        fontSize: 12,
        color: "#97a4af",
        fontFamily: "Open Sans, sans-serif",
        padding: 10,
        postfix: "k",
      },
    },
    xAxes: {
      grid: {
        display: false,
      },
      gridLines: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        fontSize: 12,
        color: "#97a4af",
        fontFamily: "Open Sans, sans-serif",
        padding: 5,
      },
    },
  },
  tooltips: {
    mode: "index",
    intersect: false,
  },
  hover: {
    mode: "index",
    intersect: false,
  },
};

function Dashboard() {
  const [yourTasks, setyourTasks] = useState([1, 2, 3]);
  const dispatch = useDispatch();
  const tasksAssigned = useSelector(selecttasksAssigned);
  const tasksCompleted = useSelector(selecttasksCompleted);
  const checkins = useSelector(selectcheckins);
  const me = JSON.parse(localStorage.getItem("userData", ""));
  // console.log('me Dashboard:>> ', me);
  const projects = useSelector(selectProjects);
  // console.log('projects DB:>> ', projects);
  const goalsData = useSelector(selectgoalsData);
  console.log("goalsData :>> ", goalsData);
  let goalInfo = goalsData.filter((idea) => idea.owner === me?.id);
// console.log('goalInfo :>> ', goalInfo);
  const isUserInGoalsProjectTeam = goalsData.filter(
    (goal) => goal?.project?.team.includes(me?.id || me?._id) 

  );
  console.log('isUserInGoalsProjectTeam:>> ',isUserInGoalsProjectTeam)
  const testsData = useSelector(selecttestsData);
  // console.log('testsData :>> ', testsData);
  const isUserInTestsProjectTeam = testsData.filter(
    (test) => test?.project?.team.includes(me?.id || me?._id)  

  );
  const learningsData = useSelector(selectlearningsData);
  // console.log('learningsData :>> ', learningsData);
  const isUserInLearningsProjectTeam = learningsData.filter(
    (learning) => learning?.project?.team.includes(me?.id || me?._id) 

  );
  const ideasData = useSelector(selectideasData);
  // console.log('ideasData :>> ', ideasData);
  let ideaInfo = ideasData.some((idea) => idea.owner === me?.id);

  const isUserInTeamIdea = ideasData.filter(
    (idea) => idea?.project?.team.includes(me?.id || me?._id) 

  );
  // console.log('isUserInTeamIdea:>> ', isUserInTeamIdea)

  const navigate = useNavigate();

  const [activeGoalsSelectedProject, setactiveGoalsSelectedProject] =
    useState("");
  const [recentideasSelectedProject, setrecentideasSelectedProject] =
    useState("");
  const [activeTestsSelectedProject, setactiveTestsSelectedProject] =
    useState("");
  const [recentLearningSelectedProject, setrecentLearningSelectedProject] =
    useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Loading state flag

  useEffect(() => {
    dispatch(getMe()); 
    dispatch(getAllProjects());
    dispatch(readTasks());
    dispatch(readTasks());
    dispatch(readCheckins());
    dispatch(readLearnings());
    dispatch(readIdeas());
    dispatch(readGoals());
    dispatch(readTests());
    dispatch(getAllUsers()); 
    setTimeout(() => {
      setShowLoader(false);
    }, 2000);  
  }, []);

  useEffect(() => {
    if (goalsData.length || testsData.length || learningsData.length || ideasData.length || !projects.length) {
      setTimeout(() => {
        setShowLoader(false);
      }, 2000); 

    } else {
      setShowLoader(true); 
    }
  }, [goalsData, testsData, learningsData, ideasData]);

  return (
    <div className="page-body-widthout-sidebar">
      {showLoader && <div 
      style={{
        width: "100%",
        height: "100%",
        zIndex: "999",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        paddingRight: "111px",
        paddingBottom: "150px",
        background: "#f6f7f970"
      }}>
        <Spinner/>
        </div>}
      <div className="d-flex">
        <div className="flex-fill">
          <h1 className="mb-5">Hi {me?.firstName}, Welcome Back</h1>
        </div>
        <div>
          <button
            className="btn btn-outline-primary"
            data-bs-toggle="modal"
            data-bs-target="#AddWidgetDialog"
          >
            Add Widget
          </button>
        </div>
      </div>

      <div className="row">
        {me?.widgets?.activeGoals == false &&
          me?.widgets?.activeTests == false &&
          me?.widgets?.activity == false &&
          me?.widgets?.keyMetrics == false &&
          me?.widgets?.recentIdeas == false &&
          me?.widgets?.recentLearnings == false && (
            <>
              <div className="d-flex align-items-center justify-content-center mt-5">
                <div className="vstack gap-3 text-center">
                  <img
                    src="/static/illustrations/no-projects-found.svg"
                    alt=""
                    height="200px"
                  />
                  <h2 className="body1 regular-weight">
                    Customize your dashboard and <br /> have complete overview
                  </h2>

                  <div>
                    <button
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#AddWidgetDialog"
                    >
                      Add Widget
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}

        {me?.widgets?.yourTasks == true && (
          <>
            {/* Your Tasks */}
            <div className="col-12 col-lg-7 d-flex flex-column pb-5 pe-0 pe-lg-5">
              <div className="d-flex align-items-center justify-space-between">
                <div className="hstack gap-3">
                  <h3>Your Tasks</h3>

                  {tasksAssigned?.length !== 0 && (
                    <div className="dark-green-chip">
                      <p className="body2">{tasksAssigned?.length}</p>
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  background: "#fff",
                  margin: "20px",
                  borderRadius: "3px",
                  border: "1px solid #D6D6D6",
                  padding: "20px",
                }}
              >
                <div
                  className="flex-fill customScrollBarLight"
                  style={{
                    minHeight: "27vh",
                    maxHeight: "27vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  {tasksAssigned?.length !== 0 && (
                    <>
                      <div
                        className="row p-3"
                        style={{ borderBottom: "1px solid #dee2e6!important" }}
                      >
                        <div className="col-4 text-secondary body3 regular-weight">
                          Name
                        </div>
                        <div className="col-4 text-secondary body3 regular-weight">
                          Project
                        </div>
                        <div className="col-2 text-secondary body3 regular-weight">
                          Due
                        </div>
                        <div className="col-2 text-secondary body3 regular-weight"></div>
                      </div>

                      {tasksAssigned.map((task, index) => {
                        return (
                          <NavLink
                            to={`/projects/${tasksAssigned?.project?._id}/tests/${tasksAssigned?.test?._id}`}
                            style={{ color: "black", textDecoration: "none" }}
                          >
                            <section key={`goal_body_${index}`}>
                              <div className="row p-2 m-0 bg-white border mb-1 rounded">
                                <div className="col-4 body3 d-flex">
                                  {task.name}
                                </div>
                                <div className="col-4 body3 regular-weight d-flex ">
                                  Acme Essentials
                                </div>
                                <div className="col-2 body3 regular-weight d-flex">
                                  Jul 16
                                </div>
                                <div
                                  className="col-2 body3 regular-weight d-flex align-items-center justify-content-end"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }}
                                >
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      defaultChecked={task.status}
                                      onClick={(e) => {
                                        dispatch(
                                          updateTestTaskStatus({
                                            status: e.target.checked,
                                            taskId: task._id,
                                          })
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </section>
                          </NavLink>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {me?.widgets?.pendingCheckins == true && (
          <>
            {/* Pending Check-ins */}
            <div className="col-12 col-lg-5 d-flex flex-column pb-5">
              <div className="hstack gap-3">
                <h3>Pending Check-ins</h3>

                {checkins?.filter((c) => c.status !== "On-Track").length !==
                  0 && (
                  <div className="dark-green-chip">
                    <p className="body2">{checkins?.length}</p>
                  </div>
                )}
              </div>

              <div
                className="flex-fill customScrollBarLight"
                style={{
                  background: "#fff",
                  minHeight: "27vh",
                  maxHeight: "27vh",
                  overflowY: "auto",
                  overflowX: "hidden",
                  margin: "20px",
                  borderRadius: "3px",
                  border: "1px solid #D6D6D6",
                  padding: "20px",
                }}
              >
                {checkins?.filter((c) => c.status !== "On-Track").length !==
                  0 && (
                  <>
                    <div className="row p-3">
                      <div className="col-1 text-secondary body3 regular-weight"></div>
                      <div className="col-5 text-secondary body3 regular-weight">
                        Name
                      </div>
                      <div className="col-4 text-secondary body3 regular-weight">
                        Project
                      </div>
                      <div className="col-2 text-secondary body3 regular-weight">
                        Status
                      </div>
                    </div>

                    {checkins
                      .filter((c) => c.status !== "On-Track")
                      .map((checkin, index) => {
                        return (
                          <NavLink
                            to={`/projects/${checkins?.project?._id}/goals/${checkins?.goal?._id}`}
                            style={{ color: "black", textDecoration: "none" }}
                          >
                            <section key={`goal_body_${index}`}>
                              <div className="row p-2 m-0 bg-white border mb-1 rounded">
                                <div className="col-1 body3 d-flex ">
                                  <span style={{ marginRight: "0.4rem" }}>
                                    {checkin.status === "Not-Defined" && (
                                      <div className="gray-chip">
                                        {checkin.metrics.length === 0
                                          ? 0
                                          : Math.round(
                                              (checkin.metrics[
                                                checkin.metrics.length - 1
                                              ].value /
                                                checkin.targetValue) *
                                                100
                                            )}
                                        %
                                      </div>
                                    )}
                                    {checkin.status === "On-Track" && (
                                      <div className="blue-chip">
                                        {checkin.metrics.length === 0
                                          ? 0
                                          : Math.round(
                                              (checkin.metrics[
                                                checkin.metrics.length - 1
                                              ].value /
                                                checkin.targetValue) *
                                                100
                                            )}
                                        %
                                      </div>
                                    )}
                                    {checkin.status === "Off-Track" && (
                                      <div className="yellow-chip">
                                        {checkin.metrics.length === 0
                                          ? 0
                                          : Math.round(
                                              (checkin.metrics[
                                                checkin.metrics.length - 1
                                              ].value /
                                                checkin.targetValue) *
                                                100
                                            )}
                                        %
                                      </div>
                                    )}
                                    {checkin.status === "At-Risk" && (
                                      <div className="red-chip">
                                        {checkin.metrics.length === 0
                                          ? 0
                                          : Math.round(
                                              (checkin.metrics[
                                                checkin.metrics.length - 1
                                              ].value /
                                                checkin.targetValue) *
                                                100
                                            )}
                                        %
                                      </div>
                                    )}
                                    {checkin.status === "Achieved" && (
                                      <div className="green-chip">
                                        {checkin.metrics.length === 0
                                          ? 0
                                          : Math.round(
                                              (checkin.metrics[
                                                checkin.metrics.length - 1
                                              ].value /
                                                checkin.targetValue) *
                                                100
                                            )}
                                        %
                                      </div>
                                    )}
                                  </span>
                                </div>
                                <div className="col-5 body3 regular-weight d-flex ">
                                  {checkin.name}
                                </div>
                                <div className="col-4 body3 regular-weight d-flex ">
                                  Weekly Sales from 10 100
                                </div>
                                <div className="col-2 body3 regular-weight d-flex ">
                                  {checkin.status === "Not-Defined" && (
                                    <div className="gray-chip">
                                      {checkin.status}
                                    </div>
                                  )}
                                  {checkin.status === "On-Track" && (
                                    <div className="blue-chip">
                                      {checkin.status}
                                    </div>
                                  )}
                                  {checkin.status === "Off-Track" && (
                                    <div className="yellow-chip">
                                      {checkin.status}
                                    </div>
                                  )}
                                  {checkin.status === "At-Risk" && (
                                    <div className="red-chip">
                                      {checkin.status}
                                    </div>
                                  )}
                                  {checkin.status === "Achieved" && (
                                    <div className="green-chip">
                                      {checkin.status}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </section>
                          </NavLink>
                        );
                      })}
                  </>
                )}
              </div>
            </div>
          </>
        )}

        {me?.widgets?.recentlyClosedTasks == true && (
          <>
            {/* Recently Closed Tasks */}
            <div className="col-12 col-lg-7 d-flex flex-column pb-5 pe-0 pe-lg-5">
              <div className="hstack gap-3">
                <h3>Recently Closed Tasks</h3>

                {tasksCompleted?.length !== 0 && (
                  <div className="dark-green-chip">
                    <p className="body2">{tasksCompleted?.length}</p>
                  </div>
                )}
              </div>

              <div
                className="flex-fill customScrollBarLight"
                style={{
                  minHeight: "27vh",
                  maxHeight: "27vh",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {tasksCompleted?.length !== 0 && (
                  <>
                    <div className="row p-3">
                      <div className="col-4 text-secondary body3 regular-weight">
                        Name
                      </div>
                      <div className="col-4 text-secondary body3 regular-weight">
                        Project
                      </div>
                      <div className="col-2 text-secondary body3 regular-weight">
                        Due
                      </div>
                      <div className="col-2 text-secondary body3 regular-weight"></div>
                    </div>

                    {tasksCompleted.map((task, index) => {
                      return (
                        <NavLink
                          to={`/projects/${tasksAssigned?.project?._id}/tests/${tasksAssigned?.test?._id}`}
                          style={{ color: "black", textDecoration: "none" }}
                        >
                          <section key={`goal_body_${index}`}>
                            <div className="row p-2 m-0 bg-white border mb-1 rounded">
                              <div className="col-4 body3 d-flex ">
                                {task.name}
                              </div>
                              <div className="col-4 body3 regular-weight d-flex ">
                                Acme Essentials
                              </div>
                              <div className="col-2 body3 regular-weight d-flex ">
                                Jul 16
                              </div>
                              <div className="col-2 body3 regular-weight d-flex  justify-content-end">
                                <div
                                  className="form-check"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }}
                                >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultChecked={task.status}
                                    onClick={(e) => {
                                      dispatch(
                                        updateTestTaskStatus({
                                          status: e.target.checked,
                                          taskId: task._id,
                                        })
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </section>
                        </NavLink>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </>
        )}

        {me?.widgets?.uptodateCheckins == true && (
          <>
            {/* Up-to-date Check-ins */}
            <div className="col-12 col-lg-5 d-flex flex-column pb-5">
              <div className="hstack gap-3">
                <h3>Up-to-date Check-ins</h3>

                {checkins?.filter((c) => c.status === "On-Track").length !==
                  0 && (
                  <div className="dark-green-chip">
                    <p className="body2">{checkins?.length}</p>
                  </div>
                )}
              </div>

              <div
                className="flex-fill customScrollBarLight"
                style={{
                  minHeight: "27vh",
                  maxHeight: "27vh",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {checkins?.filter((c) => c.status === "On-Track").length !==
                  0 && (
                  <>
                    <div className="row p-3">
                      <div className="col-1 text-secondary body3 regular-weight"></div>
                      <div className="col-5 text-secondary body3 regular-weight">
                        Name
                      </div>
                      <div className="col-4 text-secondary body3 regular-weight">
                        Project
                      </div>
                      <div className="col-2 text-secondary body3 regular-weight">
                        Status
                      </div>
                    </div>

                    {checkins
                      .filter((c) => c.status === "On-Track")
                      .map((checkin, index) => {
                        return (
                          <NavLink
                            to={`/projects/${checkins?.project?._id}/goals/${checkins?.goal?._id}`}
                            style={{ color: "black", textDecoration: "none" }}
                          >
                            <section key={`goal_body_${index}`}>
                              <div className="row p-2 m-0 bg-white border mb-1 rounded">
                                <div className="col-1 body3 d-flex ">
                                  <span style={{ marginRight: "0.4rem" }}>
                                    {checkin.status === "Not-Defined" && (
                                      <div className="gray-chip">
                                        {checkin.metrics.length === 0
                                          ? 0
                                          : Math.round(
                                              (checkin.metrics[
                                                checkin.metrics.length - 1
                                              ].value /
                                                checkin.targetValue) *
                                                100
                                            )}
                                        %
                                      </div>
                                    )}
                                    {checkin.status === "On-Track" && (
                                      <div className="blue-chip">
                                        {checkin.metrics.length === 0
                                          ? 0
                                          : Math.round(
                                              (checkin.metrics[
                                                checkin.metrics.length - 1
                                              ].value /
                                                checkin.targetValue) *
                                                100
                                            )}
                                        %
                                      </div>
                                    )}
                                    {checkin.status === "Off-Track" && (
                                      <div className="yellow-chip">
                                        {checkin.metrics.length === 0
                                          ? 0
                                          : Math.round(
                                              (checkin.metrics[
                                                checkin.metrics.length - 1
                                              ].value /
                                                checkin.targetValue) *
                                                100
                                            )}
                                        %
                                      </div>
                                    )}
                                    {checkin.status === "At-Risk" && (
                                      <div className="red-chip">
                                        {checkin.metrics.length === 0
                                          ? 0
                                          : Math.round(
                                              (checkin.metrics[
                                                checkin.metrics.length - 1
                                              ].value /
                                                checkin.targetValue) *
                                                100
                                            )}
                                        %
                                      </div>
                                    )}
                                    {checkin.status === "Achieved" && (
                                      <div className="green-chip">
                                        {checkin.metrics.length === 0
                                          ? 0
                                          : Math.round(
                                              (checkin.metrics[
                                                checkin.metrics.length - 1
                                              ].value /
                                                checkin.targetValue) *
                                                100
                                            )}
                                        %
                                      </div>
                                    )}
                                  </span>
                                </div>
                                <div className="col-5 body3 regular-weight d-flex ">
                                  {checkin.name}
                                </div>
                                <div className="col-4 body3 regular-weight d-flex ">
                                  Weekly Sales from 10 100
                                </div>
                                <div className="col-2 body3 regular-weight d-flex ">
                                  {checkin.status === "Not-Defined" && (
                                    <div className="gray-chip">
                                      {checkin.status}
                                    </div>
                                  )}
                                  {checkin.status === "On-Track" && (
                                    <div className="blue-chip">
                                      {checkin.status}
                                    </div>
                                  )}
                                  {checkin.status === "Off-Track" && (
                                    <div className="yellow-chip">
                                      {checkin.status}
                                    </div>
                                  )}
                                  {checkin.status === "At-Risk" && (
                                    <div className="red-chip">
                                      {checkin.status}
                                    </div>
                                  )}
                                  {checkin.status === "Achieved" && (
                                    <div className="green-chip">
                                      {checkin.status}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </section>
                          </NavLink>
                        );
                      })}
                  </>
                )}
              </div>
            </div>
          </>
        )}

        {/*  */}
        {/*  */}
        {/*  */}

        {me?.widgets?.activeGoals === true &&
        //  goalsData?.length !== 0 ?
          (
          <>
            {" "}
            {/* Active Goals */}
            <div className="col-12 col-lg-6 d-flex flex-column pb-5 pe-0 pe-lg-5">
              <div className="d-flex align-items-center justify-content-between">
                <div className="hstack gap-3">
                  <h3>Active Goals</h3>

                  {goalsData?.length !== 0 && (
                    <div className="dark-green-chip">
                      <p className="body2">{me?.role?.name === "Owner" ? goalsData?.length : isUserInGoalsProjectTeam.length}</p>
                    </div>
                  )}
                </div>

                
                  <select
                    className="form-select"
                    style={{ maxWidth: "10rem" }}
                    onChange={(e) => {
                      setactiveGoalsSelectedProject(e.target.value);
                    }}
                  >
                    <option value="">All Projects</option>


                    {projects?.map((singleProject) => {
                      // Check if the user is the creator or is in the team
                      const isCreatedByUser = singleProject.createdBy._id === me?._id || me.id;
                      const isUserInTeam = singleProject.team.some(
                        (teamMember) => teamMember?._id === me?._id || me.id
                      );

                      if (isCreatedByUser || isUserInTeam) {
                        return (
                          <option key={singleProject._id} value={singleProject._id}>
                            {singleProject?.name}
                          </option>
                        );
                      }

                      return null; // Exclude projects where the user is not the creator or in the team
                    })}
                  </select>
              </div>
              <div
                style={{
                  background: "#fff",
                  margin: "20px 0",
                  borderRadius: "3px",
                  border: "1px solid #D6D6D6",
                }}
              >
                <div
                  className="flex-fill customScrollBarLight"
                  style={{
                    minHeight: "27vh",
                    maxHeight: "27vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  {goalsData?.length !== 0 && (
                    <>
                      <div
                        className="row p-3"
                        style={{ borderBottom: "1px solid #e6dee4", position: "sticky", top: "0", zIndex: "998", background: "#fff" }}
                      >
                        {goalsData?.length ? 
                         <div className="col-4 text-secondary body3 regular-weight">
                          Name
                        </div> : <span class="placeholder col-6"></span>}
                        <div className="col-2 text-secondary body3 regular-weight">
                          Project
                        </div>
                        <div className="col-2 text-secondary body3 regular-weight">
                          Progress
                        </div>
                        <div className="col-2 text-secondary body3 regular-weight">
                          Due date
                        </div>
                        <div className="col-2 text-secondary body3 regular-weight">
                          Members
                        </div>
                      </div>
                    <div>    
                      {me?.role?.name === "Owner" ? goalsData 
                        .filter((g) =>
                          activeGoalsSelectedProject == ""
                            ? true
                            : g.project?._id == activeGoalsSelectedProject
                        )
                        .map((task, index) => {
                          return (
                            <NavLink
                              to={`/projects/${task?.project?._id}/goals/${task?._id}`}
                              style={{ color: "black", textDecoration: "none" }}
                            >
                              <section key={`goal_body_${index}`}>
                                <div
                                  className="row p-2 m-0 bg-white d-flex align-items-center custom-hover-effect"
                                  style={{ borderBottom: "1px solid #e6dee4" }}
                                >
                                  <div
                                    className="col-4 body3 "
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "inline-block",
                                    }}
                                  >
                                    {task.name}
                                  </div>

                                  <div className="col-2 body3 d-flex ">
                                    {task?.project?.name}
                                  </div>

                                  <div className="col-2 body3 regular-weight d-flex ">
                                    <div className="col-2  body3 regular-weight d-flex ">
                                      <div className="flex-fill">
                                        {Math.round(
                                          task.keymetric.reduce(
                                            (partialSum, metric) => {
                                              const lastMetric =
                                                metric.metrics[
                                                  metric.metrics.length - 1
                                                ];
                                              const percentage = lastMetric
                                                ? (lastMetric.value /
                                                    metric.targetValue) *
                                                  100
                                                : 0;
                                              return partialSum + percentage;
                                            },
                                            0
                                          )
                                        )}
                                        %
                                        <div
                                          class="progress"
                                          style={{
                                            height: "4px",
                                            width: "3rem",
                                          }}
                                        >
                                          <div
                                            class="progress-bar"
                                            role="progressbar"
                                            style={{
                                              width: `${Math.round(
                                                task.keymetric.reduce(
                                                  (partialSum, a) =>
                                                    partialSum +
                                                      a.metrics.length ===
                                                    0
                                                      ? 0
                                                      : (a.metrics[
                                                          a.metrics.length - 1
                                                        ]?.value /
                                                          a.targetValue) *
                                                        100,
                                                  0
                                                )
                                              )}%`,
                                            }}
                                          ></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-2 body3 regular-weight d-flex ">
                                    {/* {formatTime(task.startDate)} - */}
                                    {formatTime(task.endDate)}
                                  </div>

                                  <div className="col-2 body3 regular-weight d-flex">
                                    {" "}
                                    <AvatarGroup
                                      listOfUrls={task.members?.map(
                                        (member) =>
                                          `${backendServerBaseURL}/${member.avatar}`
                                      )}
                                      userName={task.members?.map((t) => [
                                        t?.firstName,
                                        `${backendServerBaseURL}/${t?.avatar}`,
                                        t?.lastName,
                                      ])}
                                      show={3}
                                      total={task.members.length}
                                      style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "inline-block",
                                      }}
                                    />
                                  </div>
                                </div>
                              </section>
                            </NavLink>
                          ) 
                        }) : isUserInGoalsProjectTeam.filter((g) =>
                        activeGoalsSelectedProject == ""
                          ? true
                          : g.project?._id == activeGoalsSelectedProject
                      )
                      .map((task, index) => {
                        return (
                          <NavLink
                            to={`/projects/${task?.project?._id}/goals/${task?._id}`}
                            style={{ color: "black", textDecoration: "none" }}
                          >
                            <section key={`goal_body_${index}`}>
                              <div
                                className="row p-2 m-0 bg-white d-flex align-items-center custom-hover-effect"
                                style={{ borderBottom: "1px solid #e6dee4" }}
                              >
                                <div
                                  className="col-4 body3 "
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "inline-block",
                                  }}
                                >
                                  {task.name}
                                </div>

                                <div className="col-2 body3 d-flex ">
                                  {task?.project?.name}
                                </div>

                                <div className="col-2 body3 regular-weight d-flex ">
                                  <div className="col-2  body3 regular-weight d-flex ">
                                    <div className="flex-fill">
                                      {Math.round(
                                        task.keymetric.reduce(
                                          (partialSum, metric) => {
                                            const lastMetric =
                                              metric.metrics[
                                                metric.metrics.length - 1
                                              ];
                                            const percentage = lastMetric
                                              ? (lastMetric.value /
                                                  metric.targetValue) *
                                                100
                                              : 0;
                                            return partialSum + percentage;
                                          },
                                          0
                                        )
                                      )}
                                      %
                                      <div
                                        class="progress"
                                        style={{
                                          height: "4px",
                                          width: "3rem",
                                        }}
                                      >
                                        <div
                                          class="progress-bar"
                                          role="progressbar"
                                          style={{
                                            width: `${Math.round(
                                              task.keymetric.reduce(
                                                (partialSum, a) =>
                                                  partialSum +
                                                    a.metrics.length ===
                                                  0
                                                    ? 0
                                                    : (a.metrics[
                                                        a.metrics.length - 1
                                                      ]?.value /
                                                        a.targetValue) *
                                                      100,
                                                0
                                              )
                                            )}%`,
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-2 body3 regular-weight d-flex ">
                                  {/* {formatTime(task.startDate)} - */}
                                  {formatTime(task.endDate)}
                                </div>

                                <div className="col-2 body3 regular-weight d-flex">
                                  {" "}
                                  <AvatarGroup
                                    listOfUrls={task.members?.map(
                                      (member) =>
                                        `${backendServerBaseURL}/${member.avatar}`
                                    )}
                                    userName={task.members?.map((t) => [
                                      t?.firstName,
                                      `${backendServerBaseURL}/${t?.avatar}`,
                                      t?.lastName,
                                    ])}
                                    show={3}
                                    total={task.members.length}
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "inline-block",
                                    }}
                                  />
                                </div>
                              </div>
                            </section>
                          </NavLink>
                        ) 
                      })}
                        </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        ) 
        // : (
        //   isLoading &&
        //   <div className="col-12 col-lg-6 d-flex flex-column pb-5 pe-0 pe-lg-5" style={{
        //     minHeight: "27vh",
        //     maxHeight: "27vh",
        //     }}>
        //       <div className="d-flex align-items-center justify-content-between">
        //   <table className="table table-borderless mt-2 custom-table-sm">
        //      <thead className="border-none">
        //      <div style={{ display: "flex", justifyContent: "space-between" }}>

        //     <tr style={{width: "100%"}}>
        //       <td className="text-secondary body3 regular-weight" style={{width: "90px",
        //                   maxWidth: "20%",}}>
        //         Name
        //       </td>
        //       </tr>
        //       <tr style={{width: "100%"}}>
        //       <td className="text-secondary body3 regular-weight" style={{  width: "100px",
        //                   maxWidth: "20%",}}>
        //         Project
        //       </td>
        //       </tr>
        //       <tr style={{width: "100%"}}>
        //       <td className="text-secondary body3 regular-weight" style={{ width: "100px",
        //                   maxWidth: "20%",}}>
        //         Progress
        //       </td>
        //       </tr>
        //       <tr style={{width: "100%"}}>
        //       <td className="text-secondary body3 regular-weight" style={{width: "100px",
        //                   maxWidth: "20%",}}>
        //         Due date
        //       </td>
        //       </tr>
        //       <tr style={{width: "100%"}}>
        //       <td  className="text-secondary body3 regular-weight" style={{  width: "100px",
        //                   maxWidth: "20%",}}>
        //         Members
        //       </td>
        //       </tr>
             
        //     </div>
        //   </thead>
        //     <tbody style={{width: "100%"}}>
        //       <tr 
        //         style={{
        //           cursor: "pointer", marginTop: "10px", 
        //           borderRadius: "4px",
        //           border: "1px solid var(--black-400, #D6D6D6)",
        //           background: "var(--color-gray-gray-100, #F3F4F6)"
        //         }}

        //       >
        //         <div className="skeleton-placeholder">
        //           <div style={{ display: "flex", justifyContent: "space-between" }}>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 className="body3 regular-weight align-middle"
        //                 style={{
        //                   width: "90px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB",   
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "100px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "100px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "50px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "100px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
                    
        //           </div>
        //         </div>
        //       </tr>
        //       <tr 
        //         style={{
        //           cursor: "pointer", marginTop: "10px", 
        //           borderRadius: "4px",
        //           border: "1px solid var(--black-400, #D6D6D6)",
        //           background: "var(--color-gray-gray-100, #F3F4F6)"
        //         }}

        //       >
        //         <div className="skeleton-placeholder">
        //           <div style={{ display: "flex", justifyContent: "space-between" }}>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 className="body3 regular-weight align-middle"
        //                 style={{
        //                   width: "90px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB",   
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "100px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "100px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "50px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "100px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
                    
        //           </div>
        //         </div>
        //       </tr>
        //       <tr 
        //         style={{
        //           cursor: "pointer", marginTop: "10px", 
        //           borderRadius: "4px",
        //           border: "1px solid var(--black-400, #D6D6D6)",
        //           background: "var(--color-gray-gray-100, #F3F4F6)"
        //         }}

        //       >
        //         <div className="skeleton-placeholder">
        //           <div style={{ display: "flex", justifyContent: "space-between" }}>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 className="body3 regular-weight align-middle"
        //                 style={{
        //                   width: "90px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB",   
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "100px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "100px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "50px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "100px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
                    
        //           </div>
        //         </div>
        //       </tr>
              
        //     </tbody>
        //   </table> 
        //   </div>
        //   </div>      
        //  )
         }

        {me?.widgets?.recentIdeas == true &&
        //  ideasData?.length !== 0 ?
         (
          <>
            {/* Recent Ideas */}
            <div className="col-12 col-lg-6 d-flex flex-column pb-5 pe-0 pe-lg-5">
              <div className="d-flex align-items-center justify-content-between">
                <div className="hstack gap-3">
                  <h3>Recent Ideas</h3>

                  {ideasData?.length !== 0 && (
                    <div className="dark-green-chip">
                      <p className="body2">{me?.role?.name === "Owner" ? ideasData?.length : isUserInTeamIdea.length}</p>
                    </div>
                  )}
                </div>

                <select
                  className="form-select"
                  style={{ maxWidth: "10rem" }}
                  onChange={(e) => {
                    setrecentideasSelectedProject(e.target.value);
                  }}
                >
                  <option value="">All Projects</option>

                  {projects?.map((singleProject) => {
                      // Check if the user is the creator or is in the team
                      const isCreatedByUser = singleProject.createdBy._id === me?._id || me.id;
                      // if(singleProject.createdBy._id === me.id || singleProject.createdBy._id === me._id) {
                      //       console.log('singleProject.createdBy._id :>> ', singleProject.createdBy._id);
                      // }
                      const isUserInTeam = singleProject.team.some(
                        (teamMember) => teamMember?._id === me.id || me._id
                      );
                      if (isCreatedByUser || isUserInTeam) {
                        return (
                          <option key={singleProject._id} value={singleProject._id}>
                            {singleProject?.name}
                          </option>
                        );
                      }

                      return null; // Exclude projects where the user is not the creator or in the team
                    })}
                </select>
              </div>
              <div
                style={{
                  background: "#fff",
                  margin: "20px 0",
                  borderRadius: "3px",
                  border: "1px solid #D6D6D6",
                }}
              >
                <div
                  className="flex-fill customScrollBarLight"
                  style={{
                    minHeight: "27vh",
                    maxHeight: "27vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  {ideasData?.length !== 0 && (
                    <>
                      <div
                        className="row p-3"
                        style={{ borderBottom: "1px solid #e6dee4", position: "sticky", top: "0", background: "#fff" }}
                      >
                        <div className="col-3 text-secondary body3 regular-weight">
                          Idea Name
                        </div>
                        <div className="col-2 text-secondary body3 regular-weight">
                          Project
                        </div>
                        <div className="col-2 text-secondary body3 regular-weight">
                          Lever
                        </div>
                        <div className="col-2 text-secondary body3 regular-weight">
                          Created by
                        </div>
                        <div className="col-2 text-secondary body3 regular-weight">
                          Created On
                        </div>
                        <div className="col-1 text-secondary body3 regular-weight">
                          I.C.E{" "}
                        </div>
                      </div>

                      {me?.role?.name === "Owner" ? ideasData 
                        .filter((g) =>
                          recentideasSelectedProject == ""
                            ? true
                            : g.project?._id == recentideasSelectedProject
                        ) 
                        .map((task, index) => {
                          return (
                            <NavLink
                              to={`/projects/${task?.project?._id}/ideas/${task?._id}`}
                              style={{ color: "black", textDecoration: "none" }}
                            >
                              <section key={`goal_body_${index}`}>
                                <div
                                  className="row p-2 m-0 bg-white d-flex align-items-center custom-hover-effect"
                                  style={{ borderBottom: "1px solid #dee2e6" }}
                                >
                                  <div
                                    className="col-3 body3"
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "inline-block",
                                      // paddingTop: "8px",
                                    }}
                                  >
                                    {task.name}
                                  </div>

                                  <div className="col-2 body3 d-flex ">
                                    {task?.project?.name}
                                  </div>
                                  <div className="col-2 body3 d-flex ">
                                    <div className="blue-chip">
                                      {task.lever}
                                    </div>
                                  </div>

                                  <div className="col-2 body3 regular-weight d-flex align-items-center">
                                    <img
                                      src={`${backendServerBaseURL}/${
                                        task.createdBy
                                          ? task.createdBy.avatar
                                          : "uploads/default.png"
                                      }`}
                                      alt=""
                                      className="avatar3"
                                      style={{ marginRight: "0.3rem" }}
                                    />
                                    <div
                                      style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "inline-block",
                                        paddinTtop: "15px",
                                      }}
                                    >{`${task?.createdBy?.firstName} ${task?.createdBy?.lastName}`}</div>
                                  </div>

                                  <div className="col-2 body3 regular-weight d-flex ">
                                    {formatTime(task?.createdAt)}
                                  </div>
                                  <div className="col-1 body3 regular-weight d-flex align-items-center">
                                    <div className="green-outline-chip">
                                      {task?.score}
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </NavLink>
                          );                      
                        }) : isUserInTeamIdea.filter((g) =>
                        recentideasSelectedProject == ""
                          ? true
                          : g.project?._id == recentideasSelectedProject
                      ) 
                      .map((task, index) => {
                        return (
                          <NavLink
                            to={`/projects/${task?.project?._id}/ideas/${task?._id}`}
                            style={{ color: "black", textDecoration: "none" }}
                          >
                            <section key={`goal_body_${index}`}>
                              <div
                                className="row p-2 m-0 bg-white d-flex align-items-center custom-hover-effect"
                                style={{ borderBottom: "1px solid #dee2e6" }}
                              >
                                <div
                                  className="col-3 body3"
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "inline-block",
                                    // paddingTop: "8px",
                                  }}
                                >
                                  {task.name}
                                </div>

                                <div className="col-2 body3 d-flex ">
                                  {task?.project?.name}
                                </div>
                                <div className="col-2 body3 d-flex ">
                                  <div className="blue-chip">
                                    {task.lever}
                                  </div>
                                </div>

                                <div className="col-2 body3 regular-weight d-flex align-items-center">
                                  <img
                                    src={`${backendServerBaseURL}/${
                                      task.createdBy
                                        ? task.createdBy.avatar
                                        : "uploads/default.png"
                                    }`}
                                    alt=""
                                    className="avatar3"
                                    style={{ marginRight: "0.3rem" }}
                                  />
                                  <div
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "inline-block",
                                      paddinTtop: "15px",
                                    }}
                                  >{`${task?.createdBy?.firstName} ${task?.createdBy?.lastName}`}</div>
                                </div>

                                <div className="col-2 body3 regular-weight d-flex ">
                                  {formatTime(task?.createdAt)}
                                </div>
                                <div className="col-1 body3 regular-weight d-flex align-items-center">
                                  <div className="green-outline-chip">
                                    {task?.score}
                                  </div>
                                </div>
                              </div>
                            </section>
                          </NavLink>
                        )
                        })}
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )
        // : (
        //   isLoading &&
        //   <div className="col-12 col-lg-6 d-flex flex-column pb-5 pe-0 pe-lg-5" style={{
        //     minHeight: "27vh",
        //     maxHeight: "27vh",
        //     }}>
        //       <div className="d-flex align-items-center justify-content-between">
        //   <table className="table table-borderless mt-2 custom-table-sm">
        //      <thead className="border-none">
        //      <div style={{ display: "flex", justifyContent: "space-between" }}>

        //     <tr style={{width: "100%"}}>
        //       <td className="text-secondary body3 regular-weight" style={{width: "90px",
        //                   maxWidth: "20%",}}>
        //         Idea Name
        //       </td>
        //       </tr>
        //       <tr style={{width: "100%"}}>
        //       <td className="text-secondary body3 regular-weight" style={{  width: "100px",
        //                   maxWidth: "20%",}}>
        //         Project
        //       </td>
        //       </tr>
        //       <tr style={{width: "100%"}}>
        //       <td className="text-secondary body3 regular-weight" style={{ width: "100px",
        //                   maxWidth: "20%",}}>
        //         Lever
        //       </td>
        //       </tr>
        //       <tr style={{width: "100%"}}>
        //       <td className="text-secondary body3 regular-weight" style={{width: "100px",
        //                   maxWidth: "20%",}}>
        //         Created by
        //       </td>
        //       </tr>
        //       <tr style={{width: "100%"}}>
        //       <td  className="text-secondary body3 regular-weight" style={{  width: "100px",
        //                   maxWidth: "20%",}}>
        //         Created on
        //       </td>
        //       </tr>
        //       <tr style={{width: "100%"}}>
        //       <td  className="text-secondary body3 regular-weight" style={{  width: "100px",
        //                   maxWidth: "20%",}}>
        //         I.C.E
        //       </td>
        //       </tr>
        //     </div>
        //   </thead>
        //     <tbody style={{width: "100%"}}>
        //       <tr 
        //         style={{
        //           cursor: "pointer", marginTop: "10px", 
        //           borderRadius: "4px",
        //           border: "1px solid var(--black-400, #D6D6D6)",
        //           background: "var(--color-gray-gray-100, #F3F4F6)"
        //         }}

        //       >
        //         <div className="skeleton-placeholder">
        //           <div style={{ display: "flex", justifyContent: "space-between" }}>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 className="body3 regular-weight align-middle"
        //                 style={{
        //                   width: "90px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB",   
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "100px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "100px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "50px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "10px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
                    
        //           </div>
        //         </div>
        //       </tr>
        //       <tr 
        //         style={{
        //           cursor: "pointer", marginTop: "10px", 
        //           borderRadius: "4px",
        //           border: "1px solid var(--black-400, #D6D6D6)",
        //           background: "var(--color-gray-gray-100, #F3F4F6)"
        //         }}

        //       >
        //         <div className="skeleton-placeholder">
        //           <div style={{ display: "flex", justifyContent: "space-between" }}>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 className="body3 regular-weight align-middle"
        //                 style={{
        //                   width: "90px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB",   
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "100px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "100px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "50px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "10px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
                    
        //           </div>
        //         </div>
        //       </tr>
        //       <tr 
        //         style={{
        //           cursor: "pointer", marginTop: "10px", 
        //           borderRadius: "4px",
        //           border: "1px solid var(--black-400, #D6D6D6)",
        //           background: "var(--color-gray-gray-100, #F3F4F6)"
        //         }}

        //       >
        //         <div className="skeleton-placeholder">
        //           <div style={{ display: "flex", justifyContent: "space-between" }}>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 className="body3 regular-weight align-middle"
        //                 style={{
        //                   width: "90px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB",   
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "100px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "100px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "50px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "10px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
                    
        //           </div>
        //         </div>
        //       </tr>
        //     </tbody>
        //   </table> 
        //   </div>
        //   </div>      
        //  )
         }

        {me?.widgets?.activeTests == true && 
        // testsData.length !== 0 ? 
        (
          <>
            {/* Active Tests */}
            <div className="col-12 col-lg-6 d-flex flex-column pb-5 pe-0 pe-lg-5">
              <div className="d-flex align-items-center justify-content-between">
                <div className="hstack gap-3">
                  <h3>Active Tests</h3>

                  {testsData?.length !== 0 && (
                    <div className="dark-green-chip">
                      <p className="body2">{me?.role?.name === "Owner" ? testsData?.length : isUserInTestsProjectTeam.length}</p>
                    </div>
                  )}
                </div>

                <select
                  className="form-select"
                  style={{ maxWidth: "10rem" }}
                  onChange={(e) => {
                    setactiveTestsSelectedProject(e.target.value);
                  }}
                >
                  <option value="">All Projects</option>

                  {projects?.map((singleProject) => {
                      // Check if the user is the creator or is in the team
                      const isCreatedByUser = singleProject.createdBy._id === me?._id || me.id;
                      const isUserInTeam = singleProject.team.some(
                        (teamMember) => teamMember?._id === me?._id || me.id
                      );

                      if (isCreatedByUser || isUserInTeam) {
                        return (
                          <option key={singleProject._id} value={singleProject._id}>
                            {singleProject?.name}
                          </option>
                        );
                      }

                      return null; // Exclude projects where the user is not the creator or in the team
                    })}
                </select>
              </div>
              <div
                style={{
                  background: "#fff",
                  margin: "20px 0",
                  borderRadius: "3px",
                  border: "1px solid #D6D6D6",
                }}
              >
                <div
                  className="flex-fill customScrollBarLight"
                  style={{
                    minHeight: "27vh",
                    maxHeight: "27vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  {testsData?.length !== 0 && (
                    <>
                      <div
                        className="row p-3"
                        style={{ borderBottom: "1px solid #e6dee4", position: "sticky", top: "0", background: "#fff" }}
                      >
                        <div className="col-4 text-secondary body3 regular-weight">
                          Name
                        </div>
                        <div className="col-2 text-secondary body3 regular-weight">
                          Project
                        </div>
                        <div className="col-2 text-secondary body3 regular-weight">
                          Status
                        </div>
                        <div className="col-2 text-secondary body3 regular-weight">
                          Due Date
                        </div>
                        <div className="col-2 text-secondary body3 regular-weight">
                          ICE Score
                        </div>
                      </div>

                      {me?.role?.name === "Owner" ? testsData
                        .filter((g) =>
                          activeTestsSelectedProject == ""
                            ? true
                            : g.project?._id == activeTestsSelectedProject
                        )
                        .map((task, index) => {
                          return (
                            <NavLink
                              to={`/projects/${task?.project?._id}/tests/${task?._id}`}
                              style={{ color: "black", textDecoration: "none" }}
                            >
                              <section key={`goal_body_${index}`}>
                                <div
                                  className="row p-2 m-0 bg-white d-flex align-items-center custom-hover-effect"
                                  style={{ borderBottom: "1px solid #e6dee4" }}
                                >
                                  <div
                                    className="col-4 body3"
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "inline-block",
                                    }}
                                  >
                                    {task?.name}
                                  </div>
                                  <div className="col-2 body3 d-flex ">
                                    {task?.project?.name}
                                  </div>
                                  <div className="col-2 body3 d-flex ">
                                    {task?.status}
                                  </div>
                                  <div className="col-2 body3 d-flex ">
                                    {formatTime(task?.dueDate)}
                                  </div>
                                  <div className="col-2 body3 d-flex align-items-center">
                                    <>
                                      <div className="green-outline-chip">
                                        {task?.score}
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </section>
                            </NavLink>
                          );
                        }) : isUserInTestsProjectTeam .filter((g) =>
                        activeTestsSelectedProject == ""
                          ? true
                          : g.project?._id == activeTestsSelectedProject
                      )
                      .map((task, index) => {
                        return (
                          <NavLink
                            to={`/projects/${task?.project?._id}/tests/${task?._id}`}
                            style={{ color: "black", textDecoration: "none" }}
                          >
                            <section key={`goal_body_${index}`}>
                              <div
                                className="row p-2 m-0 bg-white d-flex align-items-center custom-hover-effect"
                                style={{ borderBottom: "1px solid #e6dee4" }}
                              >
                                <div
                                  className="col-4 body3"
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "inline-block",
                                  }}
                                >
                                  {task?.name}
                                </div>
                                <div className="col-2 body3 d-flex ">
                                  {task?.project?.name}
                                </div>
                                <div className="col-2 body3 d-flex ">
                                  {task?.status}
                                </div>
                                <div className="col-2 body3 d-flex ">
                                  {formatTime(task?.dueDate)}
                                </div>
                                <div className="col-2 body3 d-flex align-items-center">
                                  <>
                                    <div className="green-outline-chip">
                                      {task?.score}
                                    </div>
                                  </>
                                </div>
                              </div>
                            </section>
                          </NavLink>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        ) 
        // : (
        //   isLoading &&
        //   <div className="col-12 col-lg-6 d-flex flex-column pb-5 pe-0 pe-lg-5" style={{
        //     minHeight: "27vh",
        //     maxHeight: "27vh",
        //     }}>
        //       <div className="d-flex align-items-center justify-content-between">
        //   <table className="table table-borderless mt-2 custom-table-sm">
        //      <thead className="border-none">
        //      <div style={{ display: "flex", justifyContent: "space-between" }}>

        //     <tr style={{width: "100%"}}>
        //       <td className="text-secondary body3 regular-weight" style={{width: "90px",
        //                   maxWidth: "20%",}}>
        //          Name
        //       </td>
        //       </tr>
        //       <tr style={{width: "100%"}}>
        //       <td className="text-secondary body3 regular-weight" style={{  width: "100px",
        //                   maxWidth: "20%",}}>
        //         Project
        //       </td>
        //       </tr>
        //       <tr style={{width: "100%"}}>
        //       <td className="text-secondary body3 regular-weight" style={{ width: "100px",
        //                   maxWidth: "20%",}}>
        //         Status
        //       </td>
        //       </tr>
        //       <tr style={{width: "100%"}}>
        //       <td className="text-secondary body3 regular-weight" style={{width: "100px",
        //                   maxWidth: "20%",}}>
        //         Due date  
        //       </td>
        //       </tr>
             
        //       <tr style={{width: "100%"}}>
        //       <td  className="text-secondary body3 regular-weight" style={{  width: "100px",
        //                   maxWidth: "20%",}}>
        //         I.C.E
        //       </td>
        //       </tr>
        //     </div>
        //   </thead>
        //     <tbody style={{width: "100%"}}>
        //       <tr 
        //         style={{
        //           cursor: "pointer", marginTop: "10px", 
        //           borderRadius: "4px",
        //           border: "1px solid var(--black-400, #D6D6D6)",
        //           background: "var(--color-gray-gray-100, #F3F4F6)"
        //         }}

        //       >
        //         <div className="skeleton-placeholder">
        //           <div style={{ display: "flex", justifyContent: "space-between" }}>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 className="body3 regular-weight align-middle"
        //                 style={{
        //                   width: "90px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB",   
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "100px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "100px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "50px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "10px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
                    
        //           </div>
        //         </div>
        //       </tr>
        //       <tr 
        //         style={{
        //           cursor: "pointer", marginTop: "10px", 
        //           borderRadius: "4px",
        //           border: "1px solid var(--black-400, #D6D6D6)",
        //           background: "var(--color-gray-gray-100, #F3F4F6)"
        //         }}

        //       >
        //         <div className="skeleton-placeholder">
        //           <div style={{ display: "flex", justifyContent: "space-between" }}>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 className="body3 regular-weight align-middle"
        //                 style={{
        //                   width: "90px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB",   
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "100px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "100px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "50px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "10px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
                    
        //           </div>
        //         </div>
        //       </tr>
        //       <tr 
        //         style={{
        //           cursor: "pointer", marginTop: "10px", 
        //           borderRadius: "4px",
        //           border: "1px solid var(--black-400, #D6D6D6)",
        //           background: "var(--color-gray-gray-100, #F3F4F6)"
        //         }}

        //       >
        //         <div className="skeleton-placeholder">
        //           <div style={{ display: "flex", justifyContent: "space-between" }}>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 className="body3 regular-weight align-middle"
        //                 style={{
        //                   width: "90px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB",   
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "100px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "100px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "50px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "10px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
                    
        //           </div>
        //         </div>
        //       </tr>
        //     </tbody>
        //   </table> 
        //   </div>
        //   </div>      
        //  )
         }

        {me?.widgets?.recentLearnings == true && 
        // learningsData.length !==0 ?
         (
          <>
            {/* Recent Learnings */}
            <div className="col-12 col-lg-6 d-flex flex-column pb-5 pe-0 pe-lg-5">
              <div className="d-flex align-items-center justify-content-between">
                <div className="hstack gap-3">
                  <h3>Recent Learnings</h3>

                  {learningsData?.length !== 0 && (
                    <div className="dark-green-chip">
                      <p className="body2">{me?.role?.name === "Owner" ? learningsData?.length : isUserInLearningsProjectTeam.length}</p>
                    </div>
                  )}
                </div>

                <select
                  className="form-select"
                  style={{ maxWidth: "10rem" }}
                  onChange={(e) => {
                    setrecentLearningSelectedProject(e.target.value);
                  }}
                >
                  <option value="">All Projects</option>

                  {projects?.map((singleProject) => {
                      // Check if the user is the creator or is in the team
                      const isCreatedByUser = singleProject.createdBy._id === me?._id || me.id;
                      const isUserInTeam = singleProject.team.some(
                        (teamMember) => teamMember?._id === me?._id || me.id
                      );

                      if (isCreatedByUser || isUserInTeam) {
                        return (
                          <option key={singleProject._id} value={singleProject._id}>
                            {singleProject?.name}
                          </option>
                        );
                      }

                      return null; // Exclude projects where the user is not the creator or in the team
                    })}
                </select>
              </div>
              <div
                style={{
                  background: "#fff",
                  margin: "20px 0",
                  borderRadius: "3px",
                  border: "1px solid #D6D6D6",
                }}
              >
                <div
                  className="flex-fill customScrollBarLight"
                  style={{
                    minHeight: "27vh",
                    maxHeight: "27vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  {learningsData?.length !== 0 && (
                    <>
                      <div
                        className="row p-3"
                        style={{ borderBottom: "1px solid #e6dee4", position: "sticky", top: "0", background: "#fff" }}
                      >
                        <div className="col-4 text-secondary body3 regular-weight">
                          Name
                        </div>
                        <div className="col-2 text-secondary body3 regular-weight">
                          Project
                        </div>
                        <div className="col-2 text-secondary body3 regular-weight">
                          Result
                        </div>
                        <div className="col-2 text-secondary body3 regular-weight">
                          Created On
                        </div>
                        {/* <div className="col-2 text-secondary body3 regular-weight">ICE Score</div> */}
                      </div>

                      {me.role?.name === "Owner" ? learningsData
                        .filter((g) =>
                          recentLearningSelectedProject == ""
                            ? true
                            : g.project?._id == recentLearningSelectedProject
                        )
                        .map((task, index) => {
                          return (
                            <NavLink
                              to={`/projects/${task?.project?._id}/learnings/${task?._id}`}
                              style={{ color: "black", textDecoration: "none" }}
                            >
                              <section key={`goal_body_${index}`}>
                                <div
                                  className="row m-0 bg-white custom-hover-effect"
                                  style={{
                                    borderBottom: "1px solid #e6dee4",
                                    padding: "13.5px 0",
                                  }}
                                >
                                  <div
                                    className="col-4 body3 "
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "inline-block",
                                    }}
                                  >
                                    {task.name}
                                  </div>
                                  <div className="col-2 body3 d-flex ">
                                    {task?.project?.name}
                                  </div>
                                  <div className="col-2 body3 d-flex ">
                                    {task.result}
                                  </div>
                                  <div className="col-2 body3 d-flex ">
                                    {formatTime(task.createdAt)}
                                  </div>
                                  {/* <div className="col-2 body3 d-flex "> */}
                                  <>
                                    {/* <div className="green-outline-chip">{task.score}</div> */}
                                  </>
                                  {/* </div> */}
                                </div>
                              </section>
                            </NavLink>
                          );
                        }) : isUserInLearningsProjectTeam.filter((g) =>
                        recentLearningSelectedProject == ""
                          ? true
                          : g.project?._id == recentLearningSelectedProject
                      )
                      .map((task, index) => {
                        return (
                          <NavLink
                            to={`/projects/${task?.project?._id}/learnings/${task?._id}`}
                            style={{ color: "black", textDecoration: "none" }}
                          >
                            <section key={`goal_body_${index}`}>
                              <div
                                className="row m-0 bg-white custom-hover-effect"
                                style={{
                                  borderBottom: "1px solid #e6dee4",
                                  padding: "13.5px 0",
                                }}
                              >
                                <div
                                  className="col-4 body3 "
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "inline-block",
                                  }}
                                >
                                  {task.name}
                                </div>
                                <div className="col-2 body3 d-flex ">
                                  {task?.project?.name}
                                </div>
                                <div className="col-2 body3 d-flex ">
                                  {task.result}
                                </div>
                                <div className="col-2 body3 d-flex ">
                                  {formatTime(task.createdAt)}
                                </div>
                                {/* <div className="col-2 body3 d-flex "> */}
                                <>
                                  {/* <div className="green-outline-chip">{task.score}</div> */}
                                </>
                                {/* </div> */}
                              </div>
                            </section>
                          </NavLink>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )
        // : 
        //  (
        //   isLoading &&
        //   <div className="col-12 col-lg-6 d-flex flex-column pb-5 pe-0 pe-lg-5" style={{
        //     minHeight: "27vh",
        //     maxHeight: "27vh",
        //     }}>
        //       <div className="d-flex align-items-center justify-content-between">
        //   <table className="table table-borderless mt-2 custom-table-sm">
        //      <thead className="border-none">
        //      <div style={{ display: "flex", justifyContent: "space-between" }}>

        //     <tr style={{width: "100%"}}>
        //       <td className="text-secondary body3 regular-weight" style={{width: "90px",
        //                   maxWidth: "20%",}}>
        //        Name
        //       </td>
        //       </tr>
        //       <tr style={{width: "100%"}}>
        //       <td className="text-secondary body3 regular-weight" style={{  width: "100px",
        //                   maxWidth: "20%",}}>
        //         Project
        //       </td>
        //       </tr>
        //       <tr style={{width: "100%"}}>
        //       <td className="text-secondary body3 regular-weight" style={{ width: "100px",
        //                   maxWidth: "20%",}}>
        //         Result
        //       </td>
        //       </tr>
        //       <tr style={{width: "100%"}}>
        //       <td className="text-secondary body3 regular-weight" style={{width: "100px",
        //                   maxWidth: "20%",}}>
        //         Created on
        //       </td>
        //       </tr>
        //     </div>
        //   </thead>
        //     <tbody style={{width: "100%"}}>
        //       <tr 
        //         style={{
        //           cursor: "pointer", marginTop: "10px", 
        //           borderRadius: "4px",
        //           border: "1px solid var(--black-400, #D6D6D6)",
        //           background: "var(--color-gray-gray-100, #F3F4F6)"
        //         }}

        //       >
        //         <div className="skeleton-placeholder">
        //           <div style={{ display: "flex", justifyContent: "space-between" }}>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 className="body3 regular-weight align-middle"
        //                 style={{
        //                   width: "90px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB",   
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "100px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "100px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "50px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
                   
                    
        //           </div>
        //         </div>
        //       </tr>
        //       <tr 
        //         style={{
        //           cursor: "pointer", marginTop: "10px", 
        //           borderRadius: "4px",
        //           border: "1px solid var(--black-400, #D6D6D6)",
        //           background: "var(--color-gray-gray-100, #F3F4F6)"
        //         }}

        //       >
        //         <div className="skeleton-placeholder">
        //           <div style={{ display: "flex", justifyContent: "space-between" }}>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 className="body3 regular-weight align-middle"
        //                 style={{
        //                   width: "90px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB",   
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "100px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "100px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "50px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //           </div>
        //         </div>
        //       </tr>
        //       <tr 
        //         style={{
        //           cursor: "pointer", marginTop: "10px", 
        //           borderRadius: "4px",
        //           border: "1px solid var(--black-400, #D6D6D6)",
        //           background: "var(--color-gray-gray-100, #F3F4F6)"
        //         }}

        //       >
        //         <div className="skeleton-placeholder">
        //           <div style={{ display: "flex", justifyContent: "space-between" }}>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 className="body3 regular-weight align-middle"
        //                 style={{
        //                   width: "90px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB",   
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "100px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "100px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
        //             <div>
        //               <td
        //                 data-bs-placement="bottom"
        //                 style={{
        //                   width: "50px",
        //                   maxWidth: "20%",
        //                   borderRadius: "4px",
        //                   background: "#E5E7EB", 
        //                   height: "5px !important",
        //                 }}
        //               >
        //               </td>
        //             </div>
                  
                    
        //           </div>
        //         </div>
        //       </tr>
        //     </tbody>
        //   </table> 
        //   </div>
        //   </div>      
        //  )
         }

        {/* {me?.widgets?.keyMetrics == true && (
          <>
            Key Metrics
            <div
              className="col-12 col-lg-6 d-flex flex-column pb-5 pe-0 pe-lg-5 customScrollBarLight"
              style={{ maxHeight: "25rem", overflowY: "scroll" }}
            >
              <div className="d-flex align-items-center justify-content-between">
                <div className="hstack gap-3">
                  <h3>Key Metrics</h3>

                  {testsData?.length !== 0 && (
                    <div className="dark-green-chip">
                      <p className="body2">{testsData?.length}</p>
                    </div>
                  )}
                </div>

                <select
                  className="form-select"
                  style={{ maxWidth: "10rem" }}
                  onChange={(e) => {
                    setactiveTestsSelectedProject(e.target.value);
                  }}
                >
                  <option value="">All Projects</option>

                  {projects?.map((singleProject) => {
                    return <option value={singleProject._id}>{singleProject?.name}</option>;
                  })}
                </select>
              </div>

              {checkins?.map((singleKeyMetric) => {
                return (
                  <div
                    className="flex-fill customScrollBarLight mt-4"
                    style={{ minHeight: "35vh", maxHeight: "35vh", overflowY: "auto", overflowX: "hidden" }}
                  >
                    <div className="row align-items-center">
                      <div className="col-7">
                        <Bar
                          data={{
                            labels: singleKeyMetric?.metrics?.map((m) => formatDate2(m.date)),
                            datasets: [
                              {
                                data: singleKeyMetric?.metrics?.map((m) => m.value),
                                label: "Users",
                                barThickness: 30,
                                backgroundColor: "#19b351",
                                borderColor: "#19b351",
                                borderWidth: 2,
                                lineTension: 0.4,
                                pointRadius: 0,
                                hoverBorderColor: "#19b351",
                                pointBackgroundColor: "#19b351",
                                pointBorderColor: "#19b351",
                                pointHoverRadius: 0,
                              },
                            ],
                          }}
                          options={options}
                        />
                      </div>
                      <div className="col-5">
                        <div>
                          <p className="mb-1 body1">Start Value</p>
                          <h6 className="h3 mb-0">{singleKeyMetric?.startValue}</h6>
                          <p className="text-secondary">{formatTime(singleKeyMetric?.createdAt)}</p>
                        </div>

                        <hr />

                        <div>
                          <p className="mb-1 body1">Current Value</p>
                          <h6 className="h3 mb-0">{singleKeyMetric?.metrics?.length == 0 ? 0 : singleKeyMetric?.metrics[0]?.value}</h6>
                          <p className="text-secondary">
                            {singleKeyMetric?.metrics?.length == 0 ? "-" : formatTime(singleKeyMetric?.metrics[0]?.date)}
                          </p>
                        </div>

                        <hr />

                        <div>
                          <p className="mb-1 body1">Target Value</p>
                          <h6 className="h3 mb-0">{singleKeyMetric?.targetValue}</h6>
                          <p className="text-secondary">{formatTime(singleKeyMetric?.createdAt)}</p>
                        </div>

                        <hr />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )} */}
      </div>

      <AddWidgetDialog />
    </div>
  );
}

export default Dashboard;

import { Form, FormikProvider, useFormik } from "formik";
// import { useState } from "react";
import * as Yup from "yup";
import { useEffect } from "react";
import LoadingButton from "../../components/common/LoadingButton";
// import { useDispatch } from "react-redux";
// import { loginUser } from "../../redux/slices/generalSlice";
import Alert from "../../components/common/Alert";
import { useNavigate } from "react-router-dom";
import { backendServerBaseURL } from "../../utils/backendServerBaseURL";
// import { loginsuperOwner } from "../redux/slices/adminSlice";

export default function ForgotPassword() {
  // const [passwordHide, setpasswordHide] = useState(true);
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  const RegisterSchema = Yup.object().shape({
    email: Yup.string().email("Enter valid email").required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      setSubmitting(true);
      // await dispatch(loginUser({ ...values, setErrors }));
      setSubmitting(false);
    },
  });

  const { errors, touched, isSubmitting, getFieldProps } = formik;

  useEffect(() => {
    if (localStorage.getItem("accessToken", null) !== null && localStorage.getItem("accessToken", null) !== undefined) {
      window.open("/dashboard", "_self");
    }
  }, []);


  const onForgotPassword = () => {

    let emailVal = {...getFieldProps("email")};

    const payload = {
      email:  emailVal.value,
    };
    const value =  forgotPassword(payload);
   console.log(value);

  };

  const forgotPassword = (data) =>{
     fetch(`${backendServerBaseURL}/api/v1/auth/forgot-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
      .then(data => {
        console.log('data :>> ', data);
        navigate("/forgot-password-link-sent-successfully");
        
      })  
      .catch((error) => {
        console.log("errorerror", error);
       
        throw error
      })
  }

  return (
    <>
      <div style={{ minHeight: "80vh" }} className="d-flex align-items-center justify-content-center">
        <div className="container col-3">
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={onForgotPassword}>
              <div className="text-center">
                <div className="mb-4">
                  <h1 className="mb-2">Forgot Password</h1>
                  <p className="text-center">Fill in the following information</p>
                </div>
              </div>

              <div className="bg-white border rounded p-4">
                {/* Email */}
                <div className="mb-4">
                  <label className="form-label">Email</label>
                  <input {...getFieldProps("email")} className="form-control form-control-lg" placeholder="Email Address" />
                  <p className="mb-0 text-secondary">Enter email linked to your account</p>
                  <span className="invalid-feedback" style={{ display: Boolean(touched.email && errors.email) ? "block" : "none" }}>
                    {errors.email}
                  </span>
                </div>

                {/* Errors from server */}
                {errors.afterSubmit && <Alert value={errors.afterSubmit} variant="danger" />}

                <div className="d-grid mt-3">
                  <LoadingButton type="submit" loading={isSubmitting} className="btn btn-lg btn-primary ">
                    Reset Password
                  </LoadingButton>
                </div>
              </div>
            </Form>
          </FormikProvider>

          <p
            className="text-primary mt-3 text-center"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/login");
            }}
          >
            Back to Log In
          </p>
        </div>
      </div>
    </>
  );
}

import { Form, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { useEffect } from "react";
import LoadingButton from "../../components/common/LoadingButton";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/slices/generalSlice";
import Alert from "../../components/common/Alert";
import { useNavigate } from "react-router-dom";
// import { loginsuperOwner } from "../redux/slices/adminSlice";

export default function ForgotPasswordLinkSentSuccessfully() {
  const [passwordHide, setpasswordHide] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const RegisterSchema = Yup.object().shape({
    email: Yup.string().email("Enter valid email").required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      setSubmitting(true);
      // await dispatch(loginUser({ ...values, setErrors }));
      setSubmitting(false);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  useEffect(() => {
    if (localStorage.getItem("accessToken", null) !== null && localStorage.getItem("accessToken", null) !== undefined) {
      window.open("/dashboard", "_self");
    }
  }, []);

  return (
    <>
      <div style={{ minHeight: "80vh" }} className="d-flex align-items-center justify-content-center">
        <div className="container col-3">
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <div className="text-center">
                <div className="mb-4">
                  <h1 className="mb-2">Forgot Password</h1>
                  <p className="text-center">Fill in the following information</p>
                </div>
              </div>

              <div className="bg-white d-flex flex-column justify-content-center align-items-center border rounded p-4">
                <img src="/static/illustrations/forgotPasswordLinkSentSuccessfully.svg" alt="" style={{ marginBottom: "1.5rem" }} />

                <h3>Email Sent!</h3>
                <p>We’ve forwarded further instructions to your email</p>

                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  Back to Log In
                </button>
              </div>
            </Form>
          </FormikProvider>
        </div>
      </div>
    </>
  );
}

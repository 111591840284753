import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AvatarGroup from "../../components/common/AvatarGroup";
import {
  archiveProject,
  getAllArchievedProjects,
  getAllProjects,
  getAllUsers,
  selectProjects,
  unarchiveProject,
  updateProjectSearch,
  updateProjectSelectedTab,
  updateProjectStatus,
  updateSelectedProject,
} from "../../redux/slices/projectSlice";
import { backendServerBaseURL } from "../../utils/backendServerBaseURL";
import { formatTime } from "../../utils/formatTime";
import { hasPermission_create_project, isTypeOwner, isRoleAdmin, isRoleMember, hasPermission_delete_project } from "../../utils/permissions";
import CreateNewProjectDialog from "./CreateNewProjectDialog";
import DeleteProjectDialog from "./DeleteProjectDialog";

function Projects() {
  const [selectedMenu, setselectedMenu] = useState("All");
  const projects = useSelector(selectProjects);
  console.log("projects --",projects)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const statusDropdownRef = useRef();
  const newProjectDialogRef = useRef();
  const [projectToDelete, setprojectToDelete] = useState(null);
  const [dropdownIsOpen, setdropdownIsOpen] = useState(false);
  const ProjectsMenus = [
    {
      name: "All",
    },
    {
      name: "Active",
    },
    {
      name: "Completed",
    },
    {
      name: "On Hold",
    },
  ];

  const RightProjectsMenus = hasPermission_create_project() || isTypeOwner() || isRoleAdmin() 
    ? [
        {
          name: "Archived",
        },
      ]
    : [];

    const [isLoading, setIsLoading] = useState(true); // Loading state flag


  useEffect(() => {
    if (selectedMenu !== "Archived") {
      dispatch(updateProjectSelectedTab(selectedMenu));
      dispatch(getAllProjects());
    } else {
      dispatch(getAllArchievedProjects());
    }

    dispatch(getAllUsers());
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); 
  }, [selectedMenu]);

  useEffect(() => {
    if (localStorage.getItem("openNewProjectDialog", null) === "1") {
      newProjectDialogRef.current.click();
      localStorage.setItem("openNewProjectDialog", "0");
    }
  }, []);

  return (
    <div className="page-body-widthout-sidebar">
      <div className="d-flex">
        <div>
          <h1 className="mb-1">Projects</h1>
          <p className="text-secondary">
            {
              projects?.filter((p) => {
                if (selectedMenu === "All") return !p?.isArchived;
                if (selectedMenu === "Active") return !p?.isArchived && p?.status === "Active";
                if (selectedMenu === "On Hold") return !p?.isArchived && p?.status === "On Hold";
                if (selectedMenu === "Completed") return !p?.isArchived && p?.status === "Completed";
                if (selectedMenu === "Archived") return p?.isArchived;
                return true;
              }).length
            }{" "}
            Projects
          </p>
        </div>

        <div className="flex-fill d-flex flex-row-reverse">
          <div className="hstack gap-3" style={{ padding: 0 }}>
            <div className="input-group" style={{ maxWidth: "34rem", zIndex: 1 }}>
              <input
                type="text"
                className="form-control"
                placeholder="Search Projects"
                onChange={(e) => {
                  dispatch(updateProjectSearch(e.target.value));
                  dispatch(getAllProjects());
                }}
              />
              <span className="input-group-text bg-white" style={{ cursor: "pointer" }}>
                <img src="/static/icons/search.svg" alt="" />
              </span>
            </div>

            {hasPermission_create_project()   ? (
              <button
                type="button"
                ref={newProjectDialogRef}
                className="btn btn-primary"
                style={{ minWidth: "10rem" }}
                data-bs-toggle="modal"
                data-bs-target="#createProjectModal"
                onClick={() => {
                  dispatch(updateSelectedProject(null));
                }}
              >
                New Project +
              </button>
            ): <div></div>}
          </div>
        </div>
      </div>

      <div className="border-bottom mt-3">
        <div className="flex-fill d-flex align-items-center">
          {ProjectsMenus?.map((menu) => {
            return (
              <div
                style={{ textDecoration: "none" }}
                className="text-dark body3 regular-weight cp"
                onClick={() => {
                  setselectedMenu(menu.name);
                }}
              >
                <div
                  className={selectedMenu === menu.name ? "text-center border-bottom border-primary border-3" : "text-center pb-1"}
                  style={{ minWidth: "7rem" }}
                >
                  <p className="mb-1">{menu.name}</p>
                </div>
              </div>
            );
          })}

          <div className="flex-fill ml-auto"></div>

          {RightProjectsMenus?.map((menu) => {
            return (
              <div
                style={{ textDecoration: "none" }}
                className="text-dark body3 regular-weight cp"
                onClick={() => {
                  setselectedMenu(menu.name);
                }}
              >
                <div
                  className={selectedMenu === menu.name ? "text-center border-bottom border-primary border-3" : "text-center pb-1"}
                  style={{ minWidth: "7rem" }}
                >
                  <p className="mb-1">{menu.name}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {projects?.length === 0 && !isLoading && (
        <div className="d-flex align-items-center justify-content-center mt-5">
          <div className="vstack gap-3 text-center">
            <img src="/static/illustrations/no-projects-found.svg" alt="" height="200px" />
            <h2 className="body1 regular-weight">No Projects Found</h2>
            {selectedMenu === "All Projects" && (
              <div>
                <button
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#createProjectModal"
                  onClick={() => {
                    dispatch(updateSelectedProject(null));
                  }}
                >
                  Create My First Project
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {projects?.length !== 0 ? (
        <table className="table table-borderless mt-2 custom-table-sm">
          <thead className="border-none">
            <tr>
              <td scope="col" className="text-secondary body3 regular-weight">
                Name
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Created
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Owner
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Members
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Goals
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Status
              </td>
            </tr>
          </thead>
          <tbody>
            {projects
              ?.filter((p) => {
                if (selectedMenu === "All") return !p?.isArchived;
                if (selectedMenu === "Active") return !p?.isArchived && p?.status === "Active";
                if (selectedMenu === "On Hold") return !p?.isArchived && p?.status === "On Hold";
                if (selectedMenu === "Completed") return !p?.isArchived && p?.status === "Completed";
                if (selectedMenu === "Archived") return p?.isArchived;
                return true;
              })
              ?.map((project) => {
                return (
                  <tr   
                    className="border bg-white custom-hover-effect"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      localStorage.setItem(
                        "openedProject",
                        JSON.stringify(project)
                      );
                      navigate(`/projects/${project._id}/goals`);
                    }}
                  >
                    <td
                      className="body3 regular-weight align-middle"
                      data-bs-placement="bottom"
                      title={project.name}
                    >
                      <span
                        data-bs-toggle="tooltip"
                        style={{
                          width: "150px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "inline-block",
                        }}
                      >
                        {project.name}
                      </span>
                    </td>
                    <td className="body3 regular-weight align-middle">
                      {formatTime(project.createdAt)}
                    </td>
                    <td className="body3 regular-weight align-middle">
                      <img
                        src={`${backendServerBaseURL}/${
                          project.createdBy
                            ? project.createdBy.avatar
                            : "uploads/default.png"
                        }`}
                        className="avatar3"
                        alt=""
                        style={{ marginRight: "0.3rem" }}
                      />
                      {`${
                        project.createdBy
                          ? [
                              project.createdBy.firstName,
                              project.createdBy.lastName,
                            ].join(" ")
                          : "Removed User"
                      }`}
                    </td>

                    {/* tooltip */}
                    <td className="body3 regular-weight align-middle">
                      <AvatarGroup
                        listOfUrls={project.team?.map(
                          (t) => `${backendServerBaseURL}/${t?.avatar}`
                        )}
                        userName={project.team?.map((t) => [
                          t?.firstName,
                          `${backendServerBaseURL}/${t?.avatar}`,
                          t?.lastName,
                        ])}
                        show={3}
                        total={project.team?.length}
                        owner={project?.createdBy}
                      />
                    </td>

                    <td className="body3 regular-weight align-middle">
                      {project?.goals}
                    </td>
                    <td
                      className="body3 regular-weight align-middle"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      {hasPermission_create_project() ? (
                        <div class="dropdown">
                          <div
                            onClick={(e) => {
                              setdropdownIsOpen(!dropdownIsOpen);
                              statusDropdownRef.current = e.target;
                            }}
                            className={`d-flex justify-content-between align-items-center rounded ${
                              project.status === "Not Defined" &&
                              "blue-outline-chip"
                            } ${
                              project.status === "Completed" &&
                              "green-outline-chip"
                            } ${
                              project.status === "Active" &&
                              "green-outline-chip"
                            } ${
                              project.status === "On Hold" &&
                              "yellow-outline-chip"
                            }`}
                            data-bs-toggle="dropdown"
                            style={{ maxWidth: "7.3rem", cursor: "pointer" }}
                          >
                            <p className="m-0">{project.status}</p>
                            <p
                              style={{ WebkitTransform: "rotate(90deg)" }}
                              className="body2"
                            >{`>`}</p>
                          </div>

                          <ul
                            open={dropdownIsOpen}
                            className="dropdown-menu"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            <li>
                              <a
                                className="dropdown-item body3 regular-weight"
                                onClick={() => {
                                  statusDropdownRef.current.click();
                                  dispatch(
                                    updateProjectStatus({
                                      status: "Not Defined",
                                      projectId: project._id,
                                    })
                                  );
                                }}
                              >
                                Not Defined
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item body3 regular-weight"
                                onClick={() => {
                                  statusDropdownRef.current.click();
                                  dispatch(
                                    updateProjectStatus({
                                      status: "Active",
                                      projectId: project._id,
                                    })
                                  );
                                }}
                              >
                                Active
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item body3 regular-weight"
                                onClick={() => {
                                  statusDropdownRef.current.click();
                                  dispatch(
                                    updateProjectStatus({
                                      status: "Completed",
                                      projectId: project._id,
                                    })
                                  );
                                }}
                              >
                                Completed
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item body3 regular-weight"
                                onClick={() => {
                                  statusDropdownRef.current.click();
                                  dispatch(
                                    updateProjectStatus({
                                      status: "On Hold",
                                      projectId: project._id,
                                    })
                                  );
                                }}
                              >
                                On Hold
                              </a>
                            </li>
                          </ul>
                        </div>
                      ) : (
                        <div
                          className={`d-flex justify-content-between align-items-center rounded ${
                            project.status === "Not Defined" && "blue-chip"
                          } ${project.status === "Completed" && "green-chip"} ${
                            project.status === "Active" && "green-chip"
                          } ${project.status === "On Hold" && "yellow-chip"}`}
                          style={{ maxWidth: "6.3rem", cursor: "pointer" }}
                        >
                          <p className="m-0">{project.status}</p>
                        </div>
                      )}
                    </td>

                    <td className="body3 regular-weight align-middle">
                      {
                      hasPermission_create_project() ? (
                        <div class="dropdown">
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                            className="d-flex justify-content-between align-items-center rounded"
                            data-bs-toggle="dropdown"
                            style={{  cursor: "pointer" }}
                          >
                            <i class="bi bi-three-dots-vertical cp custom-more-icon-hover-effect" style={{padding: "0.7em"}}></i>
                          </div>

                          <ul
                            className="dropdown-menu"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            <li>
                              <span
                                className="dropdown-item body3 regular-weight"
                                onClick={() => {
                                  localStorage.setItem(
                                    "openedProject",
                                    JSON.stringify(project)
                                  );
                                  navigate(`/projects/${project._id}`);
                                }}
                              >
                                Open Project
                              </span>
                            </li>

                            <>
                              {!project.isArchived && (
                                <>
                                  <li>
                                    <a
                                      className="dropdown-item body3 regular-weight"
                                      data-bs-toggle="modal"
                                      data-bs-target="#createProjectModal"
                                      onClick={() => {
                                        dispatch(
                                          updateSelectedProject(project)
                                        );
                                      }}
                                    >
                                      Edit Project
                                    </a>
                                  </li>
                                </>
                              )}

                              {!project.isArchived && (
                                <li>
                                  <a
                                    className="dropdown-item body3 regular-weight"
                                    onClick={() => {
                                      dispatch(
                                        archiveProject({
                                          projectId: project._id,
                                        })
                                      );
                                    }}
                                  >
                                    Archive Project
                                  </a>
                                </li>
                              )}
                              {project.isArchived && (
                                <li>
                                  <a
                                    className="dropdown-item body3 regular-weight"
                                    onClick={() => {
                                      dispatch(
                                        unarchiveProject({
                                          projectId: project._id,
                                        })
                                      );
                                    }}
                                  >
                                    Unarchive Project
                                  </a>
                                </li>
                              )}

                              {
                      hasPermission_delete_project() ?   <li>
                                <a
                                  className="dropdown-item body3 regular-weight"
                                  data-bs-toggle="modal"
                                  data-bs-target="#deleteProjectDialog"
                                  onClick={() => {
                                    setprojectToDelete(project);
                                  }}
                                >
                                  Delete Project
                                </a>
                              </li> : <li></li>
                              }
                           
                            </>
                          </ul>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      ): (isLoading &&
          <table className="table table-borderless mt-2 custom-table-sm">
             <thead className="border-none">
             <div style={{ display: "flex", justifyContent: "space-between" }}>

            <tr style={{width: "100%"}}>
              <td className="text-secondary body3 regular-weight" style={{width: "90px",
                          maxWidth: "20%",}}>
                Name
              </td>
              </tr>
              <tr style={{width: "100%"}}>
              <td className="text-secondary body3 regular-weight" style={{  width: "100px",
                          maxWidth: "20%",}}>
                Created
              </td>
              </tr>
              <tr style={{width: "100%"}}>
              <td className="text-secondary body3 regular-weight" style={{ width: "100px",
                          maxWidth: "20%",}}>
                Owner
              </td>
              </tr>
              <tr style={{width: "100%"}}>
              <td className="text-secondary body3 regular-weight" style={{  width: "10px",
                          maxWidth: "20%",}}>
                Members
              </td>
              </tr>
              <tr style={{width: "100%"}}>
              <td  className="text-secondary body3 regular-weight" style={{  width: "100px",
                          maxWidth: "20%",}}>
                Goals
              </td>
              </tr>
              <tr style={{width: "100%"}}>
              <td  className="text-secondary body3 regular-weight"style={{  width: "10px",
                          maxWidth: "20%",}}>
                Status
              </td>
            </tr>
            </div>
          </thead>
            <tbody style={{width: "100%"}}>
              <tr 
                style={{
                  cursor: "pointer", marginTop: "10px", 
                  borderRadius: "4px",
                  border: "1px solid var(--black-400, #D6D6D6)",
                  background: "var(--color-gray-gray-100, #F3F4F6)"
                }}

              >
                <div className="skeleton-placeholder">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        className="body3 regular-weight align-middle"
                        style={{
                          width: "90px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",   
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",                         
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                  </div>
                </div>
              </tr>
              <tr 
                style={{
                  cursor: "pointer", marginTop: "10px", 
                  borderRadius: "4px",
                  border: "1px solid var(--black-400, #D6D6D6)",
                  background: "var(--color-gray-gray-100, #F3F4F6)"
                }}

              >
                <div className="skeleton-placeholder">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        className="body3 regular-weight align-middle"
                        style={{
                          width: "90px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",   
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",                         
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                  </div>
                </div>
              </tr>
              <tr 
                style={{
                  cursor: "pointer", marginTop: "10px", 
                  borderRadius: "4px",
                  border: "1px solid var(--black-400, #D6D6D6)",
                  background: "var(--color-gray-gray-100, #F3F4F6)"
                }}

              >
                <div className="skeleton-placeholder">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        className="body3 regular-weight align-middle"
                        style={{
                          width: "90px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",   
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",                         
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                  </div>
                </div>
              </tr>
              <tr 
                style={{
                  cursor: "pointer", marginTop: "10px", 
                  borderRadius: "4px",
                  border: "1px solid var(--black-400, #D6D6D6)",
                  background: "var(--color-gray-gray-100, #F3F4F6)"
                }}

              >
                <div className="skeleton-placeholder">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        className="body3 regular-weight align-middle"
                        style={{
                          width: "90px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",   
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",                         
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                  </div>
                </div>
              </tr>
              <tr 
                style={{
                  cursor: "pointer", marginTop: "10px", 
                  borderRadius: "4px",
                  border: "1px solid var(--black-400, #D6D6D6)",
                  background: "var(--color-gray-gray-100, #F3F4F6)"
                }}

              >
                <div className="skeleton-placeholder">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        className="body3 regular-weight align-middle"
                        style={{
                          width: "90px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",   
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",                         
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                  </div>
                </div>
              </tr>
              <tr 
                style={{
                  cursor: "pointer", marginTop: "10px", 
                  borderRadius: "4px",
                  border: "1px solid var(--black-400, #D6D6D6)",
                  background: "var(--color-gray-gray-100, #F3F4F6)"
                }}

              >
                <div className="skeleton-placeholder">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        className="body3 regular-weight align-middle"
                        style={{
                          width: "90px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",   
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",                         
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                  </div>
                </div>
              </tr>
              <tr 
                style={{
                  cursor: "pointer", marginTop: "10px", 
                  borderRadius: "4px",
                  border: "1px solid var(--black-400, #D6D6D6)",
                  background: "var(--color-gray-gray-100, #F3F4F6)"
                }}

              >
                <div className="skeleton-placeholder">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        className="body3 regular-weight align-middle"
                        style={{
                          width: "90px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",   
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",                         
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                  </div>
                </div>
              </tr>
              <tr 
                style={{
                  cursor: "pointer", marginTop: "10px", 
                  borderRadius: "4px",
                  border: "1px solid var(--black-400, #D6D6D6)",
                  background: "var(--color-gray-gray-100, #F3F4F6)"
                }}

              >
                <div className="skeleton-placeholder">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        className="body3 regular-weight align-middle"
                        style={{
                          width: "90px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",   
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",                         
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                  </div>
                </div>
              </tr>
              <tr 
                style={{
                  cursor: "pointer", marginTop: "10px", 
                  borderRadius: "4px",
                  border: "1px solid var(--black-400, #D6D6D6)",
                  background: "var(--color-gray-gray-100, #F3F4F6)"
                }}

              >
                <div className="skeleton-placeholder">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        className="body3 regular-weight align-middle"
                        style={{
                          width: "90px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",   
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",                         
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                  </div>
                </div>
              </tr>
              <tr 
                style={{
                  cursor: "pointer", marginTop: "10px", 
                  borderRadius: "4px",
                  border: "1px solid var(--black-400, #D6D6D6)",
                  background: "var(--color-gray-gray-100, #F3F4F6)"
                }}

              >
                <div className="skeleton-placeholder">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        className="body3 regular-weight align-middle"
                        style={{
                          width: "90px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",   
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",                         
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                  </div>
                </div>
              </tr>
              <tr 
                style={{
                  cursor: "pointer", marginTop: "10px", 
                  borderRadius: "4px",
                  border: "1px solid var(--black-400, #D6D6D6)",
                  background: "var(--color-gray-gray-100, #F3F4F6)"
                }}

              >
                <div className="skeleton-placeholder">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        className="body3 regular-weight align-middle"
                        style={{
                          width: "90px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",   
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",                         
                          borderRadius: "4px",
                          background: "#E5E7EB", 
                          height: "5px !important",
                        }}
                      >
                      </td>
                    </div>
                  </div>
                </div>
              </tr>


            
            
            </tbody>
          </table>       
         )}

      <CreateNewProjectDialog />
      <DeleteProjectDialog projectToDelete={projectToDelete} />
    </div>
  );
}

export default Projects;


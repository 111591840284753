import React from "react";
import { Outlet } from "react-router-dom";
import SupportModal from "../pages/Projects/Support/SupportModal";
import PopupMessage from "./PopupMessage";
import Toolbar from "./Toolbar";

function MainLayout({ socket }) {
  return (
    <div>
      <PopupMessage />

      <Toolbar socket={socket} />
      <Outlet />

      <div>
        <img
          src="/static/icons/support-menu.svg"
          alt=""
          style={{ position: "fixed", bottom: "32px", right: "32px", cursor: "pointer", width: "38px", height: "38px" }}
          data-bs-toggle="modal"
          data-bs-target="#SupportModal"
        />

        <SupportModal />
      </div>
    </div>
  );
}

export default MainLayout;

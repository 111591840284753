import { useCallback, useRef } from "react";
import { Handle, Position } from "reactflow";
import NodeResult from "../../components/NodeTypes/NodeResult";
import NodeModal from "./NodeModal";
import { createPortal } from "react-dom";

function WaitNode({ data, isConnectable, id }) {
  const closeModalRef = useRef();
  const modalOpenRef = useRef();
  return (
    <div>
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
        style={{ marginTop: "0.4rem" }}
      />
      <div
        onDoubleClick={() => {
          modalOpenRef.current.click();
        }}
      >
        {/* <NodeResult
          traffic={data.traffic}
          name={data.name}
          convertedTraffic={data.convertedTraffic}
          label={data.label}
        /> */}

        <div>
          <p
            className="mb-0 text-white text-center"
            style={{
              fontSize: "0.3rem",
              fontWeight: 700,
              position: "relative",
              right: "0rem",
              bottom: "-1.5rem",
            }}
          >
            {data.waitDuration} <br />{" "}
            {data.waitDuration == 1
              ? data.waitType.replace("s", "")
              : data.waitType}
          </p>
        </div>

        <img
          src="/static/icons/sidebar/blue/objects-wait.png"
          alt=""
          style={{ maxHeight: "2rem", minHeight: "2rem" }}
        />

        <div
          ref={modalOpenRef}
          style={{
            display: "none",
            cursor: "pointer",
            fontSize: "1.5rem",
            marginRight: "1.5rem",
          }}
          data-bs-toggle="modal"
          data-bs-target={`#m${id.replaceAll("-", "")}`}
        >
          <i class="fa-solid fa-plus"></i>
        </div>

        {createPortal(
          <NodeModal
            modalId={`m${id.replaceAll("-", "")}`}
            data={data}
            closeModalRef={closeModalRef}
            nodeId={id}
          />,
          document.body
        )}
      </div>
      <Handle
        type="source"
        position={Position.Right}
        id="yes_handle"
        isConnectable={isConnectable}
        style={{
          background: "green",
          marginTop: "0.4rem",
        }}
      />
      {/* <Handle
        type="source"
        position={Position.Bottom}
        id="no_handle"
        isConnectable={isConnectable}
        style={{ background: "red" }}
      /> */}
    </div>
  );
}

export default WaitNode;

import { useCallback, useRef } from "react";
import { Handle, Position } from "reactflow";
import NodeModal from "./NodeModal";
import { createPortal } from "react-dom";

function camelToSentence(camelCase) {
  // Use a regular expression to split camelCase string into words
  const wordsArray = camelCase.split(/(?=[A-Z])/);

  // Capitalize the first letter of each word and join with spaces
  const sentence = wordsArray
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return sentence;
}

const getIconName = (socialName) => {
  console.log(socialName);
  switch (socialName) {
    case "Facebook":
      return "facebook.svg";
    case "Google":
      return "google.svg";
    case "Bing":
      return "bing.svg";
    default:
      return "facebook.svg";
  }
};

function TrafficEntry({ data, isConnectable, id }) {
  const closeModalRef = useRef();
  const modalOpenRef = useRef();

  return (
    <div>
      <div
        onDoubleClick={() => {
          modalOpenRef.current.click();
        }}
      >
        <div style={{ position: "relative", left: 10, bottom: "3px" }}>
          <div className="d-flex align-items-center border ps-1">
            <i
              class="fa-solid fa-user"
              style={{
                maxWidth: "0.3rem",
                width: "0.1rem",
                fontSize: "0.5rem",
                marginRight: "0.5rem",
                marginTop: "0.2rem",
                marginBottom: "0.2rem",
              }}
            ></i>

            <p className="mb-0" style={{ fontSize: "0.3rem" }}>
              {data.traffic}
            </p>
          </div>
        </div>

        {/* <p
          className="mb-0 fw-bold"
          style={{
            fontSize: "5px",
            position: "relative",
            bottom: "-9px",
            left: "16px",
          }}
        >
          {camelToSentence(data.name)}
        </p> */}

        <div className="p-1 ps-2 pe-2 border rounded d-flex align-items-center justify-content-center">
          {data?.trafficSources?.map((singleSouce) => {
            return (
              <div>
                <img
                  src={`/static/socialIcons/${getIconName(
                    singleSouce.trafficSource
                  )}`}
                  alt=""
                  style={{
                    maxHeight: "1rem",
                    minHeight: "1rem",
                    marginRight: "0.5rem",
                  }}
                />
              </div>
            );
          })}

          {data?.trafficSources?.length == 0 && (
            <p style={{ fontSize: "8px" }} className="text-secondary mb-0">
              No Traffic Sources
            </p>
          )}
        </div>

        <div
          ref={modalOpenRef}
          style={{
            display: "none",
            cursor: "pointer",
            fontSize: "1.5rem",
            marginRight: "1.5rem",
          }}
          data-bs-toggle="modal"
          data-bs-target={`#m${id.replaceAll("-", "")}`}
        >
          <i class="fa-solid fa-plus"></i>
        </div>

        {createPortal(
          <NodeModal
            modalId={`m${id.replaceAll("-", "")}`}
            data={data}
            closeModalRef={closeModalRef}
            nodeId={id}
          />,
          document.body
        )}
      </div>
      <Handle
        type="source"
        position={Position.Right}
        id="yes_handle"
        isConnectable={isConnectable}
        style={{ background: "green" }}
      />
    </div>
  );
}

export default TrafficEntry;

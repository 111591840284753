import React, { useRef } from "react";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { Field, FieldArray } from "formik";
import { Form, FormikProvider } from "formik";
import { createkeyMetric } from "../../../redux/slices/settingSlice";
import { useDispatch } from "react-redux";

function NewKeyMetricDialog() {
  const [selectedTeamMembers, setselectedTeamMembers] = useState([]);
  const [numberOfTeamMembersToShowInSelect, setnumberOfTeamMembersToShowInSelect] = useState(4);
  const teamMembersDropdown = useRef();
  const [selectedMenu, setselectedMenu] = useState("About Key Metric");
  const [mediaDocuments, setmediaDocuments] = useState([]);
  const mediaAndDocRef = useRef();
  const dispatch = useDispatch();
  const closeRef = useRef();

  const ProjectsMenus = [
    {
      name: "About Key Metric",
    },
    {
      name: "Metric Type",
    },
  ];

  const RightProjectsMenus = [];

  const aboutKeyMetricFormik = useFormik({
    initialValues: {
      shortName: "",
      name: "",
      description: "",
    },
    validationSchema: Yup.object().shape({
      shortName: Yup.string().required("Short name is required"),
      name: Yup.string().required("Name is required"),
      description: Yup.string().required("Description is required"),
    }),
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log(aboutKeyMetricFormik.values);
      setSubmitting(false);
    },
  });

  const metricTypeFormik = useFormik({
    initialValues: {
      metricType: "",
      currencyType: "",
      metricTimePeriod: "",
    },
    validationSchema: Yup.object().shape({
      metricType: Yup.string().required("Metric type is required"),
      currencyType: Yup.string(),
      metricTimePeriod: Yup.string().required("Metric time period is required"),
    }),
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log(aboutKeyMetricFormik.values);
      setSubmitting(false);
    },
  });

  const closeModal = () => {
    closeRef.current.click();
  };
  const submitNewGoalForm = () => {
    dispatch(createkeyMetric({ ...aboutKeyMetricFormik.values, ...metricTypeFormik.values, closeModal }));
  };

  const resetAllFields = () => {
    aboutKeyMetricFormik.resetForm();
    setselectedTeamMembers([]);
    metricTypeFormik.resetForm();
  };

  return (
    <>
      <div>
        <div className="modal fade" id="createKeyMetricModal" tabIndex={-1} aria-labelledby="createKeyMetricModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="border-bottom mt-3">
                  {/* <span className="text-success">{JSON.stringify(aboutKeyMetricFormik.errors)}</span>
                  <span className="text-warning">{JSON.stringify(metricTypeFormik.errors)}</span> */}

                  <h2>New Key Metric</h2>
                  <p className="mb-4">Create a custom key metric for your goals</p>

                  {/* Tabs */}
                  <div className="flex-fill d-flex align-items-center">
                    {ProjectsMenus.map((menu) => {
                      return (
                        <div
                          style={{ textDecoration: "none" }}
                          className="text-dark body3 regular-weight cp"
                          onClick={() => {
                            setselectedMenu(menu.name);
                          }}
                        >
                          <div
                            className={selectedMenu === menu.name ? "text-center border-bottom border-primary border-3" : "text-center pb-1"}
                            style={{ minWidth: "7rem" }}
                          >
                            <p className="mb-1">{menu.name}</p>
                          </div>
                        </div>
                      );
                    })}

                    <div className="flex-fill ml-auto"></div>

                    {RightProjectsMenus.map((menu) => {
                      return (
                        <div
                          style={{ textDecoration: "none" }}
                          className="text-dark body3 regular-weight cp"
                          onClick={() => {
                            setselectedMenu(menu.name);
                          }}
                        >
                          <div
                            className={selectedMenu === menu.name ? "text-center border-bottom border-primary border-3" : "text-center pb-1"}
                            style={{ minWidth: "7rem" }}
                          >
                            <p className="mb-1">{menu.name}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* About Key Metric STEP */}
                <FormikProvider value={aboutKeyMetricFormik}>
                  <Form autoComplete="off" noValidate onSubmit={aboutKeyMetricFormik.handleSubmit}>
                    {selectedMenu === "About Key Metric" && (
                      <>
                        {/* Name */}
                        <div className="form-field mt-3">
                          <label className="form-label">Short Name</label>
                          <input
                            type={"text"}
                            {...aboutKeyMetricFormik.getFieldProps("shortName")}
                            className="form-control form-control-lg"
                            placeholder="MRR, CPC, etc"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(aboutKeyMetricFormik.touched.shortName && aboutKeyMetricFormik.errors.shortName) ? "block" : "none",
                            }}
                          >
                            {aboutKeyMetricFormik.errors.shortName}
                          </span>
                        </div>

                        {/* Name */}
                        <div className="form-field">
                          <label className="form-label">Name</label>
                          <input
                            type={"text"}
                            {...aboutKeyMetricFormik.getFieldProps("name")}
                            className="form-control form-control-lg"
                            placeholder="Monthly Revenue Rate, Cost per Click, etc"
                          />
                          <span
                            className="invalid-feedback"
                            style={{ display: Boolean(aboutKeyMetricFormik.touched.name && aboutKeyMetricFormik.errors.name) ? "block" : "none" }}
                          >
                            {aboutKeyMetricFormik.errors.name}
                          </span>
                        </div>

                        {/* Description */}
                        <div className="form-field">
                          <label className="form-label">Description</label>
                          <textarea
                            rows="4"
                            type={"text"}
                            {...aboutKeyMetricFormik.getFieldProps("description")}
                            className="form-control form-control-lg"
                            placeholder="A quick explanation"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(aboutKeyMetricFormik.touched.description && aboutKeyMetricFormik.errors.description)
                                ? "block"
                                : "none",
                            }}
                          >
                            {aboutKeyMetricFormik.errors.description}
                          </span>
                        </div>

                        {/* Action buttons */}
                        <div className="d-flex align-items-center">
                          <div className="flex-fill"></div>

                          <div className="hstack gap-2">
                            <button
                              type="button"
                              class="btn btn-lg btn-outline-danger"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                resetAllFields();
                              }}
                            >
                              Close
                            </button>

                            <button
                              type="button"
                              onClick={() => {
                                setselectedMenu("Metric Type");
                              }}
                              class={"btn btn-lg " + (!aboutKeyMetricFormik.isValid || !aboutKeyMetricFormik.dirty ? "btn-secondary" : "btn-primary")}
                              disabled={!aboutKeyMetricFormik.isValid || !aboutKeyMetricFormik.dirty}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </Form>
                </FormikProvider>

                {/* Metric Type */}
                <FormikProvider value={metricTypeFormik}>
                  <Form autoComplete="off" noValidate onSubmit={metricTypeFormik.handleSubmit}>
                    {selectedMenu === "Metric Type" && (
                      <>
                        {/* Metric Type */}
                        <div className="mb-3 mt-3">
                          <label className="form-label">Metric Type</label>
                          <select
                            {...metricTypeFormik.getFieldProps("metricType")}
                            class="form-select"
                            onChange={(e) => {
                              metricTypeFormik.setFieldValue("metricType", e.target.value);
                            }}
                          >
                            <option value="" className="text-secondary">
                              Select a type
                            </option>
                            <option value="Count">Count: 10</option>
                            <option value="Decimal">Decimal: 1,0</option>
                            <option value="Currency">Currency: $10,100</option>
                            <option value="Rate">Rate: 10%</option>
                          </select>
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(metricTypeFormik.touched.metricType && metricTypeFormik.errors.metricType) ? "block" : "none",
                            }}
                          >
                            {metricTypeFormik.errors.metricType}
                          </span>
                        </div>

                        {metricTypeFormik.values.metricType == "Currency" && (
                          <>
                            {/* Currency Type */}
                            <div className="mb-3">
                              <label className="form-label">Currency Type</label>
                              <select {...metricTypeFormik.getFieldProps("currencyType")} class="form-select">
                                <option value="">Select a currency type</option>
                                <option value="$-Dollars">$-Dollars</option>
                                <option value="₹-Rupee">₹-Rupee</option>
                              </select>
                              <span
                                className="invalid-feedback"
                                style={{
                                  display: Boolean(metricTypeFormik.touched.currencyType && metricTypeFormik.errors.currencyType) ? "block" : "none",
                                }}
                              >
                                {metricTypeFormik.errors.currencyType}
                              </span>
                            </div>
                          </>
                        )}

                        {metricTypeFormik.values.metricType && metricTypeFormik.values.metricType != "" && (
                          <>
                            {/* Metric Time Period */}
                            <div className="mb-3">
                              <label className="form-label">Metric Time Period</label>
                              <select {...metricTypeFormik.getFieldProps("metricTimePeriod")} class="form-select">
                                <option value="" className="text-secondary">
                                  Select a time period
                                </option>
                                <option value="All Time">All Time</option>
                                <option value="Daily">Daily</option>
                                <option value="Weekly">Weekly</option>
                                <option value="Monthly">Monthly</option>
                                <option value="Quaterly">Quaterly</option>
                                <option value="Annualy">Annualy</option>
                              </select>
                              <span
                                className="invalid-feedback"
                                style={{
                                  display: Boolean(metricTypeFormik.touched.metricTimePeriod && metricTypeFormik.errors.metricTimePeriod)
                                    ? "block"
                                    : "none",
                                }}
                              >
                                {metricTypeFormik.errors.metricTimePeriod}
                              </span>
                            </div>
                          </>
                        )}

                        {/* Action Buttons */}
                        <div className="d-flex align-items-center">
                          <div className="flex-fill"></div>

                          <div className="hstack gap-2">
                            <button
                              type="button"
                              class="btn btn-lg btn-outline-danger"
                              data-bs-dismiss="modal"
                              ref={closeRef}
                              onClick={() => {
                                resetAllFields();
                              }}
                            >
                              Close
                            </button>

                            <button
                              type="button"
                              onClick={() => {
                                submitNewGoalForm();
                              }}
                              class={"btn btn-lg " + (!metricTypeFormik.isValid || !metricTypeFormik.dirty ? "btn-secondary" : "btn-primary")}
                              disabled={!metricTypeFormik.isValid || !metricTypeFormik.dirty}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </Form>
                </FormikProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewKeyMetricDialog;

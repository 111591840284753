import { Form, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { useEffect } from "react";
import LoadingButton from "../../components/common/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import {
  completeProfile,
  loginUser,
  readIncomplete,
  incomplete,
  readIncompleteProfile,
} from "../../redux/slices/generalSlice";
import Alert from "../../components/common/Alert";
import { Navigate, useParams, useNavigate } from "react-router-dom";
// import { loginsuperOwner } from "../redux/slices/adminSlice";

export default function CompleteProfile() {
  const params = useParams();
  const [passwordHide, setpasswordHide] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inCompleteProfile = useSelector(incomplete);
  console.log("INCOMPLETE firstname", inCompleteProfile?.user?.firstName);
  console.log("INCOMPLETE lastname", inCompleteProfile?.user?.lastName);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    password: Yup.string().required("Password is required"),
    employees: Yup.string().required("No of employees is required"),
    // phone: Yup.string().required("Phone is required"),
    industry: Yup.string().required("Industry is required"),
    company: Yup.string().required("Company is required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      password: "",
      employees: "",
      phone: "",
      industry: "",
      company: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      setSubmitting(true);
      await dispatch(
        completeProfile({ ...values, setErrors, token: params.token })
      );
      setSubmitting(false);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  useEffect(() => {
    if (
      localStorage.getItem("accessToken", null) !== null &&
      localStorage.getItem("accessToken", null) !== undefined
    ) {
      //  window.open("/quick-start", "_self");
    }
  }, []);

  useEffect(() => {
    dispatch(readIncompleteProfile({token:params.token,formik}));
  }, []);

  return (
    <>
      <div
        style={{ minHeight: "90vh" }}
        className="d-flex align-items-center justify-content-center"
      >
        <div className="container col-3">
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <a className="d-flex justify-content-center mb-3" href="/">
                <img
                  className="zi-2"
                  src="/static/icons/logo.svg"
                  alt="Image Description"
                  style={{ width: "3rem" }}
                />
              </a>

              <div className="text-center">
                <div className="mb-3">
                  <h1>Complete Profile</h1>
                </div>
              </div>

              <div className="row mb-3">
                {/* First Name */}
                <div className="col-6">
                  <label className="form-label">First Name</label>
                  <input
                    // defaultValue={inCompleteProfile?.user?.firstName}
                    {...getFieldProps("firstName")}
                    className="form-control form-control-lg"
                    placeholder="First Name"
                  />
                  <span
                    className="invalid-feedback"
                    style={{
                      display: Boolean(touched.firstName && errors.firstName)
                        ? "block"
                        : "none",
                    }}
                  >
                    {errors.firstName}
                  </span>
                </div>

                {/* Last Name */}
                <div className="col-6">
                  <label className="form-label">Last Name</label>
                  <input
                    {...getFieldProps("lastName")}
                    className="form-control form-control-lg"
                    // defaultValue={inCompleteProfile?.user?.lastName}
                    placeholder="Last Name"
                  />
                  <span
                    className="invalid-feedback"
                    style={{
                      display: Boolean(touched.lastName && errors.lastName)
                        ? "block"
                        : "none",
                    }}
                  >
                    {errors.lastName}
                  </span>
                </div>
              </div>

              {/* Password */}
              <div className="mb-3">
                <label className="form-label">Password</label>
                <div className="input-group">
                  <input
                    type={passwordHide ? "password" : "text"}
                    {...getFieldProps("password")}
                    className="form-control form-control-lg"
                    placeholder="Enter Password"
                  />
                  <span className="input-group-text">
                    <i
                      className={passwordHide ? "bi bi-eye-slash" : "bi bi-eye"}
                      id="togglePassword"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setpasswordHide(!passwordHide);
                      }}
                    />
                  </span>
                </div>

                <span
                  className="invalid-feedback"
                  style={{
                    display: Boolean(touched.password && errors.password)
                      ? "block"
                      : "none",
                  }}
                >
                  {errors.password}
                </span>
              </div>

              {/* No of employees */}
              <div className="mb-3">
                <label className="form-label">No of employees</label>
                <select
                  {...getFieldProps("employees")}
                  className="form-select"
                  onChange={(e) => {
                    formik.setFieldValue("employees", e.target.value);
                  }}
                >
                  <option value="">Select No Of Employees</option>
                  <option value="Just Me">Just Me</option>
                  <option value="2-10">2-10</option>
                  <option value="11-50">11-50</option>
                  <option value="51-100">51-100</option>
                  <option value="101-500">101-500</option>
                  <option value="501-1000">501-1000</option>
                  <option value="1000+">1000+</option>
                </select>
                <span
                  className="invalid-feedback"
                  style={{
                    display: Boolean(touched.employees && errors.employees)
                      ? "block"
                      : "none",
                  }}
                >
                  {errors.employees}
                </span>
              </div>

              {/* Phone */}
              <div className="mb-3">
                <label className="form-label">Phone</label>
                <input
                  {...getFieldProps("phone")}
                  className="form-control form-control-lg"
                  placeholder="Phone"
                />
                <span
                  className="invalid-feedback"
                  style={{
                    display: Boolean(touched.phone && errors.phone)
                      ? "block"
                      : "none",
                  }}
                >
                  {errors.phone}
                </span>
              </div>

              {/* Industry */}
              <div className="mb-3">
                <label className="form-label">Industry</label>
                <select
                  {...getFieldProps("industry")}
                  className="form-select"
                  onChange={(e) => {
                    formik.setFieldValue("industry", e.target.value);
                  }}
                >
                  <option value="">Select Industry</option>
                  <option value="Agency">Agency</option>
                  <option value="Software">Software</option>
                  <option value="Ecommerce">Ecommerce</option>
                  <option value="Dropshipping">Dropshipping</option>
                  <option value="Freelance">Freelance</option>
                  <option value="Other">Other</option>
                </select>
                <span
                  className="invalid-feedback"
                  style={{
                    display: Boolean(touched.industry && errors.industry)
                      ? "block"
                      : "none",
                  }}
                >
                  {errors.industry}
                </span>
              </div>

              {/* Company */}
              <div className="mb-3">
                <label className="form-label">Designation</label>
                <input
                  {...getFieldProps("company")}
                  className="form-control form-control-lg"
                  placeholder="Designation"
                />
                <span
                  className="invalid-feedback"
                  style={{
                    display: Boolean(touched.company && errors.company)
                      ? "block"
                      : "none",
                  }}
                >
                  {errors.company}
                </span>
              </div>

              {/* Errors from server */}
              {errors.afterSubmit && (
                <Alert value={errors.afterSubmit} variant="danger" />
              )}

              <div className="d-grid mt-3">
                <LoadingButton
                  type="submit"
                  loading={isSubmitting}
                  className="btn btn-lg btn-primary "
                >
                  Proceed
                </LoadingButton>
              </div>
            </Form>
          </FormikProvider>
        </div>
      </div>
    </>
  );
}

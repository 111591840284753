import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AvatarGroup from "../../../components/common/AvatarGroup";
import { getAllGoals, getProjectUsers, selectGoals, updateSelectedGoal } from "../../../redux/slices/projectSlice";
import { getAllkeyMetrics } from "../../../redux/slices/settingSlice";
import { backendServerBaseURL } from "../../../utils/backendServerBaseURL";
import { formatTime } from "../../../utils/formatTime";
import { formatDate } from "../../../utils/formatTime";
import { hasPermission_create_goals, isRoleAdmin, isTypeOwner, isRoleMember } from "../../../utils/permissions";
import TourModal from "../Tour/TourModal";
import CreateNewGoalDialog from "./CreateNewGoalDialog";
import DeleteGoalDialog from "./DeleteGoalDialog";
import RequestIdeaDialog from "./RequestIdeaDialog";

function Goals() {
  const [selectedMenu, setselectedMenu] = useState("All Goals");
  const [arrowStateUpdater, setArrowStateUpdater] = useState("AS@#SADX");
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params.projectId;
  const dispatch = useDispatch();
  const requestIdeaDialogRef = useRef();
  const goals = useSelector(selectGoals);

  console.log("goals", goals)
  const [filteredGoals, setfilteredGoals] = useState([]);
  const openedProject = JSON.parse(localStorage.getItem("openedProject", ""));
  const [selectedTab, setselectedTab] = useState("About Goal");
  const ProjectsMenus = [
    {
      name: "All Goals",
    },
    {
      name: "Active",
    },
    {
      name: "Completed",
    },
  ];

  const RightProjectsMenus = [];

  const openRequestIdeaDialog = () => {
    requestIdeaDialogRef.current.click();
  };

  const [isLoading, setIsLoading] = useState(true); // Loading state flag
  const [showSkeletonLoader, setShowSkeletonLoader] = useState(true);
  const [isLoadingActive, setIsLoadingActive] = useState(true);



  const tabSwitch = async () => {
    if (selectedMenu === "Completed") {
      setfilteredGoals(
        goals?.filter((goal) => {
          if (
            Math.round(
              goal.keymetric.reduce(
                (partialSum, a) => (partialSum + a?.metrics?.length === 0 ? 0 : (a?.metrics[a?.metrics?.length - 1]?.value / a?.targetValue) * 100),
                0
              )
            ) >= 100
          ) {
            return goal;
          }
        })
      );
    }

    if (selectedMenu === "Active") {   
      isLoadingActive(true); // Set isLoadingActive to false after the API call is completed
      setfilteredGoals(goals);
    }

    if (selectedMenu === "All Goals") {
      setfilteredGoals(goals);
    }
  };

  useEffect(tabSwitch, [selectedMenu, goals]);

  useEffect(() => {
    // Show the skeleton loader on tab switch
    setShowSkeletonLoader(true);

    // Handle the "Active" tab separately
    if (selectedMenu === "Active") {
      console.log('isLoadingActive :>> ', isLoadingActive);
      setIsLoadingActive(true);
      setIsLoading(false); // Set isLoadingActive to false after the API call is completed
      // Set isLoadingActive to true for "Active" tab
      dispatch(getAllGoals({ projectId }));
      dispatch(getProjectUsers({ projectId }));
      dispatch(getAllkeyMetrics());

      // Simulate API call time - Replace this with actual API calls in your code
      setTimeout(() => {
        setShowSkeletonLoader(false); // Hide the skeleton loader when data is available
        setIsLoadingActive(false); // Set isLoadingActive to false after the API call is completed
      }, 2000);
    } else if (selectedMenu === "All Goals") {
      setIsLoading(true); // Set isLoading to true for other tabs
      dispatch(getAllGoals({ projectId }));
      dispatch(getProjectUsers({ projectId }));
      dispatch(getAllkeyMetrics());

      // Simulate API call time - Replace this with actual API calls in your code
      setTimeout(() => {
        setShowSkeletonLoader(false); // Hide the skeleton loader when data is available
        setIsLoading(false); // Set isLoading to false after the API call is completed
      }, 2000);
    }
  }, [selectedMenu]);



  return (
    <div>
      <div className="d-flex">
        <div>
          <h1 className="mb-1">{openedProject?.name}</h1>
          <p className="text-secondary">
            {goals?.length == 1
              ? `${goals?.length} Goal`
              : `${goals?.length} Goals`}
          </p>
        </div>

        <div className="flex-fill d-flex flex-row-reverse">
          <div className="hstack gap-3" style={{ padding: 0 }}>
            {hasPermission_create_goals()  ? (
              <button
                type="button"
                className="btn btn-primary"
                style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                data-bs-toggle="modal"
                data-bs-target="#createNewGoalDialog"
                onClick={() => {
                  setselectedTab("About Goal");
                  dispatch(updateSelectedGoal(null));
                }}
                disabled={
                  hasPermission_create_goals() 
                    ? false
                    : true
                }
              >
                + New Goal
              </button>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>

      <div className="border-bottom mt-3">
        <div className="flex-fill d-flex align-items-center">
          {ProjectsMenus.map((menu) => {
            return (
              <div
                style={{ textDecoration: "none" }}
                className="text-dark body3 regular-weight cp "
                onClick={() => {
                  setselectedMenu(menu.name);
                }}
              >
                <div
                  className={
                    selectedMenu === menu.name
                      ? "text-center border-bottom border-primary border-3"
                      : "text-center pb-1"
                  }
                  style={{ minWidth: "7rem" }}
                >
                  <p className="mb-1">{menu.name}</p>
                </div>
              </div>
            );
          })}

          <div className="flex-fill ml-auto"></div>

          {RightProjectsMenus.map((menu) => {
            return (
              <div
                style={{ textDecoration: "none" }}
                className="text-dark body3 regular-weight cp"
                onClick={() => {
                  setselectedMenu(menu.name);
                }}
              >
                <div
                  className={
                    selectedMenu === menu.name
                      ? "text-center border-bottom border-primary border-3"
                      : "text-center pb-1"
                  }
                  style={{ minWidth: "7rem" }}
                >
                  <p className="mb-1">{menu.name}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {filteredGoals?.length === 0 && !isLoading ? (
            <div className="d-flex align-items-center justify-content-center mt-5">
            <div className="vstack gap-3 text-center">
              <img
                src="/static/illustrations/no-projects-found.svg"
                alt=""
                height="200px"
              />
              <h2 className="body1 regular-weight">No goals created yet</h2>
              {selectedMenu === "All Projects" && (
                <div>
                  <button className="btn btn-primary">
                    Create My First Goal
                  </button>
                </div>
              )}
            </div>
          </div>    
    )
   : (filteredGoals?.length > 0  ? 
     (
      <>
      <div className="row p-3">
        <div className="col-4 text-secondary body3 regular-weight">
          Name
        </div>
        <div className="col-2 text-secondary body3 regular-weight">
          Duration
        </div>
        <div className="col-2 text-secondary body3 regular-weight">
          Members
        </div>
        <div className="col-2 text-secondary body3 regular-weight">
          Ideas
        </div>
        <div className="col-2 text-secondary body3 regular-weight">
          Status/Progress
        </div>
      </div>

      {filteredGoals.map((goal, index) => {
        return (
          filteredGoals.length !== 0 ?
          <section key={`goal_body_${index}`}>
            <div
              className="custom-hover-effect row p-2 m-0 bg-white border mb-1 rounded"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/projects/${projectId}/goals/${goal._id}`);
              }}
              
            >
              <div
                className="col-4  body3 align-items-center"
                style={{ display: "flex" }}
              >
                <div className="custom-arrow-icon-hover-effect">
                <img
                  src={
                    arrowStateUpdater.indexOf(goal._id) === -1
                      ? "/static/icons/right-arrow.svg"
                      : "/static/icons/down-arrow.svg"
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (arrowStateUpdater.indexOf(goal._id) === -1) {
                      setArrowStateUpdater([
                        ...arrowStateUpdater,
                        goal._id,
                      ]);
                    } else {
                      let tempArr = [...arrowStateUpdater];
                      tempArr.splice(
                        arrowStateUpdater.indexOf(goal._id),
                        1
                      );
                      setArrowStateUpdater(tempArr);
                    }
                  }}
                  alt=""
                  className="cp"
                  width={"12px"}
                  height={"12px"}
                  style={{ marginRight: "0.5rem" }}
                  data-bs-toggle="collapse"
                  data-bs-target={`#goal_${index}`}
                />
                </div>
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title={goal.name}
                  style={{
                    width: "200px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "inline-block",
                  }}
                >
                  {goal.name}
                </span>
              </div>
              <div className="col-2  body3 regular-weight d-flex align-items-center">
                {formatDate(goal.startDate)} - {formatDate(goal.endDate)}
              </div>
              <div className="col-2  body3 regular-weight d-flex align-items-center">
                <AvatarGroup
                  listOfUrls={goal.members.map(
                    (member) => `${backendServerBaseURL}/${member.avatar}`
                  )}
                  show={3}
                  total={goal.members.length}
                  owner={goal?.createdBy}
                  userName={goal.members?.map((t) => [
                    t?.firstName,
                    `${backendServerBaseURL}/${t?.avatar}`,
                    t?.lastName,
                  ])}
                />
                <div className="align-items-center">
                {goal.members.map((m) => m.firstName)} {goal.members.map((m) => m.lastName)}
              </div>
              </div>
              <div className="col-2  body3 regular-weight d-flex align-items-center">
                {goal.ideas}
              </div>
              <div className="col-2  body3 regular-weight d-flex align-items-center">
                <div className="flex-fill">
                  {Math.round(
                    goal.keymetric.reduce((partialSum, metric) => {
                      const lastMetric =
                        metric.metrics[metric.metrics.length - 1];
                      const percentage = lastMetric
                        ? (lastMetric.value / metric.targetValue) * 100
                        : 0;
                      return partialSum + percentage;
                    }, 0)
                  )}
                  %
                  <div
                    class="progress"
                    style={{ height: "4px", width: "3rem" }}
                  >
                    <div
                      class="progress-bar"
                      role="progressbar"
                      style={{
                        width: `${Math.round(
                          goal.keymetric.reduce(
                            (partialSum, a) =>
                              partialSum + a.metrics.length === 0
                                ? 0
                                : (a.metrics[a.metrics.length - 1]?.value /
                                    a.targetValue) *
                                  100,
                            0
                          )
                        )}%`,
                      }}
                    ></div>
                  </div>
                </div>

                {hasPermission_create_goals()  ? (
                  <div class="dropdown">
                    <div
                      data-bs-toggle="dropdown"
                      style={{cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <i class="bi bi-three-dots-vertical custom-more-icon-hover-effect" style={{padding: "0.7em"}}></i>
                    </div>

                    <ul
                      className="dropdown-menu"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <li className="border-bottom">
                        <a
                          className="dropdown-item body3 regular-weight"
                          onClick={() => {
                            navigate(
                              `/projects/${projectId}/goals/${goal._id}`
                            );
                          }}
                        >
                          View Goal
                        </a>
                      </li>
                      <li
                        className="border-bottom"
                        data-bs-toggle="modal"
                        data-bs-target="#createNewGoalDialog"
                        onClick={() => {
                          setselectedTab("About Goal");
                          dispatch(updateSelectedGoal(goal));
                        }}
                      >
                        <a
                          className="dropdown-item body3 regular-weight"
                          href="#"
                        >
                          Edit Goal
                        </a>
                      </li>
                      <li
                        className="border-bottom"
                        data-bs-toggle="modal"
                        data-bs-target="#deleteGoalDialog"
                        onClick={() => {
                          dispatch(updateSelectedGoal(goal));
                        }}
                      >
                        <a
                          className="dropdown-item body3 regular-weight"
                          href="#"
                        >
                          Delete Goal
                        </a>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>

            <div class="collapse" id={`goal_${index}`}>
              {goal.keymetric.map((keyMetric) => {
                return (
                  <div
                    className="row m-0 mb-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(`/projects/${projectId}/goals/${goal._id}`);
                    }}
                  >
                    <div className="col-4 body3 p-0">
                      <div
                        className="rounded border-start border-top border-bottom p-2 bg-white w-100 h-100 d-flex align-items-center"
                        style={{ marginLeft: "1.4rem" }}
                      >
                        <span style={{ marginRight: "0.4rem" }}>
                          {keyMetric.status === "Not-Defined" && (
                            <div className="gray-chip">
                              {keyMetric.metrics.length === 0
                                ? 0
                                : Math.round(
                                    (keyMetric.metrics[
                                      keyMetric.metrics.length - 1
                                    ]?.value /
                                      keyMetric.targetValue) *
                                      100
                                  )}
                              %
                            </div>
                          )}
                          {keyMetric.status === "On-Track" && (
                            <div className="blue-chip">
                              {keyMetric.metrics.length === 0
                                ? 0
                                : Math.round(
                                    (keyMetric.metrics[
                                      keyMetric.metrics.length - 1
                                    ]?.value /
                                      keyMetric.targetValue) *
                                      100
                                  )}
                              %
                            </div>
                          )}
                          {keyMetric.status === "Off-Track" && (
                            <div className="yellow-chip">
                              {keyMetric.metrics.length === 0
                                ? 0
                                : Math.round(
                                    (keyMetric.metrics[
                                      keyMetric.metrics.length - 1
                                    ]?.value /
                                      keyMetric.targetValue) *
                                      100
                                  )}
                              %
                            </div>
                          )}
                          {keyMetric.status === "At-Risk" && (
                            <div className="red-chip">
                              {keyMetric.metrics.length === 0
                                ? 0
                                : Math.round(
                                    (keyMetric.metrics[
                                      keyMetric.metrics.length - 1
                                    ]?.value /
                                      keyMetric.targetValue) *
                                      100
                                  )}
                              %
                            </div>
                          )}
                          {keyMetric.status === "Achieved" && (
                            <div className="green-chip">
                              {keyMetric.metrics.length === 0
                                ? 0
                                : Math.round(
                                    (keyMetric.metrics[
                                      keyMetric.metrics.length - 1
                                    ]?.value /
                                      keyMetric.targetValue) *
                                      100
                                  )}
                              %
                            </div>
                          )}
                        </span>
                        {keyMetric.name}
                      </div>
                    </div>

                    <div
                      className="col-2 body3 regular-weight d-flex align-items-center border-top border-bottom bg-white"
                      style={{ paddingLeft: "0.8rem" }}
                    >
                      {formatTime(goal.startDate)} -{" "}
                      {formatTime(goal.endDate)}
                    </div>
                    <div className="col-2 body3 regular-weight d-flex align-items-center border-top border-bottom bg-white">
                      <AvatarGroup
                        listOfUrls={goal.members.map(
                          (member) =>
                            `${backendServerBaseURL}/${member.avatar}`
                        )}
                        show={3}
                        total={goal.members.length}
                        userName={goal.members?.map((t) => [
                          t?.firstName,
                          `${backendServerBaseURL}/${t?.avatar}`,
                          t?.lastName,
                        ])}
                      />
                    </div>
                    <div
                      className="col-2 body3 regular-weight d-flex align-items-center border-top border-bottom bg-white"
                      style={{ paddingLeft: "0.5rem" }}
                    >
                      {goal.ideas}
                    </div>
                    <div className="col-2 body3 regular-weight d-flex align-items-center border-top border-bottom border-end bg-white ps-1">
                      {keyMetric.status === "Not-Defined" && (
                        <div className="gray-chip">{keyMetric.status}</div>
                      )}
                      {keyMetric.status === "On-Track" && (
                        <div className="blue-chip">{keyMetric.status}</div>
                      )}
                      {keyMetric.status === "Off-Track" && (
                        <div className="yellow-chip">
                          {keyMetric.status}
                        </div>
                      )}
                      {keyMetric.status === "At-Risk" && (
                        <div className="red-chip">{keyMetric.status}</div>
                      )}
                      {keyMetric.status === "Achieved" && (
                        <div className="green-chip">{keyMetric.status}</div>
                      )}

                      <div className="flex-fill"></div>

                      <div class="dropdown">
                        <div
                          data-bs-toggle="dropdown"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <i
                            class="bi bi-three-dots-vertical custom-more-icon-hover-effect" style={{ marginRight: "0.4rem", padding: "0.5rem" }}>
                          </i>
                        </div>

                        <ul
                          className="dropdown-menu"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <li className="border-bottom">
                            <a
                              className="dropdown-item body3 regular-weight"
                              onClick={() => {
                                navigate(
                                  `/projects/${projectId}/goals/${goal._id}`
                                );
                              }}
                            >
                              View Key Metric
                            </a>
                          </li>
                          {/* <li className="border-bottom">
                            <a className="dropdown-item body3 regular-weight" href="#">
                              Edit Key Metric
                            </a>
                          </li>
                          <li className="border-bottom">
                            <a className="dropdown-item body3 regular-weight" href="#">
                              Delete Key Metric
                            </a>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div
                style={{
                  marginLeft: "1.7rem",
                  marginTop: "0.5rem",
                  marginBottom: "0.5rem",
                }}
              >
                {hasPermission_create_goals()||isTypeOwner() ||
                isRoleAdmin() ||
                isRoleMember() ? (
                  <p
                    className="body2 cp text-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#createNewGoalDialog"
                    onClick={() => {
                      setselectedTab("Key Metrics");
                      dispatch(updateSelectedGoal(goal));
                    }}
                    // onClick={() => {
                    //   // navigate(`/projects/${projectId}/goals/${goal._id}`);

                    // }}
                  >
                    Add a Key metric +
                  </p>
                ) : (
                  <p></p>
                )}
              </div>
            </div>
          </section>
       : (isLoading ?
        <table className="table table-borderless mt-2 custom-table-sm">
           <thead className="border-none">
           <div style={{ display: "flex", justifyContent: "space-between" }}>

          <tr style={{width: "100%"}}>
            <td className="text-secondary body3 regular-weight" style={{width: "90px",
                        maxWidth: "20%",}}>
              Name
            </td>
            </tr>
            <tr style={{width: "100%"}}>
            <td className="text-secondary body3 regular-weight" style={{  width: "100px",
                        maxWidth: "20%",}}>
              Created
            </td>
            </tr>
            <tr style={{width: "100%"}}>
            <td className="text-secondary body3 regular-weight" style={{ width: "100px",
                        maxWidth: "20%",}}>
              Owner
            </td>
            </tr>
            <tr style={{width: "100%"}}>
            <td className="text-secondary body3 regular-weight" style={{  width: "10px",
                        maxWidth: "20%",}}>
              Members
            </td>
            </tr>
            <tr style={{width: "100%"}}>
            <td  className="text-secondary body3 regular-weight" style={{  width: "100px",
                        maxWidth: "20%",}}>
              Goals
            </td>
            </tr>
            <tr style={{width: "100%"}}>
            <td  className="text-secondary body3 regular-weight"style={{  width: "10px",
                        maxWidth: "20%",}}>
              Status
            </td>
          </tr>
          </div>
        </thead>
          <tbody style={{width: "100%"}}>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", 
                borderRadius: "4px",
                border: "1px solid var(--black-400, #D6D6D6)",
                background: "var(--color-gray-gray-100, #F3F4F6)"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB",   
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", 
                borderRadius: "4px",
                border: "1px solid var(--black-400, #D6D6D6)",
                background: "var(--color-gray-gray-100, #F3F4F6)"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB !important",   
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", 
                borderRadius: "4px",
                border: "1px solid var(--black-400, #D6D6D6)",
                background: "var(--color-gray-gray-100, #F3F4F6)"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB",   
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", 
                borderRadius: "4px",
                border: "1px solid var(--black-400, #D6D6D6)",
                background: "var(--color-gray-gray-100, #F3F4F6)"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB",   
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", 
                borderRadius: "4px",
                border: "1px solid var(--black-400, #D6D6D6)",
                background: "var(--color-gray-gray-100, #F3F4F6)"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB",   
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", 
                borderRadius: "4px",
                border: "1px solid var(--black-400, #D6D6D6)",
                background: "var(--color-gray-gray-100, #F3F4F6)"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB",   
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", 
                borderRadius: "4px",
                border: "1px solid var(--black-400, #D6D6D6)",
                background: "var(--color-gray-gray-100, #F3F4F6)"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB",   
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", 
                borderRadius: "4px",
                border: "1px solid var(--black-400, #D6D6D6)",
                background: "var(--color-gray-gray-100, #F3F4F6)"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB",   
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", 
                borderRadius: "4px",
                border: "1px solid var(--black-400, #D6D6D6)",
                background: "var(--color-gray-gray-100, #F3F4F6)"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB",   
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", 
                borderRadius: "4px",
                border: "1px solid var(--black-400, #D6D6D6)",
                background: "var(--color-gray-gray-100, #F3F4F6)"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB",   
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", 
                borderRadius: "4px",
                border: "1px solid var(--black-400, #D6D6D6)",
                background: "var(--color-gray-gray-100, #F3F4F6)"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB",   
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>


          
          
          </tbody>
        </table>       
       : (<div></div>) ))
      })}
    </>
    )
  : (
    // Optional: You can return a loading spinner or fallback UI in case the conditions above are not met.
     (<div><table className="table table-borderless mt-2 custom-table-sm">
     <thead className="border-none">
     <div style={{ display: "flex", justifyContent: "space-between" }}>

    <tr style={{width: "100%"}}>
      <td className="text-secondary body3 regular-weight" style={{width: "90px",
                  maxWidth: "20%",}}>
        Name
      </td>
      </tr>
      <tr style={{width: "100%"}}>
      <td className="text-secondary body3 regular-weight" style={{  width: "100px",
                  maxWidth: "20%",}}>
        Duration
      </td>
      </tr>
      <tr style={{width: "100%"}}>
      <td className="text-secondary body3 regular-weight" style={{ width: "100px",
                  maxWidth: "20%",}}>
        Members
      </td>
      </tr>
      <tr style={{width: "100%"}}>
      <td className="text-secondary body3 regular-weight" style={{  width: "10px",
                  maxWidth: "20%",}}>
        Ideas
      </td>
      </tr>
      <tr style={{width: "100%"}}>
      <td  className="text-secondary body3 regular-weight" style={{  width: "100px",
                  maxWidth: "20%",}}>
    Status/Progress
      </td>
      </tr>
     
    </div>
  </thead>
    <tbody style={{width: "100%"}}>
      <tr 
        style={{
          cursor: "pointer", marginTop: "10px", 
          border: "1px solid var(--black-400, #D6D6D6)",
          background: "var(--color-gray-gray-100, #F3F4F6)"
        }}

      >
        <div className="skeleton-placeholder">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <td
                data-bs-placement="bottom"
                className="body3 regular-weight align-middle"
                style={{
                  width: "90px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",                         
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
          </div>
        </div>
      </tr>
      <tr 
        style={{
          cursor: "pointer", marginTop: "10px", 
          border: "1px solid var(--black-400, #D6D6D6)",
          background: "var(--color-gray-gray-100, #F3F4F6)"
        }}

      >
        <div className="skeleton-placeholder">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <td
                data-bs-placement="bottom"
                className="body3 regular-weight align-middle"
                style={{
                  width: "90px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",                         
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
          </div>
        </div>
      </tr>
      <tr 
        style={{
          cursor: "pointer", marginTop: "10px", 
          border: "1px solid var(--black-400, #D6D6D6)",
          background: "var(--color-gray-gray-100, #F3F4F6)"
        }}

      >
        <div className="skeleton-placeholder">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <td
                data-bs-placement="bottom"
                className="body3 regular-weight align-middle"
                style={{
                  width: "90px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",                         
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
          </div>
        </div>
      </tr>
      <tr 
        style={{
          cursor: "pointer", marginTop: "10px", 
          border: "1px solid var(--black-400, #D6D6D6)",
          background: "var(--color-gray-gray-100, #F3F4F6)"
        }}

      >
        <div className="skeleton-placeholder">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <td
                data-bs-placement="bottom"
                className="body3 regular-weight align-middle"
                style={{
                  width: "90px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",                         
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
          </div>
        </div>
      </tr>
      <tr 
        style={{
          cursor: "pointer", marginTop: "10px", 
          border: "1px solid var(--black-400, #D6D6D6)",
          background: "var(--color-gray-gray-100, #F3F4F6)"
        }}

      >
        <div className="skeleton-placeholder">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <td
                data-bs-placement="bottom"
                className="body3 regular-weight align-middle"
                style={{
                  width: "90px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",                         
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
          </div>
        </div>
      </tr>
      <tr 
        style={{
          cursor: "pointer", marginTop: "10px", 
          border: "1px solid var(--black-400, #D6D6D6)",
          background: "var(--color-gray-gray-100, #F3F4F6)"
        }}

      >
        <div className="skeleton-placeholder">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <td
                data-bs-placement="bottom"
                className="body3 regular-weight align-middle"
                style={{
                  width: "90px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",                         
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
          </div>
        </div>
      </tr>
      <tr 
        style={{
          cursor: "pointer", marginTop: "10px", 
          border: "1px solid var(--black-400, #D6D6D6)",
          background: "var(--color-gray-gray-100, #F3F4F6)"
        }}

      >
        <div className="skeleton-placeholder">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <td
                data-bs-placement="bottom"
                className="body3 regular-weight align-middle"
                style={{
                  width: "90px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",                         
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
          </div>
        </div>
      </tr>
      <tr 
        style={{
          cursor: "pointer", marginTop: "10px", 
          border: "1px solid var(--black-400, #D6D6D6)",
          background: "var(--color-gray-gray-100, #F3F4F6)"
        }}

      >
        <div className="skeleton-placeholder">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <td
                data-bs-placement="bottom"
                className="body3 regular-weight align-middle"
                style={{
                  width: "90px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",                         
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
          </div>
        </div>
      </tr>
      <tr 
        style={{
          cursor: "pointer", marginTop: "10px", 
          border: "1px solid var(--black-400, #D6D6D6)",
          background: "var(--color-gray-gray-100, #F3F4F6)"
        }}

      >
        <div className="skeleton-placeholder">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <td
                data-bs-placement="bottom"
                className="body3 regular-weight align-middle"
                style={{
                  width: "90px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",                         
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
          </div>
        </div>
      </tr>
      <tr 
        style={{
          cursor: "pointer", marginTop: "10px", 
          border: "1px solid var(--black-400, #D6D6D6)",
          background: "var(--color-gray-gray-100, #F3F4F6)"
        }}

      >
        <div className="skeleton-placeholder">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <td
                data-bs-placement="bottom"
                className="body3 regular-weight align-middle"
                style={{
                  width: "90px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",                         
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
          </div>
        </div>
      </tr>
      <tr 
        style={{
          cursor: "pointer", marginTop: "10px", 
          border: "1px solid var(--black-400, #D6D6D6)",
          background: "var(--color-gray-gray-100, #F3F4F6)"
        }}

      >
        <div className="skeleton-placeholder">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <td
                data-bs-placement="bottom"
                className="body3 regular-weight align-middle"
                style={{
                  width: "90px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",                         
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
          </div>
        </div>
      </tr>
      <tr 
        style={{
          cursor: "pointer", marginTop: "10px", 
          border: "1px solid var(--black-400, #D6D6D6)",
          background: "var(--color-gray-gray-100, #F3F4F6)"
        }}

      >
        <div className="skeleton-placeholder">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <td
                data-bs-placement="bottom"
                className="body3 regular-weight align-middle"
                style={{
                  width: "90px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",                         
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
          </div>
        </div>
      </tr>
      <tr 
        style={{
          cursor: "pointer", marginTop: "10px", 
          border: "1px solid var(--black-400, #D6D6D6)",
          background: "var(--color-gray-gray-100, #F3F4F6)"
        }}

      >
        <div className="skeleton-placeholder">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <td
                data-bs-placement="bottom"
                className="body3 regular-weight align-middle"
                style={{
                  width: "90px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",                         
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
          </div>
        </div>
      </tr>
      <tr 
        style={{
          cursor: "pointer", marginTop: "10px", 
          border: "1px solid var(--black-400, #D6D6D6)",
          background: "var(--color-gray-gray-100, #F3F4F6)"
        }}

      >
        <div className="skeleton-placeholder">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <td
                data-bs-placement="bottom"
                className="body3 regular-weight align-middle"
                style={{
                  width: "90px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",                         
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
          </div>
        </div>
      </tr>
      <tr 
        style={{
          cursor: "pointer", marginTop: "10px", 
          border: "1px solid var(--black-400, #D6D6D6)",
          background: "var(--color-gray-gray-100, #F3F4F6)"
        }}

      >
        <div className="skeleton-placeholder">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <td
                data-bs-placement="bottom"
                className="body3 regular-weight align-middle"
                style={{
                  width: "90px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",                         
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
          </div>
        </div>
      </tr>
      <tr 
        style={{
          cursor: "pointer", marginTop: "10px", 
          border: "1px solid var(--black-400, #D6D6D6)",
          background: "var(--color-gray-gray-100, #F3F4F6)"
        }}

      >
        <div className="skeleton-placeholder">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <td
                data-bs-placement="bottom"
                className="body3 regular-weight align-middle"
                style={{
                  width: "90px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",                         
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
          </div>
        </div>
      </tr>
      <tr 
        style={{
          cursor: "pointer", marginTop: "10px", 
          border: "1px solid var(--black-400, #D6D6D6)",
          background: "var(--color-gray-gray-100, #F3F4F6)"
        }}

      >
        <div className="skeleton-placeholder">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <td
                data-bs-placement="bottom"
                className="body3 regular-weight align-middle"
                style={{
                  width: "90px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",                         
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
          </div>
        </div>
      </tr>
      <tr 
        style={{
          cursor: "pointer", marginTop: "10px", 
          border: "1px solid var(--black-400, #D6D6D6)",
          background: "var(--color-gray-gray-100, #F3F4F6)"
        }}

      >
        <div className="skeleton-placeholder">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <td
                data-bs-placement="bottom"
                className="body3 regular-weight align-middle"
                style={{
                  width: "90px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "100px",
                  maxWidth: "20%",
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
            <div>
              <td
                data-bs-placement="bottom"
                style={{
                  width: "10px",
                  maxWidth: "20%",                         
                  background: "#E5E7EB", 
                  height: "5px !important",
                }}
              >
              </td>
            </div>
          </div>
        </div>
      </tr>
          </tbody>
  </table></div>)
  )
  )}

      <CreateNewGoalDialog
        openRequestIdeaDialog={openRequestIdeaDialog}
        selectedTab={selectedTab}
      />
      <DeleteGoalDialog />

      <div
        ref={requestIdeaDialogRef}
        data-bs-toggle="modal"
        data-bs-target="#requestIdeaModal"
      ></div>
      <RequestIdeaDialog />

      <TourModal />
    </div>
  );
}

export default Goals;

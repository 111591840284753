export const blueprints = [
  {
    bluePrintName: "Agency Client-Audit Funnel",
    nodes: [
      {
        width: 36,
        height: 47,
        id: "9d4874ec-947e-4925-8830-cb5471891f64",
        type: "TrafficEntry",
        position: {
          x: 185.53125,
          y: 145,
        },
        data: {
          name: "TrafficEntry",
          label: "TrafficEntry node",
          traffic: 1000,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          cpc: 1,
          trafficSources: [
            {
              trafficSource: "Facebook",
              value: 1000,
            },
          ],
        },
        selected: false,
        dragging: false,
        positionAbsolute: {
          x: 185.53125,
          y: 145,
        },
      },
      {
        id: "11d9c100-3354-407a-b95e-f4107bc1ee3c",
        type: "OptInNode",
        position: {
          x: 265.296875,
          y: 103.25,
        },
        data: {
          name: "OptInNode",
          label: "OptInNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 265.296875,
          y: 103.25,
        },
        dragging: false,
      },
      {
        id: "5a05b838-2614-4c4e-88f9-c7269660685a",
        type: "ApplicationPageNode",
        position: {
          x: 362.296875,
          y: 103.25,
        },
        data: {
          name: "ApplicationPageNode",
          label: "ApplicationPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 362.296875,
          y: 103.25,
        },
        dragging: false,
      },
      {
        id: "53f3b1d0-412d-4fc4-be9b-b0045a2020b7",
        type: "ThankYouNode",
        position: {
          x: 454.796875,
          y: 103.25000000000003,
        },
        data: {
          name: "ThankYouNode",
          label: "ThankYouNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 454.796875,
          y: 103.25000000000003,
        },
        dragging: false,
      },
      {
        id: "92cd9ef2-621a-4407-8204-4a8cc681384c",
        type: "CustomPageNode",
        position: {
          x: 552.796875,
          y: 102.25,
        },
        data: {
          name: "CustomPageNode",
          label: "CustomPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 552.796875,
          y: 102.25,
        },
        dragging: false,
      },
      {
        id: "9fe62b8d-114e-43fa-ad57-899ed4e5b3ac",
        type: "WaitNode",
        position: {
          x: 663.796875,
          y: 158.75,
        },
        data: {
          name: "WaitNode",
          label: "WaitNode node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
        },
        width: 29,
        height: 46,
        selected: false,
        positionAbsolute: {
          x: 663.796875,
          y: 158.75,
        },
        dragging: false,
      },
      {
        id: "7e461cee-eecb-447b-9548-9865c10ad75e",
        type: "PhoneNode",
        position: {
          x: 732.296875,
          y: 131.25,
        },
        data: {
          name: "PhoneNode",
          label: "PhoneNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
        },
        width: 32,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 732.296875,
          y: 131.25,
        },
        dragging: false,
      },
      {
        id: "512eaeba-be55-4b66-93d5-bbd9df1061be",
        type: "SalesPageNode",
        position: {
          x: 816.296875,
          y: 102.75,
        },
        data: {
          name: "SalesPageNode",
          label: "SalesPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 816.296875,
          y: 102.75,
        },
        dragging: false,
      },
      {
        id: "7efbd029-0441-451c-8e67-eecd0b838ada",
        type: "OrderFormPage",
        position: {
          x: 916.296875,
          y: 103.25,
        },
        data: {
          name: "OrderFormPage",
          label: "OrderFormPage node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 916.296875,
          y: 103.25,
        },
        dragging: false,
      },
    ],
    edges: [
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "9d4874ec-947e-4925-8830-cb5471891f64",
        sourceHandle: "yes_handle",
        target: "11d9c100-3354-407a-b95e-f4107bc1ee3c",
        targetHandle: null,
        id: "reactflow__edge-9d4874ec-947e-4925-8830-cb5471891f64yes_handle-11d9c100-3354-407a-b95e-f4107bc1ee3c",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "11d9c100-3354-407a-b95e-f4107bc1ee3c",
        sourceHandle: "yes_handle",
        target: "5a05b838-2614-4c4e-88f9-c7269660685a",
        targetHandle: null,
        id: "reactflow__edge-11d9c100-3354-407a-b95e-f4107bc1ee3cyes_handle-5a05b838-2614-4c4e-88f9-c7269660685a",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "5a05b838-2614-4c4e-88f9-c7269660685a",
        sourceHandle: "yes_handle",
        target: "53f3b1d0-412d-4fc4-be9b-b0045a2020b7",
        targetHandle: null,
        id: "reactflow__edge-5a05b838-2614-4c4e-88f9-c7269660685ayes_handle-53f3b1d0-412d-4fc4-be9b-b0045a2020b7",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "53f3b1d0-412d-4fc4-be9b-b0045a2020b7",
        sourceHandle: "yes_handle",
        target: "92cd9ef2-621a-4407-8204-4a8cc681384c",
        targetHandle: null,
        id: "reactflow__edge-53f3b1d0-412d-4fc4-be9b-b0045a2020b7yes_handle-92cd9ef2-621a-4407-8204-4a8cc681384c",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "92cd9ef2-621a-4407-8204-4a8cc681384c",
        sourceHandle: "yes_handle",
        target: "9fe62b8d-114e-43fa-ad57-899ed4e5b3ac",
        targetHandle: null,
        id: "reactflow__edge-92cd9ef2-621a-4407-8204-4a8cc681384cyes_handle-9fe62b8d-114e-43fa-ad57-899ed4e5b3ac",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "9fe62b8d-114e-43fa-ad57-899ed4e5b3ac",
        sourceHandle: "yes_handle",
        target: "7e461cee-eecb-447b-9548-9865c10ad75e",
        targetHandle: null,
        id: "reactflow__edge-9fe62b8d-114e-43fa-ad57-899ed4e5b3acyes_handle-7e461cee-eecb-447b-9548-9865c10ad75e",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "7e461cee-eecb-447b-9548-9865c10ad75e",
        sourceHandle: "yes_handle",
        target: "512eaeba-be55-4b66-93d5-bbd9df1061be",
        targetHandle: null,
        id: "reactflow__edge-7e461cee-eecb-447b-9548-9865c10ad75eyes_handle-512eaeba-be55-4b66-93d5-bbd9df1061be",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "512eaeba-be55-4b66-93d5-bbd9df1061be",
        sourceHandle: "yes_handle",
        target: "7efbd029-0441-451c-8e67-eecd0b838ada",
        targetHandle: null,
        id: "reactflow__edge-512eaeba-be55-4b66-93d5-bbd9df1061beyes_handle-7efbd029-0441-451c-8e67-eecd0b838ada",
      },
    ],
  },
  {
    bluePrintName: "Bridge Funnel",
    nodes: [
      {
        width: 36,
        height: 47,
        id: "2d51f673-c072-4897-bc4c-7a36a17e641e",
        type: "TrafficEntry",
        position: {
          x: 109.203125,
          y: 81,
        },
        data: {
          name: "TrafficEntry",
          label: "TrafficEntry node",
          traffic: 1000,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          cpc: 1,
          trafficSources: [
            {
              trafficSource: "Facebook",
              value: 1000,
            },
          ],
          convertedTraffic: 1000,
        },
        selected: false,
        positionAbsolute: {
          x: 109.203125,
          y: 81,
        },
        dragging: false,
        incomingEdges: [],
        outgoingEdges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "2d51f673-c072-4897-bc4c-7a36a17e641e",
            sourceHandle: "yes_handle",
            target: "e05a0fee-ee76-49fb-bea4-352a68a9ad6e",
            targetHandle: null,
            id: "reactflow__edge-2d51f673-c072-4897-bc4c-7a36a17e641eyes_handle-e05a0fee-ee76-49fb-bea4-352a68a9ad6e",
          },
        ],
        edges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "2d51f673-c072-4897-bc4c-7a36a17e641e",
            sourceHandle: "yes_handle",
            target: "e05a0fee-ee76-49fb-bea4-352a68a9ad6e",
            targetHandle: null,
            id: "reactflow__edge-2d51f673-c072-4897-bc4c-7a36a17e641eyes_handle-e05a0fee-ee76-49fb-bea4-352a68a9ad6e",
          },
        ],
      },
      {
        id: "e05a0fee-ee76-49fb-bea4-352a68a9ad6e",
        type: "OptInNode",
        position: {
          x: 192.0546875,
          y: 115.75,
        },
        data: {
          name: "OptInNode",
          label: "OptInNode node",
          traffic: 1000,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          convertedTraffic: 1000,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 192.0546875,
          y: 115.75,
        },
        dragging: false,
        incomingEdges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "2d51f673-c072-4897-bc4c-7a36a17e641e",
            sourceHandle: "yes_handle",
            target: "e05a0fee-ee76-49fb-bea4-352a68a9ad6e",
            targetHandle: null,
            id: "reactflow__edge-2d51f673-c072-4897-bc4c-7a36a17e641eyes_handle-e05a0fee-ee76-49fb-bea4-352a68a9ad6e",
          },
        ],
        outgoingEdges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "e05a0fee-ee76-49fb-bea4-352a68a9ad6e",
            sourceHandle: "yes_handle",
            target: "a8b3be09-d02a-419b-8151-859b54e97497",
            targetHandle: null,
            id: "reactflow__edge-e05a0fee-ee76-49fb-bea4-352a68a9ad6eyes_handle-a8b3be09-d02a-419b-8151-859b54e97497",
          },
        ],
        edges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "e05a0fee-ee76-49fb-bea4-352a68a9ad6e",
            sourceHandle: "yes_handle",
            target: "a8b3be09-d02a-419b-8151-859b54e97497",
            targetHandle: null,
            id: "reactflow__edge-e05a0fee-ee76-49fb-bea4-352a68a9ad6eyes_handle-a8b3be09-d02a-419b-8151-859b54e97497",
          },
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "2d51f673-c072-4897-bc4c-7a36a17e641e",
            sourceHandle: "yes_handle",
            target: "e05a0fee-ee76-49fb-bea4-352a68a9ad6e",
            targetHandle: null,
            id: "reactflow__edge-2d51f673-c072-4897-bc4c-7a36a17e641eyes_handle-e05a0fee-ee76-49fb-bea4-352a68a9ad6e",
          },
        ],
      },
      {
        id: "a8b3be09-d02a-419b-8151-859b54e97497",
        type: "ContentPageNode",
        position: {
          x: 291.0546875,
          y: 116.25,
        },
        data: {
          name: "ContentPageNode",
          label: "ContentPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          convertedTraffic: 0,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 291.0546875,
          y: 116.25,
        },
        dragging: false,
        incomingEdges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "e05a0fee-ee76-49fb-bea4-352a68a9ad6e",
            sourceHandle: "yes_handle",
            target: "a8b3be09-d02a-419b-8151-859b54e97497",
            targetHandle: null,
            id: "reactflow__edge-e05a0fee-ee76-49fb-bea4-352a68a9ad6eyes_handle-a8b3be09-d02a-419b-8151-859b54e97497",
          },
        ],
        outgoingEdges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "a8b3be09-d02a-419b-8151-859b54e97497",
            sourceHandle: "yes_handle",
            target: "210d0836-a290-42cd-b6ec-d240d64bf6d1",
            targetHandle: null,
            id: "reactflow__edge-a8b3be09-d02a-419b-8151-859b54e97497yes_handle-210d0836-a290-42cd-b6ec-d240d64bf6d1",
          },
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "a8b3be09-d02a-419b-8151-859b54e97497",
            sourceHandle: "no_handle",
            target: "66724d83-c12c-425a-814a-74b94ad68bc9",
            targetHandle: null,
            id: "reactflow__edge-a8b3be09-d02a-419b-8151-859b54e97497no_handle-66724d83-c12c-425a-814a-74b94ad68bc9",
          },
        ],
        edges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "e05a0fee-ee76-49fb-bea4-352a68a9ad6e",
            sourceHandle: "yes_handle",
            target: "a8b3be09-d02a-419b-8151-859b54e97497",
            targetHandle: null,
            id: "reactflow__edge-e05a0fee-ee76-49fb-bea4-352a68a9ad6eyes_handle-a8b3be09-d02a-419b-8151-859b54e97497",
          },
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "a8b3be09-d02a-419b-8151-859b54e97497",
            sourceHandle: "yes_handle",
            target: "210d0836-a290-42cd-b6ec-d240d64bf6d1",
            targetHandle: null,
            id: "reactflow__edge-a8b3be09-d02a-419b-8151-859b54e97497yes_handle-210d0836-a290-42cd-b6ec-d240d64bf6d1",
          },
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "a8b3be09-d02a-419b-8151-859b54e97497",
            sourceHandle: "no_handle",
            target: "66724d83-c12c-425a-814a-74b94ad68bc9",
            targetHandle: null,
            id: "reactflow__edge-a8b3be09-d02a-419b-8151-859b54e97497no_handle-66724d83-c12c-425a-814a-74b94ad68bc9",
          },
        ],
      },
      {
        id: "210d0836-a290-42cd-b6ec-d240d64bf6d1",
        type: "SalesPageNode",
        position: {
          x: 419.0546875,
          y: 119.25,
        },
        data: {
          name: "SalesPageNode",
          label: "SalesPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          convertedTraffic: 0,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 419.0546875,
          y: 119.25,
        },
        dragging: false,
        incomingEdges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "a8b3be09-d02a-419b-8151-859b54e97497",
            sourceHandle: "yes_handle",
            target: "210d0836-a290-42cd-b6ec-d240d64bf6d1",
            targetHandle: null,
            id: "reactflow__edge-a8b3be09-d02a-419b-8151-859b54e97497yes_handle-210d0836-a290-42cd-b6ec-d240d64bf6d1",
          },
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "0875a021-ca2c-4866-a0e3-2ada8d93c3e2",
            sourceHandle: "yes_handle",
            target: "210d0836-a290-42cd-b6ec-d240d64bf6d1",
            targetHandle: null,
            id: "reactflow__edge-0875a021-ca2c-4866-a0e3-2ada8d93c3e2yes_handle-210d0836-a290-42cd-b6ec-d240d64bf6d1",
          },
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "b0ac840b-a102-490b-b423-9b9004f7a618",
            sourceHandle: "yes_handle",
            target: "210d0836-a290-42cd-b6ec-d240d64bf6d1",
            targetHandle: null,
            id: "reactflow__edge-b0ac840b-a102-490b-b423-9b9004f7a618yes_handle-210d0836-a290-42cd-b6ec-d240d64bf6d1",
          },
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "202a90bd-3806-4918-b5ba-48056a852224",
            sourceHandle: "yes_handle",
            target: "210d0836-a290-42cd-b6ec-d240d64bf6d1",
            targetHandle: null,
            id: "reactflow__edge-202a90bd-3806-4918-b5ba-48056a852224yes_handle-210d0836-a290-42cd-b6ec-d240d64bf6d1",
          },
        ],
        outgoingEdges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "210d0836-a290-42cd-b6ec-d240d64bf6d1",
            sourceHandle: "yes_handle",
            target: "842c27fe-cb2f-4672-9f4a-91150442e156",
            targetHandle: null,
            id: "reactflow__edge-210d0836-a290-42cd-b6ec-d240d64bf6d1yes_handle-842c27fe-cb2f-4672-9f4a-91150442e156",
          },
        ],
        edges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "210d0836-a290-42cd-b6ec-d240d64bf6d1",
            sourceHandle: "yes_handle",
            target: "842c27fe-cb2f-4672-9f4a-91150442e156",
            targetHandle: null,
            id: "reactflow__edge-210d0836-a290-42cd-b6ec-d240d64bf6d1yes_handle-842c27fe-cb2f-4672-9f4a-91150442e156",
          },
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "a8b3be09-d02a-419b-8151-859b54e97497",
            sourceHandle: "yes_handle",
            target: "210d0836-a290-42cd-b6ec-d240d64bf6d1",
            targetHandle: null,
            id: "reactflow__edge-a8b3be09-d02a-419b-8151-859b54e97497yes_handle-210d0836-a290-42cd-b6ec-d240d64bf6d1",
          },
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "0875a021-ca2c-4866-a0e3-2ada8d93c3e2",
            sourceHandle: "yes_handle",
            target: "210d0836-a290-42cd-b6ec-d240d64bf6d1",
            targetHandle: null,
            id: "reactflow__edge-0875a021-ca2c-4866-a0e3-2ada8d93c3e2yes_handle-210d0836-a290-42cd-b6ec-d240d64bf6d1",
          },
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "b0ac840b-a102-490b-b423-9b9004f7a618",
            sourceHandle: "yes_handle",
            target: "210d0836-a290-42cd-b6ec-d240d64bf6d1",
            targetHandle: null,
            id: "reactflow__edge-b0ac840b-a102-490b-b423-9b9004f7a618yes_handle-210d0836-a290-42cd-b6ec-d240d64bf6d1",
          },
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "202a90bd-3806-4918-b5ba-48056a852224",
            sourceHandle: "yes_handle",
            target: "210d0836-a290-42cd-b6ec-d240d64bf6d1",
            targetHandle: null,
            id: "reactflow__edge-202a90bd-3806-4918-b5ba-48056a852224yes_handle-210d0836-a290-42cd-b6ec-d240d64bf6d1",
          },
        ],
      },
      {
        id: "842c27fe-cb2f-4672-9f4a-91150442e156",
        type: "OrderFormPage",
        position: {
          x: 515.0546875,
          y: 118.75,
        },
        data: {
          name: "OrderFormPage",
          label: "OrderFormPage node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          convertedTraffic: 0,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 515.0546875,
          y: 118.75,
        },
        dragging: false,
        incomingEdges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "210d0836-a290-42cd-b6ec-d240d64bf6d1",
            sourceHandle: "yes_handle",
            target: "842c27fe-cb2f-4672-9f4a-91150442e156",
            targetHandle: null,
            id: "reactflow__edge-210d0836-a290-42cd-b6ec-d240d64bf6d1yes_handle-842c27fe-cb2f-4672-9f4a-91150442e156",
          },
        ],
        outgoingEdges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "842c27fe-cb2f-4672-9f4a-91150442e156",
            sourceHandle: "yes_handle",
            target: "955cf79e-424c-4cbc-9e6b-75f00bc9a778",
            targetHandle: null,
            id: "reactflow__edge-842c27fe-cb2f-4672-9f4a-91150442e156yes_handle-955cf79e-424c-4cbc-9e6b-75f00bc9a778",
          },
        ],
        edges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "842c27fe-cb2f-4672-9f4a-91150442e156",
            sourceHandle: "yes_handle",
            target: "955cf79e-424c-4cbc-9e6b-75f00bc9a778",
            targetHandle: null,
            id: "reactflow__edge-842c27fe-cb2f-4672-9f4a-91150442e156yes_handle-955cf79e-424c-4cbc-9e6b-75f00bc9a778",
          },
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "210d0836-a290-42cd-b6ec-d240d64bf6d1",
            sourceHandle: "yes_handle",
            target: "842c27fe-cb2f-4672-9f4a-91150442e156",
            targetHandle: null,
            id: "reactflow__edge-210d0836-a290-42cd-b6ec-d240d64bf6d1yes_handle-842c27fe-cb2f-4672-9f4a-91150442e156",
          },
        ],
      },
      {
        id: "955cf79e-424c-4cbc-9e6b-75f00bc9a778",
        type: "ThankYouNode",
        position: {
          x: 604.9921875,
          y: 117.75,
        },
        data: {
          name: "ThankYouNode",
          label: "ThankYouNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          convertedTraffic: 0,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 604.9921875,
          y: 117.75,
        },
        dragging: false,
        incomingEdges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "842c27fe-cb2f-4672-9f4a-91150442e156",
            sourceHandle: "yes_handle",
            target: "955cf79e-424c-4cbc-9e6b-75f00bc9a778",
            targetHandle: null,
            id: "reactflow__edge-842c27fe-cb2f-4672-9f4a-91150442e156yes_handle-955cf79e-424c-4cbc-9e6b-75f00bc9a778",
          },
        ],
        outgoingEdges: [],
        edges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "842c27fe-cb2f-4672-9f4a-91150442e156",
            sourceHandle: "yes_handle",
            target: "955cf79e-424c-4cbc-9e6b-75f00bc9a778",
            targetHandle: null,
            id: "reactflow__edge-842c27fe-cb2f-4672-9f4a-91150442e156yes_handle-955cf79e-424c-4cbc-9e6b-75f00bc9a778",
          },
        ],
      },
      {
        id: "66724d83-c12c-425a-814a-74b94ad68bc9",
        type: "EmailNode",
        position: {
          x: 157.48286415257843,
          y: 277.1335908153384,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          convertedTraffic: 0,
        },
        width: 49,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 157.48286415257843,
          y: 277.1335908153384,
        },
        dragging: false,
        incomingEdges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "a8b3be09-d02a-419b-8151-859b54e97497",
            sourceHandle: "no_handle",
            target: "66724d83-c12c-425a-814a-74b94ad68bc9",
            targetHandle: null,
            id: "reactflow__edge-a8b3be09-d02a-419b-8151-859b54e97497no_handle-66724d83-c12c-425a-814a-74b94ad68bc9",
          },
        ],
        outgoingEdges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "66724d83-c12c-425a-814a-74b94ad68bc9",
            sourceHandle: "no_handle",
            target: "ddd1ef00-d381-46f5-b501-fa9410f851c8",
            targetHandle: null,
            id: "reactflow__edge-66724d83-c12c-425a-814a-74b94ad68bc9no_handle-ddd1ef00-d381-46f5-b501-fa9410f851c8",
          },
        ],
        edges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "66724d83-c12c-425a-814a-74b94ad68bc9",
            sourceHandle: "no_handle",
            target: "ddd1ef00-d381-46f5-b501-fa9410f851c8",
            targetHandle: null,
            id: "reactflow__edge-66724d83-c12c-425a-814a-74b94ad68bc9no_handle-ddd1ef00-d381-46f5-b501-fa9410f851c8",
          },
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "a8b3be09-d02a-419b-8151-859b54e97497",
            sourceHandle: "no_handle",
            target: "66724d83-c12c-425a-814a-74b94ad68bc9",
            targetHandle: null,
            id: "reactflow__edge-a8b3be09-d02a-419b-8151-859b54e97497no_handle-66724d83-c12c-425a-814a-74b94ad68bc9",
          },
        ],
      },
      {
        id: "0875a021-ca2c-4866-a0e3-2ada8d93c3e2",
        type: "EmailNode",
        position: {
          x: 229.835021834896,
          y: 319.5235748497656,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          convertedTraffic: 0,
        },
        width: 49,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 229.835021834896,
          y: 319.5235748497656,
        },
        dragging: false,
        incomingEdges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "ddd1ef00-d381-46f5-b501-fa9410f851c8",
            sourceHandle: "yes_handle",
            target: "0875a021-ca2c-4866-a0e3-2ada8d93c3e2",
            targetHandle: null,
            id: "reactflow__edge-ddd1ef00-d381-46f5-b501-fa9410f851c8yes_handle-0875a021-ca2c-4866-a0e3-2ada8d93c3e2",
          },
        ],
        outgoingEdges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "0875a021-ca2c-4866-a0e3-2ada8d93c3e2",
            sourceHandle: "no_handle",
            target: "1cffdd23-bfd3-49f5-afee-cb48e498a92b",
            targetHandle: null,
            id: "reactflow__edge-0875a021-ca2c-4866-a0e3-2ada8d93c3e2no_handle-1cffdd23-bfd3-49f5-afee-cb48e498a92b",
          },
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "0875a021-ca2c-4866-a0e3-2ada8d93c3e2",
            sourceHandle: "yes_handle",
            target: "210d0836-a290-42cd-b6ec-d240d64bf6d1",
            targetHandle: null,
            id: "reactflow__edge-0875a021-ca2c-4866-a0e3-2ada8d93c3e2yes_handle-210d0836-a290-42cd-b6ec-d240d64bf6d1",
          },
        ],
        edges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "ddd1ef00-d381-46f5-b501-fa9410f851c8",
            sourceHandle: "yes_handle",
            target: "0875a021-ca2c-4866-a0e3-2ada8d93c3e2",
            targetHandle: null,
            id: "reactflow__edge-ddd1ef00-d381-46f5-b501-fa9410f851c8yes_handle-0875a021-ca2c-4866-a0e3-2ada8d93c3e2",
          },
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "0875a021-ca2c-4866-a0e3-2ada8d93c3e2",
            sourceHandle: "no_handle",
            target: "1cffdd23-bfd3-49f5-afee-cb48e498a92b",
            targetHandle: null,
            id: "reactflow__edge-0875a021-ca2c-4866-a0e3-2ada8d93c3e2no_handle-1cffdd23-bfd3-49f5-afee-cb48e498a92b",
          },
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "0875a021-ca2c-4866-a0e3-2ada8d93c3e2",
            sourceHandle: "yes_handle",
            target: "210d0836-a290-42cd-b6ec-d240d64bf6d1",
            targetHandle: null,
            id: "reactflow__edge-0875a021-ca2c-4866-a0e3-2ada8d93c3e2yes_handle-210d0836-a290-42cd-b6ec-d240d64bf6d1",
          },
        ],
      },
      {
        id: "b0ac840b-a102-490b-b423-9b9004f7a618",
        type: "EmailNode",
        position: {
          x: 284.54573041862,
          y: 363.7814331330208,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          convertedTraffic: 0,
        },
        width: 49,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 284.54573041862,
          y: 363.7814331330208,
        },
        dragging: false,
        incomingEdges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "1cffdd23-bfd3-49f5-afee-cb48e498a92b",
            sourceHandle: "yes_handle",
            target: "b0ac840b-a102-490b-b423-9b9004f7a618",
            targetHandle: null,
            id: "reactflow__edge-1cffdd23-bfd3-49f5-afee-cb48e498a92byes_handle-b0ac840b-a102-490b-b423-9b9004f7a618",
          },
        ],
        outgoingEdges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "b0ac840b-a102-490b-b423-9b9004f7a618",
            sourceHandle: "no_handle",
            target: "a3c8e96b-7883-48b4-b048-f0a4401bfbad",
            targetHandle: null,
            id: "reactflow__edge-b0ac840b-a102-490b-b423-9b9004f7a618no_handle-a3c8e96b-7883-48b4-b048-f0a4401bfbad",
          },
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "b0ac840b-a102-490b-b423-9b9004f7a618",
            sourceHandle: "yes_handle",
            target: "210d0836-a290-42cd-b6ec-d240d64bf6d1",
            targetHandle: null,
            id: "reactflow__edge-b0ac840b-a102-490b-b423-9b9004f7a618yes_handle-210d0836-a290-42cd-b6ec-d240d64bf6d1",
          },
        ],
        edges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "1cffdd23-bfd3-49f5-afee-cb48e498a92b",
            sourceHandle: "yes_handle",
            target: "b0ac840b-a102-490b-b423-9b9004f7a618",
            targetHandle: null,
            id: "reactflow__edge-1cffdd23-bfd3-49f5-afee-cb48e498a92byes_handle-b0ac840b-a102-490b-b423-9b9004f7a618",
          },
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "b0ac840b-a102-490b-b423-9b9004f7a618",
            sourceHandle: "no_handle",
            target: "a3c8e96b-7883-48b4-b048-f0a4401bfbad",
            targetHandle: null,
            id: "reactflow__edge-b0ac840b-a102-490b-b423-9b9004f7a618no_handle-a3c8e96b-7883-48b4-b048-f0a4401bfbad",
          },
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "b0ac840b-a102-490b-b423-9b9004f7a618",
            sourceHandle: "yes_handle",
            target: "210d0836-a290-42cd-b6ec-d240d64bf6d1",
            targetHandle: null,
            id: "reactflow__edge-b0ac840b-a102-490b-b423-9b9004f7a618yes_handle-210d0836-a290-42cd-b6ec-d240d64bf6d1",
          },
        ],
      },
      {
        id: "202a90bd-3806-4918-b5ba-48056a852224",
        type: "EmailNode",
        position: {
          x: 334.967147586068,
          y: 419.407165665104,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
        },
        width: 49,
        height: 72,
        selected: true,
        positionAbsolute: {
          x: 334.967147586068,
          y: 419.407165665104,
        },
        dragging: false,
        incomingEdges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "a3c8e96b-7883-48b4-b048-f0a4401bfbad",
            sourceHandle: "yes_handle",
            target: "202a90bd-3806-4918-b5ba-48056a852224",
            targetHandle: null,
            id: "reactflow__edge-a3c8e96b-7883-48b4-b048-f0a4401bfbadyes_handle-202a90bd-3806-4918-b5ba-48056a852224",
          },
        ],
        outgoingEdges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "202a90bd-3806-4918-b5ba-48056a852224",
            sourceHandle: "yes_handle",
            target: "210d0836-a290-42cd-b6ec-d240d64bf6d1",
            targetHandle: null,
            id: "reactflow__edge-202a90bd-3806-4918-b5ba-48056a852224yes_handle-210d0836-a290-42cd-b6ec-d240d64bf6d1",
          },
        ],
        edges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "a3c8e96b-7883-48b4-b048-f0a4401bfbad",
            sourceHandle: "yes_handle",
            target: "202a90bd-3806-4918-b5ba-48056a852224",
            targetHandle: null,
            id: "reactflow__edge-a3c8e96b-7883-48b4-b048-f0a4401bfbadyes_handle-202a90bd-3806-4918-b5ba-48056a852224",
          },
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "202a90bd-3806-4918-b5ba-48056a852224",
            sourceHandle: "yes_handle",
            target: "210d0836-a290-42cd-b6ec-d240d64bf6d1",
            targetHandle: null,
            id: "reactflow__edge-202a90bd-3806-4918-b5ba-48056a852224yes_handle-210d0836-a290-42cd-b6ec-d240d64bf6d1",
          },
        ],
      },
      {
        id: "ddd1ef00-d381-46f5-b501-fa9410f851c8",
        type: "WaitNode",
        position: {
          x: 178.06144698513043,
          y: 356.5550079827864,
        },
        data: {
          name: "WaitNode",
          label: "WaitNode node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          convertedTraffic: 0,
        },
        width: 29,
        height: 46,
        selected: false,
        positionAbsolute: {
          x: 178.06144698513043,
          y: 356.5550079827864,
        },
        dragging: false,
        incomingEdges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "66724d83-c12c-425a-814a-74b94ad68bc9",
            sourceHandle: "no_handle",
            target: "ddd1ef00-d381-46f5-b501-fa9410f851c8",
            targetHandle: null,
            id: "reactflow__edge-66724d83-c12c-425a-814a-74b94ad68bc9no_handle-ddd1ef00-d381-46f5-b501-fa9410f851c8",
          },
        ],
        outgoingEdges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "ddd1ef00-d381-46f5-b501-fa9410f851c8",
            sourceHandle: "yes_handle",
            target: "0875a021-ca2c-4866-a0e3-2ada8d93c3e2",
            targetHandle: null,
            id: "reactflow__edge-ddd1ef00-d381-46f5-b501-fa9410f851c8yes_handle-0875a021-ca2c-4866-a0e3-2ada8d93c3e2",
          },
        ],
        edges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "66724d83-c12c-425a-814a-74b94ad68bc9",
            sourceHandle: "no_handle",
            target: "ddd1ef00-d381-46f5-b501-fa9410f851c8",
            targetHandle: null,
            id: "reactflow__edge-66724d83-c12c-425a-814a-74b94ad68bc9no_handle-ddd1ef00-d381-46f5-b501-fa9410f851c8",
          },
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "ddd1ef00-d381-46f5-b501-fa9410f851c8",
            sourceHandle: "yes_handle",
            target: "0875a021-ca2c-4866-a0e3-2ada8d93c3e2",
            targetHandle: null,
            id: "reactflow__edge-ddd1ef00-d381-46f5-b501-fa9410f851c8yes_handle-0875a021-ca2c-4866-a0e3-2ada8d93c3e2",
          },
        ],
      },
      {
        id: "1cffdd23-bfd3-49f5-afee-cb48e498a92b",
        type: "WaitNode",
        position: {
          x: 235.18717951721362,
          y: 401.5550079827864,
        },
        data: {
          name: "WaitNode",
          label: "WaitNode node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          convertedTraffic: 0,
        },
        width: 29,
        height: 46,
        selected: false,
        positionAbsolute: {
          x: 235.18717951721362,
          y: 401.5550079827864,
        },
        dragging: false,
        incomingEdges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "0875a021-ca2c-4866-a0e3-2ada8d93c3e2",
            sourceHandle: "no_handle",
            target: "1cffdd23-bfd3-49f5-afee-cb48e498a92b",
            targetHandle: null,
            id: "reactflow__edge-0875a021-ca2c-4866-a0e3-2ada8d93c3e2no_handle-1cffdd23-bfd3-49f5-afee-cb48e498a92b",
          },
        ],
        outgoingEdges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "1cffdd23-bfd3-49f5-afee-cb48e498a92b",
            sourceHandle: "yes_handle",
            target: "b0ac840b-a102-490b-b423-9b9004f7a618",
            targetHandle: null,
            id: "reactflow__edge-1cffdd23-bfd3-49f5-afee-cb48e498a92byes_handle-b0ac840b-a102-490b-b423-9b9004f7a618",
          },
        ],
        edges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "0875a021-ca2c-4866-a0e3-2ada8d93c3e2",
            sourceHandle: "no_handle",
            target: "1cffdd23-bfd3-49f5-afee-cb48e498a92b",
            targetHandle: null,
            id: "reactflow__edge-0875a021-ca2c-4866-a0e3-2ada8d93c3e2no_handle-1cffdd23-bfd3-49f5-afee-cb48e498a92b",
          },
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "1cffdd23-bfd3-49f5-afee-cb48e498a92b",
            sourceHandle: "yes_handle",
            target: "b0ac840b-a102-490b-b423-9b9004f7a618",
            targetHandle: null,
            id: "reactflow__edge-1cffdd23-bfd3-49f5-afee-cb48e498a92byes_handle-b0ac840b-a102-490b-b423-9b9004f7a618",
          },
        ],
      },
      {
        id: "a3c8e96b-7883-48b4-b048-f0a4401bfbad",
        type: "WaitNode",
        position: {
          x: 282.89788810093756,
          y: 448.58644111580725,
        },
        data: {
          name: "WaitNode",
          label: "WaitNode node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
        },
        width: 29,
        height: 46,
        selected: false,
        positionAbsolute: {
          x: 282.89788810093756,
          y: 448.58644111580725,
        },
        dragging: false,
        incomingEdges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "b0ac840b-a102-490b-b423-9b9004f7a618",
            sourceHandle: "no_handle",
            target: "a3c8e96b-7883-48b4-b048-f0a4401bfbad",
            targetHandle: null,
            id: "reactflow__edge-b0ac840b-a102-490b-b423-9b9004f7a618no_handle-a3c8e96b-7883-48b4-b048-f0a4401bfbad",
          },
        ],
        outgoingEdges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "a3c8e96b-7883-48b4-b048-f0a4401bfbad",
            sourceHandle: "yes_handle",
            target: "202a90bd-3806-4918-b5ba-48056a852224",
            targetHandle: null,
            id: "reactflow__edge-a3c8e96b-7883-48b4-b048-f0a4401bfbadyes_handle-202a90bd-3806-4918-b5ba-48056a852224",
          },
        ],
        edges: [
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "b0ac840b-a102-490b-b423-9b9004f7a618",
            sourceHandle: "no_handle",
            target: "a3c8e96b-7883-48b4-b048-f0a4401bfbad",
            targetHandle: null,
            id: "reactflow__edge-b0ac840b-a102-490b-b423-9b9004f7a618no_handle-a3c8e96b-7883-48b4-b048-f0a4401bfbad",
          },
          {
            markerEnd: {
              type: "arrowclosed",
            },
            source: "a3c8e96b-7883-48b4-b048-f0a4401bfbad",
            sourceHandle: "yes_handle",
            target: "202a90bd-3806-4918-b5ba-48056a852224",
            targetHandle: null,
            id: "reactflow__edge-a3c8e96b-7883-48b4-b048-f0a4401bfbadyes_handle-202a90bd-3806-4918-b5ba-48056a852224",
          },
        ],
      },
    ],
    edges: [
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "842c27fe-cb2f-4672-9f4a-91150442e156",
        sourceHandle: "yes_handle",
        target: "955cf79e-424c-4cbc-9e6b-75f00bc9a778",
        targetHandle: null,
        id: "reactflow__edge-842c27fe-cb2f-4672-9f4a-91150442e156yes_handle-955cf79e-424c-4cbc-9e6b-75f00bc9a778",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "210d0836-a290-42cd-b6ec-d240d64bf6d1",
        sourceHandle: "yes_handle",
        target: "842c27fe-cb2f-4672-9f4a-91150442e156",
        targetHandle: null,
        id: "reactflow__edge-210d0836-a290-42cd-b6ec-d240d64bf6d1yes_handle-842c27fe-cb2f-4672-9f4a-91150442e156",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "e05a0fee-ee76-49fb-bea4-352a68a9ad6e",
        sourceHandle: "yes_handle",
        target: "a8b3be09-d02a-419b-8151-859b54e97497",
        targetHandle: null,
        id: "reactflow__edge-e05a0fee-ee76-49fb-bea4-352a68a9ad6eyes_handle-a8b3be09-d02a-419b-8151-859b54e97497",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "2d51f673-c072-4897-bc4c-7a36a17e641e",
        sourceHandle: "yes_handle",
        target: "e05a0fee-ee76-49fb-bea4-352a68a9ad6e",
        targetHandle: null,
        id: "reactflow__edge-2d51f673-c072-4897-bc4c-7a36a17e641eyes_handle-e05a0fee-ee76-49fb-bea4-352a68a9ad6e",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "a8b3be09-d02a-419b-8151-859b54e97497",
        sourceHandle: "yes_handle",
        target: "210d0836-a290-42cd-b6ec-d240d64bf6d1",
        targetHandle: null,
        id: "reactflow__edge-a8b3be09-d02a-419b-8151-859b54e97497yes_handle-210d0836-a290-42cd-b6ec-d240d64bf6d1",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "66724d83-c12c-425a-814a-74b94ad68bc9",
        sourceHandle: "no_handle",
        target: "ddd1ef00-d381-46f5-b501-fa9410f851c8",
        targetHandle: null,
        id: "reactflow__edge-66724d83-c12c-425a-814a-74b94ad68bc9no_handle-ddd1ef00-d381-46f5-b501-fa9410f851c8",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "ddd1ef00-d381-46f5-b501-fa9410f851c8",
        sourceHandle: "yes_handle",
        target: "0875a021-ca2c-4866-a0e3-2ada8d93c3e2",
        targetHandle: null,
        id: "reactflow__edge-ddd1ef00-d381-46f5-b501-fa9410f851c8yes_handle-0875a021-ca2c-4866-a0e3-2ada8d93c3e2",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "0875a021-ca2c-4866-a0e3-2ada8d93c3e2",
        sourceHandle: "no_handle",
        target: "1cffdd23-bfd3-49f5-afee-cb48e498a92b",
        targetHandle: null,
        id: "reactflow__edge-0875a021-ca2c-4866-a0e3-2ada8d93c3e2no_handle-1cffdd23-bfd3-49f5-afee-cb48e498a92b",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "1cffdd23-bfd3-49f5-afee-cb48e498a92b",
        sourceHandle: "yes_handle",
        target: "b0ac840b-a102-490b-b423-9b9004f7a618",
        targetHandle: null,
        id: "reactflow__edge-1cffdd23-bfd3-49f5-afee-cb48e498a92byes_handle-b0ac840b-a102-490b-b423-9b9004f7a618",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "b0ac840b-a102-490b-b423-9b9004f7a618",
        sourceHandle: "no_handle",
        target: "a3c8e96b-7883-48b4-b048-f0a4401bfbad",
        targetHandle: null,
        id: "reactflow__edge-b0ac840b-a102-490b-b423-9b9004f7a618no_handle-a3c8e96b-7883-48b4-b048-f0a4401bfbad",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "a3c8e96b-7883-48b4-b048-f0a4401bfbad",
        sourceHandle: "yes_handle",
        target: "202a90bd-3806-4918-b5ba-48056a852224",
        targetHandle: null,
        id: "reactflow__edge-a3c8e96b-7883-48b4-b048-f0a4401bfbadyes_handle-202a90bd-3806-4918-b5ba-48056a852224",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "a8b3be09-d02a-419b-8151-859b54e97497",
        sourceHandle: "no_handle",
        target: "66724d83-c12c-425a-814a-74b94ad68bc9",
        targetHandle: null,
        id: "reactflow__edge-a8b3be09-d02a-419b-8151-859b54e97497no_handle-66724d83-c12c-425a-814a-74b94ad68bc9",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "0875a021-ca2c-4866-a0e3-2ada8d93c3e2",
        sourceHandle: "yes_handle",
        target: "210d0836-a290-42cd-b6ec-d240d64bf6d1",
        targetHandle: null,
        id: "reactflow__edge-0875a021-ca2c-4866-a0e3-2ada8d93c3e2yes_handle-210d0836-a290-42cd-b6ec-d240d64bf6d1",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "b0ac840b-a102-490b-b423-9b9004f7a618",
        sourceHandle: "yes_handle",
        target: "210d0836-a290-42cd-b6ec-d240d64bf6d1",
        targetHandle: null,
        id: "reactflow__edge-b0ac840b-a102-490b-b423-9b9004f7a618yes_handle-210d0836-a290-42cd-b6ec-d240d64bf6d1",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "202a90bd-3806-4918-b5ba-48056a852224",
        sourceHandle: "yes_handle",
        target: "210d0836-a290-42cd-b6ec-d240d64bf6d1",
        targetHandle: null,
        id: "reactflow__edge-202a90bd-3806-4918-b5ba-48056a852224yes_handle-210d0836-a290-42cd-b6ec-d240d64bf6d1",
      },
    ],
  },
  {
    bluePrintName: "Chatbot Lead Funnel",
    nodes: [
      {
        width: 36,
        height: 47,
        id: "66f58cfc-a979-497f-8ccc-95ccccaf707a",
        type: "TrafficEntry",
        position: {
          x: -16.46875,
          y: 29.5,
        },
        data: {
          name: "TrafficEntry",
          label: "TrafficEntry node",
          traffic: 1000,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          cpc: 1,
          trafficSources: [
            {
              trafficSource: "Facebook",
              value: 1000,
            },
          ],
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: -16.46875,
          y: 29.5,
        },
        dragging: false,
      },
      {
        id: "e2191e58-57ea-45e7-af71-06669dae4e4a",
        type: "ChatbotOptInNode",
        position: {
          x: 48.796875,
          y: 40.75,
        },
        data: {
          name: "ChatbotOptInNode",
          label: "ChatbotOptInNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 48.796875,
          y: 40.75,
        },
        dragging: false,
      },
      {
        id: "5ed68a13-3073-4a61-abe8-e02bfae804fb",
        type: "ThankYouNode",
        position: {
          x: 147.796875,
          y: 39.75,
        },
        data: {
          name: "ThankYouNode",
          label: "ThankYouNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 147.796875,
          y: 39.75,
        },
        dragging: false,
      },
      {
        id: "58e141ce-3294-4937-ad10-998477cf8e43",
        type: "ChatbotNode",
        position: {
          x: 262.296875,
          y: 63.75,
        },
        data: {
          name: "ChatbotNode",
          label: "ChatbotNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 33,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 262.296875,
          y: 63.75,
        },
        dragging: false,
      },
      {
        id: "b8cabeed-06cf-41f8-a7af-a00e14b0f7f9",
        type: "WaitNode",
        position: {
          x: 326.296875,
          y: 90.25,
        },
        data: {
          name: "WaitNode",
          label: "WaitNode node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 29,
        height: 46,
        selected: false,
        positionAbsolute: {
          x: 326.296875,
          y: 90.25,
        },
        dragging: false,
      },
      {
        id: "1feac024-08ca-44a0-9500-1739e3fa64fe",
        type: "ChatbotNode",
        position: {
          x: 390.796875,
          y: 63.75,
        },
        data: {
          name: "ChatbotNode",
          label: "ChatbotNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 33,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 390.796875,
          y: 63.75,
        },
        dragging: false,
      },
      {
        id: "bd304fb7-da97-44f7-bd08-d1370be0f464",
        type: "WaitNode",
        position: {
          x: 459.296875,
          y: 90.75,
        },
        data: {
          name: "WaitNode",
          label: "WaitNode node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 29,
        height: 46,
        selected: false,
        positionAbsolute: {
          x: 459.296875,
          y: 90.75,
        },
        dragging: false,
      },
      {
        id: "8450b62d-a907-47fd-b265-34c5c9b105ea",
        type: "ChatbotNode",
        position: {
          x: 522.796875,
          y: 64.25,
        },
        data: {
          name: "ChatbotNode",
          label: "ChatbotNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 33,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 522.796875,
          y: 64.25,
        },
        dragging: false,
      },
      {
        id: "bf49aaf5-cf7b-4e4e-998f-6cbb01e9e183",
        type: "WaitNode",
        position: {
          x: 518.6880826929296,
          y: 147.45437176606757,
        },
        data: {
          name: "WaitNode",
          label: "WaitNode node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 29,
        height: 46,
        selected: false,
        positionAbsolute: {
          x: 518.6880826929296,
          y: 147.45437176606757,
        },
        dragging: false,
      },
      {
        id: "c93577bd-4495-4f9a-8ab4-9b9907df4013",
        type: "ChatbotNode",
        position: {
          x: 524.6880826929296,
          y: 209.45437176606757,
        },
        data: {
          name: "ChatbotNode",
          label: "ChatbotNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 33,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 524.6880826929296,
          y: 209.45437176606757,
        },
        dragging: false,
      },
      {
        id: "62c3d18a-8354-4c8b-b8e2-884efffee2e3",
        type: "WaitNode",
        position: {
          x: 520.0037242761879,
          y: 287.17862491080024,
        },
        data: {
          name: "WaitNode",
          label: "WaitNode node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 29,
        height: 46,
        selected: false,
        positionAbsolute: {
          x: 520.0037242761879,
          y: 287.17862491080024,
        },
        dragging: false,
      },
      {
        id: "bbe53e46-b00f-4fe3-9074-c19fd5641bff",
        type: "ChatbotNode",
        position: {
          x: 524.622001963893,
          y: 349.19549671712616,
        },
        data: {
          name: "ChatbotNode",
          label: "ChatbotNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 33,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 524.622001963893,
          y: 349.19549671712616,
        },
        dragging: false,
      },
      {
        id: "b14078d3-5ab3-4fb6-84f4-8965029d7aa9",
        type: "SalesPageNode",
        position: {
          x: 618.3070636287686,
          y: 50.98670888245209,
        },
        data: {
          name: "SalesPageNode",
          label: "SalesPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 618.3070636287686,
          y: 50.98670888245209,
        },
        dragging: false,
      },
      {
        id: "9f6c436c-3447-48e4-b41e-cdb680fda6cb",
        type: "ThankYouNode",
        position: {
          x: 808.3162027800653,
          y: 52.965970748611454,
        },
        data: {
          name: "ThankYouNode",
          label: "ThankYouNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 808.3162027800653,
          y: 52.965970748611454,
        },
        dragging: false,
      },
      {
        id: "51cde1a7-6477-4974-b4ca-be54cf7cd3c0",
        type: "OrderFormPage",
        position: {
          x: 713.9713871598032,
          y: 52.306216793224976,
        },
        data: {
          name: "OrderFormPage",
          label: "OrderFormPage node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 713.9713871598032,
          y: 52.306216793224976,
        },
        dragging: false,
      },
    ],
    edges: [
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "66f58cfc-a979-497f-8ccc-95ccccaf707a",
        sourceHandle: "yes_handle",
        target: "e2191e58-57ea-45e7-af71-06669dae4e4a",
        targetHandle: null,
        id: "reactflow__edge-66f58cfc-a979-497f-8ccc-95ccccaf707ayes_handle-e2191e58-57ea-45e7-af71-06669dae4e4a",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "e2191e58-57ea-45e7-af71-06669dae4e4a",
        sourceHandle: "yes_handle",
        target: "5ed68a13-3073-4a61-abe8-e02bfae804fb",
        targetHandle: null,
        id: "reactflow__edge-e2191e58-57ea-45e7-af71-06669dae4e4ayes_handle-5ed68a13-3073-4a61-abe8-e02bfae804fb",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "5ed68a13-3073-4a61-abe8-e02bfae804fb",
        sourceHandle: "yes_handle",
        target: "58e141ce-3294-4937-ad10-998477cf8e43",
        targetHandle: null,
        id: "reactflow__edge-5ed68a13-3073-4a61-abe8-e02bfae804fbyes_handle-58e141ce-3294-4937-ad10-998477cf8e43",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "58e141ce-3294-4937-ad10-998477cf8e43",
        sourceHandle: "yes_handle",
        target: "b8cabeed-06cf-41f8-a7af-a00e14b0f7f9",
        targetHandle: null,
        id: "reactflow__edge-58e141ce-3294-4937-ad10-998477cf8e43yes_handle-b8cabeed-06cf-41f8-a7af-a00e14b0f7f9",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "b8cabeed-06cf-41f8-a7af-a00e14b0f7f9",
        sourceHandle: "yes_handle",
        target: "1feac024-08ca-44a0-9500-1739e3fa64fe",
        targetHandle: null,
        id: "reactflow__edge-b8cabeed-06cf-41f8-a7af-a00e14b0f7f9yes_handle-1feac024-08ca-44a0-9500-1739e3fa64fe",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "1feac024-08ca-44a0-9500-1739e3fa64fe",
        sourceHandle: "yes_handle",
        target: "bd304fb7-da97-44f7-bd08-d1370be0f464",
        targetHandle: null,
        id: "reactflow__edge-1feac024-08ca-44a0-9500-1739e3fa64feyes_handle-bd304fb7-da97-44f7-bd08-d1370be0f464",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "bd304fb7-da97-44f7-bd08-d1370be0f464",
        sourceHandle: "yes_handle",
        target: "8450b62d-a907-47fd-b265-34c5c9b105ea",
        targetHandle: null,
        id: "reactflow__edge-bd304fb7-da97-44f7-bd08-d1370be0f464yes_handle-8450b62d-a907-47fd-b265-34c5c9b105ea",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "8450b62d-a907-47fd-b265-34c5c9b105ea",
        sourceHandle: "no_handle",
        target: "bf49aaf5-cf7b-4e4e-998f-6cbb01e9e183",
        targetHandle: null,
        id: "reactflow__edge-8450b62d-a907-47fd-b265-34c5c9b105eano_handle-bf49aaf5-cf7b-4e4e-998f-6cbb01e9e183",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "bf49aaf5-cf7b-4e4e-998f-6cbb01e9e183",
        sourceHandle: "yes_handle",
        target: "c93577bd-4495-4f9a-8ab4-9b9907df4013",
        targetHandle: null,
        id: "reactflow__edge-bf49aaf5-cf7b-4e4e-998f-6cbb01e9e183yes_handle-c93577bd-4495-4f9a-8ab4-9b9907df4013",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "c93577bd-4495-4f9a-8ab4-9b9907df4013",
        sourceHandle: "no_handle",
        target: "62c3d18a-8354-4c8b-b8e2-884efffee2e3",
        targetHandle: null,
        id: "reactflow__edge-c93577bd-4495-4f9a-8ab4-9b9907df4013no_handle-62c3d18a-8354-4c8b-b8e2-884efffee2e3",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "62c3d18a-8354-4c8b-b8e2-884efffee2e3",
        sourceHandle: "yes_handle",
        target: "bbe53e46-b00f-4fe3-9074-c19fd5641bff",
        targetHandle: null,
        id: "reactflow__edge-62c3d18a-8354-4c8b-b8e2-884efffee2e3yes_handle-bbe53e46-b00f-4fe3-9074-c19fd5641bff",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "b14078d3-5ab3-4fb6-84f4-8965029d7aa9",
        sourceHandle: "yes_handle",
        target: "51cde1a7-6477-4974-b4ca-be54cf7cd3c0",
        targetHandle: null,
        id: "reactflow__edge-b14078d3-5ab3-4fb6-84f4-8965029d7aa9yes_handle-51cde1a7-6477-4974-b4ca-be54cf7cd3c0",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "51cde1a7-6477-4974-b4ca-be54cf7cd3c0",
        sourceHandle: "yes_handle",
        target: "9f6c436c-3447-48e4-b41e-cdb680fda6cb",
        targetHandle: null,
        id: "reactflow__edge-51cde1a7-6477-4974-b4ca-be54cf7cd3c0yes_handle-9f6c436c-3447-48e4-b41e-cdb680fda6cb",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "8450b62d-a907-47fd-b265-34c5c9b105ea",
        sourceHandle: "yes_handle",
        target: "b14078d3-5ab3-4fb6-84f4-8965029d7aa9",
        targetHandle: null,
        id: "reactflow__edge-8450b62d-a907-47fd-b265-34c5c9b105eayes_handle-b14078d3-5ab3-4fb6-84f4-8965029d7aa9",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "c93577bd-4495-4f9a-8ab4-9b9907df4013",
        sourceHandle: "yes_handle",
        target: "b14078d3-5ab3-4fb6-84f4-8965029d7aa9",
        targetHandle: null,
        id: "reactflow__edge-c93577bd-4495-4f9a-8ab4-9b9907df4013yes_handle-b14078d3-5ab3-4fb6-84f4-8965029d7aa9",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "bbe53e46-b00f-4fe3-9074-c19fd5641bff",
        sourceHandle: "yes_handle",
        target: "b14078d3-5ab3-4fb6-84f4-8965029d7aa9",
        targetHandle: null,
        id: "reactflow__edge-bbe53e46-b00f-4fe3-9074-c19fd5641bffyes_handle-b14078d3-5ab3-4fb6-84f4-8965029d7aa9",
      },
    ],
  },
  {
    bluePrintName: "Ecom Coupon Funnel",
    nodes: [
      {
        width: 36,
        height: 47,
        id: "ed5411c2-1668-46b6-bfd3-0bee24f15f09",
        type: "TrafficEntry",
        position: {
          x: 23.53125,
          y: 37,
        },
        data: {
          name: "TrafficEntry",
          label: "TrafficEntry node",
          traffic: 1000,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          cpc: 1,
          trafficSources: [
            {
              trafficSource: "Facebook",
              value: 1000,
            },
          ],
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: 23.53125,
          y: 37,
        },
        dragging: false,
      },
      {
        id: "89afea30-0600-4bb5-a7af-c0e7762b5d3b",
        type: "OptInNode",
        position: {
          x: 94.796875,
          y: 43.25,
        },
        data: {
          name: "OptInNode",
          label: "OptInNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 94.796875,
          y: 43.25,
        },
        dragging: false,
      },
      {
        id: "02806119-37bd-4096-a25a-88daf94d43e5",
        type: "ThankYouNode",
        position: {
          x: 195.296875,
          y: 43.25,
        },
        data: {
          name: "ThankYouNode",
          label: "ThankYouNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 195.296875,
          y: 43.25,
        },
        dragging: false,
      },
      {
        id: "adcfb4b2-9d90-4cf6-89f5-ad3a93f78583",
        type: "OrderFormPage",
        position: {
          x: 427.796875,
          y: 42.75,
        },
        data: {
          name: "OrderFormPage",
          label: "OrderFormPage node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 427.796875,
          y: 42.75,
        },
        dragging: false,
      },
      {
        id: "683f8a05-f41a-4c40-a0f8-a4791c2c18bd",
        type: "SalesPageNode",
        position: {
          x: 314.296875,
          y: 42.75,
        },
        data: {
          name: "SalesPageNode",
          label: "SalesPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 314.296875,
          y: 42.75,
        },
        dragging: false,
      },
      {
        id: "b84d3cd5-793b-474c-9695-848af1912e53",
        type: "ThankYouNode",
        position: {
          x: 520.296875,
          y: 41.75,
        },
        data: {
          name: "ThankYouNode",
          label: "ThankYouNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 520.296875,
          y: 41.75,
        },
        dragging: false,
      },
      {
        id: "4217f7bf-8f81-4f8d-b2d6-2e3aa772a9f6",
        type: "EmailNode",
        position: {
          x: 326.796875,
          y: 201.75,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 49,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 326.796875,
          y: 201.75,
        },
        dragging: false,
      },
      {
        id: "910ef256-dc82-46cd-a687-7c97933e0739",
        type: "WaitNode",
        position: {
          x: 338.796875,
          y: 294.25,
        },
        data: {
          name: "WaitNode",
          label: "WaitNode node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 29,
        height: 46,
        selected: false,
        positionAbsolute: {
          x: 338.796875,
          y: 294.25,
        },
        dragging: false,
      },
      {
        id: "6473cf41-995a-4b20-a0e4-26fcafdf52e5",
        type: "EmailNode",
        position: {
          x: 324.296875,
          y: 352.75,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 49,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 324.296875,
          y: 352.75,
        },
        dragging: false,
      },
      {
        id: "08c0c209-e77f-4f7e-bb54-4b234abb52f8",
        type: "SalesPageNode",
        position: {
          x: 448.296875,
          y: 253.25,
        },
        data: {
          name: "SalesPageNode",
          label: "SalesPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 448.296875,
          y: 253.25,
        },
        dragging: false,
      },
      {
        id: "23fa8427-3e39-48c6-8123-5a4fac7cc7fe",
        type: "OrderFormPage",
        position: {
          x: 542.796875,
          y: 252.75,
        },
        data: {
          name: "OrderFormPage",
          label: "OrderFormPage node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 542.796875,
          y: 252.75,
        },
        dragging: false,
      },
      {
        id: "0d3d3871-b8f8-46ec-a2a0-3e254e46b55c",
        type: "ThankYouNode",
        position: {
          x: 636.796875,
          y: 251.25,
        },
        data: {
          name: "ThankYouNode",
          label: "ThankYouNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 636.796875,
          y: 251.25,
        },
        dragging: false,
      },
    ],
    edges: [
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "683f8a05-f41a-4c40-a0f8-a4791c2c18bd",
        sourceHandle: "yes_handle",
        target: "adcfb4b2-9d90-4cf6-89f5-ad3a93f78583",
        targetHandle: null,
        id: "reactflow__edge-683f8a05-f41a-4c40-a0f8-a4791c2c18bdyes_handle-adcfb4b2-9d90-4cf6-89f5-ad3a93f78583",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "adcfb4b2-9d90-4cf6-89f5-ad3a93f78583",
        sourceHandle: "yes_handle",
        target: "b84d3cd5-793b-474c-9695-848af1912e53",
        targetHandle: null,
        id: "reactflow__edge-adcfb4b2-9d90-4cf6-89f5-ad3a93f78583yes_handle-b84d3cd5-793b-474c-9695-848af1912e53",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "ed5411c2-1668-46b6-bfd3-0bee24f15f09",
        sourceHandle: "yes_handle",
        target: "89afea30-0600-4bb5-a7af-c0e7762b5d3b",
        targetHandle: null,
        id: "reactflow__edge-ed5411c2-1668-46b6-bfd3-0bee24f15f09yes_handle-89afea30-0600-4bb5-a7af-c0e7762b5d3b",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "89afea30-0600-4bb5-a7af-c0e7762b5d3b",
        sourceHandle: "yes_handle",
        target: "02806119-37bd-4096-a25a-88daf94d43e5",
        targetHandle: null,
        id: "reactflow__edge-89afea30-0600-4bb5-a7af-c0e7762b5d3byes_handle-02806119-37bd-4096-a25a-88daf94d43e5",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "02806119-37bd-4096-a25a-88daf94d43e5",
        sourceHandle: "yes_handle",
        target: "683f8a05-f41a-4c40-a0f8-a4791c2c18bd",
        targetHandle: null,
        id: "reactflow__edge-02806119-37bd-4096-a25a-88daf94d43e5yes_handle-683f8a05-f41a-4c40-a0f8-a4791c2c18bd",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "683f8a05-f41a-4c40-a0f8-a4791c2c18bd",
        sourceHandle: "no_handle",
        target: "4217f7bf-8f81-4f8d-b2d6-2e3aa772a9f6",
        targetHandle: null,
        id: "reactflow__edge-683f8a05-f41a-4c40-a0f8-a4791c2c18bdno_handle-4217f7bf-8f81-4f8d-b2d6-2e3aa772a9f6",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "4217f7bf-8f81-4f8d-b2d6-2e3aa772a9f6",
        sourceHandle: "no_handle",
        target: "910ef256-dc82-46cd-a687-7c97933e0739",
        targetHandle: null,
        id: "reactflow__edge-4217f7bf-8f81-4f8d-b2d6-2e3aa772a9f6no_handle-910ef256-dc82-46cd-a687-7c97933e0739",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "910ef256-dc82-46cd-a687-7c97933e0739",
        sourceHandle: "yes_handle",
        target: "6473cf41-995a-4b20-a0e4-26fcafdf52e5",
        targetHandle: null,
        id: "reactflow__edge-910ef256-dc82-46cd-a687-7c97933e0739yes_handle-6473cf41-995a-4b20-a0e4-26fcafdf52e5",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "08c0c209-e77f-4f7e-bb54-4b234abb52f8",
        sourceHandle: "yes_handle",
        target: "23fa8427-3e39-48c6-8123-5a4fac7cc7fe",
        targetHandle: null,
        id: "reactflow__edge-08c0c209-e77f-4f7e-bb54-4b234abb52f8yes_handle-23fa8427-3e39-48c6-8123-5a4fac7cc7fe",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "23fa8427-3e39-48c6-8123-5a4fac7cc7fe",
        sourceHandle: "yes_handle",
        target: "0d3d3871-b8f8-46ec-a2a0-3e254e46b55c",
        targetHandle: null,
        id: "reactflow__edge-23fa8427-3e39-48c6-8123-5a4fac7cc7feyes_handle-0d3d3871-b8f8-46ec-a2a0-3e254e46b55c",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "4217f7bf-8f81-4f8d-b2d6-2e3aa772a9f6",
        sourceHandle: "yes_handle",
        target: "08c0c209-e77f-4f7e-bb54-4b234abb52f8",
        targetHandle: null,
        id: "reactflow__edge-4217f7bf-8f81-4f8d-b2d6-2e3aa772a9f6yes_handle-08c0c209-e77f-4f7e-bb54-4b234abb52f8",
      },
    ],
  },
  {
    bluePrintName: "Free Plus Shipping Funnel",
    nodes: [
      {
        width: 36,
        height: 47,
        id: "5da29068-bce4-47d8-af0b-9c11c2d67e90",
        type: "TrafficEntry",
        position: {
          x: 24.53125,
          y: -1,
        },
        data: {
          name: "TrafficEntry",
          label: "TrafficEntry node",
          traffic: 1000,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          cpc: 1,
          trafficSources: [
            {
              trafficSource: "Facebook",
              value: 1000,
            },
          ],
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: 24.53125,
          y: -1,
        },
        dragging: false,
      },
      {
        id: "8528ba16-d0a7-446d-bf71-126b131586a9",
        type: "SalesPageNode",
        position: {
          x: 91.65785917845423,
          y: 6.43049208922713,
        },
        data: {
          name: "SalesPageNode",
          label: "SalesPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 91.65785917845423,
          y: 6.43049208922713,
        },
        dragging: false,
      },
      {
        id: "c932a69c-5fd7-43e3-9f64-61024dc11535",
        type: "OrderFormPage",
        position: {
          x: 198.796875,
          y: 7.25,
        },
        data: {
          name: "OrderFormPage",
          label: "OrderFormPage node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 198.796875,
          y: 7.25,
        },
        dragging: false,
      },
      {
        id: "58aa7ead-7a82-4d11-9741-6e9afc5495cf",
        type: "UpsellPageNode",
        position: {
          x: 306.296875,
          y: 9.25,
        },
        data: {
          name: "UpsellPageNode",
          label: "UpsellPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 306.296875,
          y: 9.25,
        },
        dragging: false,
      },
      {
        id: "a118c4a7-c7ab-484a-a8f2-ad1bb66c4844",
        type: "DownsellPageNode",
        position: {
          x: 310.4358908215458,
          y: 181.34753955386446,
        },
        data: {
          name: "DownsellPageNode",
          label: "DownsellPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: true,
        positionAbsolute: {
          x: 310.4358908215458,
          y: 181.34753955386446,
        },
        dragging: false,
      },
      {
        id: "9bd63499-a174-47d9-a941-7d0d9dead039",
        type: "UpsellPageNode",
        position: {
          x: 433.63712104461354,
          y: 9.791476267681304,
        },
        data: {
          name: "UpsellPageNode",
          label: "UpsellPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 433.63712104461354,
          y: 9.791476267681304,
        },
        dragging: false,
      },
      {
        id: "c578922a-8519-4e44-b6cc-7cd061d8fcca",
        type: "DownsellPageNode",
        position: {
          x: 444.9773670892272,
          y: 173.71467484765753,
        },
        data: {
          name: "DownsellPageNode",
          label: "DownsellPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 444.9773670892272,
          y: 173.71467484765753,
        },
        dragging: false,
      },
      {
        id: "2f850750-9f1f-4c2f-9f5d-52f8bc594309",
        type: "ThankYouNode",
        position: {
          x: 553.6088872561141,
          y: 8.471968356908377,
        },
        data: {
          name: "ThankYouNode",
          label: "ThankYouNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 553.6088872561141,
          y: 8.471968356908377,
        },
        dragging: false,
      },
    ],
    edges: [
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "5da29068-bce4-47d8-af0b-9c11c2d67e90",
        sourceHandle: "yes_handle",
        target: "8528ba16-d0a7-446d-bf71-126b131586a9",
        targetHandle: null,
        id: "reactflow__edge-5da29068-bce4-47d8-af0b-9c11c2d67e90yes_handle-8528ba16-d0a7-446d-bf71-126b131586a9",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "8528ba16-d0a7-446d-bf71-126b131586a9",
        sourceHandle: "yes_handle",
        target: "c932a69c-5fd7-43e3-9f64-61024dc11535",
        targetHandle: null,
        id: "reactflow__edge-8528ba16-d0a7-446d-bf71-126b131586a9yes_handle-c932a69c-5fd7-43e3-9f64-61024dc11535",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "c932a69c-5fd7-43e3-9f64-61024dc11535",
        sourceHandle: "yes_handle",
        target: "58aa7ead-7a82-4d11-9741-6e9afc5495cf",
        targetHandle: null,
        id: "reactflow__edge-c932a69c-5fd7-43e3-9f64-61024dc11535yes_handle-58aa7ead-7a82-4d11-9741-6e9afc5495cf",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "58aa7ead-7a82-4d11-9741-6e9afc5495cf",
        sourceHandle: "yes_handle",
        target: "9bd63499-a174-47d9-a941-7d0d9dead039",
        targetHandle: null,
        id: "reactflow__edge-58aa7ead-7a82-4d11-9741-6e9afc5495cfyes_handle-9bd63499-a174-47d9-a941-7d0d9dead039",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "9bd63499-a174-47d9-a941-7d0d9dead039",
        sourceHandle: "yes_handle",
        target: "2f850750-9f1f-4c2f-9f5d-52f8bc594309",
        targetHandle: null,
        id: "reactflow__edge-9bd63499-a174-47d9-a941-7d0d9dead039yes_handle-2f850750-9f1f-4c2f-9f5d-52f8bc594309",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "58aa7ead-7a82-4d11-9741-6e9afc5495cf",
        sourceHandle: "no_handle",
        target: "a118c4a7-c7ab-484a-a8f2-ad1bb66c4844",
        targetHandle: null,
        id: "reactflow__edge-58aa7ead-7a82-4d11-9741-6e9afc5495cfno_handle-a118c4a7-c7ab-484a-a8f2-ad1bb66c4844",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "a118c4a7-c7ab-484a-a8f2-ad1bb66c4844",
        sourceHandle: "yes_handle",
        target: "9bd63499-a174-47d9-a941-7d0d9dead039",
        targetHandle: null,
        id: "reactflow__edge-a118c4a7-c7ab-484a-a8f2-ad1bb66c4844yes_handle-9bd63499-a174-47d9-a941-7d0d9dead039",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "a118c4a7-c7ab-484a-a8f2-ad1bb66c4844",
        sourceHandle: "no_handle",
        target: "9bd63499-a174-47d9-a941-7d0d9dead039",
        targetHandle: null,
        id: "reactflow__edge-a118c4a7-c7ab-484a-a8f2-ad1bb66c4844no_handle-9bd63499-a174-47d9-a941-7d0d9dead039",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "9bd63499-a174-47d9-a941-7d0d9dead039",
        sourceHandle: "no_handle",
        target: "c578922a-8519-4e44-b6cc-7cd061d8fcca",
        targetHandle: null,
        id: "reactflow__edge-9bd63499-a174-47d9-a941-7d0d9dead039no_handle-c578922a-8519-4e44-b6cc-7cd061d8fcca",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "c578922a-8519-4e44-b6cc-7cd061d8fcca",
        sourceHandle: "yes_handle",
        target: "2f850750-9f1f-4c2f-9f5d-52f8bc594309",
        targetHandle: null,
        id: "reactflow__edge-c578922a-8519-4e44-b6cc-7cd061d8fccayes_handle-2f850750-9f1f-4c2f-9f5d-52f8bc594309",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "c578922a-8519-4e44-b6cc-7cd061d8fcca",
        sourceHandle: "no_handle",
        target: "2f850750-9f1f-4c2f-9f5d-52f8bc594309",
        targetHandle: null,
        id: "reactflow__edge-c578922a-8519-4e44-b6cc-7cd061d8fccano_handle-2f850750-9f1f-4c2f-9f5d-52f8bc594309",
      },
    ],
  },
  {
    bluePrintName: "High Ticket Call Funnel",
    nodes: [
      {
        width: 36,
        height: 47,
        id: "60317e84-9a8f-42c5-a518-21bb02f757bd",
        type: "TrafficEntry",
        position: {
          x: 69.03125,
          y: 38.5,
        },
        data: {
          name: "TrafficEntry",
          label: "TrafficEntry node",
          traffic: 1000,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          cpc: 1,
          trafficSources: [
            {
              trafficSource: "Facebook",
              value: 1000,
            },
          ],
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: 69.03125,
          y: 38.5,
        },
        dragging: false,
      },
      {
        id: "0962619c-5228-4c6e-a3dc-9f60508ea9b6",
        type: "OptInNode",
        position: {
          x: 153.796875,
          y: 2.75,
        },
        data: {
          name: "OptInNode",
          label: "OptInNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 153.796875,
          y: 2.75,
        },
        dragging: false,
      },
      {
        id: "3d963dce-918d-4464-abb3-3fafc4de74f4",
        type: "ApplicationPageNode",
        position: {
          x: 254.796875,
          y: 2.75,
        },
        data: {
          name: "ApplicationPageNode",
          label: "ApplicationPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 254.796875,
          y: 2.75,
        },
        dragging: false,
      },
      {
        id: "befe7dae-2409-4163-919d-eb13e44efc19",
        type: "ThankYouNode",
        position: {
          x: 356.296875,
          y: 3.25,
        },
        data: {
          name: "ThankYouNode",
          label: "ThankYouNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 356.296875,
          y: 3.25,
        },
        dragging: false,
      },
      {
        id: "64666c6d-4376-4f65-8f00-0612f9f2bfee",
        type: "PhoneNode",
        position: {
          x: 474.296875,
          y: 28.75,
        },
        data: {
          name: "PhoneNode",
          label: "PhoneNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 32,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 474.296875,
          y: 28.75,
        },
        dragging: false,
      },
      {
        id: "ef9774cd-bf39-496b-8dea-b54451b1c8f0",
        type: "SalesPageNode",
        position: {
          x: 548.296875,
          y: 2.75,
        },
        data: {
          name: "SalesPageNode",
          label: "SalesPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 548.296875,
          y: 2.75,
        },
        dragging: false,
      },
      {
        id: "a476d5e5-87cf-460a-b278-ec6a10b14d58",
        type: "OrderFormPage",
        position: {
          x: 647.796875,
          y: 2.25,
        },
        data: {
          name: "OrderFormPage",
          label: "OrderFormPage node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 647.796875,
          y: 2.25,
        },
        dragging: false,
      },
      {
        id: "c5eebb49-896c-4317-8f16-1729f9d769ab",
        type: "ThankYouNode",
        position: {
          x: 750.371875,
          y: 3.75,
        },
        data: {
          name: "ThankYouNode",
          label: "ThankYouNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 750.371875,
          y: 3.75,
        },
        dragging: false,
      },
    ],
    edges: [
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "ef9774cd-bf39-496b-8dea-b54451b1c8f0",
        sourceHandle: "yes_handle",
        target: "a476d5e5-87cf-460a-b278-ec6a10b14d58",
        targetHandle: null,
        id: "reactflow__edge-ef9774cd-bf39-496b-8dea-b54451b1c8f0yes_handle-a476d5e5-87cf-460a-b278-ec6a10b14d58",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "a476d5e5-87cf-460a-b278-ec6a10b14d58",
        sourceHandle: "yes_handle",
        target: "c5eebb49-896c-4317-8f16-1729f9d769ab",
        targetHandle: null,
        id: "reactflow__edge-a476d5e5-87cf-460a-b278-ec6a10b14d58yes_handle-c5eebb49-896c-4317-8f16-1729f9d769ab",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "64666c6d-4376-4f65-8f00-0612f9f2bfee",
        sourceHandle: "yes_handle",
        target: "ef9774cd-bf39-496b-8dea-b54451b1c8f0",
        targetHandle: null,
        id: "reactflow__edge-64666c6d-4376-4f65-8f00-0612f9f2bfeeyes_handle-ef9774cd-bf39-496b-8dea-b54451b1c8f0",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "befe7dae-2409-4163-919d-eb13e44efc19",
        sourceHandle: "yes_handle",
        target: "64666c6d-4376-4f65-8f00-0612f9f2bfee",
        targetHandle: null,
        id: "reactflow__edge-befe7dae-2409-4163-919d-eb13e44efc19yes_handle-64666c6d-4376-4f65-8f00-0612f9f2bfee",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "3d963dce-918d-4464-abb3-3fafc4de74f4",
        sourceHandle: "yes_handle",
        target: "befe7dae-2409-4163-919d-eb13e44efc19",
        targetHandle: null,
        id: "reactflow__edge-3d963dce-918d-4464-abb3-3fafc4de74f4yes_handle-befe7dae-2409-4163-919d-eb13e44efc19",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "0962619c-5228-4c6e-a3dc-9f60508ea9b6",
        sourceHandle: "yes_handle",
        target: "3d963dce-918d-4464-abb3-3fafc4de74f4",
        targetHandle: null,
        id: "reactflow__edge-0962619c-5228-4c6e-a3dc-9f60508ea9b6yes_handle-3d963dce-918d-4464-abb3-3fafc4de74f4",
      },
    ],
  },
  {
    bluePrintName: "Lead Gen Upsell Funnel",
    nodes: [
      {
        width: 36,
        height: 47,
        id: "034b8c1e-a635-45be-adec-653ab18ce751",
        type: "TrafficEntry",
        position: {
          x: -56.46875,
          y: -4.5,
        },
        data: {
          name: "TrafficEntry",
          label: "TrafficEntry node",
          traffic: 1000,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          cpc: 1,
          trafficSources: [
            {
              trafficSource: "Facebook",
              value: 1000,
            },
          ],
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: -56.46875,
          y: -4.5,
        },
        dragging: false,
      },
      {
        id: "80dbfea6-4f01-4afc-a158-b0ed379a328a",
        type: "OptInNode",
        position: {
          x: 28.296875,
          y: -37.75,
        },
        data: {
          name: "OptInNode",
          label: "OptInNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 28.296875,
          y: -37.75,
        },
        dragging: false,
      },
      {
        id: "90e6c06f-6593-4a97-99db-d5af3797812b",
        type: "SalesPageNode",
        position: {
          x: 122.296875,
          y: -37.25,
        },
        data: {
          name: "SalesPageNode",
          label: "SalesPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 122.296875,
          y: -37.25,
        },
        dragging: false,
      },
      {
        id: "e95c1a01-4d5a-4fb6-bb92-c011e24eea6a",
        type: "OrderFormPage",
        position: {
          x: 219.796875,
          y: -36.25,
        },
        data: {
          name: "OrderFormPage",
          label: "OrderFormPage node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 219.796875,
          y: -36.25,
        },
        dragging: false,
      },
      {
        id: "a8e6f9bb-8351-4792-910d-54709ad48581",
        type: "UpsellPageNode",
        position: {
          x: 312.796875,
          y: -37.75,
        },
        data: {
          name: "UpsellPageNode",
          label: "UpsellPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 312.796875,
          y: -37.75,
        },
        dragging: false,
      },
      {
        id: "1c279a20-0851-42dd-be6f-ae5421ad49e4",
        type: "ThankYouNode",
        position: {
          x: 409.796875,
          y: -37.25,
        },
        data: {
          name: "ThankYouNode",
          label: "ThankYouNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 409.796875,
          y: -37.25,
        },
        dragging: false,
      },
      {
        id: "21cdf91d-eb32-4b7c-90ba-ab0652a451ec",
        type: "EmailNode",
        position: {
          x: 44.796875,
          y: 112.75,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 49,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 44.796875,
          y: 112.75,
        },
        dragging: false,
      },
      {
        id: "83ceddf4-d643-4adf-84e0-3249a37d265c",
        type: "EmailNode",
        position: {
          x: 45.296875,
          y: 265.75,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 49,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 45.296875,
          y: 265.75,
        },
        dragging: false,
      },
      {
        id: "41cdf339-f154-4dc3-a22a-f427928e3adb",
        type: "EmailNode",
        position: {
          x: 44.796875,
          y: 411.75,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 49,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 44.796875,
          y: 411.75,
        },
        dragging: false,
      },
      {
        id: "d96a11c3-e118-4d25-897c-52bf8159543a",
        type: "WaitNode",
        position: {
          x: 53.796875,
          y: 196.75,
        },
        data: {
          name: "WaitNode",
          label: "WaitNode node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 29,
        height: 46,
        selected: false,
        positionAbsolute: {
          x: 53.796875,
          y: 196.75,
        },
        dragging: false,
      },
      {
        id: "aaae8ad6-2ede-4516-9b41-d968853644ef",
        type: "WaitNode",
        position: {
          x: 54.296875,
          y: 349.75,
        },
        data: {
          name: "WaitNode",
          label: "WaitNode node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 29,
        height: 46,
        selected: false,
        positionAbsolute: {
          x: 54.296875,
          y: 349.75,
        },
        dragging: false,
      },
      {
        id: "d3454c5a-fa8a-452d-a441-60ee34151501",
        type: "SalesPageNode",
        position: {
          x: 172.296875,
          y: 243.75,
        },
        data: {
          name: "SalesPageNode",
          label: "SalesPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 172.296875,
          y: 243.75,
        },
        dragging: false,
      },
      {
        id: "6eb4e5bf-e672-4216-95a1-df7360a17a6c",
        type: "OrderFormPage",
        position: {
          x: 274.796875,
          y: 242.25,
        },
        data: {
          name: "OrderFormPage",
          label: "OrderFormPage node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 274.796875,
          y: 242.25,
        },
        dragging: false,
      },
      {
        id: "4d7744be-568f-48e3-8d97-c30496772091",
        type: "UpsellPageNode",
        position: {
          x: 372.296875,
          y: 242.25,
        },
        data: {
          name: "UpsellPageNode",
          label: "UpsellPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 372.296875,
          y: 242.25,
        },
        dragging: false,
      },
      {
        id: "b1616074-a8f6-456a-a176-1b3c9f0427c0",
        type: "ThankYouNode",
        position: {
          x: 473.296875,
          y: 243.75,
        },
        data: {
          name: "ThankYouNode",
          label: "ThankYouNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 473.296875,
          y: 243.75,
        },
        dragging: false,
      },
    ],
    edges: [
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "034b8c1e-a635-45be-adec-653ab18ce751",
        sourceHandle: "yes_handle",
        target: "80dbfea6-4f01-4afc-a158-b0ed379a328a",
        targetHandle: null,
        id: "reactflow__edge-034b8c1e-a635-45be-adec-653ab18ce751yes_handle-80dbfea6-4f01-4afc-a158-b0ed379a328a",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "80dbfea6-4f01-4afc-a158-b0ed379a328a",
        sourceHandle: "yes_handle",
        target: "90e6c06f-6593-4a97-99db-d5af3797812b",
        targetHandle: null,
        id: "reactflow__edge-80dbfea6-4f01-4afc-a158-b0ed379a328ayes_handle-90e6c06f-6593-4a97-99db-d5af3797812b",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "90e6c06f-6593-4a97-99db-d5af3797812b",
        sourceHandle: "yes_handle",
        target: "e95c1a01-4d5a-4fb6-bb92-c011e24eea6a",
        targetHandle: null,
        id: "reactflow__edge-90e6c06f-6593-4a97-99db-d5af3797812byes_handle-e95c1a01-4d5a-4fb6-bb92-c011e24eea6a",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "e95c1a01-4d5a-4fb6-bb92-c011e24eea6a",
        sourceHandle: "yes_handle",
        target: "a8e6f9bb-8351-4792-910d-54709ad48581",
        targetHandle: null,
        id: "reactflow__edge-e95c1a01-4d5a-4fb6-bb92-c011e24eea6ayes_handle-a8e6f9bb-8351-4792-910d-54709ad48581",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "a8e6f9bb-8351-4792-910d-54709ad48581",
        sourceHandle: "yes_handle",
        target: "1c279a20-0851-42dd-be6f-ae5421ad49e4",
        targetHandle: null,
        id: "reactflow__edge-a8e6f9bb-8351-4792-910d-54709ad48581yes_handle-1c279a20-0851-42dd-be6f-ae5421ad49e4",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "80dbfea6-4f01-4afc-a158-b0ed379a328a",
        sourceHandle: "no_handle",
        target: "21cdf91d-eb32-4b7c-90ba-ab0652a451ec",
        targetHandle: null,
        id: "reactflow__edge-80dbfea6-4f01-4afc-a158-b0ed379a328ano_handle-21cdf91d-eb32-4b7c-90ba-ab0652a451ec",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "d96a11c3-e118-4d25-897c-52bf8159543a",
        sourceHandle: "yes_handle",
        target: "83ceddf4-d643-4adf-84e0-3249a37d265c",
        targetHandle: null,
        id: "reactflow__edge-d96a11c3-e118-4d25-897c-52bf8159543ayes_handle-83ceddf4-d643-4adf-84e0-3249a37d265c",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "21cdf91d-eb32-4b7c-90ba-ab0652a451ec",
        sourceHandle: "no_handle",
        target: "d96a11c3-e118-4d25-897c-52bf8159543a",
        targetHandle: null,
        id: "reactflow__edge-21cdf91d-eb32-4b7c-90ba-ab0652a451ecno_handle-d96a11c3-e118-4d25-897c-52bf8159543a",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "83ceddf4-d643-4adf-84e0-3249a37d265c",
        sourceHandle: "no_handle",
        target: "aaae8ad6-2ede-4516-9b41-d968853644ef",
        targetHandle: null,
        id: "reactflow__edge-83ceddf4-d643-4adf-84e0-3249a37d265cno_handle-aaae8ad6-2ede-4516-9b41-d968853644ef",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "aaae8ad6-2ede-4516-9b41-d968853644ef",
        sourceHandle: "yes_handle",
        target: "41cdf339-f154-4dc3-a22a-f427928e3adb",
        targetHandle: null,
        id: "reactflow__edge-aaae8ad6-2ede-4516-9b41-d968853644efyes_handle-41cdf339-f154-4dc3-a22a-f427928e3adb",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "d3454c5a-fa8a-452d-a441-60ee34151501",
        sourceHandle: "yes_handle",
        target: "6eb4e5bf-e672-4216-95a1-df7360a17a6c",
        targetHandle: null,
        id: "reactflow__edge-d3454c5a-fa8a-452d-a441-60ee34151501yes_handle-6eb4e5bf-e672-4216-95a1-df7360a17a6c",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "6eb4e5bf-e672-4216-95a1-df7360a17a6c",
        sourceHandle: "yes_handle",
        target: "4d7744be-568f-48e3-8d97-c30496772091",
        targetHandle: null,
        id: "reactflow__edge-6eb4e5bf-e672-4216-95a1-df7360a17a6cyes_handle-4d7744be-568f-48e3-8d97-c30496772091",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "4d7744be-568f-48e3-8d97-c30496772091",
        sourceHandle: "yes_handle",
        target: "b1616074-a8f6-456a-a176-1b3c9f0427c0",
        targetHandle: null,
        id: "reactflow__edge-4d7744be-568f-48e3-8d97-c30496772091yes_handle-b1616074-a8f6-456a-a176-1b3c9f0427c0",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "41cdf339-f154-4dc3-a22a-f427928e3adb",
        sourceHandle: "yes_handle",
        target: "d3454c5a-fa8a-452d-a441-60ee34151501",
        targetHandle: null,
        id: "reactflow__edge-41cdf339-f154-4dc3-a22a-f427928e3adbyes_handle-d3454c5a-fa8a-452d-a441-60ee34151501",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "83ceddf4-d643-4adf-84e0-3249a37d265c",
        sourceHandle: "yes_handle",
        target: "d3454c5a-fa8a-452d-a441-60ee34151501",
        targetHandle: null,
        id: "reactflow__edge-83ceddf4-d643-4adf-84e0-3249a37d265cyes_handle-d3454c5a-fa8a-452d-a441-60ee34151501",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "21cdf91d-eb32-4b7c-90ba-ab0652a451ec",
        sourceHandle: "yes_handle",
        target: "d3454c5a-fa8a-452d-a441-60ee34151501",
        targetHandle: null,
        id: "reactflow__edge-21cdf91d-eb32-4b7c-90ba-ab0652a451ecyes_handle-d3454c5a-fa8a-452d-a441-60ee34151501",
      },
    ],
  },
  {
    bluePrintName: "Mini Class Funnel",
    nodes: [
      {
        width: 36,
        height: 47,
        id: "8c412551-c9fc-4195-b9ce-938df1dbe39c",
        type: "TrafficEntry",
        position: {
          x: -14.46875,
          y: 25,
        },
        data: {
          name: "TrafficEntry",
          label: "TrafficEntry node",
          traffic: 1000,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          cpc: 1,
          trafficSources: [
            {
              trafficSource: "Facebook",
              value: 1000,
            },
          ],
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: -14.46875,
          y: 25,
        },
        dragging: false,
      },
      {
        id: "0ada789a-d738-46a5-80d7-0af7ea0b15b7",
        type: "OptInNode",
        position: {
          x: 67.796875,
          y: -16.75,
        },
        data: {
          name: "OptInNode",
          label: "OptInNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 67.796875,
          y: -16.75,
        },
        dragging: false,
      },
      {
        id: "35958ff0-402d-44cf-8993-5698f4adf7d9",
        type: "ContentPageNode",
        position: {
          x: 164.296875,
          y: -17.75,
        },
        data: {
          name: "ContentPageNode",
          label: "ContentPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 164.296875,
          y: -17.75,
        },
        dragging: false,
      },
      {
        id: "6d82ca64-d1e0-41f8-8c73-2cebde9830bb",
        type: "EmailNode",
        position: {
          x: 183.796875,
          y: 143.75,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 49,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 183.796875,
          y: 143.75,
        },
        dragging: false,
      },
      {
        id: "166b97dd-9aaf-4a3b-a255-964c7894cd3f",
        type: "EmailNode",
        position: {
          x: 275.796875,
          y: 208.75,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 49,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 275.796875,
          y: 208.75,
        },
        dragging: false,
      },
      {
        id: "f30c1b3b-aa25-4fb2-b7b7-3d20e7c94610",
        type: "EmailNode",
        position: {
          x: 365.296875,
          y: 272.25,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 49,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 365.296875,
          y: 272.25,
        },
        dragging: false,
      },
      {
        id: "112e6408-2109-43a8-8680-80dd5b05a94a",
        type: "WaitNode",
        position: {
          x: 200.296875,
          y: 234.25,
        },
        data: {
          name: "WaitNode",
          label: "WaitNode node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 29,
        height: 46,
        selected: false,
        positionAbsolute: {
          x: 200.296875,
          y: 234.25,
        },
        dragging: false,
      },
      {
        id: "fbcbf234-4665-430c-93a6-2a7a5783d9fa",
        type: "WaitNode",
        position: {
          x: 302.796875,
          y: 297.25,
        },
        data: {
          name: "WaitNode",
          label: "WaitNode node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 29,
        height: 46,
        selected: false,
        positionAbsolute: {
          x: 302.796875,
          y: 297.25,
        },
        dragging: false,
      },
      {
        id: "15bb26b6-a765-4ffb-be64-71a3e2147732",
        type: "ContentPageNode",
        position: {
          x: 459.296875,
          y: 20.75,
        },
        data: {
          name: "ContentPageNode",
          label: "ContentPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 459.296875,
          y: 20.75,
        },
        dragging: false,
      },
      {
        id: "4ce30f4d-ff91-4159-8a3f-a96dd562c54e",
        type: "ContentPageNode",
        position: {
          x: 566.484622705086,
          y: 107.06041316002376,
        },
        data: {
          name: "ContentPageNode",
          label: "ContentPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 566.484622705086,
          y: 107.06041316002376,
        },
        dragging: false,
      },
      {
        id: "12369b4a-b207-4e1a-919a-7e0207df2825",
        type: "ContentPageNode",
        position: {
          x: 657.2837872970715,
          y: 216.8351613483881,
        },
        data: {
          name: "ContentPageNode",
          label: "ContentPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 657.2837872970715,
          y: 216.8351613483881,
        },
        dragging: false,
      },
      {
        id: "d29e60e7-4157-4748-84e2-3d8804295d45",
        type: "SalesPageNode",
        position: {
          x: 566.5366172523056,
          y: -190.95275467555945,
        },
        data: {
          name: "SalesPageNode",
          label: "SalesPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 566.5366172523056,
          y: -190.95275467555945,
        },
        dragging: false,
      },
      {
        id: "f8a86f01-d19b-4543-baaf-2cc0d7a953f6",
        type: "OrderFormPage",
        position: {
          x: 690.5960395919856,
          y: -190.95275467555945,
        },
        data: {
          name: "OrderFormPage",
          label: "OrderFormPage node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 690.5960395919856,
          y: -190.95275467555945,
        },
        dragging: false,
      },
    ],
    edges: [
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "6d82ca64-d1e0-41f8-8c73-2cebde9830bb",
        sourceHandle: "no_handle",
        target: "112e6408-2109-43a8-8680-80dd5b05a94a",
        targetHandle: null,
        id: "reactflow__edge-6d82ca64-d1e0-41f8-8c73-2cebde9830bbno_handle-112e6408-2109-43a8-8680-80dd5b05a94a",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "35958ff0-402d-44cf-8993-5698f4adf7d9",
        sourceHandle: "no_handle",
        target: "6d82ca64-d1e0-41f8-8c73-2cebde9830bb",
        targetHandle: null,
        id: "reactflow__edge-35958ff0-402d-44cf-8993-5698f4adf7d9no_handle-6d82ca64-d1e0-41f8-8c73-2cebde9830bb",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "112e6408-2109-43a8-8680-80dd5b05a94a",
        sourceHandle: "yes_handle",
        target: "166b97dd-9aaf-4a3b-a255-964c7894cd3f",
        targetHandle: null,
        id: "reactflow__edge-112e6408-2109-43a8-8680-80dd5b05a94ayes_handle-166b97dd-9aaf-4a3b-a255-964c7894cd3f",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "166b97dd-9aaf-4a3b-a255-964c7894cd3f",
        sourceHandle: "no_handle",
        target: "fbcbf234-4665-430c-93a6-2a7a5783d9fa",
        targetHandle: null,
        id: "reactflow__edge-166b97dd-9aaf-4a3b-a255-964c7894cd3fno_handle-fbcbf234-4665-430c-93a6-2a7a5783d9fa",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "fbcbf234-4665-430c-93a6-2a7a5783d9fa",
        sourceHandle: "yes_handle",
        target: "f30c1b3b-aa25-4fb2-b7b7-3d20e7c94610",
        targetHandle: null,
        id: "reactflow__edge-fbcbf234-4665-430c-93a6-2a7a5783d9fayes_handle-f30c1b3b-aa25-4fb2-b7b7-3d20e7c94610",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "8c412551-c9fc-4195-b9ce-938df1dbe39c",
        sourceHandle: "yes_handle",
        target: "0ada789a-d738-46a5-80d7-0af7ea0b15b7",
        targetHandle: null,
        id: "reactflow__edge-8c412551-c9fc-4195-b9ce-938df1dbe39cyes_handle-0ada789a-d738-46a5-80d7-0af7ea0b15b7",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "0ada789a-d738-46a5-80d7-0af7ea0b15b7",
        sourceHandle: "yes_handle",
        target: "35958ff0-402d-44cf-8993-5698f4adf7d9",
        targetHandle: null,
        id: "reactflow__edge-0ada789a-d738-46a5-80d7-0af7ea0b15b7yes_handle-35958ff0-402d-44cf-8993-5698f4adf7d9",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "6d82ca64-d1e0-41f8-8c73-2cebde9830bb",
        sourceHandle: "yes_handle",
        target: "15bb26b6-a765-4ffb-be64-71a3e2147732",
        targetHandle: null,
        id: "reactflow__edge-6d82ca64-d1e0-41f8-8c73-2cebde9830bbyes_handle-15bb26b6-a765-4ffb-be64-71a3e2147732",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "166b97dd-9aaf-4a3b-a255-964c7894cd3f",
        sourceHandle: "yes_handle",
        target: "4ce30f4d-ff91-4159-8a3f-a96dd562c54e",
        targetHandle: null,
        id: "reactflow__edge-166b97dd-9aaf-4a3b-a255-964c7894cd3fyes_handle-4ce30f4d-ff91-4159-8a3f-a96dd562c54e",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "f30c1b3b-aa25-4fb2-b7b7-3d20e7c94610",
        sourceHandle: "yes_handle",
        target: "12369b4a-b207-4e1a-919a-7e0207df2825",
        targetHandle: null,
        id: "reactflow__edge-f30c1b3b-aa25-4fb2-b7b7-3d20e7c94610yes_handle-12369b4a-b207-4e1a-919a-7e0207df2825",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "d29e60e7-4157-4748-84e2-3d8804295d45",
        sourceHandle: "yes_handle",
        target: "f8a86f01-d19b-4543-baaf-2cc0d7a953f6",
        targetHandle: null,
        id: "reactflow__edge-d29e60e7-4157-4748-84e2-3d8804295d45yes_handle-f8a86f01-d19b-4543-baaf-2cc0d7a953f6",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "35958ff0-402d-44cf-8993-5698f4adf7d9",
        sourceHandle: "yes_handle",
        target: "d29e60e7-4157-4748-84e2-3d8804295d45",
        targetHandle: null,
        id: "reactflow__edge-35958ff0-402d-44cf-8993-5698f4adf7d9yes_handle-d29e60e7-4157-4748-84e2-3d8804295d45",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "15bb26b6-a765-4ffb-be64-71a3e2147732",
        sourceHandle: "yes_handle",
        target: "d29e60e7-4157-4748-84e2-3d8804295d45",
        targetHandle: null,
        id: "reactflow__edge-15bb26b6-a765-4ffb-be64-71a3e2147732yes_handle-d29e60e7-4157-4748-84e2-3d8804295d45",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "4ce30f4d-ff91-4159-8a3f-a96dd562c54e",
        sourceHandle: "yes_handle",
        target: "d29e60e7-4157-4748-84e2-3d8804295d45",
        targetHandle: null,
        id: "reactflow__edge-4ce30f4d-ff91-4159-8a3f-a96dd562c54eyes_handle-d29e60e7-4157-4748-84e2-3d8804295d45",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "12369b4a-b207-4e1a-919a-7e0207df2825",
        sourceHandle: "yes_handle",
        target: "d29e60e7-4157-4748-84e2-3d8804295d45",
        targetHandle: null,
        id: "reactflow__edge-12369b4a-b207-4e1a-919a-7e0207df2825yes_handle-d29e60e7-4157-4748-84e2-3d8804295d45",
      },
    ],
  },
  // {
  //   bluePrintName: "Retargeting Webinar On-Demand Funnel",
  //   nodes: [],
  //   edges: [],
  // },
  {
    bluePrintName: "Sales Funnel",
    nodes: [
      {
        width: 36,
        height: 47,
        id: "af118822-929d-4209-9888-8daa117e84b0",
        type: "TrafficEntry",
        position: {
          x: 61.03125,
          y: 21,
        },
        data: {
          name: "TrafficEntry",
          label: "TrafficEntry node",
          traffic: 1000,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          cpc: 1,
          trafficSources: [
            {
              trafficSource: "Facebook",
              value: 1000,
            },
          ],
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: 61.03125,
          y: 21,
        },
        dragging: false,
      },
      {
        id: "a4147b6a-191d-4086-94a2-08fa3cf71a60",
        type: "SalesPageNode",
        position: {
          x: 126.796875,
          y: 41.25,
        },
        data: {
          name: "SalesPageNode",
          label: "SalesPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 126.796875,
          y: 41.25,
        },
        dragging: false,
      },
      {
        id: "8627d979-015a-43d9-a342-f070cb144267",
        type: "OrderFormPage",
        position: {
          x: 224.296875,
          y: 42.75,
        },
        data: {
          name: "OrderFormPage",
          label: "OrderFormPage node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 224.296875,
          y: 42.75,
        },
        dragging: false,
      },
      {
        id: "d277e7e0-2b10-4b22-b9ae-6bdeac3663a8",
        type: "ThankYouNode",
        position: {
          x: 319.296875,
          y: 43.25,
        },
        data: {
          name: "ThankYouNode",
          label: "ThankYouNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 319.296875,
          y: 43.25,
        },
        dragging: false,
      },
      {
        id: "f265a966-0690-4340-990a-bd21cc83caac",
        type: "EmailNode",
        position: {
          x: 233.796875,
          y: 201.75,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 49,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 233.796875,
          y: 201.75,
        },
        dragging: false,
      },
      {
        id: "dd5b9ab4-ccfa-46e6-b012-b80d821d83c0",
        type: "EmailNode",
        position: {
          x: 238.296875,
          y: 350.25,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 49,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 238.296875,
          y: 350.25,
        },
        dragging: false,
      },
      {
        id: "3dc80500-3835-4edc-b099-af4d62dc2942",
        type: "EmailNode",
        position: {
          x: 235.796875,
          y: 507.45000000000005,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 49,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 235.796875,
          y: 507.45000000000005,
        },
        dragging: false,
      },
      {
        id: "c6020c29-83aa-4b22-94b0-54b33d1af845",
        type: "WaitNode",
        position: {
          x: 245.296875,
          y: 286.25,
        },
        data: {
          name: "WaitNode",
          label: "WaitNode node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 29,
        height: 46,
        selected: false,
        positionAbsolute: {
          x: 245.296875,
          y: 286.25,
        },
        dragging: false,
      },
      {
        id: "340082e9-c5b3-4591-9fd1-97838488146f",
        type: "WaitNode",
        position: {
          x: 246.796875,
          y: 441.75,
        },
        data: {
          name: "WaitNode",
          label: "WaitNode node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 29,
        height: 46,
        selected: false,
        positionAbsolute: {
          x: 246.796875,
          y: 441.75,
        },
        dragging: false,
      },
      {
        id: "a7061723-ee95-4329-8783-589e9ee5e516",
        type: "SalesPageNode",
        position: {
          x: 377.796875,
          y: 323.94999999999993,
        },
        data: {
          name: "SalesPageNode",
          label: "SalesPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 377.796875,
          y: 323.94999999999993,
        },
        dragging: false,
      },
      {
        id: "dff2f915-c2c6-4b06-93e9-f6613b14c61f",
        type: "OrderFormPage",
        position: {
          x: 478.296875,
          y: 323.45000000000005,
        },
        data: {
          name: "OrderFormPage",
          label: "OrderFormPage node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 478.296875,
          y: 323.45000000000005,
        },
        dragging: false,
      },
      {
        id: "09989a63-7a2c-4b98-800d-30fcf18194bd",
        type: "ThankYouNode",
        position: {
          x: 577.796875,
          y: 322.45000000000005,
        },
        data: {
          name: "ThankYouNode",
          label: "ThankYouNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 577.796875,
          y: 322.45000000000005,
        },
        dragging: false,
      },
    ],
    edges: [
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "a4147b6a-191d-4086-94a2-08fa3cf71a60",
        sourceHandle: "yes_handle",
        target: "8627d979-015a-43d9-a342-f070cb144267",
        targetHandle: null,
        id: "reactflow__edge-a4147b6a-191d-4086-94a2-08fa3cf71a60yes_handle-8627d979-015a-43d9-a342-f070cb144267",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "8627d979-015a-43d9-a342-f070cb144267",
        sourceHandle: "yes_handle",
        target: "d277e7e0-2b10-4b22-b9ae-6bdeac3663a8",
        targetHandle: null,
        id: "reactflow__edge-8627d979-015a-43d9-a342-f070cb144267yes_handle-d277e7e0-2b10-4b22-b9ae-6bdeac3663a8",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "af118822-929d-4209-9888-8daa117e84b0",
        sourceHandle: "yes_handle",
        target: "a4147b6a-191d-4086-94a2-08fa3cf71a60",
        targetHandle: null,
        id: "reactflow__edge-af118822-929d-4209-9888-8daa117e84b0yes_handle-a4147b6a-191d-4086-94a2-08fa3cf71a60",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "a7061723-ee95-4329-8783-589e9ee5e516",
        sourceHandle: "yes_handle",
        target: "dff2f915-c2c6-4b06-93e9-f6613b14c61f",
        targetHandle: null,
        id: "reactflow__edge-a7061723-ee95-4329-8783-589e9ee5e516yes_handle-dff2f915-c2c6-4b06-93e9-f6613b14c61f",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "dff2f915-c2c6-4b06-93e9-f6613b14c61f",
        sourceHandle: "yes_handle",
        target: "09989a63-7a2c-4b98-800d-30fcf18194bd",
        targetHandle: null,
        id: "reactflow__edge-dff2f915-c2c6-4b06-93e9-f6613b14c61fyes_handle-09989a63-7a2c-4b98-800d-30fcf18194bd",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "8627d979-015a-43d9-a342-f070cb144267",
        sourceHandle: "no_handle",
        target: "f265a966-0690-4340-990a-bd21cc83caac",
        targetHandle: null,
        id: "reactflow__edge-8627d979-015a-43d9-a342-f070cb144267no_handle-f265a966-0690-4340-990a-bd21cc83caac",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "f265a966-0690-4340-990a-bd21cc83caac",
        sourceHandle: "no_handle",
        target: "c6020c29-83aa-4b22-94b0-54b33d1af845",
        targetHandle: null,
        id: "reactflow__edge-f265a966-0690-4340-990a-bd21cc83caacno_handle-c6020c29-83aa-4b22-94b0-54b33d1af845",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "c6020c29-83aa-4b22-94b0-54b33d1af845",
        sourceHandle: "yes_handle",
        target: "dd5b9ab4-ccfa-46e6-b012-b80d821d83c0",
        targetHandle: null,
        id: "reactflow__edge-c6020c29-83aa-4b22-94b0-54b33d1af845yes_handle-dd5b9ab4-ccfa-46e6-b012-b80d821d83c0",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "dd5b9ab4-ccfa-46e6-b012-b80d821d83c0",
        sourceHandle: "no_handle",
        target: "340082e9-c5b3-4591-9fd1-97838488146f",
        targetHandle: null,
        id: "reactflow__edge-dd5b9ab4-ccfa-46e6-b012-b80d821d83c0no_handle-340082e9-c5b3-4591-9fd1-97838488146f",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "340082e9-c5b3-4591-9fd1-97838488146f",
        sourceHandle: "yes_handle",
        target: "3dc80500-3835-4edc-b099-af4d62dc2942",
        targetHandle: null,
        id: "reactflow__edge-340082e9-c5b3-4591-9fd1-97838488146fyes_handle-3dc80500-3835-4edc-b099-af4d62dc2942",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "3dc80500-3835-4edc-b099-af4d62dc2942",
        sourceHandle: "yes_handle",
        target: "a7061723-ee95-4329-8783-589e9ee5e516",
        targetHandle: null,
        id: "reactflow__edge-3dc80500-3835-4edc-b099-af4d62dc2942yes_handle-a7061723-ee95-4329-8783-589e9ee5e516",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "dd5b9ab4-ccfa-46e6-b012-b80d821d83c0",
        sourceHandle: "yes_handle",
        target: "a7061723-ee95-4329-8783-589e9ee5e516",
        targetHandle: null,
        id: "reactflow__edge-dd5b9ab4-ccfa-46e6-b012-b80d821d83c0yes_handle-a7061723-ee95-4329-8783-589e9ee5e516",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "f265a966-0690-4340-990a-bd21cc83caac",
        sourceHandle: "yes_handle",
        target: "a7061723-ee95-4329-8783-589e9ee5e516",
        targetHandle: null,
        id: "reactflow__edge-f265a966-0690-4340-990a-bd21cc83caacyes_handle-a7061723-ee95-4329-8783-589e9ee5e516",
      },
    ],
  },
  {
    bluePrintName: "Self Liquidating Offer Funnel",
    nodes: [
      {
        width: 36,
        height: 47,
        id: "96f7ad87-1103-4249-941d-c64a1de7f7c5",
        type: "TrafficEntry",
        position: {
          x: -54.64924208922707,
          y: 43.221968356908405,
        },
        data: {
          name: "TrafficEntry",
          label: "TrafficEntry node",
          traffic: 1000,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          cpc: 1,
          trafficSources: [
            {
              trafficSource: "Facebook",
              value: 1000,
            },
          ],
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: -54.64924208922707,
          y: 43.221968356908405,
        },
        dragging: false,
      },
      {
        id: "b451ca2f-94c6-494f-9a99-f03edb40f7a6",
        type: "SalesPageNode",
        position: {
          x: 32.296875,
          y: 2.75,
        },
        data: {
          name: "SalesPageNode",
          label: "SalesPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 32.296875,
          y: 2.75,
        },
        dragging: false,
      },
      {
        id: "bcf8b00b-d315-495b-80a0-9f6de75b3f27",
        type: "OrderFormPage",
        position: {
          x: 131.296875,
          y: 2.75,
        },
        data: {
          name: "OrderFormPage",
          label: "OrderFormPage node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 131.296875,
          y: 2.75,
        },
        dragging: false,
      },
      {
        id: "820917fd-1678-471a-899f-d73a2c875516",
        type: "UpsellPageNode",
        position: {
          x: 231.296875,
          y: 3.25,
        },
        data: {
          name: "UpsellPageNode",
          label: "UpsellPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 231.296875,
          y: 3.25,
        },
        dragging: false,
      },
      {
        id: "0f424b26-a5ab-4a59-b5c8-1601148ca6f9",
        type: "DownsellPageNode",
        position: {
          x: 232.9773670892272,
          y: 161.65246044613554,
        },
        data: {
          name: "DownsellPageNode",
          label: "DownsellPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: true,
        positionAbsolute: {
          x: 232.9773670892272,
          y: 161.65246044613554,
        },
        dragging: false,
      },
      {
        id: "17859cb7-bf2e-4bbf-a291-bc642221f4db",
        type: "UpsellPageNode",
        position: {
          x: 337.796875,
          y: 3.25,
        },
        data: {
          name: "UpsellPageNode",
          label: "UpsellPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 337.796875,
          y: 3.25,
        },
        dragging: false,
      },
      {
        id: "5f535a63-725d-473a-8ad1-a858e32ad7a3",
        type: "DownsellPageNode",
        position: {
          x: 335.796875,
          y: 161.75,
        },
        data: {
          name: "DownsellPageNode",
          label: "DownsellPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 335.796875,
          y: 161.75,
        },
        dragging: false,
      },
      {
        id: "a3496471-1b2a-4e50-8d84-a2c246ac8f82",
        type: "ThankYouNode",
        position: {
          x: 468.796875,
          y: 3.75,
        },
        data: {
          name: "ThankYouNode",
          label: "ThankYouNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 468.796875,
          y: 3.75,
        },
        dragging: false,
      },
    ],
    edges: [
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "b451ca2f-94c6-494f-9a99-f03edb40f7a6",
        sourceHandle: "yes_handle",
        target: "bcf8b00b-d315-495b-80a0-9f6de75b3f27",
        targetHandle: null,
        id: "reactflow__edge-b451ca2f-94c6-494f-9a99-f03edb40f7a6yes_handle-bcf8b00b-d315-495b-80a0-9f6de75b3f27",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "96f7ad87-1103-4249-941d-c64a1de7f7c5",
        sourceHandle: "yes_handle",
        target: "b451ca2f-94c6-494f-9a99-f03edb40f7a6",
        targetHandle: null,
        id: "reactflow__edge-96f7ad87-1103-4249-941d-c64a1de7f7c5yes_handle-b451ca2f-94c6-494f-9a99-f03edb40f7a6",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "bcf8b00b-d315-495b-80a0-9f6de75b3f27",
        sourceHandle: "yes_handle",
        target: "820917fd-1678-471a-899f-d73a2c875516",
        targetHandle: null,
        id: "reactflow__edge-bcf8b00b-d315-495b-80a0-9f6de75b3f27yes_handle-820917fd-1678-471a-899f-d73a2c875516",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "820917fd-1678-471a-899f-d73a2c875516",
        sourceHandle: "no_handle",
        target: "0f424b26-a5ab-4a59-b5c8-1601148ca6f9",
        targetHandle: null,
        id: "reactflow__edge-820917fd-1678-471a-899f-d73a2c875516no_handle-0f424b26-a5ab-4a59-b5c8-1601148ca6f9",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "820917fd-1678-471a-899f-d73a2c875516",
        sourceHandle: "yes_handle",
        target: "17859cb7-bf2e-4bbf-a291-bc642221f4db",
        targetHandle: null,
        id: "reactflow__edge-820917fd-1678-471a-899f-d73a2c875516yes_handle-17859cb7-bf2e-4bbf-a291-bc642221f4db",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "17859cb7-bf2e-4bbf-a291-bc642221f4db",
        sourceHandle: "no_handle",
        target: "5f535a63-725d-473a-8ad1-a858e32ad7a3",
        targetHandle: null,
        id: "reactflow__edge-17859cb7-bf2e-4bbf-a291-bc642221f4dbno_handle-5f535a63-725d-473a-8ad1-a858e32ad7a3",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "0f424b26-a5ab-4a59-b5c8-1601148ca6f9",
        sourceHandle: "yes_handle",
        target: "17859cb7-bf2e-4bbf-a291-bc642221f4db",
        targetHandle: null,
        id: "reactflow__edge-0f424b26-a5ab-4a59-b5c8-1601148ca6f9yes_handle-17859cb7-bf2e-4bbf-a291-bc642221f4db",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "17859cb7-bf2e-4bbf-a291-bc642221f4db",
        sourceHandle: "yes_handle",
        target: "a3496471-1b2a-4e50-8d84-a2c246ac8f82",
        targetHandle: null,
        id: "reactflow__edge-17859cb7-bf2e-4bbf-a291-bc642221f4dbyes_handle-a3496471-1b2a-4e50-8d84-a2c246ac8f82",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "5f535a63-725d-473a-8ad1-a858e32ad7a3",
        sourceHandle: "yes_handle",
        target: "a3496471-1b2a-4e50-8d84-a2c246ac8f82",
        targetHandle: null,
        id: "reactflow__edge-5f535a63-725d-473a-8ad1-a858e32ad7a3yes_handle-a3496471-1b2a-4e50-8d84-a2c246ac8f82",
      },
    ],
  },
  {
    bluePrintName: "Squeeze Page Funnel",
    nodes: [
      {
        width: 36,
        height: 47,
        id: "2cb1afe1-9d65-44e8-aaf4-bfa12b439df6",
        type: "TrafficEntry",
        position: {
          x: -25.96875,
          y: -2,
        },
        data: {
          name: "TrafficEntry",
          label: "TrafficEntry node",
          traffic: 1000,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          cpc: 1,
          trafficSources: [
            {
              trafficSource: "Facebook",
              value: 1000,
            },
          ],
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: -25.96875,
          y: -2,
        },
        dragging: false,
      },
      {
        id: "8f8e45b7-121e-48a3-93fc-32f2dd21aa84",
        type: "OptInNode",
        position: {
          x: 57.296875,
          y: 24.25,
        },
        data: {
          name: "OptInNode",
          label: "OptInNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 57.296875,
          y: 24.25,
        },
        dragging: false,
      },
      {
        id: "b0af9be6-e354-4348-bfd9-46d0dfa1b5db",
        type: "ThankYouNode",
        position: {
          x: 164.296875,
          y: 22.75,
        },
        data: {
          name: "ThankYouNode",
          label: "ThankYouNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 164.296875,
          y: 22.75,
        },
        dragging: false,
      },
      {
        id: "c317009b-18ee-42f3-98a3-68c915d872c4",
        type: "EmailNode",
        position: {
          x: 284.796875,
          y: 161.25,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 49,
        height: 72,
      },
      {
        id: "326a9e56-88a1-4dd4-a5e3-2a4ec2d77ba6",
        type: "EmailNode",
        position: {
          x: 369.796875,
          y: 214.75,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 49,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 369.796875,
          y: 214.75,
        },
        dragging: false,
      },
      {
        id: "b0fef6d0-cf40-4a02-ae39-0813d46c32e2",
        type: "EmailNode",
        position: {
          x: 461.796875,
          y: 271.25,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 49,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 461.796875,
          y: 271.25,
        },
        dragging: false,
      },
      {
        id: "6344d467-d085-46ad-8a18-f4fa4094cb05",
        type: "WaitNode",
        position: {
          x: 301.296875,
          y: 245.75,
        },
        data: {
          name: "WaitNode",
          label: "WaitNode node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 29,
        height: 46,
        selected: false,
        positionAbsolute: {
          x: 301.296875,
          y: 245.75,
        },
        dragging: false,
      },
      {
        id: "465a83ac-8028-4126-b088-e1da495c4ccc",
        type: "WaitNode",
        position: {
          x: 401.296875,
          y: 298.25,
        },
        data: {
          name: "WaitNode",
          label: "WaitNode node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 29,
        height: 46,
        selected: false,
        positionAbsolute: {
          x: 401.296875,
          y: 298.25,
        },
        dragging: false,
      },
      {
        id: "2878c998-72f0-481f-8c27-2de1cca7eca2",
        type: "ContentPageNode",
        position: {
          x: 566.296875,
          y: 41.75,
        },
        data: {
          name: "ContentPageNode",
          label: "ContentPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 566.296875,
          y: 41.75,
        },
        dragging: false,
      },
    ],
    edges: [
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "2cb1afe1-9d65-44e8-aaf4-bfa12b439df6",
        sourceHandle: "yes_handle",
        target: "8f8e45b7-121e-48a3-93fc-32f2dd21aa84",
        targetHandle: null,
        id: "reactflow__edge-2cb1afe1-9d65-44e8-aaf4-bfa12b439df6yes_handle-8f8e45b7-121e-48a3-93fc-32f2dd21aa84",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "8f8e45b7-121e-48a3-93fc-32f2dd21aa84",
        sourceHandle: "yes_handle",
        target: "b0af9be6-e354-4348-bfd9-46d0dfa1b5db",
        targetHandle: null,
        id: "reactflow__edge-8f8e45b7-121e-48a3-93fc-32f2dd21aa84yes_handle-b0af9be6-e354-4348-bfd9-46d0dfa1b5db",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "c317009b-18ee-42f3-98a3-68c915d872c4",
        sourceHandle: "no_handle",
        target: "6344d467-d085-46ad-8a18-f4fa4094cb05",
        targetHandle: null,
        id: "reactflow__edge-c317009b-18ee-42f3-98a3-68c915d872c4no_handle-6344d467-d085-46ad-8a18-f4fa4094cb05",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "6344d467-d085-46ad-8a18-f4fa4094cb05",
        sourceHandle: "yes_handle",
        target: "326a9e56-88a1-4dd4-a5e3-2a4ec2d77ba6",
        targetHandle: null,
        id: "reactflow__edge-6344d467-d085-46ad-8a18-f4fa4094cb05yes_handle-326a9e56-88a1-4dd4-a5e3-2a4ec2d77ba6",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "326a9e56-88a1-4dd4-a5e3-2a4ec2d77ba6",
        sourceHandle: "no_handle",
        target: "465a83ac-8028-4126-b088-e1da495c4ccc",
        targetHandle: null,
        id: "reactflow__edge-326a9e56-88a1-4dd4-a5e3-2a4ec2d77ba6no_handle-465a83ac-8028-4126-b088-e1da495c4ccc",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "465a83ac-8028-4126-b088-e1da495c4ccc",
        sourceHandle: "yes_handle",
        target: "b0fef6d0-cf40-4a02-ae39-0813d46c32e2",
        targetHandle: null,
        id: "reactflow__edge-465a83ac-8028-4126-b088-e1da495c4cccyes_handle-b0fef6d0-cf40-4a02-ae39-0813d46c32e2",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "b0af9be6-e354-4348-bfd9-46d0dfa1b5db",
        sourceHandle: "yes_handle",
        target: "c317009b-18ee-42f3-98a3-68c915d872c4",
        targetHandle: null,
        id: "reactflow__edge-b0af9be6-e354-4348-bfd9-46d0dfa1b5dbyes_handle-c317009b-18ee-42f3-98a3-68c915d872c4",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "c317009b-18ee-42f3-98a3-68c915d872c4",
        sourceHandle: "yes_handle",
        target: "2878c998-72f0-481f-8c27-2de1cca7eca2",
        targetHandle: null,
        id: "reactflow__edge-c317009b-18ee-42f3-98a3-68c915d872c4yes_handle-2878c998-72f0-481f-8c27-2de1cca7eca2",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "326a9e56-88a1-4dd4-a5e3-2a4ec2d77ba6",
        sourceHandle: "yes_handle",
        target: "2878c998-72f0-481f-8c27-2de1cca7eca2",
        targetHandle: null,
        id: "reactflow__edge-326a9e56-88a1-4dd4-a5e3-2a4ec2d77ba6yes_handle-2878c998-72f0-481f-8c27-2de1cca7eca2",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "b0fef6d0-cf40-4a02-ae39-0813d46c32e2",
        sourceHandle: "yes_handle",
        target: "2878c998-72f0-481f-8c27-2de1cca7eca2",
        targetHandle: null,
        id: "reactflow__edge-b0fef6d0-cf40-4a02-ae39-0813d46c32e2yes_handle-2878c998-72f0-481f-8c27-2de1cca7eca2",
      },
    ],
  },
  {
    bluePrintName: "Strategy Call Funnel",
    nodes: [
      {
        width: 36,
        height: 47,
        id: "1c1896dc-8bfc-4c88-ac6d-e2de110538c3",
        type: "TrafficEntry",
        position: {
          x: 34.03125,
          y: -74,
        },
        data: {
          name: "TrafficEntry",
          label: "TrafficEntry node",
          traffic: 1000,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          cpc: 1,
          trafficSources: [
            {
              trafficSource: "Facebook",
              value: 1000,
            },
          ],
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: 34.03125,
          y: -74,
        },
        dragging: false,
      },
      {
        id: "9735185b-d7f5-4db8-b6bd-91adef0c5275",
        type: "OptInNode",
        position: {
          x: 117.796875,
          y: -96.75,
        },
        data: {
          name: "OptInNode",
          label: "OptInNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 117.796875,
          y: -96.75,
        },
        dragging: false,
      },
      {
        id: "ff90646d-0264-4b0d-9d5b-4b5ea817081f",
        type: "ApplicationPageNode",
        position: {
          x: 217.296875,
          y: -96.25,
        },
        data: {
          name: "ApplicationPageNode",
          label: "ApplicationPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 217.296875,
          y: -96.25,
        },
        dragging: false,
      },
      {
        id: "ef0ee0a4-44ee-4b8b-aa0f-f6d9d4799536",
        type: "ThankYouNode",
        position: {
          x: 315.296875,
          y: -97.25,
        },
        data: {
          name: "ThankYouNode",
          label: "ThankYouNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 315.296875,
          y: -97.25,
        },
        dragging: false,
      },
      {
        id: "f5737383-bb24-4ba7-bf33-078d94eddaf1",
        type: "EmailNode",
        position: {
          x: 422.796875,
          y: -52.75,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 49,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 422.796875,
          y: -52.75,
        },
        dragging: false,
      },
      {
        id: "28680fe3-cfa7-4bee-ac0f-c922c3026faa",
        type: "WaitNode",
        position: {
          x: 508.296875,
          y: -27.25,
        },
        data: {
          name: "WaitNode",
          label: "WaitNode node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 29,
        height: 46,
        selected: false,
        positionAbsolute: {
          x: 508.296875,
          y: -27.25,
        },
        dragging: false,
      },
      {
        id: "d458eb7a-6b5f-4c7b-9ee3-e413d8e4b486",
        type: "EmailNode",
        position: {
          x: 572.296875,
          y: -54.25,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 49,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 572.296875,
          y: -54.25,
        },
        dragging: false,
      },
      {
        id: "949d5334-4b7e-4f72-be76-fdae5c1f4fb4",
        type: "WaitNode",
        position: {
          x: 660.796875,
          y: -27.75,
        },
        data: {
          name: "WaitNode",
          label: "WaitNode node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 29,
        height: 46,
        selected: false,
        positionAbsolute: {
          x: 660.796875,
          y: -27.75,
        },
        dragging: false,
      },
      {
        id: "1a6dd012-8dd0-4cf8-b2fb-a4615e5e5366",
        type: "EmailNode",
        position: {
          x: 723.796875,
          y: -53.75,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 49,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 723.796875,
          y: -53.75,
        },
        dragging: false,
      },
      {
        id: "b913a874-f18c-4c51-99f7-f8693ef40002",
        type: "PhoneNode",
        position: {
          x: 821.296875,
          y: -54.75,
        },
        data: {
          name: "PhoneNode",
          label: "PhoneNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 32,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 821.296875,
          y: -54.75,
        },
        dragging: false,
      },
      {
        id: "f723bce4-9e8c-4bc6-93bf-202a3bb7d9d8",
        type: "SalesPageNode",
        position: {
          x: 909.296875,
          y: -78.25,
        },
        data: {
          name: "SalesPageNode",
          label: "SalesPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 909.296875,
          y: -78.25,
        },
        dragging: false,
      },
      {
        id: "e345fd9f-93c2-4965-b49a-f229cbb0fc73",
        type: "OrderFormPage",
        position: {
          x: 1016.796875,
          y: -76.25,
        },
        data: {
          name: "OrderFormPage",
          label: "OrderFormPage node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 1016.796875,
          y: -76.25,
        },
        dragging: false,
      },
    ],
    edges: [
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "9735185b-d7f5-4db8-b6bd-91adef0c5275",
        sourceHandle: "yes_handle",
        target: "ff90646d-0264-4b0d-9d5b-4b5ea817081f",
        targetHandle: null,
        id: "reactflow__edge-9735185b-d7f5-4db8-b6bd-91adef0c5275yes_handle-ff90646d-0264-4b0d-9d5b-4b5ea817081f",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "ff90646d-0264-4b0d-9d5b-4b5ea817081f",
        sourceHandle: "yes_handle",
        target: "ef0ee0a4-44ee-4b8b-aa0f-f6d9d4799536",
        targetHandle: null,
        id: "reactflow__edge-ff90646d-0264-4b0d-9d5b-4b5ea817081fyes_handle-ef0ee0a4-44ee-4b8b-aa0f-f6d9d4799536",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "1c1896dc-8bfc-4c88-ac6d-e2de110538c3",
        sourceHandle: "yes_handle",
        target: "9735185b-d7f5-4db8-b6bd-91adef0c5275",
        targetHandle: null,
        id: "reactflow__edge-1c1896dc-8bfc-4c88-ac6d-e2de110538c3yes_handle-9735185b-d7f5-4db8-b6bd-91adef0c5275",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "ef0ee0a4-44ee-4b8b-aa0f-f6d9d4799536",
        sourceHandle: "yes_handle",
        target: "f5737383-bb24-4ba7-bf33-078d94eddaf1",
        targetHandle: null,
        id: "reactflow__edge-ef0ee0a4-44ee-4b8b-aa0f-f6d9d4799536yes_handle-f5737383-bb24-4ba7-bf33-078d94eddaf1",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "f5737383-bb24-4ba7-bf33-078d94eddaf1",
        sourceHandle: "yes_handle",
        target: "28680fe3-cfa7-4bee-ac0f-c922c3026faa",
        targetHandle: null,
        id: "reactflow__edge-f5737383-bb24-4ba7-bf33-078d94eddaf1yes_handle-28680fe3-cfa7-4bee-ac0f-c922c3026faa",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "28680fe3-cfa7-4bee-ac0f-c922c3026faa",
        sourceHandle: "yes_handle",
        target: "d458eb7a-6b5f-4c7b-9ee3-e413d8e4b486",
        targetHandle: null,
        id: "reactflow__edge-28680fe3-cfa7-4bee-ac0f-c922c3026faayes_handle-d458eb7a-6b5f-4c7b-9ee3-e413d8e4b486",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "d458eb7a-6b5f-4c7b-9ee3-e413d8e4b486",
        sourceHandle: "yes_handle",
        target: "949d5334-4b7e-4f72-be76-fdae5c1f4fb4",
        targetHandle: null,
        id: "reactflow__edge-d458eb7a-6b5f-4c7b-9ee3-e413d8e4b486yes_handle-949d5334-4b7e-4f72-be76-fdae5c1f4fb4",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "949d5334-4b7e-4f72-be76-fdae5c1f4fb4",
        sourceHandle: "yes_handle",
        target: "1a6dd012-8dd0-4cf8-b2fb-a4615e5e5366",
        targetHandle: null,
        id: "reactflow__edge-949d5334-4b7e-4f72-be76-fdae5c1f4fb4yes_handle-1a6dd012-8dd0-4cf8-b2fb-a4615e5e5366",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "1a6dd012-8dd0-4cf8-b2fb-a4615e5e5366",
        sourceHandle: "yes_handle",
        target: "b913a874-f18c-4c51-99f7-f8693ef40002",
        targetHandle: null,
        id: "reactflow__edge-1a6dd012-8dd0-4cf8-b2fb-a4615e5e5366yes_handle-b913a874-f18c-4c51-99f7-f8693ef40002",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "f723bce4-9e8c-4bc6-93bf-202a3bb7d9d8",
        sourceHandle: "yes_handle",
        target: "e345fd9f-93c2-4965-b49a-f229cbb0fc73",
        targetHandle: null,
        id: "reactflow__edge-f723bce4-9e8c-4bc6-93bf-202a3bb7d9d8yes_handle-e345fd9f-93c2-4965-b49a-f229cbb0fc73",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "b913a874-f18c-4c51-99f7-f8693ef40002",
        sourceHandle: "yes_handle",
        target: "f723bce4-9e8c-4bc6-93bf-202a3bb7d9d8",
        targetHandle: null,
        id: "reactflow__edge-b913a874-f18c-4c51-99f7-f8693ef40002yes_handle-f723bce4-9e8c-4bc6-93bf-202a3bb7d9d8",
      },
    ],
  },
  {
    bluePrintName: "Survey Q2 Funnel",
    nodes: [
      {
        width: 36,
        height: 47,
        id: "9024f101-0d74-4d4e-b721-1ff1ca7e7672",
        type: "TrafficEntry",
        position: {
          x: 18.53125,
          y: 98,
        },
        data: {
          name: "TrafficEntry",
          label: "TrafficEntry node",
          traffic: 1000,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          cpc: 1,
          trafficSources: [
            {
              trafficSource: "Facebook",
              value: 1000,
            },
          ],
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: 18.53125,
          y: 98,
        },
        dragging: false,
      },
      {
        id: "b2dc9e89-561a-47f3-b6fd-5fb6de432e05",
        type: "OptInNode",
        position: {
          x: 120.296875,
          y: 63.75,
        },
        data: {
          name: "OptInNode",
          label: "OptInNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 120.296875,
          y: 63.75,
        },
        dragging: false,
      },
      {
        id: "6ae37f67-a373-49b6-b0b6-045c7c4e68fc",
        type: "SurveyNode",
        position: {
          x: 214.296875,
          y: 62.75,
        },
        data: {
          name: "SurveyNode",
          label: "SurveyNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 214.296875,
          y: 62.75,
        },
        dragging: false,
      },
      {
        id: "6359ab35-6136-4ff9-9810-d88dcbdbf75a",
        type: "EmailNode",
        position: {
          x: 343.296875,
          y: 8.75,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 49,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 343.296875,
          y: 8.75,
        },
        dragging: false,
      },
      {
        id: "f410c660-0845-41b2-9e83-4012b3801fd0",
        type: "EmailNode",
        position: {
          x: 428.296875,
          y: 10.25,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 49,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 428.296875,
          y: 10.25,
        },
        dragging: false,
      },
      {
        id: "43822023-9336-461d-a703-debca0314a17",
        type: "EmailNode",
        position: {
          x: 515.796875,
          y: 11.75,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 49,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 515.796875,
          y: 11.75,
        },
        dragging: false,
      },
      {
        id: "1935e4cc-ac70-4ebe-b403-d9a18682eded",
        type: "WaitNode",
        position: {
          x: 359.296875,
          y: 104.75,
        },
        data: {
          name: "WaitNode",
          label: "WaitNode node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 29,
        height: 46,
        selected: false,
        positionAbsolute: {
          x: 359.296875,
          y: 104.75,
        },
        dragging: false,
      },
      {
        id: "139920cb-1d1a-40b8-a7c7-3f02203b7970",
        type: "WaitNode",
        position: {
          x: 440.296875,
          y: 99.75,
        },
        data: {
          name: "WaitNode",
          label: "WaitNode node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 29,
        height: 46,
        selected: false,
        positionAbsolute: {
          x: 440.296875,
          y: 99.75,
        },
        dragging: false,
      },
      {
        id: "d9630831-f810-416a-b123-50c7a4e1120e",
        type: "SalesPageNode",
        position: {
          x: 623.9982726777689,
          y: -136.88128316651654,
        },
        data: {
          name: "SalesPageNode",
          label: "SalesPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 623.9982726777689,
          y: -136.88128316651654,
        },
        dragging: false,
      },
      {
        id: "2e5cbf83-0211-4498-a5b7-88a293349f57",
        type: "OrderFormPage",
        position: {
          x: 724.2808738965089,
          y: -135.56177525574367,
        },
        data: {
          name: "OrderFormPage",
          label: "OrderFormPage node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 724.2808738965089,
          y: -135.56177525574367,
        },
        dragging: false,
      },
      {
        id: "fc324d37-7b61-4966-96f8-db352fc63f54",
        type: "EmailNode",
        position: {
          x: 346.901611415461,
          y: 194.97495639286637,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 49,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 346.901611415461,
          y: 194.97495639286637,
        },
        dragging: false,
      },
      {
        id: "375052d5-83f2-4d02-a6f8-d3f146b9f607",
        type: "EmailNode",
        position: {
          x: 454.4415061434519,
          y: 192.33594057132058,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 49,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 454.4415061434519,
          y: 192.33594057132058,
        },
        dragging: false,
      },
      {
        id: "2a7cc92b-0577-44a7-be44-6d17c33f8c50",
        type: "EmailNode",
        position: {
          x: 558.6826310945105,
          y: 188.3774168390019,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 49,
        height: 72,
        selected: false,
        positionAbsolute: {
          x: 558.6826310945105,
          y: 188.3774168390019,
        },
        dragging: false,
      },
      {
        id: "8cbebe5a-f339-4a51-80b2-7dff4768ad12",
        type: "WaitNode",
        position: {
          x: 357.45767470164424,
          y: 307.1331288085624,
        },
        data: {
          name: "WaitNode",
          label: "WaitNode node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 29,
        height: 46,
        selected: false,
        positionAbsolute: {
          x: 357.45767470164424,
          y: 307.1331288085624,
        },
        dragging: false,
      },
      {
        id: "09184521-712c-4a89-b01a-1a41b7703f95",
        type: "WaitNode",
        position: {
          x: 472.9146168942724,
          y: 305.15386694240306,
        },
        data: {
          name: "WaitNode",
          label: "WaitNode node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 29,
        height: 46,
        selected: false,
        positionAbsolute: {
          x: 472.9146168942724,
          y: 305.15386694240306,
        },
        dragging: false,
      },
      {
        id: "dc1738f7-9488-454f-8464-2610a3c44dfe",
        type: "SalesPageNode",
        position: {
          x: 619.3799949900638,
          y: 302.5148511208573,
        },
        data: {
          name: "SalesPageNode",
          label: "SalesPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 619.3799949900638,
          y: 302.5148511208573,
        },
        dragging: false,
      },
      {
        id: "2372915c-649d-482c-878b-bdb50e44ead7",
        type: "OrderFormPage",
        position: {
          x: 713.7248106103256,
          y: 302.5148511208572,
        },
        data: {
          name: "OrderFormPage",
          label: "OrderFormPage node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 713.7248106103256,
          y: 302.5148511208572,
        },
        dragging: false,
      },
    ],
    edges: [
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "6ae37f67-a373-49b6-b0b6-045c7c4e68fc",
        sourceHandle: "yes_handle",
        target: "6359ab35-6136-4ff9-9810-d88dcbdbf75a",
        targetHandle: null,
        id: "reactflow__edge-6ae37f67-a373-49b6-b0b6-045c7c4e68fcyes_handle-6359ab35-6136-4ff9-9810-d88dcbdbf75a",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "6359ab35-6136-4ff9-9810-d88dcbdbf75a",
        sourceHandle: "yes_handle",
        target: "1935e4cc-ac70-4ebe-b403-d9a18682eded",
        targetHandle: null,
        id: "reactflow__edge-6359ab35-6136-4ff9-9810-d88dcbdbf75ayes_handle-1935e4cc-ac70-4ebe-b403-d9a18682eded",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "1935e4cc-ac70-4ebe-b403-d9a18682eded",
        sourceHandle: "yes_handle",
        target: "f410c660-0845-41b2-9e83-4012b3801fd0",
        targetHandle: null,
        id: "reactflow__edge-1935e4cc-ac70-4ebe-b403-d9a18682ededyes_handle-f410c660-0845-41b2-9e83-4012b3801fd0",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "f410c660-0845-41b2-9e83-4012b3801fd0",
        sourceHandle: "yes_handle",
        target: "139920cb-1d1a-40b8-a7c7-3f02203b7970",
        targetHandle: null,
        id: "reactflow__edge-f410c660-0845-41b2-9e83-4012b3801fd0yes_handle-139920cb-1d1a-40b8-a7c7-3f02203b7970",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "139920cb-1d1a-40b8-a7c7-3f02203b7970",
        sourceHandle: "yes_handle",
        target: "43822023-9336-461d-a703-debca0314a17",
        targetHandle: null,
        id: "reactflow__edge-139920cb-1d1a-40b8-a7c7-3f02203b7970yes_handle-43822023-9336-461d-a703-debca0314a17",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "d9630831-f810-416a-b123-50c7a4e1120e",
        sourceHandle: "yes_handle",
        target: "2e5cbf83-0211-4498-a5b7-88a293349f57",
        targetHandle: null,
        id: "reactflow__edge-d9630831-f810-416a-b123-50c7a4e1120eyes_handle-2e5cbf83-0211-4498-a5b7-88a293349f57",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "f410c660-0845-41b2-9e83-4012b3801fd0",
        sourceHandle: "yes_handle",
        target: "d9630831-f810-416a-b123-50c7a4e1120e",
        targetHandle: null,
        id: "reactflow__edge-f410c660-0845-41b2-9e83-4012b3801fd0yes_handle-d9630831-f810-416a-b123-50c7a4e1120e",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "43822023-9336-461d-a703-debca0314a17",
        sourceHandle: "yes_handle",
        target: "d9630831-f810-416a-b123-50c7a4e1120e",
        targetHandle: null,
        id: "reactflow__edge-43822023-9336-461d-a703-debca0314a17yes_handle-d9630831-f810-416a-b123-50c7a4e1120e",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "fc324d37-7b61-4966-96f8-db352fc63f54",
        sourceHandle: "no_handle",
        target: "8cbebe5a-f339-4a51-80b2-7dff4768ad12",
        targetHandle: null,
        id: "reactflow__edge-fc324d37-7b61-4966-96f8-db352fc63f54no_handle-8cbebe5a-f339-4a51-80b2-7dff4768ad12",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "6ae37f67-a373-49b6-b0b6-045c7c4e68fc",
        sourceHandle: "yes_handle",
        target: "fc324d37-7b61-4966-96f8-db352fc63f54",
        targetHandle: null,
        id: "reactflow__edge-6ae37f67-a373-49b6-b0b6-045c7c4e68fcyes_handle-fc324d37-7b61-4966-96f8-db352fc63f54",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "8cbebe5a-f339-4a51-80b2-7dff4768ad12",
        sourceHandle: "yes_handle",
        target: "375052d5-83f2-4d02-a6f8-d3f146b9f607",
        targetHandle: null,
        id: "reactflow__edge-8cbebe5a-f339-4a51-80b2-7dff4768ad12yes_handle-375052d5-83f2-4d02-a6f8-d3f146b9f607",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "375052d5-83f2-4d02-a6f8-d3f146b9f607",
        sourceHandle: "no_handle",
        target: "09184521-712c-4a89-b01a-1a41b7703f95",
        targetHandle: null,
        id: "reactflow__edge-375052d5-83f2-4d02-a6f8-d3f146b9f607no_handle-09184521-712c-4a89-b01a-1a41b7703f95",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "09184521-712c-4a89-b01a-1a41b7703f95",
        sourceHandle: "yes_handle",
        target: "2a7cc92b-0577-44a7-be44-6d17c33f8c50",
        targetHandle: null,
        id: "reactflow__edge-09184521-712c-4a89-b01a-1a41b7703f95yes_handle-2a7cc92b-0577-44a7-be44-6d17c33f8c50",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "dc1738f7-9488-454f-8464-2610a3c44dfe",
        sourceHandle: "yes_handle",
        target: "2372915c-649d-482c-878b-bdb50e44ead7",
        targetHandle: null,
        id: "reactflow__edge-dc1738f7-9488-454f-8464-2610a3c44dfeyes_handle-2372915c-649d-482c-878b-bdb50e44ead7",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "fc324d37-7b61-4966-96f8-db352fc63f54",
        sourceHandle: "yes_handle",
        target: "dc1738f7-9488-454f-8464-2610a3c44dfe",
        targetHandle: null,
        id: "reactflow__edge-fc324d37-7b61-4966-96f8-db352fc63f54yes_handle-dc1738f7-9488-454f-8464-2610a3c44dfe",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "375052d5-83f2-4d02-a6f8-d3f146b9f607",
        sourceHandle: "yes_handle",
        target: "dc1738f7-9488-454f-8464-2610a3c44dfe",
        targetHandle: null,
        id: "reactflow__edge-375052d5-83f2-4d02-a6f8-d3f146b9f607yes_handle-dc1738f7-9488-454f-8464-2610a3c44dfe",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "9024f101-0d74-4d4e-b721-1ff1ca7e7672",
        sourceHandle: "yes_handle",
        target: "b2dc9e89-561a-47f3-b6fd-5fb6de432e05",
        targetHandle: null,
        id: "reactflow__edge-9024f101-0d74-4d4e-b721-1ff1ca7e7672yes_handle-b2dc9e89-561a-47f3-b6fd-5fb6de432e05",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "b2dc9e89-561a-47f3-b6fd-5fb6de432e05",
        sourceHandle: "yes_handle",
        target: "6ae37f67-a373-49b6-b0b6-045c7c4e68fc",
        targetHandle: null,
        id: "reactflow__edge-b2dc9e89-561a-47f3-b6fd-5fb6de432e05yes_handle-6ae37f67-a373-49b6-b0b6-045c7c4e68fc",
      },
    ],
  },
  {
    bluePrintName: "Tripwire Opt-In Funnel",
    nodes: [
      {
        width: 36,
        height: 47,
        id: "1deb2093-9d59-441a-a2fa-c098f4892478",
        type: "TrafficEntry",
        position: {
          x: -100.46875,
          y: 60,
        },
        data: {
          name: "TrafficEntry",
          label: "TrafficEntry node",
          traffic: 1000,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          cpc: 1,
          trafficSources: [
            {
              trafficSource: "Facebook",
              value: 1000,
            },
          ],
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: -100.46875,
          y: 60,
        },
        dragging: false,
      },
      {
        id: "7493caf7-746b-4301-a70e-80a7ce33ebc1",
        type: "OptInNode",
        position: {
          x: -19.203125,
          y: 42.75,
        },
        data: {
          name: "OptInNode",
          label: "OptInNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: -19.203125,
          y: 42.75,
        },
        dragging: false,
      },
      {
        id: "adbd7ceb-e193-4def-bc96-685f91b7ff50",
        type: "SalesPageNode",
        position: {
          x: 88.796875,
          y: 42.75,
        },
        data: {
          name: "SalesPageNode",
          label: "SalesPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 88.796875,
          y: 42.75,
        },
        dragging: false,
      },
      {
        id: "be5aab49-5dd2-47e7-be92-d2269ce7ef41",
        type: "OrderFormPage",
        position: {
          x: 188.296875,
          y: 43.75,
        },
        data: {
          name: "OrderFormPage",
          label: "OrderFormPage node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 188.296875,
          y: 43.75,
        },
        dragging: false,
      },
      {
        id: "866f539d-bee0-49af-a7c3-8a9c76d042a2",
        type: "UpsellPageNode",
        position: {
          x: 310.296875,
          y: 43.75,
        },
        data: {
          name: "UpsellPageNode",
          label: "UpsellPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 310.296875,
          y: 43.75,
        },
        dragging: false,
      },
      {
        id: "4a767db0-3cdf-4833-b220-41fb1a78a520",
        type: "DownsellPageNode",
        position: {
          x: 423.29687500000006,
          y: 202.25,
        },
        data: {
          name: "DownsellPageNode",
          label: "DownsellPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 423.29687500000006,
          y: 202.25,
        },
        dragging: false,
      },
      {
        id: "fa435b80-3ce1-4a46-acbc-6eb4b2fae43f",
        type: "UpsellPageNode",
        position: {
          x: 424.99687500000005,
          y: 43.75,
        },
        data: {
          name: "UpsellPageNode",
          label: "UpsellPageNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 424.99687500000005,
          y: 43.75,
        },
        dragging: false,
      },
      {
        id: "459dbe20-8db1-4eba-a2ec-a92c0cbbcea5",
        type: "ThankYouNode",
        position: {
          x: 548.996875,
          y: 43.25,
        },
        data: {
          name: "ThankYouNode",
          label: "ThankYouNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        width: 69,
        height: 136,
        selected: false,
        positionAbsolute: {
          x: 548.996875,
          y: 43.25,
        },
        dragging: false,
      },
    ],
    edges: [
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "1deb2093-9d59-441a-a2fa-c098f4892478",
        sourceHandle: "yes_handle",
        target: "7493caf7-746b-4301-a70e-80a7ce33ebc1",
        targetHandle: null,
        id: "reactflow__edge-1deb2093-9d59-441a-a2fa-c098f4892478yes_handle-7493caf7-746b-4301-a70e-80a7ce33ebc1",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "7493caf7-746b-4301-a70e-80a7ce33ebc1",
        sourceHandle: "yes_handle",
        target: "adbd7ceb-e193-4def-bc96-685f91b7ff50",
        targetHandle: null,
        id: "reactflow__edge-7493caf7-746b-4301-a70e-80a7ce33ebc1yes_handle-adbd7ceb-e193-4def-bc96-685f91b7ff50",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "adbd7ceb-e193-4def-bc96-685f91b7ff50",
        sourceHandle: "yes_handle",
        target: "be5aab49-5dd2-47e7-be92-d2269ce7ef41",
        targetHandle: null,
        id: "reactflow__edge-adbd7ceb-e193-4def-bc96-685f91b7ff50yes_handle-be5aab49-5dd2-47e7-be92-d2269ce7ef41",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "be5aab49-5dd2-47e7-be92-d2269ce7ef41",
        sourceHandle: "yes_handle",
        target: "866f539d-bee0-49af-a7c3-8a9c76d042a2",
        targetHandle: null,
        id: "reactflow__edge-be5aab49-5dd2-47e7-be92-d2269ce7ef41yes_handle-866f539d-bee0-49af-a7c3-8a9c76d042a2",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "866f539d-bee0-49af-a7c3-8a9c76d042a2",
        sourceHandle: "yes_handle",
        target: "fa435b80-3ce1-4a46-acbc-6eb4b2fae43f",
        targetHandle: null,
        id: "reactflow__edge-866f539d-bee0-49af-a7c3-8a9c76d042a2yes_handle-fa435b80-3ce1-4a46-acbc-6eb4b2fae43f",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "866f539d-bee0-49af-a7c3-8a9c76d042a2",
        sourceHandle: "no_handle",
        target: "fa435b80-3ce1-4a46-acbc-6eb4b2fae43f",
        targetHandle: null,
        id: "reactflow__edge-866f539d-bee0-49af-a7c3-8a9c76d042a2no_handle-fa435b80-3ce1-4a46-acbc-6eb4b2fae43f",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "fa435b80-3ce1-4a46-acbc-6eb4b2fae43f",
        sourceHandle: "yes_handle",
        target: "459dbe20-8db1-4eba-a2ec-a92c0cbbcea5",
        targetHandle: null,
        id: "reactflow__edge-fa435b80-3ce1-4a46-acbc-6eb4b2fae43fyes_handle-459dbe20-8db1-4eba-a2ec-a92c0cbbcea5",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "fa435b80-3ce1-4a46-acbc-6eb4b2fae43f",
        sourceHandle: "no_handle",
        target: "4a767db0-3cdf-4833-b220-41fb1a78a520",
        targetHandle: null,
        id: "reactflow__edge-fa435b80-3ce1-4a46-acbc-6eb4b2fae43fno_handle-4a767db0-3cdf-4833-b220-41fb1a78a520",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "4a767db0-3cdf-4833-b220-41fb1a78a520",
        sourceHandle: "yes_handle",
        target: "459dbe20-8db1-4eba-a2ec-a92c0cbbcea5",
        targetHandle: null,
        id: "reactflow__edge-4a767db0-3cdf-4833-b220-41fb1a78a520yes_handle-459dbe20-8db1-4eba-a2ec-a92c0cbbcea5",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "4a767db0-3cdf-4833-b220-41fb1a78a520",
        sourceHandle: "no_handle",
        target: "459dbe20-8db1-4eba-a2ec-a92c0cbbcea5",
        targetHandle: null,
        id: "reactflow__edge-4a767db0-3cdf-4833-b220-41fb1a78a520no_handle-459dbe20-8db1-4eba-a2ec-a92c0cbbcea5",
      },
    ],
  },
  {
    bluePrintName: "Webinar On-Demand Funnel",
    nodes: [
      {
        width: 36,
        height: 47,
        id: "84ddf1ad-3423-4d68-998e-f055fb008b53",
        type: "TrafficEntry",
        position: {
          x: 80.53125,
          y: -60.5,
        },
        data: {
          name: "TrafficEntry",
          label: "TrafficEntry node",
          traffic: 1000,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          cpc: 1,
          trafficSources: [
            {
              trafficSource: "Facebook",
              value: 1000,
            },
          ],
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: 80.53125,
          y: -60.5,
        },
        dragging: false,
      },
      {
        width: 69,
        height: 136,
        id: "2119713a-d4c8-4d4e-8fb2-cd892d5e4282",
        type: "WebinarRegisterNode",
        position: {
          x: 168.796875,
          y: -78.75,
        },
        data: {
          name: "WebinarRegisterNode",
          label: "WebinarRegisterNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: 168.796875,
          y: -78.75,
        },
        dragging: false,
      },
      {
        width: 69,
        height: 136,
        id: "cdb49e93-ff96-4f20-b000-52a493c076e2",
        type: "ThankYouNode",
        position: {
          x: 278.796875,
          y: -76.75,
        },
        data: {
          name: "ThankYouNode",
          label: "ThankYouNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: 278.796875,
          y: -76.75,
        },
        dragging: false,
      },
      {
        width: 69,
        height: 136,
        id: "68ea9c5c-4993-4d10-8e75-be741289cea7",
        type: "WebinarReplayNode",
        position: {
          x: 464.296875,
          y: -77.75,
        },
        data: {
          name: "WebinarReplayNode",
          label: "WebinarReplayNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: 464.296875,
          y: -77.75,
        },
        dragging: false,
      },
      {
        width: 29,
        height: 46,
        id: "bf98bfd3-e3d8-4055-af66-3626ef649787",
        type: "WaitNode",
        position: {
          x: 391.296875,
          y: -19.25,
        },
        data: {
          name: "WaitNode",
          label: "WaitNode node",
          traffic: 0,
          conversionRate: 100,
          waitType: "minutes",
          waitDuration: "10",
          cpc: 0,
          trafficSources: [],
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: 391.296875,
          y: -19.25,
        },
        dragging: false,
      },
      {
        width: 69,
        height: 136,
        id: "ec63ab77-f0ca-4c9a-bdcc-d557fcd8728a",
        type: "WebinarSalesNode",
        position: {
          x: 581.296875,
          y: -76.75,
        },
        data: {
          name: "WebinarSalesNode",
          label: "WebinarSalesNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: 581.296875,
          y: -76.75,
        },
        dragging: false,
      },
      {
        width: 69,
        height: 136,
        id: "65b335ac-ec36-44e3-8b6a-8825ac36d8c8",
        type: "OrderFormPage",
        position: {
          x: 692.296875,
          y: -76.25,
        },
        data: {
          name: "OrderFormPage",
          label: "OrderFormPage node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: 692.296875,
          y: -76.25,
        },
        dragging: false,
      },
      {
        width: 69,
        height: 136,
        id: "0b8e59e8-e25e-4540-838f-27063cb07952",
        type: "ThankYouNode",
        position: {
          x: 802.796875,
          y: -75.25,
        },
        data: {
          name: "ThankYouNode",
          label: "ThankYouNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: 802.796875,
          y: -75.25,
        },
        dragging: false,
      },
      {
        width: 49,
        height: 72,
        id: "05f8dd42-1380-431d-b8a0-869f43b46e0d",
        type: "EmailNode",
        position: {
          x: 481.0534731006005,
          y: 237.3967794185499,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: 481.0534731006005,
          y: 237.3967794185499,
        },
        dragging: false,
      },
      {
        width: 49,
        height: 72,
        id: "f1c1a9b8-e9f9-4c4c-beed-a749ff6a93c3",
        type: "EmailNode",
        position: {
          x: 472.0534731006005,
          y: 79.8967794185499,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: 472.0534731006005,
          y: 79.8967794185499,
        },
        dragging: false,
      },
      {
        width: 49,
        height: 72,
        id: "4d4293f4-d0b0-431b-b5c5-e6758c7cdafe",
        type: "EmailNode",
        position: {
          x: 475.5534731006005,
          y: 395.8967794185499,
        },
        data: {
          name: "EmailNode",
          label: "EmailNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: 475.5534731006005,
          y: 395.8967794185499,
        },
        dragging: false,
      },
      {
        width: 29,
        height: 46,
        id: "b419c161-04eb-4fd6-973d-c2c294bfcc9d",
        type: "WaitNode",
        position: {
          x: 483.5534731006005,
          y: 167.8967794185499,
        },
        data: {
          name: "WaitNode",
          label: "WaitNode node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: 483.5534731006005,
          y: 167.8967794185499,
        },
        dragging: false,
      },
      {
        width: 29,
        height: 46,
        id: "aeae18d1-2548-4fbd-9b0d-947e7b4127c9",
        type: "WaitNode",
        position: {
          x: 489.5534731006005,
          y: 323.3967794185499,
        },
        data: {
          name: "WaitNode",
          label: "WaitNode node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: 489.5534731006005,
          y: 323.3967794185499,
        },
        dragging: false,
      },
      {
        width: 69,
        height: 136,
        id: "d4e56a26-37a0-421b-ac23-6eff737c6b7c",
        type: "WebinarRegisterNode",
        position: {
          x: 602.5534731006005,
          y: 162.8967794185499,
        },
        data: {
          name: "WebinarRegisterNode",
          label: "WebinarRegisterNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: 602.5534731006005,
          y: 162.8967794185499,
        },
        dragging: false,
      },
      {
        width: 69,
        height: 136,
        id: "7279e46b-2fc2-4fce-b6ab-c0d2a0290540",
        type: "WebinarReplayNode",
        position: {
          x: 871.3409731006005,
          y: 161.8967794185499,
        },
        data: {
          name: "WebinarReplayNode",
          label: "WebinarReplayNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: 871.3409731006005,
          y: 161.8967794185499,
        },
        dragging: false,
      },
      {
        width: 69,
        height: 136,
        id: "be47431b-ad35-4c94-b72d-c6c88c6c00da",
        type: "ThankYouNode",
        position: {
          x: 705.0534731006005,
          y: 163.3967794185499,
        },
        data: {
          name: "ThankYouNode",
          label: "ThankYouNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: 705.0534731006005,
          y: 163.3967794185499,
        },
        dragging: false,
      },
      {
        width: 69,
        height: 136,
        id: "d4255d3d-984c-4140-be46-a74354d0e0ed",
        type: "OrderFormPage",
        position: {
          x: 1076.0534731006005,
          y: 161.8967794185499,
        },
        data: {
          name: "OrderFormPage",
          label: "OrderFormPage node",
          traffic: 0,
          conversionRate: 100,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: 1076.0534731006005,
          y: 161.8967794185499,
        },
        dragging: false,
      },
      {
        width: 69,
        height: 136,
        id: "9bb49f56-8637-4085-adfe-b6a97dc1b7c8",
        type: "WebinarSalesNode",
        position: {
          x: 972.8409731006005,
          y: 162.8967794185499,
        },
        data: {
          name: "WebinarSalesNode",
          label: "WebinarSalesNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: 972.8409731006005,
          y: 162.8967794185499,
        },
        dragging: false,
      },
      {
        width: 69,
        height: 136,
        id: "319504cd-d68b-4a0a-8585-6d3976c17939",
        type: "ThankYouNode",
        position: {
          x: 1172.8784731006008,
          y: 161.3967794185499,
        },
        data: {
          name: "ThankYouNode",
          label: "ThankYouNode node",
          traffic: 0,
          conversionRate: 0,
          waitType: "days",
          waitDuration: 1,
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: 1172.8784731006008,
          y: 161.3967794185499,
        },
        dragging: false,
      },
      {
        width: 29,
        height: 46,
        id: "78f21b92-cdc6-45da-9762-7e846ae0e649",
        type: "WaitNode",
        position: {
          x: 809.8784731006007,
          y: 219.3967794185499,
        },
        data: {
          name: "WaitNode",
          label: "WaitNode node",
          traffic: 0,
          conversionRate: 100,
          waitType: "minutes",
          waitDuration: "10",
          cpc: 0,
          trafficSources: [],
          product: null,
        },
        selected: false,
        positionAbsolute: {
          x: 809.8784731006007,
          y: 219.3967794185499,
        },
        dragging: false,
      },
    ],
    edges: [
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "84ddf1ad-3423-4d68-998e-f055fb008b53",
        sourceHandle: "yes_handle",
        target: "2119713a-d4c8-4d4e-8fb2-cd892d5e4282",
        targetHandle: null,
        id: "reactflow__edge-84ddf1ad-3423-4d68-998e-f055fb008b53yes_handle-2119713a-d4c8-4d4e-8fb2-cd892d5e4282",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "2119713a-d4c8-4d4e-8fb2-cd892d5e4282",
        sourceHandle: "yes_handle",
        target: "cdb49e93-ff96-4f20-b000-52a493c076e2",
        targetHandle: null,
        id: "reactflow__edge-2119713a-d4c8-4d4e-8fb2-cd892d5e4282yes_handle-cdb49e93-ff96-4f20-b000-52a493c076e2",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "cdb49e93-ff96-4f20-b000-52a493c076e2",
        sourceHandle: "yes_handle",
        target: "bf98bfd3-e3d8-4055-af66-3626ef649787",
        targetHandle: null,
        id: "reactflow__edge-cdb49e93-ff96-4f20-b000-52a493c076e2yes_handle-bf98bfd3-e3d8-4055-af66-3626ef649787",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "bf98bfd3-e3d8-4055-af66-3626ef649787",
        sourceHandle: "yes_handle",
        target: "68ea9c5c-4993-4d10-8e75-be741289cea7",
        targetHandle: null,
        id: "reactflow__edge-bf98bfd3-e3d8-4055-af66-3626ef649787yes_handle-68ea9c5c-4993-4d10-8e75-be741289cea7",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "68ea9c5c-4993-4d10-8e75-be741289cea7",
        sourceHandle: "yes_handle",
        target: "ec63ab77-f0ca-4c9a-bdcc-d557fcd8728a",
        targetHandle: null,
        id: "reactflow__edge-68ea9c5c-4993-4d10-8e75-be741289cea7yes_handle-ec63ab77-f0ca-4c9a-bdcc-d557fcd8728a",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "ec63ab77-f0ca-4c9a-bdcc-d557fcd8728a",
        sourceHandle: "yes_handle",
        target: "65b335ac-ec36-44e3-8b6a-8825ac36d8c8",
        targetHandle: null,
        id: "reactflow__edge-ec63ab77-f0ca-4c9a-bdcc-d557fcd8728ayes_handle-65b335ac-ec36-44e3-8b6a-8825ac36d8c8",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "65b335ac-ec36-44e3-8b6a-8825ac36d8c8",
        sourceHandle: "yes_handle",
        target: "0b8e59e8-e25e-4540-838f-27063cb07952",
        targetHandle: null,
        id: "reactflow__edge-65b335ac-ec36-44e3-8b6a-8825ac36d8c8yes_handle-0b8e59e8-e25e-4540-838f-27063cb07952",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "68ea9c5c-4993-4d10-8e75-be741289cea7",
        sourceHandle: "no_handle",
        target: "f1c1a9b8-e9f9-4c4c-beed-a749ff6a93c3",
        targetHandle: null,
        id: "reactflow__edge-68ea9c5c-4993-4d10-8e75-be741289cea7no_handle-f1c1a9b8-e9f9-4c4c-beed-a749ff6a93c3",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "f1c1a9b8-e9f9-4c4c-beed-a749ff6a93c3",
        sourceHandle: "no_handle",
        target: "b419c161-04eb-4fd6-973d-c2c294bfcc9d",
        targetHandle: null,
        id: "reactflow__edge-f1c1a9b8-e9f9-4c4c-beed-a749ff6a93c3no_handle-b419c161-04eb-4fd6-973d-c2c294bfcc9d",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "b419c161-04eb-4fd6-973d-c2c294bfcc9d",
        sourceHandle: "yes_handle",
        target: "05f8dd42-1380-431d-b8a0-869f43b46e0d",
        targetHandle: null,
        id: "reactflow__edge-b419c161-04eb-4fd6-973d-c2c294bfcc9dyes_handle-05f8dd42-1380-431d-b8a0-869f43b46e0d",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "05f8dd42-1380-431d-b8a0-869f43b46e0d",
        sourceHandle: "no_handle",
        target: "aeae18d1-2548-4fbd-9b0d-947e7b4127c9",
        targetHandle: null,
        id: "reactflow__edge-05f8dd42-1380-431d-b8a0-869f43b46e0dno_handle-aeae18d1-2548-4fbd-9b0d-947e7b4127c9",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "aeae18d1-2548-4fbd-9b0d-947e7b4127c9",
        sourceHandle: "yes_handle",
        target: "4d4293f4-d0b0-431b-b5c5-e6758c7cdafe",
        targetHandle: null,
        id: "reactflow__edge-aeae18d1-2548-4fbd-9b0d-947e7b4127c9yes_handle-4d4293f4-d0b0-431b-b5c5-e6758c7cdafe",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "7279e46b-2fc2-4fce-b6ab-c0d2a0290540",
        sourceHandle: "yes_handle",
        target: "9bb49f56-8637-4085-adfe-b6a97dc1b7c8",
        targetHandle: null,
        id: "reactflow__edge-7279e46b-2fc2-4fce-b6ab-c0d2a0290540yes_handle-9bb49f56-8637-4085-adfe-b6a97dc1b7c8",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "9bb49f56-8637-4085-adfe-b6a97dc1b7c8",
        sourceHandle: "yes_handle",
        target: "d4255d3d-984c-4140-be46-a74354d0e0ed",
        targetHandle: null,
        id: "reactflow__edge-9bb49f56-8637-4085-adfe-b6a97dc1b7c8yes_handle-d4255d3d-984c-4140-be46-a74354d0e0ed",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "d4255d3d-984c-4140-be46-a74354d0e0ed",
        sourceHandle: "yes_handle",
        target: "319504cd-d68b-4a0a-8585-6d3976c17939",
        targetHandle: null,
        id: "reactflow__edge-d4255d3d-984c-4140-be46-a74354d0e0edyes_handle-319504cd-d68b-4a0a-8585-6d3976c17939",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "be47431b-ad35-4c94-b72d-c6c88c6c00da",
        sourceHandle: "yes_handle",
        target: "78f21b92-cdc6-45da-9762-7e846ae0e649",
        targetHandle: null,
        id: "reactflow__edge-be47431b-ad35-4c94-b72d-c6c88c6c00dayes_handle-78f21b92-cdc6-45da-9762-7e846ae0e649",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "78f21b92-cdc6-45da-9762-7e846ae0e649",
        sourceHandle: "yes_handle",
        target: "7279e46b-2fc2-4fce-b6ab-c0d2a0290540",
        targetHandle: null,
        id: "reactflow__edge-78f21b92-cdc6-45da-9762-7e846ae0e649yes_handle-7279e46b-2fc2-4fce-b6ab-c0d2a0290540",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "d4e56a26-37a0-421b-ac23-6eff737c6b7c",
        sourceHandle: "yes_handle",
        target: "be47431b-ad35-4c94-b72d-c6c88c6c00da",
        targetHandle: null,
        id: "reactflow__edge-d4e56a26-37a0-421b-ac23-6eff737c6b7cyes_handle-be47431b-ad35-4c94-b72d-c6c88c6c00da",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "f1c1a9b8-e9f9-4c4c-beed-a749ff6a93c3",
        sourceHandle: "yes_handle",
        target: "d4e56a26-37a0-421b-ac23-6eff737c6b7c",
        targetHandle: null,
        id: "reactflow__edge-f1c1a9b8-e9f9-4c4c-beed-a749ff6a93c3yes_handle-d4e56a26-37a0-421b-ac23-6eff737c6b7c",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "05f8dd42-1380-431d-b8a0-869f43b46e0d",
        sourceHandle: "yes_handle",
        target: "d4e56a26-37a0-421b-ac23-6eff737c6b7c",
        targetHandle: null,
        id: "reactflow__edge-05f8dd42-1380-431d-b8a0-869f43b46e0dyes_handle-d4e56a26-37a0-421b-ac23-6eff737c6b7c",
      },
      {
        markerEnd: {
          type: "arrowclosed",
        },
        source: "4d4293f4-d0b0-431b-b5c5-e6758c7cdafe",
        sourceHandle: "yes_handle",
        target: "d4e56a26-37a0-421b-ac23-6eff737c6b7c",
        targetHandle: null,
        id: "reactflow__edge-4d4293f4-d0b0-431b-b5c5-e6758c7cdafeyes_handle-d4e56a26-37a0-421b-ac23-6eff737c6b7c",
      },
    ],
  },
];

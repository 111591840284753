import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getAllRoles, selectAllCollaborators, selectallRoles, updateselectedRole, updateviewRole } from "../../../redux/slices/settingSlice";
import DeleteRoleDialog from "./DeleteRoleDialog";
import NewRoleDialog from "./NewRoleDialog";
import { isTypeOwner, isRoleAdmin, isRoleMember, hasPermission_add_teammates, hasPermission_remove_teammates, hasPermission_add_roles } from "../../../utils/permissions";
import "../../../App.css"
import "./Roles.css"
function Roles() {
  const [selectedMenu, setselectedMenu] = useState("Team");
  const ideas = [1, 2, 3];
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params.projectId;
  const collaborators = useSelector(selectAllCollaborators);
  const allRoles = useSelector(selectallRoles);
  console.log(allRoles)
  const dispatch = useDispatch();

  const ProjectsMenus = [];

  const RightProjectsMenus = [];

  useEffect(() => {
    dispatch(getAllRoles());
  }, [selectedMenu]);



  return (
    <div>
      <div className="d-flex">
        <div>
          <h1 className="mb-1">Roles</h1>
          <p className="text-secondary">Set roles based on permissions</p>
        </div>
        {hasPermission_add_roles() ?
        <div className="flex-fill d-flex flex-row-reverse">
          <div className="hstack gap-3" style={{ padding: 0 }}>
            {selectedMenu === "Team" && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  dispatch(updateviewRole(false));
                  dispatch(updateselectedRole(null));
                }}
                style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                data-bs-toggle="modal"
                data-bs-target="#NewRoleDialog"
              >
                New Role +
              </button>
            )}
          </div>
        </div>: <div></div>
        
      }
      </div>

      <div className="border-bottom mt-3">
        <div className="flex-fill d-flex align-items-center">
          {ProjectsMenus.map((menu) => {
            return (
              <div
                style={{ textDecoration: "none" }}
                className="text-dark body3 regular-weight cp"
                onClick={() => {
                  setselectedMenu(menu.name);
                }}
              >
                <div
                  className={
                    selectedMenu === menu.name
                      ? "text-center border-bottom border-primary border-3"
                      : "text-center pb-1"
                  }
                  style={{ minWidth: "7rem" }}
                >
                  <p className="mb-1">{menu.name}</p>
                </div>
              </div>
            );
          })}

          <div className="flex-fill ml-auto"></div>

          {RightProjectsMenus.map((menu) => {
            return (
              <div
                style={{ textDecoration: "none" }}
                className="text-dark body3 regular-weight cp"
                onClick={() => {
                  setselectedMenu(menu.name);
                }}
              >
                <div
                  className={
                    selectedMenu === menu.name
                      ? "text-center border-bottom border-primary border-3"
                      : "text-center pb-1"
                  }
                  style={{ minWidth: "7rem" }}
                >
                  <p className="mb-1">{menu.name}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <table className="table table-borderless mt-2 custom-table-sm">
        <thead className="border-none">
          <tr>
            <td
              scope="col"
              className="text-secondary body3 regular-weight"
              style={{ minWidth: "80%" }}
            >
              Role Name
            </td>
            <td scope="col" className="text-secondary body3 regular-weight">
              Permission
            </td>
            <td
              scope="col"
              className="text-secondary body3 regular-weight"
            ></td>
          </tr>
        </thead>
        <tbody>
          {/* <tr className="border" style={{ backgroundColor: "#F3F3F3" }}>
            <td className="body3 regular-weight" style={{ minWidth: "80%" }}>
              Owner
            </td>
            <td className="body3 regular-weight">All</td>

            <td className="body3 regular-weight"></td>
          </tr> */}

          {allRoles.map((role, index) => {
            return (
              <tr
                // className="border bg-white"
                className={index === 0 ? "my-header-row" : ""}
              >
                <td
                  className="body3 regular-weight"
                  style={{ minWidth: "80%" }}
                >
                  {role.name}
                </td>
                <td className="body3 regular-weight">
                  {role?.permissions &&
                  Object.keys(role?.permissions)?.filter(
                    (p) => role?.permissions[p] === false
                  ).length == 0 &&
                  role.name === "Owner"
                    ? "All"
                    : "Limited access"}
                </td>

                <td className="body3 regular-weight">
                  <div class="dropdown">
                    {index !== 0 && (
                      <>
                        {hasPermission_add_roles() ? (
                          <div
                            className=" d-flex justify-content-between align-items-center rounded"
                            data-bs-toggle="dropdown"
                            style={{ cursor: "pointer" }}
                          >
                            <i class="bi bi-three-dots-vertical cp custom-more-icon-hover-effect" style={{ padding: "0.5rem" }}></i>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </>
                    )}

                    <ul className="dropdown-menu">
                      <li className="border-bottom">
                        <a
                          className="dropdown-item body3 regular-weight"
                          data-bs-toggle="modal"
                          data-bs-target="#NewRoleDialog"
                          onClick={() => {
                            dispatch(updateviewRole(true));
                            dispatch(updateselectedRole(role));
                          }}
                        >
                          View Role
                        </a>
                      </li>
                      <li className="border-bottom">
                        <a
                          className="dropdown-item body3 regular-weight"
                          data-bs-toggle="modal"
                          data-bs-target="#NewRoleDialog"
                          onClick={() => {
                            dispatch(updateviewRole(false));
                            dispatch(updateselectedRole(role));
                          }}
                        >
                          Edit Role
                        </a>
                      </li>

                      {/* {hasPermission_add_roles() ? ( */}
                        <li className="border-bottom">
                          <a
                            className="dropdown-item body3 regular-weight"
                            data-bs-toggle="modal"
                            data-bs-target="#deleteRoleDialog"
                            onClick={() => {
                              dispatch(updateviewRole(false));
                              dispatch(updateselectedRole(role));
                            }}
                          >
                            Delete Role
                          </a>
                        </li>
                      {/* ) : ( */}
                        {/* <li></li> */}
                      {/* )} */}
                    </ul>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <NewRoleDialog />
      <DeleteRoleDialog />
    </div>
  );
}

export default Roles;

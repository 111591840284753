import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllGrowthLevers,
  getAllkeyMetrics,
  readTimezone,
  selectallGrowthLevers,
  selectkeyMetrics,
  selectTimezone,
  updateselectedGrowthLever,
  updateselectedKeymetric,
  updateTimezone,
} from "../../../redux/slices/settingSlice";
import { backendServerBaseURL } from "../../../utils/backendServerBaseURL";
import { formatTime } from "../../../utils/formatTime";
import DeleteKeyMetricModal from "./DeleteKeyMetricModal";
import DeleteLeverDialog from "./DeleteLeverDialog";
import DeleteWorkspaceDialog from "./DeleteWorkspaceDialog";
import NewGrowthLeverDialog from "./NewGrowthLeverDialog";
import NewKeyMetricDialog from "./NewKeyMetricDialog";
import ViewKeyMetricDialog from "./ViewKeyMetricDialog";
import { isTypeOwner, isRoleAdmin, hasPermission_create_workspace } from "../../../utils/permissions";
import AddSampleDataDialog from "./AddSampleDataDialog";

var tzStrings = [
  { label: "(GMT-12:00) International Date Line West", value: "Etc/GMT+12" },
  { label: "(GMT-11:00) Midway Island, Samoa", value: "Pacific/Midway" },
  { label: "(GMT-10:00) Hawaii", value: "Pacific/Honolulu" },
  { label: "(GMT-09:00) Alaska", value: "US/Alaska" },
  { label: "(GMT-08:00) Pacific Time (US & Canada)", value: "America/Los_Angeles" },
  { label: "(GMT-08:00) Tijuana, Baja California", value: "America/Tijuana" },
  { label: "(GMT-07:00) Arizona", value: "US/Arizona" },
  { label: "(GMT-07:00) Chihuahua, La Paz, Mazatlan", value: "America/Chihuahua" },
  { label: "(GMT-07:00) Mountain Time (US & Canada)", value: "US/Mountain" },
  { label: "(GMT-06:00) Central America", value: "America/Managua" },
  { label: "(GMT-06:00) Central Time (US & Canada)", value: "US/Central" },
  { label: "(GMT-06:00) Guadalajara, Mexico City, Monterrey", value: "America/Mexico_City" },
  { label: "(GMT-06:00) Saskatchewan", value: "Canada/Saskatchewan" },
  { label: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco", value: "America/Bogota" },
  { label: "(GMT-05:00) Eastern Time (US & Canada)", value: "US/Eastern" },
  { label: "(GMT-05:00) Indiana (East)", value: "US/East-Indiana" },
  { label: "(GMT-04:00) Atlantic Time (Canada)", value: "Canada/Atlantic" },
  { label: "(GMT-04:00) Caracas, La Paz", value: "America/Caracas" },
  { label: "(GMT-04:00) Manaus", value: "America/Manaus" },
  { label: "(GMT-04:00) Santiago", value: "America/Santiago" },
  { label: "(GMT-03:30) Newfoundland", value: "Canada/Newfoundland" },
  { label: "(GMT-03:00) Brasilia", value: "America/Sao_Paulo" },
  { label: "(GMT-03:00) Buenos Aires, Georgetown", value: "America/Argentina/Buenos_Aires" },
  { label: "(GMT-03:00) Greenland", value: "America/Godthab" },
  { label: "(GMT-03:00) Montevideo", value: "America/Montevideo" },
  { label: "(GMT-02:00) Mid-Atlantic", value: "America/Noronha" },
  { label: "(GMT-01:00) Cape Verde Is.", value: "Atlantic/Cape_Verde" },
  { label: "(GMT-01:00) Azores", value: "Atlantic/Azores" },
  { label: "(GMT+00:00) Casablanca, Monrovia, Reykjavik", value: "Africa/Casablanca" },
  { label: "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London", value: "Etc/Greenwich" },
  { label: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna", value: "Europe/Amsterdam" },
  { label: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague", value: "Europe/Belgrade" },
  { label: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris", value: "Europe/Brussels" },
  { label: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb", value: "Europe/Sarajevo" },
  { label: "(GMT+01:00) West Central Africa", value: "Africa/Lagos" },
  { label: "(GMT+02:00) Amman", value: "Asia/Amman" },
  { label: "(GMT+02:00) Athens, Bucharest, Istanbul", value: "Europe/Athens" },
  { label: "(GMT+02:00) Beirut", value: "Asia/Beirut" },
  { label: "(GMT+02:00) Cairo", value: "Africa/Cairo" },
  { label: "(GMT+02:00) Harare, Pretoria", value: "Africa/Harare" },
  { label: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius", value: "Europe/Helsinki" },
  { label: "(GMT+02:00) Jerusalem", value: "Asia/Jerusalem" },
  { label: "(GMT+02:00) Minsk", value: "Europe/Minsk" },
  { label: "(GMT+02:00) Windhoek", value: "Africa/Windhoek" },
  { label: "(GMT+03:00) Kuwait, Riyadh, Baghdad", value: "Asia/Kuwait" },
  { label: "(GMT+03:00) Moscow, St. Petersburg, Volgograd", value: "Europe/Moscow" },
  { label: "(GMT+03:00) Nairobi", value: "Africa/Nairobi" },
  { label: "(GMT+03:00) Tbilisi", value: "Asia/Tbilisi" },
  { label: "(GMT+03:30) Tehran", value: "Asia/Tehran" },
  { label: "(GMT+04:00) Abu Dhabi, Muscat", value: "Asia/Muscat" },
  { label: "(GMT+04:00) Baku", value: "Asia/Baku" },
  { label: "(GMT+04:00) Yerevan", value: "Asia/Yerevan" },
  { label: "(GMT+04:30) Kabul", value: "Asia/Kabul" },
  { label: "(GMT+05:00) Yekaterinburg", value: "Asia/Yekaterinburg" },
  { label: "(GMT+05:00) Islamabad, Karachi, Tashkent", value: "Asia/Karachi" },
  { label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi", value: "Asia/Calcutta" },
  { label: "(GMT+05:30) Sri Jayawardenapura", value: "Asia/Calcutta" },
  { label: "(GMT+05:45) Kathmandu", value: "Asia/Katmandu" },
  { label: "(GMT+06:00) Almaty, Novosibirsk", value: "Asia/Almaty" },
  { label: "(GMT+06:00) Astana, Dhaka", value: "Asia/Dhaka" },
  { label: "(GMT+06:30) Yangon (Rangoon)", value: "Asia/Rangoon" },
  { label: "(GMT+07:00) Bangkok, Hanoi, Jakarta", value: "Asia/Bangkok" },
  { label: "(GMT+07:00) Krasnoyarsk", value: "Asia/Krasnoyarsk" },
  { label: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi", value: "Asia/Hong_Kong" },
  { label: "(GMT+08:00) Kuala Lumpur, Singapore", value: "Asia/Kuala_Lumpur" },
  { label: "(GMT+08:00) Irkutsk, Ulaan Bataar", value: "Asia/Irkutsk" },
  { label: "(GMT+08:00) Perth", value: "Australia/Perth" },
  { label: "(GMT+08:00) Taipei", value: "Asia/Taipei" },
  { label: "(GMT+09:00) Osaka, Sapporo, Tokyo", value: "Asia/Tokyo" },
  { label: "(GMT+09:00) Seoul", value: "Asia/Seoul" },
  { label: "(GMT+09:00) Yakutsk", value: "Asia/Yakutsk" },
  { label: "(GMT+09:30) Adelaide", value: "Australia/Adelaide" },
  { label: "(GMT+09:30) Darwin", value: "Australia/Darwin" },
  { label: "(GMT+10:00) Brisbane", value: "Australia/Brisbane" },
  { label: "(GMT+10:00) Canberra, Melbourne, Sydney", value: "Australia/Canberra" },
  { label: "(GMT+10:00) Hobart", value: "Australia/Hobart" },
  { label: "(GMT+10:00) Guam, Port Moresby", value: "Pacific/Guam" },
  { label: "(GMT+10:00) Vladivostok", value: "Asia/Vladivostok" },
  { label: "(GMT+11:00) Magadan, Solomon Is., New Caledonia", value: "Asia/Magadan" },
  { label: "(GMT+12:00) Auckland, Wellington", value: "Pacific/Auckland" },
  { label: "(GMT+12:00) Fiji, Kamchatka, Marshall Is.", value: "Pacific/Fiji" },
  { label: "(GMT+13:00) Nuku'alofa", value: "Pacific/Tongatapu" },
];



function Workspace() {
  const dispatch = useDispatch();
  const allKeyMetrics = useSelector(selectkeyMetrics);
  console.log('allKeyMetrics ^^^:>> ', allKeyMetrics);
  const allGrowthLevers = useSelector(selectallGrowthLevers);
  const timezone = useSelector(selectTimezone);
  // const allDefaultLevers = useSelector(selectlearningsByGrowthLeverGraphData);
  // console.log('allDefaultLevers :>> ', allDefaultLevers);

  const sampleText = localStorage.getItem("sampleDataBtn", "");
  // let userParseData = JSON.parse(userData);
  // let userCreatedDate = userParseData.createdAt;
  console.log('sampleText :>> ', sampleText);

  // let userFname = userParseData.firstName;
  // let userLname = userParseData.lastName;
  // let userAvatar = userParseData.avatar;
  
  useEffect(() => {
    dispatch(getAllkeyMetrics());
    dispatch(readTimezone());
    dispatch(getAllGrowthLevers());
  }, []);

  return (
    <div>
      <div className="d-flex">
        <div>
          <h1 className="mb-1">Workspace</h1>
          <p className="text-secondary">Manage workspace</p>
        </div>

        <div className="flex-fill d-flex flex-row-reverse">
          <div className="hstack gap-3" style={{ padding: 0 }}>
            {/* <button type="button" className="btn btn-primary" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
              + New Goal
            </button> */}
          </div>
        </div>
      </div>

      {/* <div style={{ maxWidth: "60%" }}>
        <div className="row">
          <div class="mt-3 col-8">
            <div>
              <label className="form-label">Timezone</label>

              <select
                className="form-select"
                value={timezone}
                onChange={(e) => {
                  dispatch(updateTimezone({ timeZone: e.target.value }));
                }}
              >
                {tzStrings.map((tz) => {
                  return <option value={tz.value}>{tz.label}</option>;
                })}
              </select>

              <label htmlFor="">We recommend your select a timezone where most of your team resides.</label>
            </div>

            <hr />
          </div>
        </div>
      </div> */}

      <div className="border-bottom pb-2">
        <p className="form-label">Key Metrics</p>
        {allKeyMetrics?.length === 0 && <p>No custom key metrics found</p>}

        {allKeyMetrics?.length !== 0 && (
          <table className="table table-borderless mt-2 custom-table-sm">
            <thead className="border-none">
              <tr>
                <td scope="col" className="text-secondary body3 regular-weight">
                  Short Name
                </td>
                <td scope="col" className="text-secondary body3 regular-weight">
                  Metric Name
                </td>
                <td scope="col" className="text-secondary body3 regular-weight">
                  Metric Type
                </td>
                <td scope="col" className="text-secondary body3 regular-weight">
                  Created By
                </td>
                <td scope="col" className="text-secondary body3 regular-weight">
                  Created On
                </td>
                <td
                  scope="col"
                  className="text-secondary body3 regular-weight"
                ></td>
              </tr>
            </thead>
            <tbody>
              {allKeyMetrics?.map((singleKeyMetric) => {
                return (
                  <tr className="border bg-white">
                    <td className="body3 regular-weight align-middle">
                      {singleKeyMetric.shortName}
                    </td>
                    <td className="body3 regular-weight align-middle">
                      {singleKeyMetric.name}
                    </td>
                    <td className="body3 regular-weight align-middle">
                      {singleKeyMetric.metricType}
                    </td>
                    <td className="body3 regular-weight align-middle">
                      <img
                        src={`${backendServerBaseURL}/${singleKeyMetric?.createdBy?.avatar}`}
                        alt=""
                        className="avatar3"
                        style={{ marginRight: "0.3rem" }}
                      />
                      {/* {singleKeyMetric?.createdBy?.firstName} {singleKeyMetric?.createdBy?.lastName} */}
                      {`${
                        singleKeyMetric.createdBy
                          ? [
                              singleKeyMetric.createdBy?.firstName,
                              singleKeyMetric?.createdBy?.lastName,
                            ].join(" ")
                          : "Default"
                      }`}
                    </td>
                    <td className="body3 regular-weight align-middle">
                      {formatTime(singleKeyMetric?.createdAt)
                        ? formatTime(singleKeyMetric?.createdAt)
                        : ""}
                    </td>
                    <td className="body3 regular-weight align-middle">
                      {(hasPermission_create_workspace() &&
                        !singleKeyMetric.mode) ||
                      (hasPermission_create_workspace() &&
                        !singleKeyMetric.mode) ? (
                        <div class="dropdown">
                          <div
                            className="d-flex justify-content-between align-items-center rounded"
                            data-bs-toggle="dropdown"
                            style={{ maxWidth: "6.3rem", cursor: "pointer" }}
                          >
                            <i class="bi bi-three-dots-vertical custom-more-icon-hover-effect" style={{ padding: "0.5rem" }}></i>
                          </div>

                          <ul className="dropdown-menu">
                            <li
                              data-bs-toggle="modal"
                              data-bs-target="#viewKeyMetricModal"
                              onClick={() => {
                                dispatch(
                                  updateselectedKeymetric(singleKeyMetric)
                                );
                              }}
                            >
                              <a
                                className="dropdown-item body3 regular-weight"
                                href="#"
                              >
                                View Key Metric
                              </a>
                            </li>

                            <li
                              data-bs-toggle="modal"
                              data-bs-target="#deleteKeyMetricModal"
                              onClick={() => {
                                dispatch(
                                  updateselectedKeymetric(singleKeyMetric)
                                );
                              }}
                            >
                              <a
                                className="dropdown-item body3 regular-weight"
                                href="#"
                              >
                                Delete Key Metric
                              </a>
                            </li>
                          </ul>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        {hasPermission_create_workspace() ? (
          <p
            className="text-primary cp mt-2"
            data-bs-toggle="modal"
            data-bs-target="#createKeyMetricModal"
          >
            + Create a key metric
          </p>
        ) : (
          <p></p>
        )}
      </div>

      <div className="pt-3">
        <p className="form-label">Growth Levers</p>
        {allGrowthLevers?.length === 0 && <p>No Growth Levers found</p>}

        {allGrowthLevers?.length !== 0 && (
          <table className="table table-borderless mt-2 custom-table-sm">
            <thead className="border-none">
              <tr>
                <td scope="col" className="text-secondary body3 regular-weight">
                  Name
                </td>
                <td scope="col" className="text-secondary body3 regular-weight">
                  Color
                </td>
                <td scope="col" className="text-secondary body3 regular-weight">
                  Created By
                </td>
                <td scope="col" className="text-secondary body3 regular-weight">
                  Created On
                </td>
                <td
                  scope="col"
                  className="text-secondary body3 regular-weight"
                ></td>
              </tr>
            </thead>
            <tbody>
              {/* <tr className="border bg-white">
                <td>
                  <div>Acquisition</div>

                </td>
                <td className="body3 regular-weight align-middle">
                        <div className="blue-chip text-center" style={{ maxWidth: "6rem" }}>
                          Blue
                        </div>
                 </td>
                 <td className="body3 regular-weight align-middle">
                      <img
                        src={`${backendServerBaseURL}/${userAvatar}`}
                        alt=""
                        className="avatar3"
                        style={{ marginRight: "0.3rem" }}
                      />
                      {userFname} {userLname}
                    </td>
                 <td className="body3 regular-weight align-middle">{formatTime(userCreatedDate)}</td>

              </tr> */}
              {allGrowthLevers?.map((singleGrowthLever) => {
                return (
                  <tr className="border bg-white">
                    <td className="body3 regular-weight align-middle">
                      {singleGrowthLever?.name}
                    </td>
                    <td className="body3 regular-weight align-middle">
                      {singleGrowthLever?.color == "Blue" && (
                        <div
                          className="blue-chip text-center"
                          style={{ maxWidth: "6rem" }}
                        >
                          Blue
                        </div>
                      )}
                      {singleGrowthLever?.color == "Yellow" && (
                        <div
                          className="yellow-chip text-center"
                          style={{ maxWidth: "6rem" }}
                        >
                          Yellow
                        </div>
                      )}
                      {singleGrowthLever?.color == "Orange" && (
                        <div
                          className="orange-chip text-center"
                          style={{ maxWidth: "6rem" }}
                        >
                          Orange
                        </div>
                      )}
                      {singleGrowthLever?.color == "Red" && (
                        <div
                          className="red-chip text-center"
                          style={{ maxWidth: "6rem" }}
                        >
                          Red
                        </div>
                      )}
                      {singleGrowthLever?.color == "Green" && (
                        <div
                          className="green-chip text-center"
                          style={{ maxWidth: "6rem" }}
                        >
                          Green
                        </div>
                      )}
                    </td>
                    <td className="body3 regular-weight align-middle">
                      <img
                        src={`${backendServerBaseURL}/${singleGrowthLever?.createdBy?.avatar}`}
                        alt=""
                        className="avatar3"
                        style={{ marginRight: "0.3rem" }}
                      />
                      {singleGrowthLever?.createdBy?.firstName}{" "}
                      {singleGrowthLever?.createdBy?.lastName}
                    </td>
                    <td className="body3 regular-weight align-middle">
                      {singleGrowthLever?.createdAt
                        ? formatTime(singleGrowthLever?.createdAt)
                        : "-"}
                    </td>
                    <td className="body3 regular-weight align-middle">
                      {(hasPermission_create_workspace() &&
                        !singleGrowthLever.type) ||
                      (hasPermission_create_workspace() &&
                        !singleGrowthLever.type) ? (
                        <div class="dropdown">
                          <div
                            className="d-flex justify-content-between align-items-center rounded"
                            data-bs-toggle="dropdown"
                            style={{ maxWidth: "6.3rem", cursor: "pointer" }}
                          >
                            <i class="bi bi-three-dots-vertical custom-more-icon-hover-effect" style={{ padding: "0.5rem" }}></i>
                          </div>

                          <ul className="dropdown-menu">
                            <li
                              data-bs-toggle="modal"
                              data-bs-target="#NewGrowthLeverDialog"
                              onClick={() => {
                                dispatch(
                                  updateselectedGrowthLever(singleGrowthLever)
                                );
                              }}
                            >
                              <a
                                className="dropdown-item body3 regular-weight"
                                href="#"
                              >
                                Edit Lever
                              </a>
                            </li>

                            <li
                              data-bs-toggle="modal"
                              data-bs-target="#deleteLeverDialog"
                              onClick={() => {
                                dispatch(
                                  updateselectedGrowthLever(singleGrowthLever)
                                );
                              }}
                            >
                              <a
                                className="dropdown-item body3 regular-weight"
                                href="#"
                              >
                                Delete Lever
                              </a>
                            </li>
                          </ul>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        {hasPermission_create_workspace() ? (
          <p
            className="text-primary cp mt-2"
            data-bs-toggle="modal"
            data-bs-target="#NewGrowthLeverDialog"
            onClick={() => {
              dispatch(updateselectedGrowthLever(null));
            }}
          >
            + Create a Lever
          </p>
        ) : (
          <p></p>
        )}

        {/* <p
          className="text-primary cp mt-2"
          data-bs-toggle="modal"
          data-bs-target="#AddSampleDataDialog"
          >
            Add Sample Data
          </p> */}
          <p
          className="text-primary cp mt-2"
          data-bs-toggle="modal"
          data-bs-target="#AddSampleDataDialog"
          >
            {sampleText ? 'Remove Sample Data' : 'Add Sample Data'}
          </p>

      </div>

      {/* <div className="pt-3">
        <p className="form-label">Delete Workspace</p>
        <p>
          All the users & data will get erased. <span style={{ fontWeight: "bold" }}>THIS ACTION CANNOT BE REVERTED!</span>
        </p>

        <button className="btn btn-lg btn-outline-danger" data-bs-toggle="modal" data-bs-target="#deleteWorkspaceDialog">
          Delete Workspace
        </button>
      </div> */}

      <NewKeyMetricDialog />
      <ViewKeyMetricDialog />
      <DeleteKeyMetricModal />
      <DeleteWorkspaceDialog />
      <DeleteLeverDialog />
      <NewGrowthLeverDialog />
      <AddSampleDataDialog />
    </div>
    
  );
}

export default Workspace;

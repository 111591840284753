import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateSelectedIdea } from "../../../redux/slices/projectSlice";
import { backendServerBaseURL } from "../../../utils/backendServerBaseURL";
import { formatTime } from "../../../utils/formatTime";
import { selectallGrowthLevers } from "../../../redux/slices/settingSlice";

function GoalBasedIdea({ goal }) {
  const [collapse, setcollapse] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params.projectId;
  const allGrowthLevers = useSelector(selectallGrowthLevers);
  let leversData = allGrowthLevers.map((x) => {
  return ({
   name:  x.name,
   color: x.color}
  )
});
console.log('leversData :>> ', leversData);

  return (
    <div>
      <div className="hstack gap-2 mb-2 mt-1">
        <i
          class="bi bi-chevron-down semi-bold-weight cp"
          style={{ transform: collapse ? "rotate(180deg)" : "rotate(0deg)" }}
          onClick={() => {
            setcollapse(!collapse);
          }}
        ></i>

        <p className="body3 semi-bold-weight">{goal?.name}</p>
      </div>

      {!collapse &&
        goal?.ideas?.map((idea) => {
          return (
            <div
              className="hstack border bg-white"
              style={{ cursor: "pointer", padding: "0.7rem", paddingLeft: "1rem" }}
              onClick={() => {
                dispatch(updateSelectedIdea(idea));
                navigate(`/projects/${projectId}/ideas/${idea._id}`);
              }}
            >
              <td className="body3 regular-weight" style={{ minWidth: "20%", maxWidth: "20%" }} >
              <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title={idea?.name}
                      style={{
                        width: "150px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "inline-block",
                      }}
                    >
                      {idea?.name}
                    </span>
              </td>
              <td className="body3 regular-weight" style={{ minWidth: "10%", maxWidth: "10%" }}>
              {leversData.map((lever) => {
                      if (idea.lever === lever.name) {
                        let chipColor;
                        switch (lever.color) {
                          case "Blue":
                            chipColor = "blue-chip";
                            break;
                          case "Orange":
                            chipColor = "orange-chip";
                            break;
                          case "Green":
                            chipColor = "green-chip";
                            break;
                          case "Red":
                            chipColor = "red-chip";
                            break;
                          case "Yellow":
                            chipColor = "yellow-chip";
                            break;
                          default:
                            chipColor = "blue-chip";
                            break;
                        }
                        return (
                          <span className={chipColor} key={lever.name}>
                            {idea.lever}
                          </span>
                        );
                      }
                      return null;
                    })}
              </td>
              <td className="body3 regular-weight" style={{ minWidth: "10%", maxWidth: "10%" }}>
              <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title={goal?.name}
                      style={{
                        width: "70px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "inline-block",
                      }}
                    >
                      {goal?.name}
                    </span>
              </td>
              <td className="body3 regular-weight" style={{ minWidth: "20%", maxWidth: "20%" }}>
                <img src={`${backendServerBaseURL}/${idea.createdBy.avatar}`} alt="" className="avatar3" style={{ marginRight: "0.3rem" }} />
                {`${idea.createdBy.firstName} ${idea.createdBy.lastName}`}
              </td>
              <td className="body3 regular-weight" style={{ minWidth: "10%", maxWidth: "10%" }}>
                {formatTime(idea.createdAt)}
              </td>
              <td className="body3 regular-weight" style={{ minWidth: "10%", maxWidth: "10%" }}>
                {idea.nominations.length}
              </td>
              <td className="body3 regular-weight" style={{ minWidth: "10%", maxWidth: "10%" }}>
                {idea.score}
              </td>
              <td className="body3 regular-weight" style={{ minWidth: "10%", maxWidth: "10%" }}>
                <div class="dropdown">
                  <div
                    data-bs-toggle="dropdown"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <i class="bi bi-three-dots-vertical custom-more-icon-hover-effect" style={{ padding: "0.5rem",marginRight: "0.4rem" }}></i>
                  </div>

                  <ul
                    className="dropdown-menu"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <li className="border-bottom">
                      <a
                        className="dropdown-item body3 regular-weight"
                        onClick={() => {
                          dispatch(updateSelectedIdea(idea));
                          window.open(`/projects/${projectId}/ideas/${idea._id}`, "_self");
                        }}
                      >
                        View Idea
                      </a>
                    </li>
                    <li
                      className="border-bottom"
                      onClick={() => {
                        dispatch(updateSelectedIdea(idea));
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#testIdeaModal"
                    >
                      <a className="dropdown-item body3 regular-weight" href="#">
                        Test Idea
                      </a>
                    </li>
                    <li
                      className="border-bottom"
                      onClick={() => {
                        dispatch(updateSelectedIdea(idea));
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#deleteIdeaDialog"
                    >
                      <a className="dropdown-item body3 regular-weight" href="#">
                        Delete Idea
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </div>
          );
        })}
    </div>
  );
}

export default GoalBasedIdea;

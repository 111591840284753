import React, { useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";

import { Form, FormikProvider } from "formik";
import { deleteModel } from "../../redux/slices/modelSlice";
import { useDispatch } from "react-redux";

function DeleteModelDialog() {
  const dispatch = useDispatch();
  const closeDialogRef = useRef();

  const closeModal = () => {
    closeDialogRef.current.click();
  };

  return (
    <>
      <div className="modal fade" id="deleteModelDialog" tabIndex={-1} aria-labelledby="deleteModelDialogLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <h2 style={{ marginBottom: "16px" }}>Delete Model</h2>

              <div className="form-field">
                <span>Are you sure you want to delete model? The data will be lost</span>
              </div>

              <div className="hstack gap-2 d-flex justify-content-end">
                <button type="button" class="btn btn-lg btn-outline-primary" data-bs-dismiss="modal" ref={closeDialogRef}>
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-lg btn-danger"
                  onClick={() => {
                    dispatch(deleteModel({ closeModal }));
                  }}
                >
                  Delete Model
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeleteModelDialog;

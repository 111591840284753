import { useCallback, useRef } from "react";
import { Handle, Position } from "reactflow";
import NodeResult from "../../components/NodeTypes/NodeResult";
import NodeModal from "./NodeModal";
import { createPortal } from "react-dom";
import React from "react";

function PhoneNode({ data, isConnectable, id }) {
  const closeModalRef = useRef();
  const modalOpenRef = useRef();
  return (
    <div>
      <Handle
        type="target"
        style={{ marginTop: "1.24rem" }}
        position={Position.Left}
        isConnectable={isConnectable}
      />
      <div
        onDoubleClick={() => {
          modalOpenRef.current.click();
        }}
      >
        <NodeResult
          traffic={data.traffic}
          name={data.name}
          convertedTraffic={data.convertedTraffic}
          label={data.label}
        />
        <img
          src="/static/icons/sidebar/blue/objects-phone.png"
          alt=""
          style={{ maxHeight: "2rem", minHeight: "2rem" }}
        />
        <div
          ref={modalOpenRef}
          style={{
            display: "none",
            cursor: "pointer",
            fontSize: "1.5rem",
            marginRight: "1.5rem",
          }}
          data-bs-toggle="modal"
          data-bs-target={`#m${id.replaceAll("-", "")}`}
        >
          <i class="fa-solid fa-plus"></i>
        </div>
        {createPortal(
          <NodeModal
            modalId={`m${id.replaceAll("-", "")}`}
            data={data}
            closeModalRef={closeModalRef}
            nodeId={id}
          />,
          document.body
        )}
      </div>
      <Handle
        type="source"
        position={Position.Right}
        id="yes_handle"
        isConnectable={isConnectable}
        style={{ background: "green", marginTop: "1.24rem" }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="no_handle"
        isConnectable={isConnectable}
        style={{ background: "red" }}
      />
    </div>
  );
}

export default PhoneNode;

import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { deleteGoal } from "../../../redux/slices/projectSlice";

function DeleteGoalDialog() {
  const dispatch = useDispatch();
  const params = useParams();
  const projectId = params.projectId;
  const closeDialogRef = useRef();

  const closeDialgo = () => {
    closeDialogRef.current.click();
  };

  return (
    <>
      {/* Create new Project Dialog */}
      <div>
        <div className="modal fade" id="deleteGoalDialog" tabIndex={-1} aria-labelledby="deleteLearningDialogLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h2 style={{ marginBottom: "16px" }}>Delete Goal</h2>

                <div className="form-field">
                  <span>Delete this goal</span>
                </div>

                <div className="hstack gap-2 d-flex justify-content-end">
                  <button type="button" class="btn btn-lg btn-outline-primary" data-bs-dismiss="modal" ref={closeDialogRef}>
                    Close
                  </button>
                  <button
                    type="submit"
                    class="btn btn-lg btn-danger"
                    onClick={() => {
                      dispatch(deleteGoal({ projectId, closeDialgo }));
                    }}
                  >
                    Delete Goal
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeleteGoalDialog;

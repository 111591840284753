import React, { useState } from "react";
import TestIdeaDialog from "./TestIdeaDialog";
import { MentionsInput, Mention } from "react-mentions";
import FilePreview from "../../../components/common/FilePreview";
import Members from "../../../components/common/Members";
// import Collaborators from "../../../components/common/Collaborators";
import { useDispatch, useSelector } from "react-redux";
import {
  addComment,
  deleteComment,
  getProjectUsers,
  nominateIdea,
  readSingleIdea,
  selectProjectUsers,
  selectSelectedIdea,
  selectSingleIdeaInfo,
  unnominateIdea,
  updateComment,
  updateSelectedIdea,
  updateselectedTest,
} from "../../../redux/slices/projectSlice";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { formatTime } from "../../../utils/formatTime";
import { backendServerBaseURL } from "../../../utils/backendServerBaseURL";
import CreateNewIdeaDialog from "./CreateNewIdeaDialog";
import { swapTags } from "../../../utils/tag.js";
import moment from "moment";
import { hasPermission_create_comments, hasPermission_create_ideas, hasPermission_nominate_ideas, isRoleAdmin, isRoleMember, isTypeOwner } from "../../../utils/permissions";
import Spinner from "../../../components/common/Spinner";
import LoadingButton from "../../../components/common/LoadingButton";

function IdeaInfo() {
  const [comment, setcomment] = useState("");
  const [comment2, setcomment2] = useState("");
  const dispatch = useDispatch();
  const params = useParams();
  const ideaId = params.ideaId;
  const projectId = params.projectId;
  const selectedIdea = useSelector(selectSingleIdeaInfo);
  const me = JSON.parse(localStorage.getItem("user", ""));
  const projectUsers = useSelector(selectProjectUsers);
  const [editingComment, seteditingComment] = useState(0);
  const openedProject = JSON.parse(localStorage.getItem("openedProject", ""));

  const [showLoader, setShowLoader] = useState(true);
  const [isSubmitting, setisSubmitting] = useState(false);

  // useEffect(() => {
  //   if (selectedIdea) {
  //     setShowLoader(false); 
  //   } else {
  //     setShowLoader(true); 
  //   }
  // }, [selectedIdea]);

  useEffect(() => {
    dispatch(readSingleIdea({ ideaId }));
    dispatch(getProjectUsers({ projectId }));
    setTimeout(() => {
      setShowLoader(false); 
    }, 2000);
  }, []);

  const ProjectsMenus = [
    {
      name: "Weekly Sales",
    },
    {
      name: "Monthly Revenue",
    },
  ];

  return (
    <div>
      <div className="d-flex">
        {showLoader && <div
          style={{
            width: "100%",
            height: "100%",
            zIndex: "999",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            paddingRight: "300px",
            paddingBottom: "200px",
            background: "#FBFBFB"
          }}>
          <Spinner />
        </div>}
        <div className="flex-fill" style={{ marginRight: "5rem" }}>
          <div className="mb-3 d-flex">
            <div className="flex-fill">
              <p className="body3 text-secondary">
                {openedProject?.name} / Ideas
              </p>
              <h2>{selectedIdea?.name}</h2>
              <div className="hstack gap-2 mt-2">
                <button
                  className={
                    selectedIdea?.nominations.includes(
                      JSON.parse(localStorage.getItem("user")).id
                    )
                      ? `btn btn-outline-warning`
                      : `btn btn-outline-secondary`
                  }
                  onClick={() => {
                    selectedIdea?.nominations.includes(
                      JSON.parse(localStorage.getItem("user")).id
                    )
                      ? dispatch(unnominateIdea({ ideaId: ideaId }))
                      : dispatch(nominateIdea({ ideaId: ideaId }));
                  }}
                  disabled={
                    hasPermission_nominate_ideas() 
                      ? false
                      : true
                  }
                >
                  <i class="bi bi-star" style={{ marginRight: "0.5rem" }}></i>
                  Nominate
                </button>
                <button
                  className="btn btn-outline-secondary"
                  onClick={() => {
                    dispatch(updateselectedTest(null));
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#testIdeaModal"
                  // TODO: Permission Doubt
                  disabled={
                    hasPermission_create_ideas() 
                      ? false
                      : true
                  }
                >
                  <i
                    class="bi bi-check-circle"
                    style={{ marginRight: "0.5rem" }}
                  ></i>
                  Test Idea
                </button>
              </div>
            </div>

            <div>
              <div
                className="p-2 rounded"
                style={{ backgroundColor: "var(--bs-success)" }}
              >
                <h2 style={{ color: "var(--bs-green)" }}>
                  {selectedIdea?.score}
                </h2>
                <div className="body3 mt-2">
                  <div className="hstack mb-1">
                    <div style={{ minWidth: "7rem" }}>
                      <p className="m-0">Impact</p>
                    </div>
                    {selectedIdea?.impact}
                  </div>
                  <div className="hstack mb-1">
                    <div style={{ minWidth: "7rem" }}>
                      <p className="m-0">Confidence</p>
                    </div>
                    {selectedIdea?.confidence}
                  </div>
                  <div className="hstack">
                    <div style={{ minWidth: "7rem" }}>
                      <p className="m-0">Ease</p>
                    </div>
                    {selectedIdea?.ease}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-3">
            <p className="body3 mb-2 text-secondary">Goal</p>
            <p>{selectedIdea?.goal?.name}</p>
          </div>

          <hr />
          {/* Created On and Confidence meter */}
          <div className="row mt-3 mb-3">
            <div className="col-6">
              <div className="border-bottom h-100">
                <p className="body3 mb-2 text-secondary">Created On</p>
                <p>{formatTime(selectedIdea?.createdAt)}</p>
              </div>
            </div>

            <div className="col-6 border-bottom">
              <div className="border-bottom">
                <p className="body3 mb-2 text-secondary">Growth Lever</p>
                <div>
                  <p
                    className="rounded p-1 text-center"
                    style={{
                      maxWidth: "8rem",
                      backgroundColor: "var(--bs-success)",
                      color: "var(--bs-green)",
                    }}
                  >
                    {selectedIdea?.lever != ""
                      ? selectedIdea?.lever
                      : "Not Defined"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-3">
            <p className="body3 mb-2 text-secondary">Key Metric</p>
            <p>{selectedIdea?.keymetric?.name}</p>
          </div>
          <hr />

          <div className="mb-3">
            <p className="body3 mb-2 text-secondary">Description</p>
            <div
              dangerouslySetInnerHTML={{ __html: selectedIdea?.description }}
            ></div>
          </div>
          <hr />

          <div className="mb-3">
            <p className="body3 mb-2 text-secondary">Media</p>
            <div className="row gap-2" style={{ minHeight: "4rem" }}>
              {selectedIdea?.media.map((mediaUrl) => {
                return (
                  <FilePreview
                    url={`${backendServerBaseURL}/${mediaUrl}`}
                    key={mediaUrl}
                  />
                );
              })}
            </div>
          </div>

          <div className="row">
            <hr />

            {hasPermission_create_comments() &&
            <div className="border rounded ps-3 pe-3 pb-2 bg-white mb-2">
              <div className="hstack gap-3">
                <img
                  src={`${backendServerBaseURL}/${me.avatar}`}
                  className="avatar2"
                  alt=""
                />
                {projectUsers?.length != 0 && (
                  <MentionsInput
                    className="mentions w-100"
                    value={comment}
                    placeholder="Comment Here"
                    onChange={(e) => {
                      console.log(e.target.value);
                      setcomment(e.target.value);
                    }}
                  >
                    <Mention
                      className="mentions__mention"
                      markup="@{{__type__||__id__||__display__}}"
                      trigger="@"
                      renderSuggestion={(
                        entry,
                        search,
                        highlightedDisplay,
                        index,
                        focused
                      ) => {
                        return (
                          <div className="p-1 border-bottom d-flex align-items-center">
                            <img
                              src={`${backendServerBaseURL}/${projectUsers[index].avatar}`}
                              className="avatar2"
                              alt=""
                              style={{ marginRight: "0.3rem" }}
                            />
                            <p className="mb-0">
                              {projectUsers[index].firstName}{" "}
                              {projectUsers[index].lastName}
                            </p>
                          </div>
                        );
                      }}
                      data={projectUsers.map((pu) => {
                        return {
                          id: pu._id,
                          display: `@${pu.firstName} ${pu.lastName}`,
                        };
                      })}
                    />
                  </MentionsInput>
                )}
              </div>

              <div className="d-flex justify-content-end">
              <LoadingButton
                  loading={isSubmitting}
                  className="btn btn-lg btn-primary"
                  type="submit"   
                  onClick={async () => {
                    setisSubmitting(true);
                    await dispatch(addComment({ ideaId, comment }));
                    setcomment("");
                    setTimeout(() => {
                      setisSubmitting(false);
                    }, 200); 
                  }}
                  // TODO: Permission
                  disabled={
                    comment.length > 0 && hasPermission_create_comments()
                      ? false
                      : true
                  }
                >
                  Comment
                </LoadingButton>
              </div>
            </div>}

            {/* Saved Comments */}
            {projectUsers?.length != 0 && hasPermission_create_comments() && (
              <div className="bg-white border rounded">
                {selectedIdea?.comments.map((c) => {
                  return (
                    <div className="hstack gap-2 p-3 border-bottom">
                      <div>
                        <img
                          src={`${backendServerBaseURL}/${projectUsers[0].avatar}`}
                          className="avatar2"
                          alt=""
                          style={{ marginRight: "0.3rem" }}
                        />
                      </div>
                      {editingComment !== c._id && (
                        <div className="w-100">
                          <div className="d-flex">
                            <p className="body2 mb-0">{`${c.createdBy.firstName} ${c.createdBy.lastName}`}</p>
                            <div className="flex-fill"></div>
                            <p className="mb-0 pe-2 text-secondary">
                              {moment(new Date(c.createdAt)).fromNow()}
                            </p>
                            {hasPermission_create_comments()?
                            <div class="dropdown">
                              <div
                                data-bs-toggle="dropdown"
                                style={{ cursor: "pointer" }}
                              >
                                <i class="bi bi-three-dots-vertical cp text-secondary custom-more-icon-hover-effect" style={{ padding: "0.5rem" }}></i>
                              </div>

                              <ul className="dropdown-menu">
                                <li className="border-bottom">
                                  <a
                                    className="dropdown-item body3 regular-weight"
                                    onClick={() => {
                                      seteditingComment(c._id);
                                      setcomment2(c.comment);
                                    }}
                                  >
                                    Edit Comment
                                  </a>
                                </li>
                                <li className="border-bottom">
                                  <a
                                    className="dropdown-item body3 regular-weight"
                                    onClick={() => {
                                      dispatch(
                                        deleteComment({
                                          ideaId,
                                          commentId: c._id,
                                        })
                                      );
                                    }}
                                  >
                                    Delete Comment
                                  </a>
                                </li>
                              </ul>
                            </div>:<div></div>
                            }
                          </div>

                          <div className="d-flex">
                            {c.comment && swapTags(c.comment)}
                          </div>
                        </div>
                      )}

                      {editingComment === c._id && (
                        <div className="w-100">
                          <MentionsInput
                            className="mentions w-100"
                            value={comment2}
                            placeholder="Comment Here"
                            onChange={(e) => {
                              console.log(e.target.value);
                              setcomment2(e.target.value);
                            }}
                          >
                            <Mention
                              className="mentions__mention"
                              markup="@{{__type__||__id__||__display__}}"
                              trigger="@"
                              renderSuggestion={(
                                entry,
                                search,
                                highlightedDisplay,
                                index,
                                focused
                              ) => {
                                return (
                                  <div className="p-1 border-bottom d-flex align-items-center">
                                    <img
                                      src={`${backendServerBaseURL}/${projectUsers[index].avatar}`}
                                      className="avatar2"
                                      alt=""
                                      style={{ marginRight: "0.3rem" }}
                                    />
                                    <p className="mb-0">
                                      {projectUsers[index].firstName}{" "}
                                      {projectUsers[index].lastName}
                                    </p>
                                  </div>
                                );
                              }}
                              data={projectUsers.map((pu) => {
                                return {
                                  id: pu._id,
                                  display: `@${pu.firstName} ${pu.lastName}`,
                                };
                              })}
                            />
                          </MentionsInput>

                          <div className="d-flex justify-content-end">
                            <button
                              className="btn btn-outline-danger"
                              style={{ marginRight: "0.3rem" }}
                              onClick={() => {
                                seteditingComment(0);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                const commentData = c;
                                dispatch(
                                  updateComment({
                                    commentId: commentData._id,
                                    comment: comment2,
                                    ideaId,
                                  })
                                );
                                seteditingComment(0);
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        {/* Sidebar */}
        <div
          className="border-start p-3"
          style={{ minWidth: "15rem", maxHeight: "85vh" }}
        >
          <div className="mb-4">
            <button
              className="btn btn-outline-primary w-100"
              data-bs-toggle="modal"
              data-bs-target="#createNewIdeaDialog"
              onClick={() => {
                dispatch(updateSelectedIdea(selectedIdea));
              }}
              // TODO: Permission Doubt
              disabled={
                hasPermission_create_ideas() 
                  ? false
                  : true
              }
            >
              <i
                class="bi bi-pencil-fill text-primary"
                style={{ marginRight: "0.5rem" }}
              ></i>{" "}
              Edit Idea
            </button>
          </div>

          <div className="mb-4">
            <Members />
          </div>

          <div className="mb-4">{/* <Collaborators /> */}</div>
        </div>
      </div>

      <TestIdeaDialog />
      <CreateNewIdeaDialog />
    </div>
  );
}

export default IdeaInfo;

import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe, selectMe } from "../../redux/slices/generalSlice";
import { getAllProjects, selectProjects } from "../../redux/slices/projectSlice";
import { getAllUsers, selectAllUsers } from "../../redux/slices/settingSlice";
import { hasPermission_create_ideas, isRoleAdmin, isTypeOwner } from "../../utils/permissions";

function QuickStart() {
  const navigate = useNavigate();
  const projects = useSelector(selectProjects);
  const dispatch = useDispatch();
  const team = useSelector(selectAllUsers);
  const profileData = JSON.parse(localStorage.getItem("user", ""));
  const me = JSON.parse(localStorage.getItem("user", ""));
  const meAPI = useSelector(selectMe);

  useEffect(() => {
    dispatch(getAllProjects());
    dispatch(getAllUsers());
    dispatch(getMe());
  }, []);

  const completedQuickStartGuide = () => {
    return (
      (projects?.length != 0 ? 1 : 0) +
      (team?.length != 0 ? 1 : 0) +
      (!profileData?.firstName && !profileData?.lastName && !profileData?.email ? 0 : 1)
    );
  };

  return (
    <>
      <div className="page-body-widthout-sidebar">
        <div className="d-flex justify-content-center">
          <div>
            <h2 className="text-center mb-2">Quick Start Guide</h2>
            <p className="text-center text-secondary mb-4">
              Follow these simple steps to get your team & growth up and running
            </p>

            <div className="bg-white" style={{ minWidth: "40rem" }}>
              <div className="border p-3 d-flex align-items-center">
                <div className="flex-fill">
                  <p className="body1">Getting Started</p>
                </div>

                <div className="hstack gap-3">
                  <img src="/static/icons/quickstart/tik.svg" alt="" />
                  <p className="mb-0">{completedQuickStartGuide()} / 3</p>
                  <img src="/static/icons/quickstart/openArrow.svg" alt="" />
                </div>
              </div>

              {/* Complete your profile */}
              <div className="border p-3 d-flex align-items-center">
                <div className="flex-fill hstack gap-3">
                  <img
                    src={
                      !profileData?.firstName &&
                      !profileData?.lastName &&
                      !profileData?.email
                        ? "/static/icons/quickstart/completeProfile.svg"
                        : "/static/icons/quickstart/quickStartGreenTik.svg"
                    }
                    alt=""
                  />
                  <div>
                    <p className="body1">Complete your profile</p>
                    <p className="mb-0 text-secondary">
                      Start by completing your profile details
                    </p>
                  </div>
                </div>

                <div className="hstack gap-3">
                  <button
                    className="btn btn-primary"
                    style={{ minWidth: "9rem" }}
                    onClick={() => {
                      navigate("/settings");
                    }}
                  >
                    Complete Profile
                  </button>
                </div>
              </div>

              {/* Invite your team */}
              <div className="border p-3 d-flex align-items-center">
                <div className="flex-fill hstack gap-3">
                  <img
                    src={
                      projects?.length == 0
                        ? "/static/icons/quickstart/inviteTeam.svg"
                        : "/static/icons/quickstart/quickStartGreenTik.svg"
                    }
                    alt=""
                  />
                  <div>
                    <p className="body1">Invite your team</p>
                    <p className="mb-0 text-secondary">
                      Add emails, assign roles & invite
                    </p>
                  </div>
                </div>

                <div className="hstack gap-3">
                  <button
                    className="btn btn-primary"
                    style={{ minWidth: "9rem" }}
                    onClick={() => {
                      localStorage.setItem("openAddMemberDialog", "1");
                      navigate("/settings/users");
                    }}
                  >
                    Invite Team
                  </button>
                </div>
              </div>

              {/* Create your first project */}
              <div className="border p-3 d-flex align-items-center">
                <div className="flex-fill hstack gap-3">
                  <img
                    src={
                      projects?.length == 0
                        ? "/static/icons/quickstart/createProject.svg"
                        : "/static/icons/quickstart/quickStartGreenTik.svg"
                    }
                    alt=""
                  />
                  <div>
                    <p className="body1">Create your first project</p>
                    <p className="mb-0 text-secondary">
                      Add emails, assign roles & invite
                    </p>
                  </div>
                </div>

                <div className="hstack gap-3">
                  <button
                    className="btn btn-primary"
                    style={{ minWidth: "9rem" }}
                    onClick={() => {
                      localStorage.setItem("openNewProjectDialog", "1");
                      navigate("/projects");
                    }}
                  >
                    Create Project
                  </button>
                </div>
              </div>
            </div>

            <div className="bg-white mt-3" style={{ minWidth: "40rem" }}>
              <div className="border p-3 d-flex align-items-center">
                <div className="flex-fill">
                  <p className="body1">
                    Growth Process (Create a project to unlock)
                  </p>
                </div>

                <div className="hstack gap-3">
                  <img src="/static/icons/quickstart/tik.svg" alt="" />
                  <p className="mb-0">
                    {(meAPI?.quickstart?.create_goal ? 1 : 0) +
                      (meAPI?.quickstart?.create_idea ? 1 : 0) +
                      (meAPI?.quickstart?.create_learning ? 1 : 0) +
                      (meAPI?.quickstart?.create_test ? 1 : 0) +
                      (meAPI?.quickstart?.view_insights ? 1 : 0)}{" "}
                    / 5
                  </p>
                  <img src="/static/icons/quickstart/openArrow.svg" alt="" />
                </div>
              </div>

              {/* Create a goal */}
              <div className="border p-3 d-flex align-items-center">
                <div className="flex-fill hstack gap-3">
                  <img
                    src={
                      meAPI?.quickstart?.create_goal
                        ? "/static/icons/quickstart/quickStartGreenTik.svg"
                        : "/static/icons/quickstart/Goal.svg"
                    }
                    alt=""
                  />
                  <div>
                    <p className="body1">Create a goal</p>
                    <p className="mb-0 text-secondary">
                      Start by completing your profile details
                    </p>
                  </div>
                </div>

                <div className="hstack gap-3">
                  <button
                    className="btn btn-primary"
                    style={{ minWidth: "9rem" }}
                    disabled={
                      projects?.length == 0 ||
                      !me?.role?.permissions?.create_goals
                    }
                    onClick={() => {
                      navigate(
                        `/projects/${
                          projects?.length != 0 && projects[0]._id
                        }/goals`
                      );
                    }}
                  >
                    Create a Goal
                  </button>
                </div>
              </div>

              {/* Add Ideas For Goals */}
              <div className="border p-3 d-flex align-items-center">
                <div className="flex-fill hstack gap-3">
                  <img
                    src={
                      meAPI?.quickstart?.create_idea
                        ? "/static/icons/quickstart/quickStartGreenTik.svg"
                        : "/static/icons/quickstart/Idea.svg"
                    }
                    alt=""
                  />
                  <div>
                    <p className="body1">Add Ideas For Goals</p>
                    <p className="mb-0 text-secondary">
                      Start brainstorming with team by adding ideas
                    </p>
                  </div>
                </div>

                <div className="hstack gap-3">
                  <button
                    className="btn btn-primary"
                    style={{ minWidth: "9rem" }}
                    // TODO: Permission Doubt
                    disabled={
                      projects?.length == 0 ||
                      !me?.role?.permissions?.create_ideas ||
                      hasPermission_create_ideas()
                    }
                    onClick={() => {
                      navigate(
                        `/projects/${
                          projects?.length != 0 && projects[0]._id
                        }/ideas`
                      );
                    }}
                  >
                    Suggest Ideas
                  </button>
                </div>
              </div>

              {/* Test Ideas */}
              <div className="border p-3 d-flex align-items-center">
                <div className="flex-fill hstack gap-3">
                  <img
                    src={
                      meAPI?.quickstart?.create_test
                        ? "/static/icons/quickstart/quickStartGreenTik.svg"
                        : "/static/icons/quickstart/Test.svg"
                    }
                    alt=""
                  />
                  <div>
                    <p className="body1">Test Ideas</p>
                    <p className="mb-0 text-secondary">
                      Start brainstorming with team by adding ideas
                    </p>
                  </div>
                </div>

                <div className="hstack gap-3">
                  <button
                    className="btn btn-primary"
                    style={{ minWidth: "9rem" }}
                    disabled={
                      projects?.length == 0 ||
                      !me?.role?.permissions?.create_tests
                    }
                    onClick={() => {
                      navigate(
                        `/projects/${
                          projects?.length != 0 && projects[0]._id
                        }/tests`
                      );
                    }}
                  >
                    Go To Ideas
                  </button>
                </div>
              </div>

              {/* Acquire Learnings */}
              <div className="border p-3 d-flex align-items-center">
                <div className="flex-fill hstack gap-3">
                  <img
                    src={
                      meAPI?.quickstart?.create_learning
                        ? "/static/icons/quickstart/quickStartGreenTik.svg"
                        : "/static/icons/quickstart/Learnings.svg"
                    }
                    alt=""
                  />
                  <div>
                    <p className="body1">Acquire Learnings</p>
                    <p className="mb-0 text-secondary">
                      After a test completion, create learnings from the outcome
                    </p>
                  </div>
                </div>

                <div className="hstack gap-3">
                  <button
                    className="btn btn-primary"
                    style={{ minWidth: "9rem" }}
                    disabled={
                      projects?.length == 0 ||
                      !me?.role?.permissions?.create_learnings
                    }
                    onClick={() => {
                      navigate(
                        `/projects/${
                          projects?.length != 0 && projects[0]._id
                        }/learnings`
                      );
                    }}
                  >
                    Create a Learning
                  </button>
                </div>
              </div>

              {/* View Insights */}
              <div className="border p-3 d-flex align-items-center">
                <div className="flex-fill hstack gap-3">
                  <img
                    src={
                      meAPI?.quickstart?.view_insights
                        ? "/static/icons/quickstart/quickStartGreenTik.svg"
                        : "/static/icons/quickstart/Insights.svg"
                    }
                    alt=""
                  />
                  <div>
                    <p className="body1">View Insights</p>
                    <p className="mb-0 text-secondary">
                      Get all the essential insights at one place
                    </p>
                  </div>
                </div>

                <div className="hstack gap-3">
                  <button
                    className="btn btn-primary"
                    style={{ minWidth: "9rem" }}
                    disabled={projects?.length == 0}
                    onClick={() => {
                      navigate(
                        `/projects/${
                          projects?.length != 0 && projects[0]._id
                        }/insights`
                      );
                    }}
                  >
                    Go to Insights
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default QuickStart;

import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";

import { Form, FormikProvider } from "formik";
import { useEffect } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedKeyMetric, selectSingleGoalInfo, updateKeyMetric } from "../../../redux/slices/projectSlice";

function UpdateMetricDialog() {
  const [selectedTeamMembers, setselectedTeamMembers] = useState([1]);
  const [numberOfTeamMembersToShowInSelect, setnumberOfTeamMembersToShowInSelect] = useState(4);
  const teamMembersDropdown = useRef();
  const selectedKeyMetric = useSelector(selectSelectedKeyMetric);
  const singleGoalInfo = useSelector(selectSingleGoalInfo);
  const dispatch = useDispatch();
  const closeModalRef = useRef();
  const closeDialog = () => {
    closeModalRef.current.click();
  };

  const NewProjectSchema = Yup.object().shape({
    date: Yup.string().required("Date is required"),
    value: Yup.number().required("Value is required"),
  });

  const formik = useFormik({
    initialValues: {
      date: new Date().toISOString().substring(0, 10),
      value: "",
    },
    validateOnBlur: true,
    validationSchema: NewProjectSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log("updateKeyMetric values", values);
      setSubmitting(false);
      dispatch(updateKeyMetric({ ...values, closeDialog, keymetricId: selectedKeyMetric?._id, goalId: singleGoalInfo?._id }));
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values } = formik;

  return (
    <>
      {/* Create new Project Dialog */}
      <div>
        {/* Modal */}
        <div className="modal fade" id="updateMetricModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <div style={{ marginBottom: "24px" }}>
                      <h2>Update Metric</h2>
                      <p>Daily active users</p>
                    </div>

                    <div className="form-field">
                      <label className="form-label">Date</label>
                      <input type={"date"} {...getFieldProps("date")} className="form-control form-control-lg" placeholder="Enter Date" />
                      <span className="invalid-feedback" style={{ display: Boolean(touched.date && errors.date) ? "block" : "none" }}>
                        {errors.date}
                      </span>
                    </div>

                    <div className="form-field">
                      <label className="form-label">Value</label>
                      <input type={"number"} {...getFieldProps("value")} className="form-control form-control-lg" placeholder="Enter Value" />
                      <span className="invalid-feedback" style={{ display: Boolean(touched.value && errors.value) ? "block" : "none" }}>
                        {errors.value}
                      </span>
                    </div>

                    <div className="hstack gap-2 d-flex justify-content-end">
                      <button type="button" class="btn btn-lg btn-outline-danger" data-bs-dismiss="modal" ref={closeModalRef}>
                        Close
                      </button>
                      <button type="submit" class="btn btn-lg btn-primary">
                        Update Value
                      </button>
                    </div>
                  </Form>
                </FormikProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateMetricDialog;

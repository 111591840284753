import React from "react";
import { useNavigate } from "react-router-dom";

function SignedUpSuccessfully() {
  const navigate = useNavigate();

  return (
    <>
      <div className="page-body-widthout-sidebar">
        <div className="container">
          <div style={{ minHeight: "70vh" }} className="d-flex align-items-center justify-content-center flex-column">
            <p className="semi-bold-weight mb-1" style={{ fontSize: "24px" }}>
              Get Started with Ignite
            </p>
            <p className="body1"></p>
            <div>
              <p className="text-center mb-3">Let’s quickly get to know you!</p>
            </div>
            <div className="mb-3">
              <div className="hstack gap-2">
                <div className="bg-primary" style={{ minHeight: "0.2rem", minWidth: "5rem" }}></div>
                <div className="bg-primary" style={{ minHeight: "0.2rem", minWidth: "5rem" }}></div>
                <div className="bg-primary" style={{ minHeight: "0.2rem", minWidth: "5rem" }}></div>
              </div>
            </div>
            <div>
              <div className="bg-white border p-4 mb-3" style={{ minWidth: "24rem" }}>
                <p className="body1 bold-weight text-center mb-1">Successfully Signed up</p>
                <p className="text-center text-secondary mb-3">Redirecting you to dashboard...</p>
                <div className="d-flex justify-content-center">
                  <img src="/static/illustrations/signedUpSuccessfully.svg" alt="" />
                </div>
              </div>

              <div>
                <p
                  className="body1 text-danger text-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Cancel
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignedUpSuccessfully;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeUserRole, deleteRole, findARole, findRole, selectallRoles, selectselectedRole } from "../../../redux/slices/settingSlice";
import { v4 as uuidv4 } from "uuid";

function DeleteRoleDialog() {
  const closeRef = useRef();
  const dispatch = useDispatch();
  const [btnState, setbtnState] = useState(true);
  const selectedRole = useSelector(selectselectedRole);
  const singleRole = useSelector(findARole);
  console.log(singleRole, selectedRole);
  const allRoles = useSelector(selectallRoles);
  //  const checkRoles = allRoles.map((el) => el.name);
  console.log(selectedRole);

  const closeDialog = () => {
    closeRef.current.click();
  };

  useEffect(() => {
    dispatch(findRole());
  }, [selectedRole]);

  let filterData = allRoles.filter((el) => {
    return selectedRole?.name !== el.name;
  });

// console.log(filterData)

// console.log(filterData)

  // console.log(filterData)

  return (
    <>
      <div className="modal fade" id="deleteRoleDialog" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              {singleRole.length > 0 ? (
                <div>
                  {" "}
                  <h2 style={{ marginBottom: "8px" }}>Caution</h2>
                  <div style={{ textAlign: "justify" }} className="fw-bold text-decoration-underline">
                    Please ensure that you have carefully reviewed the implications of deleting this role and the impact on the affected users before
                    proceeding with this action.
                  </div>
                  <div className="mt-3" style={{ textAlign: "justify" }}>
                    <span className="text-danger">{singleRole.length} users are assigned to this role.</span>
                    <span>
                      {" "}
                      You can only delete a role to which no one is assigned. To delete this role kindly assign the users a different roles first.
                    </span>
                  </div>
                  <div className="container mt-3">
                    <table className="table table-borderless p-0 m-0">
                      <thead>
                        <tr>
                          <th scope="col">Users</th>
                          <th scope="col">Select New Role</th>
                        </tr>
                      </thead>
                      <tbody>
                        {singleRole.map((el, ind) => (
                          <tr key={uuidv4}>
                            <>
                              <td>
                                {el.firstName} {el.lastName}
                              </td>

                              <td>
                                <select
                                  className="form-select"
                                  defaultValue="" // Set the default value here
                                  onChange={(e) => {
                                    dispatch(
                                      changeUserRole({
                                        role: e.target.value,
                                        userId: el._id,
                                      })
                                    );
                                    e.target.value = ""
                                  }}
                                >
                                  <option value="" disabled>
                                    Select Role
                                  </option>
                                  {filterData.map((el, ind) => (
                                    <option key={`${ind}1234`} value={el._id}>
                                      {el.name}
                                    </option>
                                  ))}
                                </select>
                              </td>
                            </>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <div>
                  <h2 style={{ marginBottom: "8px" }}>Delete Role</h2>

                  <div className="form-field" style={{ textAlign: "justify" }}>
                    <span className="fw-bold">0 users are assigned to this role.</span> You can only delete a role which is no one is assigned. Are
                    you sure you want to delete this role?
                  </div>
                </div>
              )}

              <div className="hstack gap-2 d-flex justify-content-end mt-3">
                <button type="button" class="btn btn-lg btn-outline-primary" data-bs-dismiss="modal" ref={closeRef}>
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-lg btn-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteLeverDialog"
                  disabled={singleRole.length > 0}
                  onClick={() => {
                    dispatch(deleteRole({ closeDialog }));
                  }}
                >
                  Delete Role
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeleteRoleDialog;

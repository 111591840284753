import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { sendTestBackToIdeas } from "../../../redux/slices/projectSlice";

function SendBackToIdeasDialog() {
  const dispatch = useDispatch();
  const params = useParams();
  const testId = params.testId;
  const projectId = params.projectId;
  const navigate = useNavigate();

  return (
    <>
      <div>
        <div className="modal fade" id="sendBackToIdeaModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div style={{ marginBottom: "24px" }}>
                  <h2>Send Test Back To Idea</h2>
                  <p>This will remove tasks and members assigned</p>
                </div>

                <div className="hstack gap-2 d-flex justify-content-end">
                  <button type="button" class="btn btn-lg btn-outline-danger" data-bs-dismiss="modal">
                    Close
                  </button>
                  <button
                    type="submit"
                    class="btn btn-lg btn-primary"
                    onClick={() => {
                      dispatch(sendTestBackToIdeas({ testId, navigate, projectId }));
                    }}
                  >
                    Send Back To Ideas
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SendBackToIdeasDialog;

import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addTestComment,
  deleteTestComment,
  getProjectCollaborators,
  getProjectUsers,
  readSingleTest,
  selectProjectCollaboratos,
  selectProjectUsers,
  selectsingleTestInfo,
  updateIdeaInTest,
  updateSelectedIdea,
  updateselectedTest,
  updateTestComment,
  updateTestTaskStatus,
} from "../../../redux/slices/projectSlice";
import MoveToLearningDialog from "./MoveToLearningDialog";
import SendBackToIdeasDialog from "./SendBackToIdeasDialog";
import { formatDate2 } from "../../../utils/formatTime";
import CreateNewIdeaDialog from "../Ideas/CreateNewIdeaDialog";
import TestIdeaDialog from "../Ideas/TestIdeaDialog";
import { backendServerBaseURL } from "../../../utils/backendServerBaseURL";
import { swapTags } from "../../../utils/tag.js";
import moment from "moment";
import { MentionsInput, Mention } from "react-mentions";
import FilePreview from "../../../components/common/FilePreview";
import InviteCollaboratorsDialog from "../../Settings/InviteCollaboratorsDialog";
import { isTypeOwner, isRoleAdmin, isRoleMember, hasPermission_create_tests, hasPermission_create_ideas, hasPermission_create_comments } from "../../../utils/permissions";
import Spinner from "../../../components/common/Spinner";
import LoadingButton from "../../../components/common/LoadingButton";

function TestInfo() {
  const [ideasForWeeklySales, setideasForWeeklySales] = useState([1, 2, 3]);
  const [selectedMenu, setselectedMenu] = useState("Weekly Sales");
  const members = [];
  const params = useParams();
  const projectId = params.projectId;
  const testId = params.testId;
  const dispatch = useDispatch();
  const singleTestInfo = useSelector(selectsingleTestInfo);
  const openedProject = JSON.parse(localStorage.getItem("openedProject", ""));
  const me = JSON.parse(localStorage.getItem("user", ""));
  const projectCollaborators = useSelector(selectProjectCollaboratos);
  const ProjectsMenus = [
    {
      name: "Weekly Sales",
    },
    {
      name: "Monthly Revenue",
    },
  ];

  console.log("simgle --",singleTestInfo)
  const [comment, setcomment] = useState("");
  const [comment2, setcomment2] = useState("");
  const projectUsers = useSelector(selectProjectUsers);
  const [editingComment, seteditingComment] = useState(0);
  const [showLoader, setShowLoader] = useState(true);
  const [isSubmitting, setisSubmitting] = useState(false);


  useEffect(() => {
    dispatch(readSingleTest({ testId }));
    dispatch(getProjectUsers({ projectId }));
    dispatch(getProjectCollaborators({ projectId }));
    setTimeout(() => {
      setShowLoader(false); 
    }, 2000);
  }, []);

  return (
    <div>
      <div className="d-flex">
        {showLoader && <div
          style={{
            width: "100%",
            height: "100%",
            zIndex: "999",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            paddingRight: "300px",
            paddingBottom: "200px",
            background: "#FBFBFB"
          }}>
          <Spinner />
        </div>}
        <div className="flex-fill" style={{ marginRight: "5rem" }}>
          <div className="mb-3 d-flex">
            <div className="flex-fill">
              <p className="body3 text-secondary">
                {openedProject.name} / Tests
              </p>
              <h2>{singleTestInfo?.name}</h2>
              <div className="hstack gap-2 mt-2">
                <button
                  className="btn btn-outline-secondary"
                  data-bs-toggle="modal"
                  data-bs-target="#sendBackToIdeaModal"
                  // TODO: Permission Doubt
                  disabled={
                    hasPermission_create_ideas() 
                      ? false
                      : true
                  }
                >
                  <i
                    class="bi bi-lightbulb"
                    style={{ marginRight: "0.5rem" }}
                  ></i>
                  Send back to ideas
                </button>
                <button
                  className="btn btn-outline-secondary"
                  data-bs-toggle="modal"
                  data-bs-target="#createNewIdeaDialog"
                  onClick={() => {
                    dispatch(updateSelectedIdea(singleTestInfo));
                  }}
                  // TODO: Permission Doubt
                  disabled={
                    hasPermission_create_ideas() 
                      ? false
                      : true
                  }
                >
                  <i
                    class="bi bi-check-circle"
                    style={{ marginRight: "0.5rem" }}
                  ></i>
                  Edit Idea
                </button>
              </div>
            </div>

            <div>
              <div
                className="p-2 rounded"
                style={{ backgroundColor: "var(--bs-success)" }}
              >
                <h2 style={{ color: "var(--bs-green)" }}>
                  {singleTestInfo?.score}
                </h2>
                <div className="body3 mt-2">
                  <div className="hstack mb-1">
                    <div style={{ minWidth: "7rem" }}>
                      <p className="m-0">Impact</p>
                    </div>
                    {singleTestInfo?.impact}
                  </div>
                  <div className="hstack mb-1">
                    <div style={{ minWidth: "7rem" }}>
                      <p className="m-0">Confidence</p>
                    </div>
                    {singleTestInfo?.confidence}
                  </div>
                  <div className="hstack">
                    <div style={{ minWidth: "7rem" }}>
                      <p className="m-0">Ease</p>
                    </div>
                    {singleTestInfo?.ease}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-3">
            <p className="body3 mb-2 text-secondary">Goal</p>
            <p>{singleTestInfo?.goal?.name}</p>
          </div>

          <hr />
          {/* Created On and Confidence meter */}
          <div className="row mt-3 mb-3">
            <div className="col-6">
              <div className="border-bottom h-100">
                <p className="body3 mb-2 text-secondary">Duration</p>
                <p>
                  {formatDate2(singleTestInfo?.createdAt)} -{" "}
                  {formatDate2(singleTestInfo?.dueDate)}
                </p>
              </div>
            </div>

            <div className="col-6 border-bottom">
              <div className="border-bottom">
                <p className="body3 mb-2 text-secondary">Growth Lever</p>
                <div>
                  <p
                    className="rounded p-1 text-center"
                    style={{
                      maxWidth: "8rem",
                      backgroundColor: "var(--bs-success)",
                      color: "var(--bs-green)",
                    }}
                  >
                    {singleTestInfo?.lever}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-3">
            <p className="body3 mb-2 text-secondary">Key Metric</p>
            <p>{singleTestInfo?.keymetric?.name}</p>
          </div>
          <hr />

          <div className="mb-3">
            <p className="body3 mb-2 text-secondary">Description</p>
            <div
              dangerouslySetInnerHTML={{ __html: singleTestInfo?.description }}
            ></div>
          </div>
          <hr />

          <div className="mb-3">
            <p className="body3 mb-2 text-secondary">Media</p>
            <div className="row gap-2" style={{ minHeight: "4rem" }}>
              {singleTestInfo?.media.map((mediaUrl) => {
                return (
                  <FilePreview url={`${backendServerBaseURL}/${mediaUrl}`} />
                );
              })}
            </div>
          </div>

          <div className="mb-3">
            <p className="body3 mb-2 text-secondary">Tasks</p>
            {singleTestInfo?.tasks.map((task, index) => {
              return (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    defaultChecked={task.status}
                    onClick={(e) => {
                      dispatch(
                        updateTestTaskStatus({
                          status: e.target.checked,
                          taskId: task._id,
                        })
                      );
                    }}
                  />
                  <label className="form-check-label">{task.name}</label>
                </div>
              );
            })}
            {/* // TODO: Permission Doubt */}
            {hasPermission_create_tests()  ? (
              <p
                className="text-primary cp mb-0"
                data-bs-toggle="modal"
                data-bs-target="#testIdeaModal"
                onClick={() => {
                  dispatch(updateselectedTest(singleTestInfo));
                }}
              >
                Add task +
              </p>
            ) : (
              <p></p>
            )}
          </div>

          <div className="row">
            <hr />

           {hasPermission_create_comments() ? 
           <div className="border rounded ps-3 pe-3 pb-2 bg-white mb-2">
              <div className="hstack gap-3">
                <img
                  src={`${backendServerBaseURL}/${me.avatar}`}
                  className="avatar2"
                  alt=""
                />
                {projectUsers?.length != 0 && (
                  <MentionsInput
                    className="mentions w-100"
                    value={comment}
                    placeholder="Comment Here"
                    onChange={(e) => {
                      // console.log(e.target.value);
                      setcomment(e.target.value);
                    }}
                  >
                    <Mention
                      className="mentions__mention"
                      markup="@{{__type__||__id__||__display__}}"
                      trigger="@"
                      renderSuggestion={(
                        entry,
                        search,
                        highlightedDisplay,
                        index,
                        focused
                      ) => {
                        return (
                          <div className="p-1 border-bottom d-flex align-items-center">
                            <img
                              src={`${backendServerBaseURL}/${projectUsers[index].avatar}`}
                              className="avatar2"
                              alt=""
                              style={{ marginRight: "0.3rem" }}
                            />
                            <p className="mb-0">
                              {projectUsers[index].firstName}{" "}
                              {projectUsers[index].lastName}
                            </p>
                          </div>
                        );
                      }}
                      data={projectUsers.map((pu) => {
                        return {
                          id: pu._id,
                          display: `@${pu.firstName} ${pu.lastName}`,
                        };
                      })}
                    />
                  </MentionsInput>
                )}
              </div>

              <div className="d-flex justify-content-end">
                <LoadingButton
                  loading={isSubmitting}
                  className="btn btn-lg btn-primary"
                  type="submit"   
                  onClick={async () => {
                    setisSubmitting(true);
                   await dispatch(addTestComment({ testId, comment }));
                    setcomment("");
                    setTimeout(() => {
                      setisSubmitting(false);
                    }, 200); 
                  }}
                  // TODO: Permission
                  // style={comment.length > 0 ? {cursor: "pointer"} : {cursor: "not-allowed"}}
                  disabled={
                    comment.length > 0 && hasPermission_create_comments()
                      ? false
                      : true
                  }
                >
                  Comment
                </LoadingButton>
              </div>
            </div> : <div></div>}

            {/* Saved Comments */}
            {projectUsers?.length != 0 && hasPermission_create_comments() && (
              <div className="bg-white border rounded">
                {singleTestInfo?.comments.map((c) => {
                  return (
                    <div className="hstack gap-2 p-3 border-bottom">
                      <div>
                        <img
                          src={`${backendServerBaseURL}/${projectUsers[0].avatar}`}
                          className="avatar2"
                          alt=""
                          style={{ marginRight: "0.3rem" }}
                        />
                      </div>
                      {editingComment !== c._id && (
                        <div className="w-100">
                          <div className="d-flex">
                            <p className="body2 mb-0">{`${c.createdBy?.firstName} ${c.createdBy?.lastName}`}</p>
                            <div className="flex-fill"></div>
                            <p className="mb-0 pe-2 text-secondary">
                              {moment(new Date(c?.createdAt)).fromNow()}
                            </p>
                            {hasPermission_create_comments()?
                            <div class="dropdown">
                              <div
                                data-bs-toggle="dropdown"
                                style={{ cursor: "pointer" }}
                              >
                                <i class="bi bi-three-dots-vertical cp text-secondary custom-more-icon-hover-effect" style={{ padding: "0.5rem" }}></i>
                              </div>

                              <ul className="dropdown-menu">
                                <li className="border-bottom">
                                  <a
                                    className="dropdown-item body3 regular-weight"
                                    onClick={() => {
                                      seteditingComment(c._id);
                                      setcomment2(c?.comment);
                                    }}
                                  >
                                    Edit Comment
                                  </a>
                                </li>
                                <li className="border-bottom">
                                  <a
                                    className="dropdown-item body3 regular-weight"
                                    onClick={() => {
                                      dispatch(
                                        deleteTestComment({
                                          testId,
                                          commentId: c._id,
                                        })
                                      );
                                    }}
                                  >
                                    Delete Comment
                                  </a>
                                </li>
                              </ul>
                            </div>:<div></div>
                            
                          }
                          </div>

                          <div className="d-flex">
                            {c.comment && swapTags(c.comment)}
                          </div>
                        </div>
                      )}

                      {editingComment === c._id && (
                        <div className="w-100">
                          <MentionsInput
                            className="mentions w-100"
                            value={comment2}
                            placeholder="Comment Here"
                            onChange={(e) => {
                              console.log(e.target.value);
                              setcomment2(e.target.value);
                            }}
                          >
                            <Mention
                              className="mentions__mention"
                              markup="@{{__type__||__id__||__display__}}"
                              trigger="@"
                              renderSuggestion={(
                                entry,
                                search,
                                highlightedDisplay,
                                index,
                                focused
                              ) => {
                                return (
                                  <div className="p-1 border-bottom d-flex align-items-center">
                                    <img
                                      src={`${backendServerBaseURL}/${projectUsers[index].avatar}`}
                                      className="avatar2"
                                      alt=""
                                      style={{ marginRight: "0.3rem" }}
                                    />
                                    <p className="mb-0">
                                      {projectUsers[index].firstName}{" "}
                                      {projectUsers[index].lastName}
                                    </p>
                                  </div>
                                );
                              }}
                              data={projectUsers.map((pu) => {
                                return {
                                  id: pu._id,
                                  display: `@${pu.firstName} ${pu.lastName}`,
                                };
                              })}
                            />
                          </MentionsInput>

                          <div className="d-flex justify-content-end">
                            <button
                              className="btn btn-outline-danger"
                              style={{ marginRight: "0.3rem" }}
                              onClick={() => {
                                seteditingComment(0);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                const commentData = c;
                                dispatch(
                                  updateTestComment({
                                    commentId: commentData._id,
                                    comment: comment2,
                                    testId,
                                  })
                                );
                                seteditingComment(0);
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        {/* Sidebar */}
        <div
          className="border-start p-3"
          style={{ minWidth: "15rem", maxHeight: "85vh" }}
        >
          <div className="mb-4">
            <button
              className="btn btn-outline-primary w-100"
              data-bs-toggle="modal"
              data-bs-target="#testIdeaModal"
              onClick={() => {
                dispatch(updateselectedTest(singleTestInfo));
              }}
              // TODO: Permission Doubt
              disabled={
                hasPermission_create_tests() 
                  ? false
                  : true
              }
            >
              <i
                class="bi bi-pencil-fill text-primary"
                style={{ marginRight: "0.5rem" }}
              ></i>{" "}
              Edit Test
            </button>
          </div>

          <div className="mb-4">
            <div className="d-flex">
              <div className="flex-fill">
                <p>MEMBERS</p>
              </div>
            </div>

            {projectUsers.length === 0 && (
              <div className="d-flex align-items-center justify-content-center flex-column pt-3 pb-3">
                <img src="/static/illustrations/invite-users.svg" alt="" />
                <p className="body1 text-center regular-weight text-secondary mt-3">
                  Invite users, clients
                  <br /> to get feedback
                </p>
              </div>
            )}

            {singleTestInfo?.length !== 0 &&
              singleTestInfo?.assignedTo?.map((member) => {
                return (
                  <div className="border-bottom d-flex align-items-center p-1">
                    <img
                      src={`${backendServerBaseURL}/${member.avatar}`}
                      className="avatar2"
                      alt=""
                      style={{ marginRight: "0.5rem" }}
                    />

                    <div>
                      <p className="mb-0">
                        {member.firstName} {member.lastName}
                      </p>
                      <p className="mb-0">{member?.role?.name}</p>
                    </div>
                  </div>
                );
              })}
          </div>

          {/* <div className="mb-4">
            <div className="d-flex">
              <div className="flex-fill">
                <p>COLLABORATORS</p>
              </div>

              <div data-bs-toggle="modal" data-bs-target="#inviteCollaborators" style={{ cursor: "pointer" }}>
                <p>+</p>
              </div>
            </div>

            {projectCollaborators?.filter((member) => member.status !== "invited").length === 0 && (
              <div className="d-flex align-items-center justify-content-center flex-column pt-3 pb-3">
                <img src="/static/illustrations/invite-users.svg" alt="" />
                <p className="body1 text-center regular-weight text-secondary mt-3">
                  Invite users, clients
                  <br /> to get feedback
                </p>
              </div>
            )}

            {projectCollaborators?.length !== 0 &&
              projectCollaborators
                ?.filter((member) => member.status !== "invited")
                .map((member) => {
                  return (
                    <div className="border-bottom d-flex align-items-center p-1">
                      <img src={`${backendServerBaseURL}/${member.avatar}`} className="avatar2" alt="" style={{ marginRight: "0.5rem" }} />

                      <div>
                        <p className="mb-0">
                          {member.firstName} {member.lastName}
                        </p>
                        <p className="mb-0">{member?.role?.name}</p>
                      </div>
                    </div>
                  );
                })}
          </div> */}
        </div>
      </div>

      <MoveToLearningDialog />
      <SendBackToIdeasDialog />
      <CreateNewIdeaDialog />
      <TestIdeaDialog />
      <InviteCollaboratorsDialog />
    </div>
  );
}

export default TestInfo;

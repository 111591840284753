import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { createProject, editProject, getAllRegisteredUsers, selectRegisteredUsers, selectSelectedProject } from "../../redux/slices/projectSlice";
import { backendServerBaseURL } from "../../utils/backendServerBaseURL";

function CreateNewProjectDialog() {
  const [selectedTeamMembers, setselectedTeamMembers] = useState([]);
  const [numberOfTeamMembersToShowInSelect, setnumberOfTeamMembersToShowInSelect] = useState(3);
  const teamMembersDropdown = useRef();
  const dispatch = useDispatch();
  const closeModalRef = useRef();
  const users = useSelector(selectRegisteredUsers);
  const selectedProject = useSelector(selectSelectedProject);

  const NewProjectSchema = Yup.object().shape({
    name: Yup.string().required("Project Name is required"),
    description: Yup.string().required("Project description is required"),
  });

  const closeModal = () => {
    closeModalRef.current.click();
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validateOnBlur: true,
    validationSchema: NewProjectSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      console.log(values);
      setSubmitting(true);
      if (selectedProject) {
        dispatch(editProject({ ...values, closeModal, projectId: selectedProject._id, selectedTeamMembers }));
      } else {
        dispatch(createProject({ ...values, closeModal, selectedTeamMembers }));
      }
      setSubmitting(false);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, resetForm } = formik;

  const addTeamMember = (teamMember) => {
    console.log(selectedTeamMembers);
    let uniqueItems = [...new Set(selectedTeamMembers.concat([teamMember]))];
    setselectedTeamMembers(uniqueItems);
  };

  const removeSelectedTeamMember = (data) => {
    let tempTM = [];
    selectedTeamMembers.map((tm, index) => {
      if (tm.id != data.id) {
        tempTM.push(tm);
      }
    });
    console.log(tempTM);
    setselectedTeamMembers(tempTM);
  };

  window.addEventListener("click", function (e) {
    if (document.getElementById("teamMemberSelectDropdown")?.contains(e.target)) {
      // Clicked in box
    } else {
      teamMembersDropdown.current?.classList.remove("show");
    }
  });

  useEffect(() => {
    if (selectedProject) {
      formik.setValues({
        name: selectedProject.name,
        description: selectedProject.description,
      });
      setselectedTeamMembers(selectedProject.team);
    } else {
      formik.setValues({
        name: "",
        description: "",
      });
    }
  }, [selectedProject]);

  useEffect(() => {
    dispatch(getAllRegisteredUsers());
  }, []);

  return (
    <>
      <div className="modal fade" id="createProjectModal" tabIndex={-1} aria-labelledby="createProjectModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" style={{ minWidth: "45rem" }}>
          <div className="modal-content">
            <div className="modal-body">
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <h2 style={{ marginBottom: "24px" }}>{selectedProject ? "Edit" : "New"} Project</h2>

                  <div className="form-field">
                    <label className="form-label">Project Name</label>
                    <input type={"text"} {...getFieldProps("name")} className="form-control form-control-lg" placeholder="Enter Project Name" />
                    <span className="invalid-feedback" style={{ display: Boolean(touched.name && errors.name) ? "block" : "none" }}>
                      {errors.name}
                    </span>
                  </div>

                  <div className="form-field">
                    <label className="form-label">Description</label>
                    <textarea
                      rows="4"
                      type={"text"}
                      {...getFieldProps("description")}
                      className="form-control form-control-lg"
                      placeholder="Enter Description"
                    />
                    <span className="invalid-feedback" style={{ display: Boolean(touched.description && errors.description) ? "block" : "none" }}>
                      {errors.description}
                    </span>
                  </div>

                  <div className="form-field">
                    <label className="form-label">Team Members</label>
                    <div class="dropdown" id="teamMemberSelectDropdown">
                      <div
                        className="border d-flex justify-content-between align-items-center p-2 rounded"
                        // data-bs-toggle="dropdown"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          teamMembersDropdown.current.classList.toggle("show");
                        }}
                      >
                        {selectedTeamMembers?.length === 0 && <p className="m-0">Select team members</p>}

                        <span>
                          {selectedTeamMembers?.slice(0, numberOfTeamMembersToShowInSelect).map((teamMember) => {
                            return (
                              <span>
                                <span>
                                  <img
                                    src={`${backendServerBaseURL}/${teamMember.avatar}`}
                                    className="avatar3"
                                    alt=""
                                    style={{ marginRight: "0.5rem" }}
                                  />
                                </span>
                                <span style={{ marginRight: "0.5rem" }}>
                                  {teamMember.firstName} {teamMember.lastName}
                                </span>
                                <button
                                  type="button"
                                  class="btn-secondary btn-close"
                                  style={{ fontSize: "8px", marginRight: "12px" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    removeSelectedTeamMember(teamMember);
                                    // teamMembersDropdown.current.classList.add("hide");
                                    // teamMembersDropdown.current.classList.remove("show");
                                  }}
                                ></button>
                              </span>
                            );
                          })}

                          {selectedTeamMembers.length > numberOfTeamMembersToShowInSelect &&
                            `${selectedTeamMembers.length - numberOfTeamMembersToShowInSelect} Others`}
                        </span>

                        <img
                          src="/static/icons/down-arrow.svg"
                          alt=""
                          className="ml-auto"
                          height={"12px"}
                          width={"12px"}
                          style={{ marginRight: "0.5rem" }}
                        />
                      </div>

                      <ul
                        className="dropdown-menu w-100 customScrollBarLight"
                        style={{ maxHeight: "200px", overflowY: "scroll" }}
                        ref={teamMembersDropdown}
                      >
                        <li
                          className="list-group-item list-group-item-action border-bottom d-flex align-items-center p-2 cp"
                          onClick={() => {
                            setselectedTeamMembers(users);
                          }}
                        >
                          <span className="avatar" style={{ marginRight: "0.5rem" }}>
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{
                                minWidth: "24px",
                                maxWidth: "24px",
                                minHeight: "24px",
                                maxHeight: "24px",
                                backgroundColor: "var(--bs-gray-300)",
                                borderRadius: "50%",
                                fontSize: "12px",
                              }}
                            >
                              <span className="body2 regular-weight">{users?.length}</span>
                            </div>
                          </span>
                          <span className="body2 regular-weight">Everyone in your team</span>
                        </li>
                        {users?.map((teamMember) => {
                          return (
                            <li
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                addTeamMember(teamMember);
                              }}
                              className={
                                selectedTeamMembers?.includes(teamMember)
                                  ? "d-flex align-items-center list-group-item list-group-item-action cp p-2 active"
                                  : "d-flex align-items-center list-group-item list-group-item-action cp border-bottom p-2"
                              }
                            >
                              <span className="avatar" style={{ marginRight: "0.5rem" }}>
                                <img src={`${backendServerBaseURL}/${teamMember.avatar}`} width={24} height={24} />
                              </span>
                              <span className="body2 regular-weight flex-fill">
                                {teamMember?.firstName} {teamMember?.lastName}
                              </span>

                              {selectedTeamMembers?.includes(teamMember) && (
                                <div className="ml-auto">
                                  <button
                                    type="button"
                                    class="btn-secondary btn-close"
                                    style={{ fontSize: "8px", marginRight: "12px" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      removeSelectedTeamMember(teamMember);
                                    }}
                                  ></button>
                                </div>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>

                  <div className="hstack gap-2 d-flex justify-content-end">
                    <button
                      type="button"
                      class="btn btn-lg btn-outline-danger"
                      data-bs-dismiss="modal"
                      ref={closeModalRef}
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Close
                    </button>
                    <button type="submit" class="btn btn-lg btn-primary">
                      {selectedProject ? "Update Project" : "Create Project"}
                    </button>
                  </div>
                </Form>
              </FormikProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateNewProjectDialog;

import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import "react-multi-email/style.css";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { createGrowthLever, selectselectedGrowthLever, updateGrowthLever } from "../../../redux/slices/settingSlice";

function NewGrowthLeverDialog() {
  const [inviteEmails, setinviteEmails] = useState([]);
  const dispatch = useDispatch();
  const closeDialogRef = useRef();
  const selectedGrowthLever = useSelector(selectselectedGrowthLever);

  const closeDialog = () => {
    closeDialogRef.current.click();
  };

  const newRoleFormik = useFormik({
    initialValues: {
      leverName: "",
      color: "",
    },
    validationSchema: Yup.object().shape({
      leverName: Yup.string().required("Lever Name is required"),
      color: Yup.string().required("Color is required"),
    }),
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log(newRoleFormik.values);
      setSubmitting(true);
      if (selectedGrowthLever) {
        await dispatch(updateGrowthLever({ ...values, workspaceId: "633a6872ee23a833592cf0f0", closeDialog }));
      } else {
        await dispatch(createGrowthLever({ ...values, workspaceId: "633a6872ee23a833592cf0f0", closeDialog }));
      }
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (selectedGrowthLever) {
      newRoleFormik.setValues({
        leverName: selectedGrowthLever.name,
        color: selectedGrowthLever.color,
      });
    } else {
      newRoleFormik.setValues({
        leverName: "",
        color: "",
      });
    }
  }, [selectedGrowthLever]);

  return (
    <>
      <div className="modal fade" id="NewGrowthLeverDialog" tabIndex={-1} aria-labelledby="moveToLearningModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" style={{ minWidth: "50rem" }}>
          <div className="modal-content">
            <div className="modal-body">
              <FormikProvider value={newRoleFormik}>
                <Form autoComplete="off" noValidate onSubmit={newRoleFormik.handleSubmit}>
                  {/* Head */}
                  <div style={{ marginBottom: "24px" }}>
                    <h2>{selectedGrowthLever ? "Edit Growth Lever" : "New Growth Lever"}</h2>
                    <p>{selectedGrowthLever ? "Edit an existing growth lever" : "Create a new growth lever"}</p>
                  </div>

                  {/* Lever Name */}
                  <div className="form-field">
                    <label className="form-label">Lever Name</label>
                    <input
                      type={"text"}
                      {...newRoleFormik.getFieldProps("leverName")}
                      className="form-control form-control-lg"
                      placeholder="Enter Lever Name"
                    />
                    <span
                      className="invalid-feedback"
                      style={{ display: Boolean(newRoleFormik.touched.leverName && newRoleFormik.errors.leverName) ? "block" : "none" }}
                    >
                      {newRoleFormik.errors.leverName}
                    </span>
                  </div>

                  {/* Color */}
                  <div className="form-field">
                    <label className="form-label">Color</label>
                    <select
                      className="form-select"
                      value={newRoleFormik.values.color}
                      onChange={(e) => {
                        newRoleFormik.setFieldValue("color", e.target.value);
                      }}
                    >
                      <option value="">Select a color</option>
                      <option value="Blue">Blue</option>
                      <option value="Yellow">Yellow</option>
                      <option value="Orange">Orange</option>
                      <option value="Red">Red</option>
                      <option value="Green">Green</option>
                    </select>
                    <span
                      className="invalid-feedback"
                      style={{ display: Boolean(newRoleFormik.touched.color && newRoleFormik.errors.color) ? "block" : "none" }}
                    >
                      {newRoleFormik.errors.color}
                    </span>
                  </div>

                  {/* Action buttons */}
                  <div className="d-flex align-items-center">
                    <div className="flex-fill"></div>

                    <div className="hstack gap-2">
                      <button type="button" class="btn btn-lg btn-outline-danger" data-bs-dismiss="modal" ref={closeDialogRef}>
                        Close
                      </button>

                      <button
                        type="submit"
                        class={"btn btn-lg btn-primary"}
                        disabled={newRoleFormik.errors && Object.keys(newRoleFormik.errors)?.length != 0}
                      >
                        {selectedGrowthLever ? "Save Changed" : "Create"}
                      </button>
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewGrowthLeverDialog;

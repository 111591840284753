import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import "react-multi-email/style.css";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  createRole,
  selectallRoles,
  selectselectedRole,
  selectviewRole,
  updateRole,
  updateviewRole,
} from "../../../redux/slices/settingSlice";

function NewRoleDialog() {
  const [inviteEmails, setinviteEmails] = useState([]);
  const dispatch = useDispatch();
  const closeDialogRef = useRef();
  const selectedRole = useSelector(selectselectedRole);
  const viewRole = useSelector(selectviewRole);
  const allRoles = useSelector(selectallRoles);
  const checkRoles = allRoles.map((el) => el.name);
  const [warningMessage, setWarningMessage] = useState("");
  const [selectedPermission, setSelectedPermission] = useState("");
  // console.log(checkRoles)
  // console.log(selectedPermission)

  const closeDialog = () => {
    closeDialogRef.current.click();
  };

  const newRoleFormik = useFormik({
    initialValues: {
      roleName: "",

      accessToCompany: true,
      createEditAWorkspace: true,
      createActionPlans: true,
      createRoles: true,
      // shareIdeasLink: true,
      addUser: true,
      removeUser: true,
      createModels: true,
      // addRemoveCollaborators: true,

      createEditProject: true,
      deleteProject: true,

      createEditDeleteGoals: true,

      createEditDeleteIdeas: true,
      createNominateIdeas: true,

      createEditDeleteTests: true,

      createEditDeleteLearnings: true,

      canCommentAndMentionUsers: true,
      canUseEveryoneMention: true,
    },
    validationSchema: Yup.object().shape({
      roleName: Yup.string().required("Role Name is required"),
    }),
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      // console.log(newRoleFormik.values);
      setSubmitting(true);
      if (selectedRole) {
        dispatch(updateRole({ ...values, setErrors, closeDialog }));
      } else {
        dispatch(createRole({ ...values, setErrors, closeDialog }));
      }
      setSubmitting(false);
    },
  });

  // console.log(newRoleFormik.values)

  useEffect(() => {
    function checkInputValue() {
      const lowerCaseInputValue = newRoleFormik.values.roleName.toLowerCase();
      const lowerCaseArray = checkRoles.map((value) => value.toLowerCase());
      if (
        lowerCaseArray.includes(lowerCaseInputValue) &&
        newRoleFormik.touched.roleName === true
      ) {
        setWarningMessage("Role name already Exit");
      } else {
        setWarningMessage("");
      }
    }

    checkInputValue();
  }, [newRoleFormik.values.roleName, checkRoles]);

  const isDisabled = checkRoles
    .map((value) => value.toLowerCase())
    .includes(newRoleFormik.values.roleName.toLowerCase());

  // console.log(isDisabled)

  useEffect(() => {
    setSelectedPermission("");

    if (selectedRole) {
      if (
        selectedRole?.permissions.company_access &&
        selectedRole?.permissions.create_workspace &&
        selectedRole?.permissions.create_actionPlans &&
        selectedRole?.permissions.create_roles &&
        // selectedRole?.permissions.share_ideas &&
        selectedRole?.permissions.add_user &&
        selectedRole?.permissions.remove_user &&
        selectedRole?.permissions.create_models &&
        // selectedRole?.permissions.add_collaborators &&
        selectedRole?.permissions.create_project &&
        selectedRole?.permissions.delete_project &&
        selectedRole?.permissions.create_goals &&
        selectedRole?.permissions.create_goals &&
        selectedRole?.permissions.create_ideas &&
        selectedRole?.permissions.create_tests &&
        selectedRole?.permissions.create_learnings &&
        selectedRole?.permissions.create_comments &&
        selectedRole?.permissions.mention_everyone
      ) {
        setSelectedPermission("all");
      } else {
        setSelectedPermission("custom");
      }

      newRoleFormik.setValues({
        roleName: selectedRole.name,
        accessToCompany: selectedRole?.permissions.company_access,
        createEditAWorkspace: selectedRole?.permissions.create_workspace,
        createActionPlans: selectedRole?.permissions.create_actionPlans,
        createRoles: selectedRole?.permissions.create_roles,
        // shareIdeasLink: selectedRole?.permissions.share_ideas,
        addUser: selectedRole?.permissions.add_user,
        removeUser: selectedRole?.permissions.remove_user,
        createModels: selectedRole?.permissions.create_models,
        // addRemoveCollaborators: selectedRole?.permissions.add_collaborators,

        createEditProject: selectedRole?.permissions.create_project,
        deleteProject: selectedRole?.permissions.delete_project,

        createEditDeleteGoals: selectedRole?.permissions.create_goals,

        createEditDeleteIdeas: selectedRole?.permissions.create_ideas,
        createNominateIdeas: selectedRole?.permissions.nominate_ideas,

        createEditDeleteTests: selectedRole?.permissions.create_tests,

        createEditDeleteLearnings: selectedRole?.permissions.create_learnings,

        canCommentAndMentionUsers: selectedRole?.permissions.create_comments,
        canUseEveryoneMention: selectedRole?.permissions.mention_everyone,
      });
    } else {
      newRoleFormik.setValues({
        roleName: "",

        accessToCompany: true,
        createEditAWorkspace: true,
        createActionPlans: true,
        createRoles: true,
        // shareIdeasLink: true,
        addUser: true,
        removeUser: true,
        createModels: true,

        // addRemoveCollaborators: true,

        createEditProject: true,
        deleteProject: true,

        createEditDeleteGoals: true,

        createEditDeleteIdeas: true,
        createNominateIdeas: true,

        createEditDeleteTests: true,

        createEditDeleteLearnings: true,

        canCommentAndMentionUsers: true,
        canUseEveryoneMention: true,
      });
    }
  }, [selectedRole]);

  const handleAlert = () => {
    const alertElement = document.createElement("div");
    alertElement.className = "alert alert-success mt-3";
    alertElement.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-check-circle-fill me-2" viewBox="0 0 16 16">
        <path d="M7.5 1C3.91 1 1 3.91 1 7.5S3.91 14 7.5 14 14 11.09 14 7.5 11.09 1 7.5 1zm2.516 5.97a.53.53 0 0 1-.77 0L6.47 9.47l-1.47-1.47a.53.53 0 1 1 .76-.76L7 8.24l2.24-2.23a.53.53 0 1 1 .76.76l-2.23 2.23 2.23 2.23a.53.53 0 0 1 0 .77z"/>
      </svg>
      New Role Added!
    `;
    // alertElement.style.cssText = `width:fit-content;margin:auto;z-index:1000; `;
    document.getElementById("alert-container").appendChild(alertElement); // Append the alert element to a container div
    setTimeout(() => {
      alertElement.remove();
    }, 3000);
  };

  return (
    <>
      <div
        className="modal fade"
        id="NewRoleDialog"
        tabIndex={-1}
        aria-labelledby="moveToLearningModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ minWidth: "50rem" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <FormikProvider value={newRoleFormik}>
                <Form
                  autoComplete="off"
                  noValidate
                  onSubmit={newRoleFormik.handleSubmit}
                >
                  {/* Head */}
                  <div style={{ marginBottom: "24px" }}>
                    <h2>
                      {viewRole
                        ? "Role"
                        : selectedRole
                        ? "Edit Role"
                        : "New Role"}{" "}
                    </h2>
                    <p>Add a new role</p>
                  </div>

                  {/* Role Name */}
                  <div className="form-field">
                    <label className="form-label">Role Name</label>
                    <input
                      type={"text"}
                      {...newRoleFormik.getFieldProps("roleName")}
                      className="form-control form-control-lg"
                      placeholder="Enter Role Name"
                      disabled={viewRole}
                    />
                    <span
                      className="invalid-feedback"
                      style={{
                        display: Boolean(
                          newRoleFormik.touched.roleName &&
                            newRoleFormik.errors.roleName
                        )
                          ? "block"
                          : "none",
                      }}
                    >
                      {newRoleFormik.errors.roleName}
                    </span>
                    <p
                      style={{
                        color: "#DD3838",
                        padding: "5px",
                        marginLeft: "13px",
                        fontSize: "12px",
                      }}
                    >
                      {warningMessage}
                    </p>
                  </div>

                  {/* Permissions */}
                  <div className="form-field">
                    <label className="form-label">Permissions</label>
                    <select
                      disabled={viewRole}
                      className="form-select"
                      onChange={(e) => {
                        setSelectedPermission(e.target.value);
                        if (e.target.value == "all") {
                          newRoleFormik.setFieldValue("accessToCompany", true);
                          newRoleFormik.setFieldValue(
                            "createEditAWorkspace",
                            true
                          );
                          newRoleFormik.setFieldValue(
                            "createActionPlans",
                            true
                          );
                          newRoleFormik.setFieldValue("createRoles", true);
                          // newRoleFormik.setFieldValue("shareIdeasLink", true);
                          newRoleFormik.setFieldValue("addUser", true);
                          newRoleFormik.setFieldValue("removeUser", true);
                          newRoleFormik.setFieldValue("createModels", true);
                          // newRoleFormik.setFieldValue("addRemoveCollaborators", true);

                          newRoleFormik.setFieldValue(
                            "createEditProject",
                            true
                          );
                          newRoleFormik.setFieldValue("deleteProject", true);

                          newRoleFormik.setFieldValue(
                            "createEditDeleteGoals",
                            true
                          );

                          newRoleFormik.setFieldValue(
                            "createEditDeleteIdeas",
                            true
                          );
                          newRoleFormik.setFieldValue(
                            "createNominateIdeas",
                            true
                          );

                          newRoleFormik.setFieldValue(
                            "createEditDeleteTests",
                            true
                          );

                          newRoleFormik.setFieldValue(
                            "createEditDeleteLearnings",
                            true
                          );

                          newRoleFormik.setFieldValue(
                            "canCommentAndMentionUsers",
                            true
                          );
                          newRoleFormik.setFieldValue(
                            "canUseEveryoneMention",
                            true
                          );
                        } else if (e.target.value === "custom") {
                          newRoleFormik.setFieldValue("accessToCompany", false);
                          newRoleFormik.setFieldValue(
                            "createEditAWorkspace",
                            false
                          );
                          newRoleFormik.setFieldValue(
                            "createActionPlans",
                            false
                          );
                          newRoleFormik.setFieldValue("createRoles", false);
                          // newRoleFormik.setFieldValue("shareIdeasLink", false);
                          newRoleFormik.setFieldValue("addUser", false);
                          newRoleFormik.setFieldValue("removeUser", false);
                          newRoleFormik.setFieldValue("createModels", false);
                          // newRoleFormik.setFieldValue("addRemoveCollaborators", false);

                          newRoleFormik.setFieldValue(
                            "createEditProject",
                            false
                          );
                          newRoleFormik.setFieldValue("deleteProject", false);

                          newRoleFormik.setFieldValue(
                            "createEditDeleteGoals",
                            false
                          );

                          newRoleFormik.setFieldValue(
                            "createEditDeleteIdeas",
                            false
                          );
                          newRoleFormik.setFieldValue(
                            "createNominateIdeas",
                            false
                          );

                          newRoleFormik.setFieldValue(
                            "createEditDeleteTests",
                            false
                          );

                          newRoleFormik.setFieldValue(
                            "createEditDeleteLearnings",
                            false
                          );

                          newRoleFormik.setFieldValue(
                            "canCommentAndMentionUsers",
                            false
                          );
                          newRoleFormik.setFieldValue(
                            "canUseEveryoneMention",
                            false
                          );
                        }
                        // else {
                        //   return null;
                        // }
                      }}
                    >
                      <option selected defaultValue value="">
                        Select Permissions
                      </option>
                      <option value="all">All</option>
                      <option value="custom">Create New</option>
                    </select>
                    <p style={{ fontSize: "12px", marginTop: "5px" }}>
                      Select permissions from an existing role, or create new
                    </p>
                  </div>

                  {/* Permissions */}
                  {selectedPermission === "" ? (
                    <div></div>
                  ) : (
                    <div id="permissionParagraph" className="form-field">
                      <label className="form-label">Permissions</label>
                      <div className="row">
                        <div className="col-6">
                          <div className="form-field">
                            <label className="form-label">Admin</label>

                            <div className="form-check">
                              <input
                                disabled={viewRole}
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  newRoleFormik.values.accessToCompany == true
                                }
                                onChange={(e) => {
                                  newRoleFormik.setFieldValue(
                                    "accessToCompany",
                                    e.target.checked
                                  );
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckChecked"
                              >
                                Access to Company
                              </label>
                            </div>

                            <div className="form-check">
                              <input
                                disabled={viewRole}
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  newRoleFormik.values.createEditAWorkspace ==
                                  true
                                }
                                onChange={(e) => {
                                  newRoleFormik.setFieldValue(
                                    "createEditAWorkspace",
                                    e.target.checked
                                  );
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckChecked"
                              >
                                Create/Edit a Workspace
                              </label>
                            </div>

                            <div className="form-check">
                              <input
                                disabled={viewRole}
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  newRoleFormik.values.createActionPlans == true
                                }
                                onChange={(e) => {
                                  newRoleFormik.setFieldValue(
                                    "createActionPlans",
                                    e.target.checked
                                  );
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckChecked"
                              >
                                Create Action Plan
                              </label>
                            </div>

                            <div className="form-check">
                              <input
                                disabled={viewRole}
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  newRoleFormik.values.createRoles == true
                                }
                                onChange={(e) => {
                                  newRoleFormik.setFieldValue(
                                    "createRoles",
                                    e.target.checked
                                  );
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckChecked"
                              >
                                Create/Edit/Delete Roles
                              </label>
                            </div>

                            <div className="form-check">
                              <input
                                disabled={viewRole}
                                className="form-check-input"
                                type="checkbox"
                                checked={newRoleFormik.values.addUser == true}
                                onChange={(e) => {
                                  newRoleFormik.setFieldValue(
                                    "addUser",
                                    e.target.checked
                                  );
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckChecked"
                              >
                                Can add user
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                disabled={viewRole}
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  newRoleFormik.values.removeUser == true
                                }
                                onChange={(e) => {
                                  newRoleFormik.setFieldValue(
                                    "removeUser",
                                    e.target.checked
                                  );
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckChecked"
                              >
                                Can remove user
                              </label>
                            </div>

                            <div className="form-check">
                              <input
                                disabled={viewRole}
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  newRoleFormik.values.createModels == true
                                }
                                onChange={(e) => {
                                  newRoleFormik.setFieldValue(
                                    "createModels",
                                    e.target.checked
                                  );
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckChecked"
                              >
                                Create/Edit/Delete Models
                              </label>
                            </div>
                          </div>

                          <div className="form-field">
                            <label className="form-label">Project</label>

                            <div className="form-check">
                              <input
                                disabled={viewRole}
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  newRoleFormik.values.createEditProject == true
                                }
                                onChange={(e) => {
                                  newRoleFormik.setFieldValue(
                                    "createEditProject",
                                    e.target.checked
                                  );
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckChecked"
                              >
                                Create/Edit Project
                              </label>
                            </div>

                            <div className="form-check">
                              <input
                                disabled={viewRole}
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  newRoleFormik.values.deleteProject == true
                                }
                                onChange={(e) => {
                                  newRoleFormik.setFieldValue(
                                    "deleteProject",
                                    e.target.checked
                                  );
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckChecked"
                              >
                                Delete Project
                              </label>
                            </div>
                          </div>

                          <div className="form-field">
                            <label className="form-label">Goals</label>

                            <div className="form-check">
                              <input
                                disabled={viewRole}
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  newRoleFormik.values.createEditDeleteGoals ==
                                  true
                                }
                                onChange={(e) => {
                                  newRoleFormik.setFieldValue(
                                    "createEditDeleteGoals",
                                    e.target.checked
                                  );
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckChecked"
                              >
                                Create/Edit/Delete Goals
                              </label>
                            </div>
                          </div>

                          <div className="form-field">
                            <label className="form-label">Ideas</label>

                            <div className="form-check">
                              <input
                                disabled={viewRole}
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  newRoleFormik.values.createEditDeleteIdeas ==
                                  true
                                }
                                onChange={(e) => {
                                  newRoleFormik.setFieldValue(
                                    "createEditDeleteIdeas",
                                    e.target.checked
                                  );
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckChecked"
                              >
                                Can Create/Edit/Delete Ideas
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                disabled={viewRole}
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  newRoleFormik.values.createNominateIdeas ==
                                  true
                                }
                                onChange={(e) => {
                                  newRoleFormik.setFieldValue(
                                    "createNominateIdeas",
                                    e.target.checked
                                  );
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckChecked"
                              >
                                Nominate Ideas
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-field">
                            <label className="form-label">Tests</label>

                            <div className="form-check">
                              <input
                                disabled={viewRole}
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  newRoleFormik.values.createEditDeleteTests ==
                                  true
                                }
                                onChange={(e) => {
                                  newRoleFormik.setFieldValue(
                                    "createEditDeleteTests",
                                    e.target.checked
                                  );
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckChecked"
                              >
                                Can Create/Edit/Delete Tests
                              </label>
                            </div>
                          </div>

                          <div className="form-field">
                            <label className="form-label">Learnings</label>

                            <div className="form-check">
                              <input
                                disabled={viewRole}
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  newRoleFormik.values
                                    .createEditDeleteLearnings == true
                                }
                                onChange={(e) => {
                                  newRoleFormik.setFieldValue(
                                    "createEditDeleteLearnings",
                                    e.target.checked
                                  );
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckChecked"
                              >
                                Can Create/Edit/Delete Learnings
                              </label>
                            </div>
                          </div>

                          <div className="form-field">
                            <label className="form-label">Comments</label>

                            <div className="form-check">
                              <input
                                disabled={viewRole}
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  newRoleFormik.values
                                    .canCommentAndMentionUsers == true
                                }
                                onChange={(e) => {
                                  newRoleFormik.setFieldValue(
                                    "canCommentAndMentionUsers",
                                    e.target.checked
                                  );
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckChecked"
                              >
                                Ability to comment & mention users
                              </label>
                            </div>

                            <div className="form-check">
                              <input
                                disabled={viewRole}
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  newRoleFormik.values.canUseEveryoneMention ==
                                  true
                                }
                                onChange={(e) => {
                                  newRoleFormik.setFieldValue(
                                    "canUseEveryoneMention",
                                    e.target.checked
                                  );
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckChecked"
                              >
                                Ability to use @everyone mention
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* <div id="permissionParagraph" className="form-field">
                    <label className="form-label">Permissions</label>
                    <div className="row">
                      <div className="col-6">
                        <div className="form-field">
                          <label className="form-label">General</label>

                          <div className="form-check">
                            <input
                              disabled={viewRole}
                              className="form-check-input"
                              type="checkbox"
                              checked={
                                newRoleFormik.values.accessToCompany == true
                              }
                              onChange={(e) => {
                                newRoleFormik.setFieldValue(
                                  "accessToCompany",
                                  e.target.checked
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Access to Company
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              disabled={viewRole}
                              className="form-check-input"
                              type="checkbox"
                              checked={
                                newRoleFormik.values.createEditAWorkspace ==
                                true
                              }
                              onChange={(e) => {
                                newRoleFormik.setFieldValue(
                                  "createEditAWorkspace",
                                  e.target.checked
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Create/Edit a Workspace
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              disabled={viewRole}
                              className="form-check-input"
                              type="checkbox"
                              checked={
                                newRoleFormik.values.createActionPlans == true
                              }
                              onChange={(e) => {
                                newRoleFormik.setFieldValue(
                                  "createActionPlans",
                                  e.target.checked
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Create Docs
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              disabled={viewRole}
                              className="form-check-input"
                              type="checkbox"
                              checked={
                                newRoleFormik.values.shareIdeasLink == true
                              }
                              onChange={(e) => {
                                newRoleFormik.setFieldValue(
                                  "shareIdeasLink",
                                  e.target.checked
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Share Ideas Link
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              disabled={viewRole}
                              className="form-check-input"
                              type="checkbox"
                              checked={
                                newRoleFormik.values.addRemoveTeammates == true
                              }
                              onChange={(e) => {
                                newRoleFormik.setFieldValue(
                                  "addRemoveTeammates",
                                  e.target.checked
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Add/Remove Teammates
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              disabled={viewRole}
                              className="form-check-input"
                              type="checkbox"
                              checked={
                                newRoleFormik.values.addRemoveCollaborators ==
                                true
                              }
                              onChange={(e) => {
                                newRoleFormik.setFieldValue(
                                  "addRemoveCollaborators",
                                  e.target.checked
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Add/Remove Collaborators
                            </label>
                          </div>
                        </div>

                        <div className="form-field">
                          <label className="form-label">Project</label>

                          <div className="form-check">
                            <input
                              disabled={viewRole}
                              className="form-check-input"
                              type="checkbox"
                              checked={
                                newRoleFormik.values.createEditProject == true
                              }
                              onChange={(e) => {
                                newRoleFormik.setFieldValue(
                                  "createEditProject",
                                  e.target.checked
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Create/Edit Project
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              disabled={viewRole}
                              className="form-check-input"
                              type="checkbox"
                              checked={
                                newRoleFormik.values.deleteProject == true
                              }
                              onChange={(e) => {
                                newRoleFormik.setFieldValue(
                                  "deleteProject",
                                  e.target.checked
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Delete Project
                            </label>
                          </div>
                        </div>

                        <div className="form-field">
                          <label className="form-label">Goals</label>

                          <div className="form-check">
                            <input
                              disabled={viewRole}
                              className="form-check-input"
                              type="checkbox"
                              checked={
                                newRoleFormik.values.createEditDeleteGoals ==
                                true
                              }
                              onChange={(e) => {
                                newRoleFormik.setFieldValue(
                                  "createEditDeleteGoals",
                                  e.target.checked
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Create/Edit/Delete Goals
                            </label>
                          </div>
                        </div>

                        <div className="form-field">
                          <label className="form-label">Ideas</label>

                          <div className="form-check">
                            <input
                              disabled={viewRole}
                              className="form-check-input"
                              type="checkbox"
                              checked={
                                newRoleFormik.values.createEditDeleteIdeas ==
                                true
                              }
                              onChange={(e) => {
                                newRoleFormik.setFieldValue(
                                  "createEditDeleteIdeas",
                                  e.target.checked
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Can Create/Edit/Delete Ideas
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-field">
                          <label className="form-label">Tests</label>

                          <div className="form-check">
                            <input
                              disabled={viewRole}
                              className="form-check-input"
                              type="checkbox"
                              checked={
                                newRoleFormik.values.createEditDeleteTests ==
                                true
                              }
                              onChange={(e) => {
                                newRoleFormik.setFieldValue(
                                  "createEditDeleteTests",
                                  e.target.checked
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Can Create/Edit/Delete Tests
                            </label>
                          </div>
                        </div>

                        <div className="form-field">
                          <label className="form-label">Learnings</label>

                          <div className="form-check">
                            <input
                              disabled={viewRole}
                              className="form-check-input"
                              type="checkbox"
                              checked={
                                newRoleFormik.values
                                  .createEditDeleteLearnings == true
                              }
                              onChange={(e) => {
                                newRoleFormik.setFieldValue(
                                  "createEditDeleteLearnings",
                                  e.target.checked
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Can Create/Edit/Delete Learnings
                            </label>
                          </div>
                        </div>

                        <div className="form-field">
                          <label className="form-label">Comments</label>

                          <div className="form-check">
                            <input
                              disabled={viewRole}
                              className="form-check-input"
                              type="checkbox"
                              checked={
                                newRoleFormik.values
                                  .canCommentAndMentionUsers == true
                              }
                              onChange={(e) => {
                                newRoleFormik.setFieldValue(
                                  "canCommentAndMentionUsers",
                                  e.target.checked
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Ability to comment & mention users
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              disabled={viewRole}
                              className="form-check-input"
                              type="checkbox"
                              checked={
                                newRoleFormik.values.canUseEveryoneMention ==
                                true
                              }
                              onChange={(e) => {
                                newRoleFormik.setFieldValue(
                                  "canUseEveryoneMention",
                                  e.target.checked
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Ability to use @everyone mention
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div
                    id="alert-container"
                    className="position-fixed top-0 start-50 translate-middle-x"
                    style={{ zIndex: "1000" }}
                  ></div>

                  {viewRole ? (
                    <>
                      {/* Action buttons */}
                      <div className="d-flex align-items-center">
                        <div className="flex-fill"></div>

                        <div className="hstack gap-2">
                          <button
                            type="button"
                            class="btn btn-lg btn-outline-danger"
                            data-bs-dismiss="modal"
                            ref={closeDialogRef}
                          >
                            Close
                          </button>

                          {/* <button
                            type="button"
                            class={"btn btn-lg btn-primary"}
                            onClick={(e) => {
                              dispatch(updateviewRole(false));
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            Edit Role
                          </button> */}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* Action buttons */}
                      <div className="d-flex align-items-center">
                        <div className="flex-fill"></div>

                        <div className="hstack gap-2">
                          <button
                            type="button"
                            class="btn btn-lg btn-outline-danger"
                            data-bs-dismiss="modal"
                            ref={closeDialogRef}
                            // onClick={()=>setSelectedPermission("")}
                          >
                            Close
                          </button>

                          <button
                            type="submit"
                            class={"btn btn-lg btn-primary"}
                            onClick={handleAlert}
                            disabled={
                              !selectedRole &&
                              (isDisabled ||
                                (newRoleFormik.errors &&
                                  Object.keys(newRoleFormik.errors)?.length !==
                                    0))
                            }
                          >
                            {selectedRole ? "Save" : "Create Role"}
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </Form>
              </FormikProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewRoleDialog;

import React, { useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";

import { Form, FormikProvider } from "formik";
import { useDispatch } from "react-redux";
import { updatePassword } from "../../redux/slices/settingSlice";

function UpdatePasswordDialog() {
  const dispatch = useDispatch();
  const closeDialogRef = useRef();

  const closeDialog = () => {
    closeDialogRef.current.click();
  };
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
    },
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
      oldPassword: Yup.string().required("Old Password is required"),
      newPassword: Yup.string().required("New Password is required"),
    }),
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      console.log(values);
      setSubmitting(true);
      await dispatch(updatePassword({ ...values, setErrors, closeDialog }));
      setSubmitting(false);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, resetForm } = formik;

  return (
    <>
      <div className="modal fade" id="updatePasswordDialog" tabIndex={-1} aria-labelledby="deleteProjectDialogLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <h2 style={{ marginBottom: "16px" }}>Update Password</h2>

                  <div className="row">
                    <div class="mb-3 col-12">
                      <div>
                        <label className="form-label">Old Password</label>
                        <input {...getFieldProps("oldPassword")} type="text" className="form-control" placeholder="Enter Old Password" />
                      </div>
                    </div>

                    <div class="mb-3 col-12">
                      <div>
                        <label className="form-label">New Password</label>
                        <input {...getFieldProps("newPassword")} type="text" className="form-control" placeholder="Enter New Password" />
                      </div>
                    </div>
                  </div>

                  {/* Errors from server */}
                  {errors.afterSubmit && (
                    <div className="alert alert-danger" role="alert">
                      {errors.afterSubmit}
                    </div>
                  )}

                  <div className="hstack gap-2 d-flex justify-content-between align-items-center" style={{float: "right"}}>
                    {/* <p className="mb-0 text-primary">Forgot Password</p> */}

                    <div className="hstack gap-2 ">
                      <button type="button" class="btn btn-lg btn-outline-primary" data-bs-dismiss="modal" ref={closeDialogRef}>
                        Close
                      </button>
                      <button type="submit" class="btn btn-lg btn-danger">
                        Reset Password
                      </button>
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdatePasswordDialog;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import {
  editpointer,
  readPointer,
  selectsinglePointerInfo,
  getAllActionPlans,
} from "../../redux/slices/actionPlanSlice";
import { hasPermission_create_actionPlans } from "../../utils/permissions";

function ViewPointer() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const contentId = params.contentId;
  const singlePointerInfo = useSelector(selectsinglePointerInfo);
  const [value, setvalue] = useState(null);

  useEffect(() => {
    dispatch(
      readPointer({
        pointerId: contentId,
      })
    );
    dispatch(getAllActionPlans());
  }, [readPointer]);

  return (
    <div className="page-body-widthout-sidebar">
      <div className="container">
        <div>
          <div>
            <div
              className="d-flex align-items-center align-items-center"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/action-plans");
              }}
            >
              <i class="bi bi-arrow-left" style={{ marginRight: "0.3rem" }}></i>
              <p className="text-secondary mb-0 ml-1">All Action Plans</p>
            </div>

            <h1 className="mb-1 noselect">{singlePointerInfo.name}</h1>
          </div>

          <div
            dangerouslySetInnerHTML={{
              __html: singlePointerInfo?.data,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default ViewPointer;

import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { deleteGoal, deleteIdea, selectIdeas } from "../../../redux/slices/projectSlice";

function DeleteIdeaDialog() {
  const dispatch = useDispatch();
  const params = useParams();
  const projectId = params.projectId;
  const closeDialogRef = useRef();
  const ideas = useSelector(selectIdeas);
  console.log('ideas :>> ', ideas);

 

  const ideasData = ideas.filter((x) => !x.owner);
  console.log('ideasData :>> ', ideasData);

  const [errorMessage, setErrorMessage] = useState("");
  console.log('errorMessage :>> ', errorMessage);

  const closeDialgo = () => {
    setErrorMessage("");
    closeDialogRef.current.click();   
  };
  return (
    <>
      {/* Create new Project Dialog */}
      <div>
        <div className="modal fade" id="deleteIdeaDialog" tabIndex={-1}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h2 style={{ marginBottom: "16px" }}>Delete Idea</h2>

                <div className="form-field">
                  <span>Delete this Idea</span>
                </div>

 
                <div className="hstack gap-2 d-flex justify-content-end">
                    <>
                  <button type="button" class="btn btn-lg btn-outline-primary" data-bs-dismiss="modal" ref={closeDialogRef}
                   onClick={() => {
                    closeDialgo()
                   }}
                 >
                    Close
                  </button>
                  <button
                    type="submit"
                    class="btn btn-lg btn-danger"
                    onClick={() => {
                      dispatch(deleteIdea({ projectId, closeDialgo }), !ideasData.length ? setErrorMessage(<div style={{color: 'red'}}>{"You do not have access to delete idea"}</div>): setErrorMessage(""));
                    }}
                  >
                    Delete Idea
                  </button>
                  {/* {data.response.message} */}
                  </>                  
                </div>
                {errorMessage}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

 
}

export default DeleteIdeaDialog;

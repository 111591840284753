import React, { useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { Form, FormikProvider } from "formik";
import { deleteProject } from "../../redux/slices/projectSlice";
import { useDispatch } from "react-redux";
import { deleteActionPlan, deletepointer } from "../../redux/slices/actionPlanSlice";

function DeletePointerDialog() {
  const dispatch = useDispatch();
  const closeModalRef = useRef();

  const closeModal = () => {
    document.elementFromPoint(100, 100).click();
    closeModalRef.current.click();
  };

  return (
    <>
      <div className="modal fade" id="DeletePointerDialog" tabIndex={-1} aria-labelledby="deleteProjectDialogLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <h2 style={{ marginBottom: "16px" }}>Delete Pointer</h2>

              <div className="form-field">
                <span>Are you sure you want to delete this pointer?</span>
              </div>

              <div className="hstack gap-2 d-flex justify-content-end">
                <button
                  type="button"
                  class="btn btn-lg btn-outline-primary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-lg btn-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteProjectDialog"
                  onClick={() => {
                    dispatch(deletepointer({ closeModal }));
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeletePointerDialog;

import React from "react";
import { Outlet } from "react-router-dom";
import SettingSidebar from "./SettingSidebar";
import Toolbar from "./Toolbar";
import PopupMessage from "./PopupMessage";


function SettingsLayout({ socket }) {
  return (
    <div style={{ minHeight: "100vh" }}>
            <PopupMessage />

      <Toolbar socket={socket} />

      <div className="flex-fill d-flex">
        <SettingSidebar />

        <div className="page-body w-100">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default SettingsLayout;

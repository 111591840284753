import React from "react";
import { Outlet } from "react-router-dom";
import FunnelToolbar from "./FunnelToolbar";

function FunnelMainLayout() {
  return (
    <div style={{ marginTop: "4.5rem" }}>
      <FunnelToolbar />
      <Outlet />
    </div>
  );
}

export default FunnelMainLayout;

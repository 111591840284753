import { Form, FormikProvider, useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/style.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeUserRole, getAllRoles, getAllUsers, inviteUser, selectallRoles } from "../../redux/slices/settingSlice";
import { useRef } from "react";
import { useEffect } from "react";

function ChangeRoleDialog({ selectedUser }) {
  // console.log('selectedUser on Chnage Role :>> ', selectedUser._id);
  const [inviteEmails, setinviteEmails] = useState([]);
  const dispatch = useDispatch();
  const closeDialogRef = useRef();
  const roleRef = useRef();
  const allRoles = useSelector(selectallRoles);

  const closeDialog = () => {
    closeDialogRef.current.click();
  };

  const isEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    dispatch(getAllRoles());
  }, []);

  return (
    <>
      <div className="modal fade" id="changeRoleDialog" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              {/* Head */}
              <div style={{ marginBottom: "24px" }}>
                <h2>Change Role</h2>
                <p>Changing role can disable some features for the user.</p>
              </div>

              <div className="mb-3">
                <label htmlFor="" className="mb-1 semi-bold-weight">
                  Select a role
                </label>
                <select className="form-select" ref={roleRef}>
                  {allRoles.map((role, index) => {
                    return (
                      <option disabled={index===0} value={role._id} selected={role._id == selectedUser?.role?._id}>
                        {role.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              {/* Action buttons */}
              <div className="d-flex align-items-center">
                <div className="flex-fill"></div>

                <div className="hstack gap-2">
                  <button
                    type="button"
                    class="btn btn-lg btn-outline-danger"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setinviteEmails([]);
                    }}
                    ref={closeDialogRef}
                  >
                    Close
                  </button>

                  <button
                    type="submit"
                    class={"btn btn-lg btn-primary"}
                    onClick={() => {
                      dispatch(
                        changeUserRole({
                          role: roleRef.current.value,
                          userId: selectedUser._id,
                          closeDialog,
                          reload:true
                        })
                      );
                    }}
                  >
                    Update Role
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangeRoleDialog;

import React, { useState } from "react";
import "../../App.css"
import { backendServerBaseURL } from "../../utils/backendServerBaseURL";
function AvatarGroup({ listOfUrls, show, total,userName ,owner}) {
  // console.log("owner",owner)
  // console.log("username",userName)
   const [showModal, setShowModal] = useState(false);
   const [showDropdown, setShowDropdown] = useState(false);

   const handleModalClose = () => {
     setShowModal(false);
   };

   const handleModalShows = () => {

     setShowModal(true);
     setShowDropdown(false);
   };

   const handleMouseEnter = () => {
     setShowDropdown(true);
   };

   const handleMouseLeave = () => {
     setShowDropdown(false);
   };

  return (
    <div>
      <div
        className="d-flex dropdown dropdown1"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ width: "max-content" }}
      >
        {listOfUrls?.slice(0, show)?.map((url, index) => {
          return (
            <div>
              <span
                className="avatar"
                type="button"
                id="user-dropdown"
                aria-expanded={showDropdown}
                style={{
                  position: "relative",
                  left: `${-8 * index}px`,
                  zIndex: "100",
                }}
              >
                <img src={url} width={24} height={24} alt="logo" />
              </span>
            </div>
          );
        })}
        {total > show && (
          <span
            className="avatar"
            style={{ position: "relative", left: `${-8 * (show - 1)}px` }}
          >
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                minWidth: "24px",
                maxWidth: "24px",
                minHeight: "24px",
                maxHeight: "24px",
                backgroundColor: "var(--bs-gray-300)",
                borderRadius: "50%",
              }}
            >
              +{total - show}
            </div>
          </span>
        )}
        <ul className={`dropdown-menu ${showDropdown ? "show" : ""}`}>
          {userName?.length > 5 ? (
            <>
              {userName?.slice(0, 5)?.map((option, index) => (
                <li className="dropdown-item disabled" key={index}>
                  <div className="d-flex align-items-center gap-2">
                    <img src={option[1]} width={24} height={24} alt="user" />
                    <span>{option[0]}</span>
                  </div>
                </li>
              ))}
              <li
                className="dropdown-item mt-1"
                style={{ color: "blue" }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleModalShows();
                }}
              >
                Show More
              </li>
            </>
          ) : (
            userName?.map((option, index) => (
              <li className="dropdown-item disabled" key={index}>
                <div className="d-flex align-items-center gap-2">
                  <img src={option[1]} width={24} height={24} alt="user" />
                  <span>{option[0]}</span>
                </div>
              </li>
            ))
          )}
        </ul>
      </div>

      {userName?.length > 5 && showModal && (
        <>
          <div className="modal-backdrop show" />
          <div
            className="modal d-block"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <h2 className="mb-3">Members</h2>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        <div className="slider-container">
                          <div className="">
                            <div >
                              <div className="d-flex align-items-center gap-2 p-1">
                                <img
                                  src={`${backendServerBaseURL}/${owner.avatar}`}
                                  width={24}
                                  height={24}
                                  alt="user"
                                />
                                <span>
                                  {owner.firstName} {owner.lastName}
                                  {/* {option[0]} {option[2]} */}
                                </span>
                              </div>
                            </div>
                            {userName?.map((option, i) => (
                              <div key={i}>
                                <div className="d-flex align-items-center gap-2 p-1">
                                  <img
                                    src={option[1]}
                                    width={24}
                                    height={24}
                                    alt="user"
                                  />
                                  <span>
                                    {option[0]} {option[2]}
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-primary mt-3"
                      data-bs-dismiss="modal"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault()(handleModalClose());
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default AvatarGroup;

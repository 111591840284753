import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { deleteProfilePicture, updateProfile, updateProfilePicture, selectAllUsers, getAllUsers } from "../../redux/slices/settingSlice";
import UpdatePasswordDialog from "./UpdatePasswordDialog";
import { useDispatch, useSelector } from "react-redux";
import { backendServerBaseURL } from "../../utils/backendServerBaseURL";
 import { useEffect } from "react";
//  import {updatepopupMessage, selectpopupMessage} from "../../redux/slices/dashboardSlice";
// import { useEffect } from "react";
// import PopupMessage from "./../../layout/PopupMessage";
import { getMe , selectMe} from "../../redux/slices/generalSlice";


function Profile() {
  const [profileEditing, setprofileEditing] = useState(false);
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const roleRef = useRef();
  const aboutMeRef = useRef();
  const profilePictureFileRef = useRef();
  const dispatch = useDispatch();
  let team = useSelector(selectAllUsers);
  const user = useSelector(selectMe);
  console.log('user :>> ', user);
  

  useEffect(() => {
      dispatch(getAllUsers());
      dispatch(getMe());
      // const profileData = JSON.parse(localStorage.getItem("userData", ""));
      // setuserData(profileData);
      // if(memberRole){      
      //   dispatch(updateProfile(profileData));
      // }
  }, [])

  // useEffect(() => {
  //   console.log('localStorage.getItem("user", "") :>> ', localStorage.getItem("user", ""));
  //   console.log('meAPI :>> ', meAPI);
  //   console.log('JSON.stringify(meAPI) :>> ', JSON.stringify(meAPI));

  //   if(meAPI != null) {
  //     localStorage.setItem("user", JSON.stringify(meAPI));
  //   }
  // }, [meAPI]);

  return (
    <div>
      <input
        className="d-none"
        type="file"
        ref={profilePictureFileRef}
        onChange={(e) => {
          if (e.target.files.length !== 0) {
            dispatch(
              updateProfilePicture({
                file: e.target.files[0],
              })
            );
          }
        }}
      />

      <div className="d-flex">
        {/* {memberRole === profileData.role && <div>Role updated successfully !!!</div>} */}
        <div>
          <h1 className="mb-1">Profile</h1>
          <p className="text-secondary">Manage your personal profile</p>
        </div>

        <div className="flex-fill d-flex flex-row-reverse">
          <div className="hstack gap-3" style={{ padding: 0 }}>
            {/* <button type="button" className="btn btn-primary" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
              + New Goal
            </button> */}
          </div>
        </div>
      </div>

      <div style={{ maxWidth: "50%" }}>
        <div className="hstack gap-3">
          <img src={`${backendServerBaseURL}/${user?.avatar}`} alt="" className="avatar1" />
          <p
            className="mb-0"
            style={{ cursor: "pointer" }}
            onClick={() => {
              profilePictureFileRef.current.click();
            }}
          >
            Edit
          </p>
          <p
            style={{ cursor: "pointer" }}
            className="mb-0 text-danger"
            onClick={() => {
              dispatch(deleteProfilePicture());
            }}
          >
            Delete
          </p>
        </div>

        <div className="row">
          <div class="mt-3 col-6">
            <div>
              <label className="form-label">First Name</label>
              <input
                ref={firstNameRef}
                type="text"
                className="form-control"
                placeholder="Enter First Name"
                defaultValue={user?.firstName}
                disabled={!profileEditing}
              />
            </div>
          </div>

          <div class="mt-3 col-6">
            <div>
              <label className="form-label">Last Name</label>
              <input
                ref={lastNameRef}
                type="text"
                className="form-control"
                placeholder="Enter Last Name"
                defaultValue={user?.lastName}
                disabled={!profileEditing}
              />
            </div>
          </div>

          <div class="mt-3 col-12">
            <div>
              <label className="form-label">Email</label>
              <input
                ref={emailRef}
                type="text"
                className="form-control"
                placeholder="Enter Email"
                defaultValue={user?.email}
                disabled={!profileEditing}
              />
            </div>
          </div>

          <div class="mt-3 col-12">
            <div>
              <label className="form-label">Role</label>
              <input
                disabled={true}
                ref={roleRef}
                type="text"
                className="form-control"
                placeholder="Enter Role"
                defaultValue={user?.role?.name}
                //  defaultValue={profileData.role !== memberRole ? memberRole : profileData.role}
                // disabled={!profileEditing}
              />
            </div>
          </div>

          <div class="mt-3 col-12">
            <div>
              <label className="form-label">About Me</label>
              <textarea
                ref={aboutMeRef}
                rows={10}
                type="text"
                className="form-control"
                placeholder="Enter About Me"
                defaultValue={user?.about}
                disabled={!profileEditing}
              />
            </div>
          </div>

          <div class="mt-4 col-12">
            <div className="hstack gap-2">
              {profileEditing && (
                <>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      dispatch(
                        updateProfile({
                          firstName: firstNameRef.current.value,
                          lastName: lastNameRef.current.value,
                          email: emailRef.current.value,
                          designation: roleRef.current.value,
                          about: aboutMeRef.current.value,
                        })
                      );
                    }}
                  >
                    Save Changes
                  </button>
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => {
                      setprofileEditing(false);
                    }}
                  >
                    Cancel
                  </button>
                </>
              )}

              {!profileEditing && (
                <>
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => {
                      setprofileEditing(true);
                    }}
                  >
                    Edit Profile
                  </button>
                  <button className="btn btn-link" style={{ textDecoration: "none" }} data-bs-toggle="modal" data-bs-target="#updatePasswordDialog">
                    Update Password
                  </button>
                </>
              )}
            </div>
          </div>

          <UpdatePasswordDialog />
        </div>
      </div>
    </div>
  );
}

export default Profile;

import { Form, FormikProvider, useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import LoadingButton from "../components/common/LoadingButton";
import { getMe, selectMe } from "../redux/slices/generalSlice";
import {
  createProject,
  deleteProject,
  getAllProjects,
  selectAllProjects,
  selectScenarioId,
  selectsingleProject,
} from "../redux/slices/funnelProjectSlice";
import axiosInstance from "../utils/axios";
import { backendServerBaseURL } from "../utils/backendServerBaseURL";
import { blueprints } from "../utils/blueprints";
import "./FunnelToolbar.css";

function FunnelToolbar() {
  const [selectedMenu, setselectedMenu] = useState("User Management");
  const navigate = useNavigate();
  const menus = [];
  const dispatch = useDispatch();
  const createNewProjectCloseRef = useRef();
  const allPrjects = useSelector(selectAllProjects);
  const params = useParams();
  const projectId = params.projectId;
  const me = useSelector(selectMe);
  const closeDeleteprojectModalRef = useRef();
  const [selectedProject, setselectedProject] = useState(null);
  const scenarioId = useSelector(selectScenarioId);
  const singleProject = useSelector(selectsingleProject);

  const updateNodesAndEdges = (nodes, edges) => {
    // Update nodes on server
    axiosInstance
      .patch(
        `${backendServerBaseURL}/api/v1/funnel-project/${projectId}/nodes`,
        {
          nodes,
        }
      )
      .then((res) => {
        axiosInstance
          .patch(
            `${backendServerBaseURL}/api/v1/funnel-project/${projectId}/edges`,
            {
              edges,
            }
          )
          .then((res) => {
            window.location.reload();
          });
      });
  };

  const createBlueprintProject = (blueprintName, nodes, edges) => {
    axiosInstance
      .post(`${backendServerBaseURL}/api/v1/funnel-project/blueprint`, {
        title: blueprintName,
        nodes: JSON.stringify(nodes),
        edges: JSON.stringify(edges),
      })
      .then((res) => {
        let targetProject = res.data.payload.project;

        window.open(`/funnel/${targetProject._id}`, "_self");
      });
  };

  useEffect(() => {
    dispatch(getAllProjects());
    dispatch(getMe());
  }, []);

  const CreateNewProjectSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    processingRatePercent: Yup.number(),
    perTransactionFee: Yup.number(),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      processingRatePercent: 2.9,
      perTransactionFee: 0.3,
    },
    validationSchema: CreateNewProjectSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      setSubmitting(true);
      await dispatch(
        createProject({ ...values, setErrors, createNewProjectCloseRef })
      );
      setSubmitting(false);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <div
      className="border-bottom d-flex align-items-center"
      style={{
        paddingBottom: "0.5rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
      }}
    >
      <img src="/static/icons/logo.svg" alt="" className="me-2" />
      <h2>Funnel Visualizer</h2>

      <div className="flex-fill d-flex justify-content-center align-items-center">
        {menus.map((menu) => {
          return (
            <Link
              to={menu.link}
              style={{ textDecoration: "none" }}
              className="text-dark body2"
              onClick={() => {
                setselectedMenu(menu.name);
              }}
            >
              <div
                className={
                  selectedMenu === menu.name
                    ? "text-center border-bottom border-primary border-3"
                    : "text-center pb-1"
                }
                style={{ minWidth: "7rem" }}
              >
                <p className="mb-1">{menu.name}</p>
              </div>
            </Link>
          );
        })}
      </div>

      <div>
        <div className="d-flex align-items-center">
          <div
            style={{
              cursor: "pointer",
              fontSize: "1.5rem",
              marginRight: "1.5rem",
            }}
            data-bs-toggle="modal"
            data-bs-target="#createNewProjectModal"
          >
            <i class="fa-solid fa-plus"></i>
          </div>

          <div
            style={{
              cursor: "pointer",
              fontSize: "1.3rem",
              marginRight: "1.3rem",
            }}
            data-bs-toggle="offcanvas"
            data-bs-target="#versionssoffcanvasWithBothOptions"
          >
            <i class="fa-solid fa-clock-rotate-left"></i>
          </div>

          <div
            style={{
              cursor: "pointer",
              fontSize: "1.5rem",
              marginRight: "1.5rem",
            }}
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasWithBothOptions"
          >
            <i class="fa-regular fa-folder"></i>
          </div>

          <div
            style={{
              cursor: "pointer",
              fontSize: "1.5rem",
              marginRight: "1.5rem",
            }}
            data-bs-toggle="offcanvas"
            data-bs-target="#blueprintsoffcanvasWithBothOptions"
          >
            <i class="fa-regular fa-rectangle-list"></i>
          </div>
        </div>

        {/* Projects Offcanvas */}
        <div
          className="offcanvas offcanvas-end"
          data-bs-scroll="true"
          tabIndex={-1}
          id="offcanvasWithBothOptions"
          aria-labelledby="offcanvasWithBothOptionsLabel"
        >
          <div className="offcanvas-header align-items-start">
            <div>
              <h5
                className="offcanvas-title"
                id="offcanvasWithBothOptionsLabel"
              >
                Projects
              </h5>
              <p className="mb-0">
                All projects you have created or you are a part of will be
                listed
              </p>
            </div>
            <div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
          </div>
          <div className="offcanvas-body">
            {allPrjects.map((singleProject) => {
              return (
                <>
                  <div
                    className="d-flex ProjectsMenu p-2"
                    onClick={() => {
                      window.open(`/funnel/${singleProject._id}`, "_self");
                    }}
                  >
                    <div className="me-2">
                      <i class="fa-regular fa-folder"></i>
                    </div>

                    <div>
                      <h3>{singleProject.title}</h3>
                      <p style={{ minHeight: "0.7rem" }}>
                        {singleProject.description?.slice(0, 25)}
                      </p>
                    </div>

                    <div
                      className="ms-auto"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteProjectModal"
                      onClick={(e) => {
                        setselectedProject(singleProject);
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      <i class="fa-solid fa-trash"></i>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>

        {/* Blueprints Offcanvas */}
        <div
          className="offcanvas offcanvas-end"
          data-bs-scroll="true"
          tabIndex={-1}
          id="blueprintsoffcanvasWithBothOptions"
          aria-labelledby="blueprintsoffcanvasWithBothOptionsLabel"
        >
          <div className="offcanvas-header align-items-start">
            <div>
              <h5
                className="offcanvas-title"
                id="blueprintsoffcanvasWithBothOptionsLabel"
              >
                Blueprints
              </h5>
              <p className="mb-0">
                You can use blueprints to create projects quickly
              </p>
            </div>
            <div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
          </div>
          <div className="offcanvas-body">
            {blueprints.map((singleBluePrintData) => {
              return (
                <>
                  <div
                    className="d-flex ProjectsMenu p-3"
                    onClick={() => {
                      createBlueprintProject(
                        singleBluePrintData.bluePrintName,
                        singleBluePrintData.nodes,
                        singleBluePrintData.edges
                      );
                    }}
                  >
                    <div className="me-2">
                      <i class="fa-regular fa-rectangle-list"></i>
                    </div>

                    <div>
                      <h3>{singleBluePrintData.bluePrintName}</h3>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>

        {/* Versions offcanvas */}
        <div
          className="offcanvas offcanvas-end"
          data-bs-scroll="true"
          tabIndex={-1}
          id="versionssoffcanvasWithBothOptions"
          aria-labelledby="versionssoffcanvasWithBothOptionsLabel"
        >
          <div className="offcanvas-header align-items-start">
            <div>
              <h5
                className="offcanvas-title"
                id="versionssoffcanvasWithBothOptionsLabel"
              >
                Versions
              </h5>
              <p className="mb-0">
                Versions of opened project will be listed here
              </p>
            </div>
            <div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
          </div>
          <div className="offcanvas-body">
            {singleProject?.scenario
              ?.find((s) => s._id == scenarioId)
              ?.versions?.map((singleVersion) => {
                return (
                  <>
                    <div
                      className="d-flex ProjectsMenu p-3"
                      onClick={() => {
                        axiosInstance
                          .get(
                            `${backendServerBaseURL}/api/v1/funnel-project/${projectId}/scenario/${scenarioId}/version/${singleVersion._id}/use`,
                            {}
                          )
                          .then((res) => {
                            console.log(res);
                            window.location.reload();
                          });
                      }}
                    >
                      <div className="me-2">
                        <i class="fa-regular fa-rectangle-list"></i>
                      </div>

                      <div>
                        <h3 style={{ fontSize: "16px" }}>
                          {singleVersion.versionName}
                        </h3>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>

      {/* Create New Project Modal */}
      <div
        className="modal fade"
        id="createNewProjectModal"
        tabIndex={-1}
        aria-labelledby="createNewProjectModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="createNewProjectModalLabel">
                New Project
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={createNewProjectCloseRef}
              />
            </div>
            <div className="modal-body">
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  {/* Title */}
                  <div className="mb-4">
                    <label className="form-label">Title</label>
                    <input
                      {...getFieldProps("title")}
                      className="form-control form-control-lg"
                      placeholder="Project title"
                    />
                    <span
                      className="invalid-feedback"
                      style={{
                        display: Boolean(touched.title && errors.title)
                          ? "block"
                          : "none",
                      }}
                    >
                      {errors.title}
                    </span>
                  </div>

                  {/* Description */}
                  <div className="mb-4">
                    <label className="form-label">Description</label>
                    <div className="input-group">
                      <textarea
                        rows={6}
                        type={"text"}
                        {...getFieldProps("description")}
                        className="form-control form-control-lg"
                        placeholder="Enter description"
                      />
                    </div>

                    <span
                      className="invalid-feedback"
                      style={{
                        display: Boolean(
                          touched.description && errors.description
                        )
                          ? "block"
                          : "none",
                      }}
                    >
                      {errors.description}
                    </span>
                  </div>

                  {/* Processing Rate Percent */}
                  <div className="mb-4">
                    <label className="form-label">
                      Processing Rate Percent
                    </label>
                    <input
                      {...getFieldProps("processingRatePercent")}
                      className="form-control form-control-lg"
                      placeholder="Processing Rate Percent"
                    />
                    <span
                      className="invalid-feedback"
                      style={{
                        display: Boolean(
                          touched.processingRatePercent &&
                            errors.processingRatePercent
                        )
                          ? "block"
                          : "none",
                      }}
                    >
                      {errors.processingRatePercent}
                    </span>
                  </div>

                  {/* Per Transaction Fee */}
                  <div className="mb-4">
                    <label className="form-label">Per Transaction Fee</label>
                    <input
                      {...getFieldProps("perTransactionFee")}
                      className="form-control form-control-lg"
                      placeholder="Per Transaction Fee"
                    />
                    <span
                      className="invalid-feedback"
                      style={{
                        display: Boolean(
                          touched.perTransactionFee && errors.perTransactionFee
                        )
                          ? "block"
                          : "none",
                      }}
                    >
                      {errors.perTransactionFee}
                    </span>
                  </div>

                  {/* Errors from server */}
                  {errors.afterSubmit && (
                    <div className="alert alert-danger" role="alert">
                      {errors.afterSubmit}
                    </div>
                  )}

                  <div className="d-grid mt-3">
                    <LoadingButton
                      type="submit"
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      className="btn btn-lg btn-primary "
                    >
                      Create
                    </LoadingButton>
                  </div>
                </Form>
              </FormikProvider>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Project Modal */}
      <div
        className="modal fade"
        id="deleteProjectModal"
        tabIndex={-1}
        aria-labelledby="deleteProjectModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-danger"
                id="deleteProjectModalLabel"
              >
                Delete Project
              </h5>
            </div>
            <div className="modal-body">
              Are you sure you want to delete this project?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeDeleteprojectModalRef}
              >
                Cancel
              </button>

              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  dispatch(
                    deleteProject({
                      projectId: selectedProject?._id,
                      closeDeleteprojectModalRef,
                      openedProjectId: projectId,
                    })
                  );
                }}
              >
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FunnelToolbar;

import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { hasPermission_company_access} from "../utils/permissions";

function SettingSidebar() {
  const [selectedMenu, setselectedMenu] = useState("Profile");
  const params = useParams();

  const [menus, setmenus] = useState([
    { name: "Profile", icon: "profile.svg", link: `/settings/profile` },
    { name: "Users", icon: "users.svg", link: `/settings/users` },
    { name: "Roles", icon: "Roles.svg", link: `/settings/roles` },
    { name: "Workspace", icon: "workspace.svg", link: `/settings/workspace` },
    { name: "Notifications", icon: "Notifications.svg", link: `/settings/notifications` },
    // { name: "Company", icon: "Company.svg", link: `/settings/company` },
  ]);
  useEffect(() => {
    if (hasPermission_company_access()) {
      setmenus([...menus.slice(0, 6), { name: "Company", icon: "Company.svg", link: `/settings/company` }, ...menus.slice(6, 20)]);
    }
  }, []);

  return (
    <div className="project-sidebar border-end p-2">
      <h3 className="p-2 border-bottom pt-3 mb-2 regular-weight">Settings</h3>

      {menus.map((menu) => {
        return (
          <Link
            to={menu.link}
            onClick={() => {
              setselectedMenu(menu.name);
            }}
            className={
              "d-flex aling-items-center p-2 border-start cp " + (selectedMenu === menu.name ? "border-primary border-5" : "border-white border-5 onhover-menu-items")
            }
            style={{ textDecoration: "none", backgroundColor: selectedMenu === menu.name ? "var(--bs-primary-light)" : "" }}
          >
            <img src={`/static/icons/${menu.icon}`} style={{ marginRight: "0.4rem", marginLeft: "0.4rem" }} />
            <p className={"m-0 " + (selectedMenu === menu.name ? "text-dark" : "text-secondary")}>{menu.name}</p>
          </Link>
        );
      })}
    </div>
  );
}

export default SettingSidebar;

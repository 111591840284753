import { Form, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { useEffect } from "react";
import LoadingButton from "../../components/common/LoadingButton";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/slices/generalSlice";
import Alert from "../../components/common/Alert";
import { useNavigate } from "react-router-dom";
// import { loginsuperOwner } from "../redux/slices/adminSlice";

export default function Login() {
  const [passwordHide, setpasswordHide] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const RegisterSchema = Yup.object().shape({
    email: Yup.string().email("Enter valid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      setSubmitting(true);
      await dispatch(loginUser({ ...values, setErrors, navigate }));
      setSubmitting(false);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  useEffect(() => {
    if (localStorage.getItem("accessToken", null) !== null && localStorage.getItem("accessToken", null) !== undefined) {
      window.open("/dashboard", "_self");
    }
  }, []);

  return (
    <>
      <div style={{ minHeight: "80vh" }} className="d-flex align-items-center justify-content-center">
        <div className="container col-3">
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <div className="text-center">
                <div className="mb-4">
                  <h1 className="mb-2">Log in to Ignite</h1>
                  <p className="text-center">Fill in the following information</p>
                </div>
              </div>

              <div className="bg-white border rounded p-4">
                {/* Email */}
                <div className="mb-4">
                  <label className="form-label">Email</label>
                  <input {...getFieldProps("email")} className="form-control form-control-lg" placeholder="admin@email.com" />
                  <span className="invalid-feedback" style={{ display: Boolean(touched.email && errors.email) ? "block" : "none" }}>
                    {errors.email}
                  </span>
                </div>

                {/* Password */}
                <div className="mb-4">
                  <label className="form-label">Password</label>
                  <div className="input-group">
                    <input
                      type={passwordHide ? "password" : "text"}
                      {...getFieldProps("password")}
                      className="form-control form-control-lg"
                      placeholder="Enter Password"
                    />
                    <span className="input-group-text">
                      <i
                        className={passwordHide ? "bi bi-eye-slash" : "bi bi-eye"}
                        id="togglePassword"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setpasswordHide(!passwordHide);
                        }}
                      />
                    </span>
                  </div>

                  <span className="invalid-feedback" style={{ display: Boolean(touched.password && errors.password) ? "block" : "none", display:"flex"}}>
                    {errors.password}
                  </span>
                </div>

                {/* Errors from server */}
                {errors.afterSubmit && <Alert style={{display:"flex" }} value={errors.afterSubmit} variant="danger" />}

                <div className="d-grid mt-3">
                  <LoadingButton type="submit" loading={isSubmitting} className="btn btn-lg btn-primary ">
                    Sign in
                  </LoadingButton>
                </div>

                <p
                  className="text-danger mt-3 text-center mb-0"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/forgot-password");
                  }}
                >
                  Forgot Password?
                </p>
              </div>
            </Form>
          </FormikProvider>

          <p
            className="text-primary mt-3 text-center"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/signup");
            }}
          >
            New to Ignite? Sign Up
          </p>
        </div>
      </div>
    </>
  );
}

import React from "react";
import { useDispatch } from "react-redux";
import { removeUser } from "../../redux/slices/projectSlice";
import { useState } from "react";

function RemoveUserDialog({ selectedUser }) {
  const dispatch = useDispatch();

  const [btnState, setbtnState] = useState(true);
  let userIdLocal = localStorage.getItem("user")
  let objData = JSON.parse(userIdLocal);
  let storedUserId = objData.id;

  // console.log('selectedUser :>> ',  selectedUser.id);

 console.log('userIdLocal :>> ', userIdLocal);

   console.log('objData :>> ', typeof objData.id, objData.id);


  return (
    <>
      <div className="modal fade" id="removeUserDialog" tabIndex={-1} aria-labelledby="deleteProjectDialogLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <h2 style={{ marginBottom: "16px" }}>Remove User</h2>

              <div className="form-field">
              Removing User will erase both the user & data. If you want to retain the data (projects, goals, ideas, test & learnings) please check the following box
              </div>

              <div className="form-check mb-3 mt-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultValue
                  id="flexCheckDefault"
                  onChange={(e) => {
                    setbtnState(!e.target.checked);
                  }}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                I am sure I want to remove the user
                </label>
              </div>

              <div className="hstack gap-2 d-flex justify-content-end">
                <button type="button" class="btn btn-lg btn-outline-primary" data-bs-dismiss="modal">
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-lg btn-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#removeUserDialog"
                  disabled={btnState || storedUserId === selectedUser._id ? true : false}
                  onClick={() => { 
                    dispatch(
                        removeUser({
                        userId: selectedUser._id,
                      })
                    ) ;
                  }}
                >
                  Remove User
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RemoveUserDialog;

import React, { useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { Field, FieldArray } from "formik";
import { Form, FormikProvider } from "formik";
import { useParams } from "react-router-dom";
import { moveToLearning, selectsingleLearningInfo, selectselectedLearning, updateLearning } from "../../../redux/slices/projectSlice";
import { useDispatch, useSelector } from "react-redux";
import { backendServerBaseURL } from "../../../utils/backendServerBaseURL";
import { useEffect } from "react";
import LoadingButton from "../../../components/common/LoadingButton";

function MoveToLearningDialog() {
  const [mediaDocuments, setmediaDocuments] = useState([]);
  const mediaAndDocRef = useRef();
  const params = useParams();
  const projectId = params.projectId;
  const dispatch = useDispatch();
  const closeRef = useRef();
  const [isSubmitting, setisSubmitting] = useState(false);

  const closeDialog = () => {
    closeRef.current.click();
  };

  const aboutGoalFormik = useFormik({
    initialValues: {
      result: "",
      description: "",
    },
    validationSchema: Yup.object().shape({
      result: Yup.string().required("result is required"),
      description: Yup.string().required("description is required"),
    }),
      onSubmit: async (values, { setErrors, setSubmitting }) => {
        console.log(aboutGoalFormik.values);
        setSubmitting(false);
      },
    });

    const onSubmitAboutGoalsForm = async () => {
      console.log(aboutGoalFormik.values);
      // setSubmitting(false);
    await dispatch(moveToLearning({ projectId, ...aboutGoalFormik.values, files: mediaDocuments, closeDialog }));

      if(selectedLearning) {
        const id = !selectedLearning ? projectId : selectedLearning?._id;
        await dispatch(
          updateLearning({ ...aboutGoalFormik.values, files: mediaDocuments, deletedMedia , projectId , learningId: id, setmediaDocuments })
        );
        closeDialog();
    }
  };

  const resetAllFields = () => {
    aboutGoalFormik.resetForm();
  };

  function isFileImage(file) {
    const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png"];

    return file && acceptedImageTypes.includes(file["type"]);
  }

  const selectedLearning = useSelector(selectselectedLearning);
  console.log('selectedLearning :>> ', selectedLearning);

  useEffect(() => {
    if (selectedLearning) {
      aboutGoalFormik.setValues({
        result: selectedLearning.result,
        description: selectedLearning.conclusion,
        files: selectedLearning.media
      });
    } else {
      aboutGoalFormik.setValues({
        result: "",
        description: "",
        files: [],
      });
    }
    console.log('src={{...aboutGoalFormik.getFieldProps("files")}} :>> ', {...aboutGoalFormik.getFieldProps("files").value});
  }, [selectedLearning]);
  const singleLearningInfo = useSelector(selectsingleLearningInfo);
  console.log('singleLearningInfo :>> ', singleLearningInfo);

  const [mediaActionsOverlay, setmediaActionsOverlay] = useState(null);

  const [deletedMedia, setdeletedMedia] = useState([]);


  return (
    <>
      <div className="modal fade" id="moveToLearningModal" tabIndex={-1} aria-labelledby="moveToLearningModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <FormikProvider value={aboutGoalFormik}>
                <Form autoComplete="off" noValidate onSubmit={aboutGoalFormik.handleSubmit}>
                  {/* Head */}
                  <div style={{ marginBottom: "24px" }}>
                    {selectedLearning ? <h2>Edit Learning</h2> : <h2>Move to learning</h2>}
                    <p>Successful or not? share your experience with this test!</p>
                  </div>

                  {/* Name */}
                  <div className="form-field">
                    <label className="form-label">Result</label>

                    {!selectedLearning ?  <select
                      {...aboutGoalFormik.getFieldProps("result")}
                      className="form-control"
                      // value={aboutGoalFormik.values.result}
                      // onChange={(e) => {
                      //   alert(e.target.value);
                      //   aboutGoalFormik.setFieldValue("result", e.target.value);
                      // }}
                    >
                      <option value="">Select an outcome</option>
                      <option value="Successful">Successful</option>
                      <option value="Unsuccessful">Unsuccessful</option>
                      <option value="Inconclusive">Inconclusive</option>
                                              
                    </select>
                    :
                   <select                      
                    className="form-control"
                    {...aboutGoalFormik.getFieldProps("result")}
                   >               
                        <option value={selectedLearning.result}>{selectedLearning.result}</option>
                        {selectedLearning.result !== "Successful" && <option value="Successful">Successful</option>}
                        {selectedLearning.result !== "Unsuccessful" && <option value="Unsuccessful">Unsuccessful</option>}
                        {selectedLearning.result !== "Inconclusive" && <option value="Inconclusive">Inconclusive</option>}
                   </select>}



                    <span
                      className="invalid-feedback"
                      style={{ display: Boolean(aboutGoalFormik.touched.name && aboutGoalFormik.errors.name) ? "block" : "none" }}
                    >
                      {aboutGoalFormik.errors.name}
                    </span>
                  </div>

                  {/* Conclusion */}
                  <div className="form-field">
                    <label className="form-label">Learning</label>
                    {selectedLearning ? <textarea
                      rows="4"
                      type={"text"}
                      defaultValue={selectedLearning.conclusion}
                      {...aboutGoalFormik.getFieldProps("description")}
                      className="form-control form-control-lg"
                      placeholder="Enter Description"
                    />: <textarea
                      rows="4"
                      type={"text"}
                      {...aboutGoalFormik.getFieldProps("description")}
                      className="form-control form-control-lg"
                      placeholder="Enter Description"
                    />}
                    <span
                      className="invalid-feedback"
                      style={{ display: Boolean(aboutGoalFormik.touched.description && aboutGoalFormik.errors.description) ? "block" : "none" }}
                    >
                      {aboutGoalFormik.errors.description}
                    </span>
                  </div>

                  {/* Media & Documents */}
                  <div className="mb-3">
                    <label className="form-label">Media & Documents</label>
                    <input
                      className="d-none"
                      ref={mediaAndDocRef}
                      type="file"
                      multiple={true}
                      onChange={(e) => {
                        setmediaDocuments([...mediaDocuments, ...e.target.files]);
                        console.log(mediaDocuments);
                      }}
                    />
                    <div className="row border rounded m-0">
                    {selectedLearning?.media.filter(mediaUrl => deletedMedia.includes(mediaUrl) === false).map((mediaUrl) => {
                             return   <div
                                  onMouseEnter={() => {
                                    setmediaActionsOverlay(mediaUrl);
                                  }}
                                  onMouseLeave={() => {
                                    setmediaActionsOverlay(null);
                                  }}
                                  className="card p-0 cp col"
                                  style={{ minWidth: "25%", maxWidth: "25%" }}
                                >
                                  <div className="border rounded">
                                   <img 
                                  src={`${backendServerBaseURL}/${mediaUrl}`} 
                                  alt="" style={{ maxWidth: "100%" }} />             
 
                                    {mediaActionsOverlay === mediaUrl && (
                                      <div
                                        className="p-2 d-flex align-items-center justify-content-center"
                                        style={{
                                          position: "absolute",
                                          left: "0rem",
                                          bottom: "0px",
                                          width: "100%",
                                          height: "100%",
                                          backgroundColor: "rgba(0,0,0,0.6)",
                                        }}
                                      >
                                        <i
                                          class="bi bi-trash3-fill cp text-danger"
                                          style={{ fontSize: "1rem" }}
                                          onClick={() => {
                                            setdeletedMedia([...deletedMedia, mediaUrl]);                                        
                                          }}
                                        ></i>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                   })}

                      {/* {mediaDocuments.map((file) => {
                        return isFileImage(file) ? (
                          <div style={{ maxWidth: "25%" }} className="p-2">
                            <div className="border rounded">
                              <img src={URL.createObjectURL(file)} alt="" style={{ maxWidth: "100%" }} />
                            </div>
                          </div>
                        ) : (
                          <div style={{ maxWidth: "25%" }} className="p-2">
                            <div className="border rounded">
                              <p>File</p>
                            </div>
                          </div>
                        );
                      })} */}

                {mediaDocuments.map((file, index) => {
                              return isFileImage(file) ? (
                                <div
                                  onMouseEnter={() => {
                                    setmediaActionsOverlay(file.name);
                                  }}
                                  onMouseLeave={() => {
                                    setmediaActionsOverlay(null);
                                  }}
                                  className="card p-0 cp col"
                                  style={{ minWidth: "25%", maxWidth: "25%" }}
                                >
                                  <div className="border rounded">
                                    <img 
                                      src={URL.createObjectURL(file)} alt="" style={{ maxWidth: "100%" }} />

                                    {mediaActionsOverlay === file.name && (
                                      <div
                                        className="p-2 d-flex align-items-center justify-content-center"
                                        style={{
                                          position: "absolute",
                                          left: "0rem",
                                          bottom: "0px",
                                          width: "100%",
                                          height: "100%",
                                          backgroundColor: "rgba(0,0,0,0.6)",
                                        }}
                                      >
                                        <i
                                          class="bi bi-trash3-fill cp text-danger"
                                          style={{ fontSize: "1rem" }}
                                          onClick={() => {
                                            setmediaDocuments([
                                              ...mediaDocuments.slice(0, index),
                                              ...mediaDocuments.slice(index + 1, mediaDocuments.length),
                                            ]);
                                          }}
                                        ></i>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <div
                                  onMouseEnter={() => {
                                    setmediaActionsOverlay(file.name);
                                  }}
                                  onMouseLeave={() => {
                                    setmediaActionsOverlay(null);
                                  }}
                                  className="card p-0 cp col"
                                  style={{ minWidth: "25%", maxWidth: "25%" }}
                                >
                                  <div className="border rounded d-flex flex-column align-items-center justify-content-center h-100">
                                    <p className="body3 mb-1">File</p>

                                    <div className="d-flex align-items-center">
                                      <p className="body4">{file.name.length < 7 ? file.name : file.name.slice(0, 6) + "..."}</p>
                                      <p className="text-secondary body4 mb-0">
                                        {file.name.split(".")[file.name.split(".").length - 1].toUpperCase()}
                                      </p>
                                    </div>

                                    {mediaActionsOverlay === file.name && (
                                      <div
                                        className="p-2 d-flex align-items-center justify-content-center"
                                        style={{
                                          position: "absolute",
                                          left: "0rem",
                                          bottom: "0px",
                                          width: "100%",
                                          height: "100%",
                                          backgroundColor: "rgba(0,0,0,0.6)",
                                        }}
                                      >
                                        <i
                                          class="bi bi-trash3-fill cp text-danger"
                                          style={{ fontSize: "1rem" }}
                                          onClick={() => {
                                            setmediaDocuments([
                                              ...mediaDocuments.slice(0, index),
                                              ...mediaDocuments.slice(index + 1, mediaDocuments.length),
                                            ]);
                                          }}
                                        ></i>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            })}


                      <div style={{ maxWidth: "25%" }} className="p-2">
                        <div
                          className="cp d-flex align-items-center justify-content-center border rounded h-100"
                          onClick={() => {
                            mediaAndDocRef.current.click();
                          }}
                        >
                          <p className="m-0 text-primary" style={{ fontSize: "2rem" }}>
                            +
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Action buttons */}
                  <div className="d-flex align-items-center">
                    <div className="flex-fill"></div>

                    <div className="hstack gap-2">
                      <button
                        type="button"
                        class="btn btn-lg btn-outline-danger"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          resetAllFields();
                        }}
                        ref={closeRef}
                      >
                        Close
                      </button>

                      {!selectedLearning ? 
                        <LoadingButton
                          loading={isSubmitting}
                          type="button"
                          onClick={async () => {
                            setisSubmitting(true);
                            await onSubmitAboutGoalsForm();
                            setisSubmitting(false);
                          }}
                          class={"btn btn-lg " + (!aboutGoalFormik.isValid || !aboutGoalFormik.dirty ? "btn-secondary" : "btn-primary")}
                          disabled={!aboutGoalFormik.isValid || !aboutGoalFormik.dirty}
                        >
                        Create Learning
                        </LoadingButton> : <LoadingButton
                          loading={isSubmitting}
                          class={"btn btn-lg btn-primary"}
                          type="button"
                          onClick={async () => {
                            setisSubmitting(true);
                            await onSubmitAboutGoalsForm();
                            setisSubmitting(false);
                          }}>Update Learning
                          </LoadingButton>}
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MoveToLearningDialog;

import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";

import { Form, FormikProvider } from "formik";
import { useEffect } from "react";
import { useRef } from "react";
import { createModel, editModel, selectselectedModel, getSingleModel, selectsingleModelInfo } from "../../redux/slices/modelSlice";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

function CreateNewModelDialog() {
  const [selectedTeamMembers, setselectedTeamMembers] = useState([1]);
  const [numberOfTeamMembersToShowInSelect, setnumberOfTeamMembersToShowInSelect] = useState(4);
  const teamMembersDropdown = useRef();
  const selectedModel = useSelector(selectselectedModel);
  const dispatch = useDispatch();
  const closeDialogRef = useRef();
  const params = useParams();
  const modelId = params.modelId;
  console.log('modelId :>> ', modelId);
  const singleModelInfo = useSelector(selectsingleModelInfo);
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const closeModal = () => {
    closeDialogRef.current.click();
  };

  const NewProjectSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  // const redirectFunct = (modelId) => {
  //   window.location.pathname(`/models/${singleModelInfo._id}`);
  // }

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    
    validateOnBlur: true,
    validationSchema: NewProjectSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log(values);
      
     
      setSubmitting(false);

      if (selectedModel) {
        dispatch(
          editModel({
            modelId,
            name: values.name,
            // values: {},
            closeModal,
          }         
          ),

     );
        dispatch(
          getSingleModel({
            modelId,
          }),
        )      
      } else {
        dispatch(
          createModel({
            name: values.name,
            values: {},
            navigate,
            closeModal,
          }),
        );  
        resetValues();
        // redirectFunct(modelId);
      }
    },
    
  });
  
  useEffect(() => {
      formik.setValues({name: ''})
      if(modelId === undefined){
        resetValues();
      }
     if(singleModelInfo){
      formik.setValues({ name: singleModelInfo ? singleModelInfo.name : ''});
    }
    console.log('formik.values :>> ', formik.values);
  
}, [singleModelInfo])


  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values } = formik;
  console.log('getFieldProps :>> ', getFieldProps(values));

  window.addEventListener("click", function (e) {
    if (document.getElementById("teamMemberSelectDropdown").contains(e.target)) {
      // Clicked in box
    } else {
      teamMembersDropdown.current.classList.remove("show");
    }
  });

 const handleChange = (value) => {
     formik.values.name = value
    //  forceUpdate();
    console.log('selectedModel :>> ', selectedModel);
   console.log('formik.values.name ====  :>> ', value );
   console.log(' formik.values.name Change :>> ',  formik.values.name );

 }

 const resetValues = () => {
  document.getElementById('inpval').value = ""

  // formik.setValues({name: ''})
  // getFieldProps(values.name = "")
  formik.values.name = ""
  console.log('getFieldProps reset :>> ', getFieldProps(values));
  // singleModelInfo.name = "";

 }

  return (
    <>
      <div>
        {/* Modal */}
        <div className="modal fade" id="createNewModel" tabIndex={-1} aria-labelledby="createNewModelLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <h2 style={{ marginBottom: "24px" }}>{selectedModel ? "Edit" : "New"} Models</h2>

                    <div className="form-field">
                      <label className="form-label">Model Name</label>
                      <input type={"text"} name="name" id="inpval"
                        defaultValue={!selectedModel && formik.values.name ? formik.values.name : selectedModel ? singleModelInfo?.name : formik.values.name = ""}
               
                       onChange={(e) => handleChange(e.target.value)}
                       className="form-control form-control-lg"   placeholder="Enter Model Name" />
                      <span className="invalid-feedback" style={{ display: Boolean(touched.name && errors.name) ? "block" : "none" }}>
                        {errors.name}
                      </span>
                    </div>

                    <div className="hstack gap-2 d-flex justify-content-end">
                      <button type="button" class="btn btn-lg btn-outline-danger" data-bs-dismiss="modal" ref={closeDialogRef}>
                        Close
                      </button>
                      <button type="submit" class="btn btn-lg btn-primary">
                        {selectedModel && singleModelInfo ? "Save" : "Create Model"}
                      </button>
                    </div>
                  </Form>
                </FormikProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateNewModelDialog;

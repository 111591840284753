import { Field, FieldArray, Form, FormikProvider, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useReactFlow } from "reactflow";
import * as Yup from "yup";
import LoadingButton from "../../components/common/LoadingButton";
import {
  getSingleProject,
  selectScenarioId,
  selectsingleProject,
} from "../../redux/slices/funnelProjectSlice";
import { useEffect } from "react";

function NodeModal({ modalId, data, closeModalRef, nodeId, type }) {
  const { setNodes } = useReactFlow();
  const singleProject = useSelector(selectsingleProject);
  const dispatch = useDispatch();
  const params = useParams();
  const projectId = params.projectId;
  const scenarioId = useSelector(selectScenarioId);

  const formik = useFormik({
    initialValues: {
      name: data.name,
      conversionRate: data.conversionRate,
      traffic: data.traffic,
      cpc: data.cpc || 0,
      product: data?.product?._id || "",
      trafficSources: data.trafficSources || [],
      waitType: data.waitType || "days",
      waitDuration: data.waitDuration || 1,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      conversionRate: Yup.number()
        .required("Conversion rate is required")
        .max(100),
      traffic: Yup.number().required("Traffic is required"),
      cpc: Yup.number().required("CPC is required"),
      product: Yup.string().nullable(true),
      trafficSources: Yup.array().of(
        Yup.object().shape({
          trafficSource: Yup.string().required(),
          value: Yup.number().required(),
        })
      ),
      waitType: Yup.string().required(),
      waitDuration: Yup.number().required(),
    }),
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log(values);
      setSubmitting(true);
      // await dispatch(
      //   createProject({ ...values, setErrors, createNewProjectCloseRef })
      // );
      setSubmitting(false);

      setNodes((nodes) => {
        let updatedNodes = nodes.map((singleNode) => {
          if (singleNode.id === nodeId) {
            return {
              ...singleNode,
              data: {
                ...singleNode.data,
                name: values.name,
                conversionRate: values.conversionRate,
                traffic: values.trafficSources.reduce(
                  (acc, curr) => acc + (curr.value == "" ? 0 : curr.value),
                  0
                ),
                cpc: values.cpc,
                product: singleProject?.scenario
                  ?.find((s) => s._id === scenarioId)
                  ?.products?.find((product) => product._id === values.product),
                trafficSources: values.trafficSources,
                waitType: values.waitType,
                waitDuration: values.waitDuration,
              },
            };
          } else {
            return singleNode;
          }
        });

        return updatedNodes;
      });

      closeModalRef.current.click();

      setTimeout(() => {
        dispatch(getSingleProject({ projectId }));
      }, 1000);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values } =
    formik;

  // useEffect(() => {
  //   console.log(values);
  //   formik.setFieldValue(
  //     "traffic",
  //     data.trafficSources.reduce(
  //       (acc, curr) => acc + (curr.value == "" ? 0 : curr.value),
  //       0
  //     )
  //   );
  // }, [values]);

  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex={-1}
        aria-labelledby="createNewProjectModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="createNewProjectModalLabel">
                {data.name}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeModalRef}
              />
            </div>
            <div className="modal-body">
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  {/* Name */}
                  <div className="mb-4">
                    <label className="form-label">Name</label>
                    <input
                      {...getFieldProps("name")}
                      className="form-control form-control-lg"
                      placeholder="Name"
                    />
                    <span
                      className="invalid-feedback"
                      style={{
                        display: Boolean(touched.name && errors.name)
                          ? "block"
                          : "none",
                      }}
                    >
                      {errors.name}
                    </span>
                  </div>

                  {data.label !== "WaitNode node" && (
                    <>
                      {/* Conversion Rate */}
                      <div className="mb-4">
                        <label className="form-label">Conversion Rate</label>
                        <div className="input-group">
                          <input
                            type={"number"}
                            {...getFieldProps("conversionRate")}
                            className="form-control form-control-lg"
                            placeholder="Enter conversion rate"
                          />
                        </div>

                        <span
                          className="invalid-feedback"
                          style={{
                            display: Boolean(
                              touched.conversionRate && errors.conversionRate
                            )
                              ? "block"
                              : "none",
                          }}
                        >
                          {errors.conversionRate}
                        </span>
                      </div>

                      {/* Traffic */}
                      <div className="mb-4">
                        <label className="form-label">Traffic</label>
                        <div className="input-group">
                          <input
                            disabled={true}
                            type={"number"}
                            {...getFieldProps("traffic")}
                            className="form-control form-control-lg"
                            placeholder="Enter conversion rate"
                          />
                        </div>

                        <span
                          className="invalid-feedback"
                          style={{
                            display: Boolean(touched.traffic && errors.traffic)
                              ? "block"
                              : "none",
                          }}
                        >
                          {errors.traffic}
                        </span>
                      </div>
                    </>
                  )}

                  {/* Wait Type and Wait duration */}
                  {data.label === "WaitNode node" && (
                    <>
                      {/* Wait Type */}
                      <div className="mb-4">
                        <label className="form-label">Wait Type</label>
                        <div className="input-group">
                          <select
                            {...getFieldProps("waitType")}
                            className="form-control"
                          >
                            <option value="days">Days</option>
                            <option value="hours">Hours</option>
                            <option value="minutes">Minutes</option>
                          </select>
                        </div>

                        <span
                          className="invalid-feedback"
                          style={{
                            display: Boolean(
                              touched.waitType && errors.waitType
                            )
                              ? "block"
                              : "none",
                          }}
                        >
                          {errors.waitType}
                        </span>
                      </div>

                      {/* Wait Duration */}
                      <div className="mb-4">
                        <label className="form-label">Wait Duration</label>
                        <div className="input-group">
                          <input
                            type={"text"}
                            {...getFieldProps("waitDuration")}
                            className="form-control form-control-lg"
                            placeholder="Enter wait duration"
                          />
                        </div>

                        <span
                          className="invalid-feedback"
                          style={{
                            display: Boolean(
                              touched.waitDuration && errors.waitDuration
                            )
                              ? "block"
                              : "none",
                          }}
                        >
                          {errors.waitDuration}
                        </span>
                      </div>
                    </>
                  )}

                  {/* CPC & Traffic Sources */}
                  {data.label === "TrafficEntry node" && (
                    <>
                      <div className="mb-4">
                        <label className="form-label">CPC</label>
                        <div className="input-group">
                          <input
                            type={"number"}
                            {...getFieldProps("cpc")}
                            className="form-control form-control-lg"
                            placeholder="Enter conversion rate"
                          />
                        </div>

                        <span
                          className="invalid-feedback"
                          style={{
                            display: Boolean(touched.cpc && errors.cpc)
                              ? "block"
                              : "none",
                          }}
                        >
                          {errors.cpc}
                        </span>
                      </div>

                      <div className="mb-4">
                        <label className="form-label">Traffic Sources</label>

                        <FieldArray
                          name="trafficSources"
                          {...getFieldProps("trafficSources")}
                          render={(arrayHelpers) => (
                            <div>
                              {typeof values.trafficSources !== "string" &&
                                values.trafficSources.map((option, index) => (
                                  <div key={index}>
                                    <div className="row mb-3" mb={2}>
                                      <div className="col-11">
                                        <div className="row align-items-center">
                                          <div className="col-7">
                                            <select
                                              name={`trafficSources.${index}.trafficSource`}
                                              {...getFieldProps(
                                                `trafficSources.${index}.trafficSource`
                                              )}
                                              className="form-select form-control form-control-lg"
                                              placeholder="Type"
                                            >
                                              <option value="">
                                                Select Traffic Sources
                                              </option>
                                              <option value="Facebook">
                                                Facebook
                                              </option>
                                              <option value="Google">
                                                Google
                                              </option>
                                              <option value="Bing">Bing</option>
                                            </select>
                                            {/* <span
                                              className="invalid-feedback"
                                              style={{
                                                display: Boolean(
                                                  touched.type && errors.type
                                                )
                                                  ? "block"
                                                  : "none",
                                              }}
                                            >
                                              {errors.trafficSource}
                                            </span> */}
                                          </div>

                                          <div className="col-4">
                                            <Field
                                              placeholder={`Visitors`}
                                              name={`trafficSources.${index}.value`}
                                              type="number"
                                              className="form-control form-control-lg"
                                            />
                                            {/* <span
                                              className="invalid-feedback"
                                              style={{
                                                display:
                                                  Object.keys(errors).includes(
                                                    "trafficSources"
                                                  ) &&
                                                  errors[`trafficSources`]
                                                    ?.length != 0 &&
                                                  errors[`trafficSources`][
                                                    index
                                                  ]?.value
                                                    ? "block"
                                                    : "none",
                                              }}
                                            >
                                              Traffic source is required
                                            </span> */}
                                          </div>

                                          <div className="col-1 d-flex align-items-start">
                                            <i
                                              class="bi bi-trash3-fill text-danger p-4 d-flex align-items-center justify-content-center"
                                              style={{
                                                cursor: "pointer",
                                                width: 14,
                                                height: 14,
                                                border: "dark",
                                                borderRadius: "10px",
                                              }}
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            ></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}

                              {errors?.trafficSources && (
                                <p className="text-danger">
                                  Something goes wrong
                                </p>
                              )}

                              <button
                                onClick={() =>
                                  arrayHelpers.push({
                                    trafficSource: "",
                                    value: "",
                                  })
                                }
                                className="btn px-5"
                                style={{
                                  background: "transparent",
                                  color: "dark",
                                  border: "1px solid #345DED",
                                  fontWeight: 700,
                                  fontSize: "14.2527px",
                                }}
                              >
                                {/* <img
                                      src={
                                        themeContextValue.selectedTheme ===
                                        "dark"
                                          ? plus
                                          : plusFill
                                      }
                                      className="me-2"
                                    /> */}
                                Add New
                              </button>
                            </div>
                          )}
                        />
                      </div>
                    </>
                  )}

                  {/* Product */}
                  {type === "OrderFormPage" && (
                    <div className="mb-4">
                      <label className="form-label">Product</label>
                      <div className="input-group">
                        <select
                          className="form-select"
                          {...getFieldProps("product")}
                        >
                          <option value="">Select Product</option>

                          {singleProject?.scenario
                            ?.find((s) => s._id === scenarioId)
                            ?.products?.map((product) => (
                              <option
                                value={product._id}
                                selected={
                                  product?._id?.toString() ==
                                  data?.product?._id?.toString()
                                }
                              >
                                {product.name}
                              </option>
                            ))}
                        </select>
                      </div>

                      <span
                        className="invalid-feedback"
                        style={{
                          display: Boolean(touched.product && errors.product)
                            ? "block"
                            : "none",
                        }}
                      >
                        {errors.product}
                      </span>
                    </div>
                  )}

                  {/* Errors from server */}
                  {errors.afterSubmit && (
                    <div className="alert alert-danger" role="alert">
                      {errors.afterSubmit}
                    </div>
                  )}

                  <div className="d-grid mt-3">
                    <LoadingButton
                      type="submit"
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      className="btn btn-lg btn-primary "
                    >
                      Update
                    </LoadingButton>
                  </div>
                </Form>
              </FormikProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NodeModal;

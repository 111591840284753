import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAllLearnings,
  selectlearnings,
  updateselectedLearning,
} from "../../../redux/slices/projectSlice";
import DeleteLearningDialog from "./DeleteLearningDialog";
import SendBackToTestsDialog from "./SendBackToTestsDialog";
import { formatTime } from "../../../utils/formatTime";
import AvatarGroup from "../../../components/common/AvatarGroup";
import { backendServerBaseURL } from "../../../utils/backendServerBaseURL";
import TourModal from "../Tour/TourModal";
import {
  isTypeOwner,
  isRoleAdmin,
  isRoleMember,
  hasPermission_create_learnings,
} from "../../../utils/permissions";
import { selectallGrowthLevers } from "../../../redux/slices/settingSlice";

function Learnings() {
  const [selectedMenu, setselectedMenu] = useState("All Learnings");
  const learnings = useSelector(selectlearnings);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params.projectId;
  const openedProject = JSON.parse(localStorage.getItem("openedProject", ""));
  const allGrowthLevers = useSelector(selectallGrowthLevers);
console.log('allGrowthLevers Ideas :>> ', allGrowthLevers);
let leversData = allGrowthLevers.map((x) => {
return ({
 name:  x.name,
 color: x.color}
)
});
  const ProjectsMenus = [
    {
      name: "All Learnings",
    },
    {
      name: "Successful",
    },
    {
      name: "Unsuccessful",
    },
    {
      name: "Inconclusive",
    },
  ];

  const RightProjectsMenus = [];
  const [isLoading, setIsLoading] = useState(true); // Loading state flag
  const [showSkeletonLoader, setShowSkeletonLoader] = useState(true);

  useEffect(() => {
    dispatch(getAllLearnings({ projectId }));
    setTimeout(() => {
      setShowSkeletonLoader(false);
    }, 2000); 

    setTimeout(() => {
      setIsLoading(false);
    }, 2000); 
  }, [selectedMenu]);

  return (
    <div>
      <div className="d-flex">
        <div>
          <h1 className="mb-1">{openedProject?.name}</h1>
          <p className="text-secondary">
            {learnings?.length == 1
              ? `${learnings?.length} Learning`
              : `${learnings?.length} Learnings`}
          </p>
        </div>

        <div className="flex-fill d-flex flex-row-reverse">
          <div className="hstack gap-3" style={{ padding: 0 }}>
            {/* <button type="button" className="btn btn-primary" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
              + Suggest Ideas
            </button> */}
          </div>
        </div>
      </div>

      <div className="border-bottom mt-3">
        <div className="flex-fill d-flex align-items-center">
          {ProjectsMenus.map((menu) => {
            return (
              <div
                style={{ textDecoration: "none" }}
                className="text-dark body3 regular-weight cp"
                onClick={() => {
                  setselectedMenu(menu.name);
                }}
              >
                <div
                  className={
                    selectedMenu === menu.name
                      ? "text-center border-bottom border-primary border-3"
                      : "text-center pb-1"
                  }
                  style={{ minWidth: "7rem" }}
                >
                  <p className="mb-1">{menu.name}</p>
                </div>
              </div>
            );
          })}

          <div className="flex-fill ml-auto"></div>

          {RightProjectsMenus.map((menu) => {
            return (
              <div
                style={{ textDecoration: "none" }}
                className="text-dark body3 regular-weight cp"
                onClick={() => {
                  setselectedMenu(menu.name);
                }}
              >
                <div
                  className={
                    selectedMenu === menu.name
                      ? "text-center border-bottom border-primary border-3"
                      : "text-center pb-1"
                  }
                  style={{ minWidth: "7rem" }}
                >
                  <p className="mb-1">{menu.name}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

    

{learnings?.length === 0 && !isLoading ? (  
      <table className="table table-borderless mt-2 custom-table-sm">
        <thead className="border-none">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <tr style={{ width: "100%" }}>
              <td className="text-secondary body3 regular-weight" style={{ width: "90px", maxWidth: "20%" }}>
                Name
              </td>
            </tr>
            <tr style={{ width: "100%" }}>
              <td className="text-secondary body3 regular-weight" style={{ width: "100px", maxWidth: "20%" }}>
                Duration
              </td>
            </tr>
            <tr style={{ width: "100%" }}>
              <td className="text-secondary body3 regular-weight" style={{ width: "100px", maxWidth: "20%" }}>
                Members
              </td>
            </tr>
            <tr style={{ width: "100%" }}>
              <td className="text-secondary body3 regular-weight" style={{ width: "10px", maxWidth: "20%" }}>
                Ideas
              </td>
            </tr>
            <tr style={{ width: "100%" }}>
              <td className="text-secondary body3 regular-weight" style={{ width: "100px", maxWidth: "20%" }}>
                Status/Progress
              </td>
            </tr>
          </div>
        </thead>
        <tbody style={{ width: "100%" }}>
          {showSkeletonLoader ? (
            <tr
              style={{
                cursor: "pointer",
                marginTop: "10px",
                background: "#D6D6D6",
                border: "1px solid #C0C0C0",
              }}
            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    ></td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    ></td>
                  </div>
                </div>
              </div>
            </tr>
          ) : (
            <div className="d-flex align-items-center justify-content-center mt-5">
            <div className="vstack gap-3 text-center">
              <img
                src="/static/illustrations/no-projects-found.svg"
                alt=""
                height="200px"
              />
              <h2 className="body1 regular-weight">No learnings added</h2>
              {selectedMenu === "All Projects" && (
                <div>
                  <button className="btn btn-primary">
                    Add test to learning
                  </button>
                </div>
              )}
            </div>
          </div>
          )}
        </tbody>
      </table>
    )
   : (learnings?.length !== 0 ? 
     (
      <>
      <table className="table table-borderless mt-2 custom-table-sm">
          <thead className="border-none">
            <tr>
              <td scope="col" className="text-secondary body3 regular-weight">
                Idea Name
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Lever
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Goal
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Members
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Date
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Result
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                ICE Score
              </td>
              <td
                scope="col"
                className="text-secondary body3 regular-weight"
              ></td>
            </tr>
          </thead>
          <tbody>
            {learnings
              ?.filter((learning) => {
                console.log(learning.result);
                if (selectedMenu === "Successful")
                  return learning.result === "Successful";
                if (selectedMenu === "Unsuccessful")
                  return learning.result === "Unsuccessful";
                if (selectedMenu === "Inconclusive")
                  return learning.result === "Inconclusive";
                return true;
              })
              .map((learning) => {
                return (
                  <tr
                    className="border bg-white custom-hover-effect"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(
                        `/projects/${projectId}/learnings/${learning._id}`
                      );
                    }}
                  >
                    <td
                      className="body3 regular-weight align-middle align-items-center"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title={learning.name}
                    >
                      <span
                        style={{
                          width: "150px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "inline-block",
                        }}
                      >
                        {learning.name}
                      </span>
                    </td>
                    <td
                      className="body3 regular-weight align-middle"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title={learning.lever}
                    >
                      {" "}
                      {leversData.map((lever) => {
                      if (learning.lever === lever.name) {
                        let chipColor;
                        switch (lever.color) {
                          case "Blue":
                            chipColor = "blue-chip";
                            break;
                          case "Orange":
                            chipColor = "orange-chip";
                            break;
                          case "Green":
                            chipColor = "green-chip";
                            break;
                          case "Red":
                            chipColor = "red-chip";
                            break;
                          case "Yellow":
                            chipColor = "yellow-chip";
                            break;
                          default:
                            chipColor = "blue-chip";
                            break;
                        }
                        return (
                          <span className={chipColor} key={lever.name}>
                            {learning.lever}
                          </span>
                        );
                      }
                      return null;
                    })}{" "}
                    </td>
                    <td
                      className="body3 regular-weight align-middle"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title={learning?.goal?.name}
                    >
                      <span
                        style={{
                          width: "150px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "inline-block",
                        }}
                      >
                        {learning?.goal?.name}
                      </span>
                    </td>
                    <td className="body3 regular-weight align-middle">
                      <AvatarGroup
                        listOfUrls={learning.assignedTo?.map(
                          (t) => `${backendServerBaseURL}/${t.avatar}`
                        )}
                        userName={learning.assignedTo?.map((t) => [
                          t?.firstName,
                          `${backendServerBaseURL}/${t?.avatar}`,
                          t?.lastName,
                        ])}
                        show={3}
                        total={learning.assignedTo.length}
                      />
                    </td>
                    <td className="body3 regular-weight align-middle">
                      {formatTime(learning.createdAt)}
                    </td>
                    <td className="body3 regular-weight align-middle">
                      {learning.result}
                    </td>
                    <td className="body3 regular-weight align-middle">
                      {learning.score}
                    </td>
                    <td className="body3 regular-weight align-middle">
                      {hasPermission_create_learnings() ? (
                        <div class="dropdown">
                          <div
                            data-bs-toggle="dropdown"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            <i
                              class="bi bi-three-dots-vertical cp custom-more-icon-hover-effect"
                              style={{ marginRight: "0.4rem", padding: "0.5rem" }}
                            ></i>
                          </div>

                          <ul
                            className="dropdown-menu"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            <li className="border-bottom">
                              <a
                                className="dropdown-item body3 regular-weight"
                                onClick={() => {
                                  navigate(
                                    `/projects/${projectId}/learnings/${learning._id}`
                                  );
                                }}
                              >
                                View Learning
                              </a>
                            </li>
                            <li className="border-bottom">
                              <a
                                className="dropdown-item body3 regular-weight"
                                data-bs-toggle="modal"
                                data-bs-target="#sendBackToIdeaModal"
                                onClick={() => {
                                  dispatch(updateselectedLearning(learning));
                                }}
                              >
                                Send to test
                              </a>
                            </li>
                          </ul>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
    </>
    )
  : (
    // Optional: You can return a loading spinner or fallback UI in case the conditions above are not met.
     (<div>
      <table className="table table-borderless mt-2 custom-table-sm">
     <thead className="border-none">
     <div style={{ display: "flex", justifyContent: "space-between" }}>

    <tr style={{width: "100%"}}>
      <td className="text-secondary body3 regular-weight" style={{width: "90px",
                  maxWidth: "20%",}}>
        Name
      </td>
      </tr>
      <tr style={{width: "100%"}}>
      <td className="text-secondary body3 regular-weight" style={{  width: "100px",
                  maxWidth: "20%",}}>
        Duration
      </td>
      </tr>
      <tr style={{width: "100%"}}>
      <td className="text-secondary body3 regular-weight" style={{ width: "100px",
                  maxWidth: "20%",}}>
        Members
      </td>
      </tr>
      <tr style={{width: "100%"}}>
      <td className="text-secondary body3 regular-weight" style={{  width: "10px",
                  maxWidth: "20%",}}>
        Ideas
      </td>
      </tr>
      <tr style={{width: "100%"}}>
      <td  className="text-secondary body3 regular-weight" style={{  width: "100px",
                  maxWidth: "20%",}}>
    Status/Progress
      </td>
      </tr>
     
    </div>
  </thead>
  <tbody style={{width: "100%"}}>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", 
                borderRadius: "4px",
                border: "1px solid var(--black-400, #D6D6D6)",
                background: "var(--color-gray-gray-100, #F3F4F6)"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB",   
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", 
                borderRadius: "4px",
                border: "1px solid var(--black-400, #D6D6D6)",
                background: "var(--color-gray-gray-100, #F3F4F6)"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB",   
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", 
                borderRadius: "4px",
                border: "1px solid var(--black-400, #D6D6D6)",
                background: "var(--color-gray-gray-100, #F3F4F6)"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB",   
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", 
                borderRadius: "4px",
                border: "1px solid var(--black-400, #D6D6D6)",
                background: "var(--color-gray-gray-100, #F3F4F6)"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB",   
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", 
                borderRadius: "4px",
                border: "1px solid var(--black-400, #D6D6D6)",
                background: "var(--color-gray-gray-100, #F3F4F6)"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB",   
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", 
                borderRadius: "4px",
                border: "1px solid var(--black-400, #D6D6D6)",
                background: "var(--color-gray-gray-100, #F3F4F6)"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB",   
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", 
                borderRadius: "4px",
                border: "1px solid var(--black-400, #D6D6D6)",
                background: "var(--color-gray-gray-100, #F3F4F6)"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB",   
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", 
                borderRadius: "4px",
                border: "1px solid var(--black-400, #D6D6D6)",
                background: "var(--color-gray-gray-100, #F3F4F6)"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB",   
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", 
                borderRadius: "4px",
                border: "1px solid var(--black-400, #D6D6D6)",
                background: "var(--color-gray-gray-100, #F3F4F6)"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB",   
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", 
                borderRadius: "4px",
                border: "1px solid var(--black-400, #D6D6D6)",
                background: "var(--color-gray-gray-100, #F3F4F6)"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB",   
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        borderRadius: "4px",
                        background: "#E5E7EB", 
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            
          </tbody>
  </table>
  </div>)
  )
  )}

      <SendBackToTestsDialog />
      <DeleteLearningDialog />
      <TourModal />
    </div>
  );
}

export default Learnings;

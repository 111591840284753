import React, { useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { Field, FieldArray } from "formik";
import { Form, FormikProvider } from "formik";
import { useParams } from "react-router-dom";
import { createGoal, selectProjectUsers, selectSelectedGoal, updateGoal, selectSingleGoalInfo,
} from "../../../redux/slices/projectSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectkeyMetrics } from "../../../redux/slices/settingSlice";
import { backendServerBaseURL } from "../../../utils/backendServerBaseURL";
import { formatDate3 } from "../../../utils/formatTime";
import LoadingButton from "../../../components/common/LoadingButton";

function CreateNewGoalDialog({ openRequestIdeaDialog, selectedTab }) {
  const [selectedTeamMembers, setselectedTeamMembers] = useState([]);
  const [numberOfTeamMembersToShowInSelect, setnumberOfTeamMembersToShowInSelect] = useState(4);
  const teamMembersDropdown = useRef();
  const [selectedMenu, setselectedMenu] = useState(selectedTab);
  const params = useParams();
  const projectId = params.projectId;
  const goalId = params.goalId;
  const dispatch = useDispatch();
  const closeModalRef = useRef();
  const projectUsers = useSelector(selectProjectUsers);
  const allKeyMetrics = useSelector(selectkeyMetrics);
  const selectedGoal = useSelector(selectSelectedGoal);
  const [isSubmitting, setisSubmitting] = useState(false);
// console.log('allKeyMetrics :>> ', allKeyMetrics);
  const closeModal = () => {
    closeModalRef.current.click();
  };
  const singleGoalInfo = useSelector(selectSingleGoalInfo);
console.log('singleGoalInfo 333 ====:>> ', singleGoalInfo);
  let singleGoalMetricsData = singleGoalInfo?.keymetric.map(x => x.metrics);


  const ProjectsMenus = [
    {
      name: "About Goal",
    },
    {
      name: "Assign Members",
    },
    {
      name: "Key Metrics",
    },
    {
      name: "Confidence",
    },
  ];

  const RightProjectsMenus = [];

  const aboutGoalFormik = useFormik({
    initialValues: {
      name: "",
      description: "",
      startDate: new Date(),
      endDate: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Goal Name is required"),
      description: Yup.string().required("Goal description is required"),
      startDate: Yup.string().required("Start date is required"),
      endDate: Yup.string().required("End Date is required"),
    }),
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log(aboutGoalFormik.values);
      setSubmitting(false);
    },
  });

  const keyMetricsFormik = useFormik({
    initialValues: {
      keyMetrics: [],
    },
    validationSchema: Yup.object().shape({
      keyMetrics: Yup.array().of(
        Yup.object().shape({
          keyMetric: Yup.string().required(),
          startValue: Yup.number().required(),
          targetValue: Yup.number().required(),
        })
      ),
    }),
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log(aboutGoalFormik.values);
      setSubmitting(false);
    },
  });


  console.log('keyMetricsFormik.values :>> ', keyMetricsFormik.values);


  const confidenceFormik = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape({
      confidence: Yup.string().required("Confidence is required"),
    }),
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log(aboutGoalFormik.values);
      setSubmitting(false);
    },
  });

  const addTeamMember = (teamMember) => {
    console.log(selectedTeamMembers);
    let uniqueItems = [...new Set(selectedTeamMembers.concat([teamMember]))];
    setselectedTeamMembers(uniqueItems);
  };

  const removeSelectedTeamMember = (id) => {
    console.log(id);
    let tempTM = [];
    selectedTeamMembers.map((tm, index) => {
      if (tm != id) {
        tempTM.push(tm);
      }
    });
    console.log(tempTM);
    setselectedTeamMembers(tempTM);
  };

  window.addEventListener("click", function (e) {
    try {
      if (document.getElementById("teamMemberSelectDropdown").contains(e.target)) {
        // Clicked in box
      } else {
        teamMembersDropdown.current.classList.remove("show");
      }
    } catch (err) {}
  });


  const reset = () => {
    setselectedMenu("About Goal");
    aboutGoalFormik.resetForm();
    keyMetricsFormik.resetForm();
    confidenceFormik.resetForm();
    setselectedTeamMembers([]);
  };

  const onChangeKeymetric = (e) => {
console.log('e.target.value :>> ', e.target.value);
  }

  const submitNewGoalForm = async () => {
    // console.log(aboutGoalFormik.values);
    // console.log(selectedTeamMembers);
    // console.log("keyMetricsFormik.values", keyMetricsFormik.values);
    // console.log("Check",aboutGoalFormik.values,
    //   keyMetricsFormik.values,
    //   confidenceFormik.values,
    //   selectedTeamMembers,
    //   projectId,
    //   closeModal,
    //   reset,
    //   openRequestIdeaDialog,
    //   goalId,);

      // console.log('...keyMetricsFormik.values, :>> ', keyMetricsFormik.values,);
      // console.log('...singleGoalInfo.keyMetric :>> ', singleGoalInfo.keymetric);
    if (selectedGoal) {
      await dispatch(
        updateGoal({
          ...aboutGoalFormik.values,
          ...keyMetricsFormik.values,
           ...confidenceFormik.values,
          members: selectedTeamMembers,
          projectId,
          closeModal,
          reset,
          openRequestIdeaDialog,
          goalId,
        })
      );
    } else {
      await dispatch(
        createGoal({
          ...aboutGoalFormik.values,
          ...keyMetricsFormik.values,
          ...confidenceFormik.values,
          members: selectedTeamMembers,
          projectId,
          closeModal,
          reset,
          openRequestIdeaDialog,
        })
      );
    }
  };

  const resetAllFields = () => {
    aboutGoalFormik.resetForm();
    setselectedTeamMembers([]);
    keyMetricsFormik.resetForm();
    confidenceFormik.resetForm();
  };

  useEffect(() => {
    console.log(selectedGoal);
    if (selectedGoal) {
      aboutGoalFormik.setValues({
        name: selectedGoal.name,
        description: selectedGoal.description,
        startDate: selectedGoal.startDate,
        endDate: selectedGoal.endDate,
      });

      setselectedTeamMembers(selectedGoal.members);
      confidenceFormik.setValues({
        confidence: selectedGoal.confidence,
      });
      keyMetricsFormik.setValues({
        keyMetrics: selectedGoal.keymetric?.map((keymetric) => {
          return {
            keyMetric: keymetric.name,
            startValue: keymetric.startValue,
            targetValue: keymetric.targetValue,
            metrics: keymetric.metrics
          };
        }),
      });
    } else {
      aboutGoalFormik.setValues({
        name: "",
        description: "",
        startDate: new Date(),
        endDate: "",
      });

      setselectedTeamMembers([]);
      confidenceFormik.setValues({
        confidence: "",
      });
      keyMetricsFormik.setValues({
        keyMetrics: [],
      });
    }
  }, [selectedGoal]);

  useEffect(() => {
    setselectedMenu(selectedTab);
  }, [selectedTab]);
  

  useEffect(()=> {
    if(singleGoalInfo?.keymetric?.metrics){
      alert("hereeeee");
      keyMetricsFormik.setValues(singleGoalMetricsData);
      console.log('keyMetricsFormik.setValues', keyMetricsFormik.setValues(singleGoalInfo?.keymetric?.metrics)
      );
    }
  })

  return (
    <>
      <div>
        <div className="modal fade" id="createNewGoalDialog" tabIndex={-1} aria-labelledby="createNewGoalDialogLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="border-bottom mt-3 mb-3">
                  <h2 style={{ marginBottom: "24px" }}>{singleGoalInfo ? "Edit" : "New"} Goal</h2>

                  {/* Tabs */}
                  <div className="flex-fill d-flex align-items-center">
                    {ProjectsMenus.map((menu) => {
                      return (
                        <div
                          style={{ textDecoration: "none" }}
                          className="text-dark body3 regular-weight cp flex-fill"
                          onClick={() => {
                            setselectedMenu(menu.name);
                          }}
                        >
                          <div
                            className={selectedMenu === menu.name ? "text-center border-bottom border-primary border-3" : "text-center pb-1"}
                            style={{ minWidth: "7rem" }}
                          >
                            <p className="mb-1">{menu.name}</p>
                          </div>
                        </div>
                      );
                    })}

                    <div className="flex-fill ml-auto"></div>

                    {RightProjectsMenus.map((menu) => {
                      return (
                        <div
                          style={{ textDecoration: "none" }}
                          className="text-dark body3 regular-weight cp"
                          onClick={() => {
                            setselectedMenu(menu.name);
                          }}
                        >
                          <div
                            className={selectedMenu === menu.name ? "text-center border-bottom border-primary border-3" : "text-center pb-1"}
                            style={{ minWidth: "7rem" }}
                          >
                            <p className="mb-1">{menu.name}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* Close btn hidden */}
                <div data-bs-dismiss="modal" ref={closeModalRef}></div>

                {/* About Goal STEP */}
                <FormikProvider value={aboutGoalFormik}>
                  <Form autoComplete="off" noValidate onSubmit={aboutGoalFormik.handleSubmit}>
                    {selectedMenu === "About Goal" && (
                      <>
                        {/* Goal Name */}
                        <div className="form-field">
                          <label className="form-label">Goal Name</label>
                          <input
                            type={"text"}
                            {...aboutGoalFormik.getFieldProps("name")}
                            className="form-control form-control-lg"
                            placeholder="Enter Goal Name"
                          />
                          <span
                            className="invalid-feedback"
                            style={{ display: Boolean(aboutGoalFormik.touched.name && aboutGoalFormik.errors.name) ? "block" : "none" }}
                          >
                            {aboutGoalFormik.errors.name}
                          </span>
                        </div>

                        {/* Goal Description */}
                        <div className="form-field">
                          <label className="form-label">Description</label>
                          <textarea
                            rows="4"
                            type={"text"}
                            {...aboutGoalFormik.getFieldProps("description")}
                            className="form-control form-control-lg"
                            placeholder="Enter Description"
                          />
                          <span
                            className="invalid-feedback"
                            style={{ display: Boolean(aboutGoalFormik.touched.description && aboutGoalFormik.errors.description) ? "block" : "none" }}
                          >
                            {aboutGoalFormik.errors.description}
                          </span>
                        </div>

                        {/* Start and End Date */}
                        <div className="row">
                          {/* Start Date */}
                          <div className="col-6">
                            <div className="form-field">
                              <label className="form-label">Start Date</label>
                              <input
                                type={"date"}
                                {...aboutGoalFormik.getFieldProps("startDate")}
                                value={aboutGoalFormik.values.startDate && new Date(aboutGoalFormik.values.startDate).toISOString().substr(0, 10)}
                                className="form-control form-control-lg"
                                placeholder="Enter Start Date"
                              />
                              <span
                                className="invalid-feedback"
                                style={{ display: Boolean(aboutGoalFormik.touched.startDate && aboutGoalFormik.errors.startDate) ? "block" : "none" }}
                              >
                                {aboutGoalFormik.errors.startDate}
                              </span>
                            </div>
                          </div>

                          {/* End Date */}
                          <div className="col-6">
                            <div className="form-field">
                              <label className="form-label">End Date</label>
                              <input
                                disabled={!aboutGoalFormik.values.startDate || aboutGoalFormik.values.startDate == ""}
                                min={aboutGoalFormik.values.startDate}
                                type={"date"}
                                {...aboutGoalFormik.getFieldProps("endDate")}
                                value={aboutGoalFormik.values.endDate && new Date(aboutGoalFormik.values.endDate).toISOString().substr(0, 10)}
                                className="form-control form-control-lg"
                                placeholder="Enter End Date"
                              />
                              <span
                                className="invalid-feedback"
                                style={{ display: Boolean(aboutGoalFormik.touched.endDate && aboutGoalFormik.errors.endDate) ? "block" : "none" }}
                              >
                                {aboutGoalFormik.errors.endDate}
                              </span>
                            </div>
                          </div>
                        </div>

                        {/* Action Buttons */}
                        <div className="hstack gap-2 d-flex justify-content-end">
                          <button
                            type="button"
                            class="btn btn-lg btn-outline-danger"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              // resetAllFields();
                            }}
                          >
                            Close
                          </button>
                          {selectedGoal ? (
                            <button
                              class={"btn btn-lg btn-primary"}
                              type="button"
                              onClick={() => {
                                submitNewGoalForm();
                              }}
                            >
                              Update Goal
                            </button>
                          ) : (
                            <button
                              type="button"
                              class={"btn btn-lg " + (!aboutGoalFormik.isValid || !aboutGoalFormik.dirty ? "btn-secondary" : "btn-primary")}
                              disabled={!aboutGoalFormik.isValid || !aboutGoalFormik.dirty}
                              onClick={() => {
                                setselectedMenu("Assign Members");
                              }}
                            >
                              Next
                            </button>
                          )}
                        </div>
                      </>
                    )}
                  </Form>
                </FormikProvider>

                {/* Assign Members STEP */}
                {selectedMenu === "Assign Members" && (
                  <div>
                    {/* Team Members */}
                    <div className="form-field">
                      <label className="form-label">Assign To</label>
                      <div class="dropdown" id="teamMemberSelectDropdown">
                        <div
                          className="border d-flex justify-content-between align-items-center p-2 rounded"
                          // data-bs-toggle="dropdown"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            teamMembersDropdown.current.classList.toggle("show");
                          }}
                        >
                          {selectedTeamMembers.length === 0 && <p className="m-0">Select team members</p>}

                          <span>
                            {selectedTeamMembers.slice(0, numberOfTeamMembersToShowInSelect).map((teamMember) => {
                              return (
                                <span>
                                  <span>
                                    <img
                                      src={`${backendServerBaseURL}/${teamMember.avatar}`}
                                      className="avatar3"
                                      alt=""
                                      style={{ marginRight: "0.5rem" }}
                                    />
                                  </span>
                                  <span style={{ marginRight: "0.5rem" }}>
                                    {teamMember.firstName} {teamMember.lastName}
                                  </span>
                                  <button
                                    type="button"
                                    class="btn-secondary btn-close"
                                    style={{ fontSize: "8px", marginRight: "12px" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      removeSelectedTeamMember(teamMember);
                                      // teamMembersDropdown.current.classList.add("hide");
                                      // teamMembersDropdown.current.classList.remove("show");
                                    }}
                                  ></button>
                                </span>
                              );
                            })}

                            {selectedTeamMembers.length > numberOfTeamMembersToShowInSelect &&
                              `${selectedTeamMembers.length - numberOfTeamMembersToShowInSelect} Others`}
                          </span>

                          <img
                            src="/static/icons/down-arrow.svg"
                            alt=""
                            className="ml-auto"
                            height={"12px"}
                            width={"12px"}
                            style={{ marginRight: "0.5rem" }}
                          />
                        </div>

                        <ul className="dropdown-menu w-100" ref={teamMembersDropdown}>
                          <li
                            onClick={() => {
                              setselectedTeamMembers(projectUsers);
                            }}
                            className="list-group-item list-group-item-action border-bottom d-flex align-items-center p-2 cp"
                          >
                            <span className="avatar" style={{ marginRight: "0.5rem" }}>
                              <div
                                className="d-flex align-items-center justify-content-center"
                                style={{
                                  minWidth: "24px",
                                  maxWidth: "24px",
                                  minHeight: "24px",
                                  maxHeight: "24px",
                                  backgroundColor: "var(--bs-gray-300)",
                                  borderRadius: "50%",
                                  fontSize: "12px",
                                }}
                              >
                                <span className="body2 regular-weight">{projectUsers?.length}</span>
                              </div>
                            </span>
                            <span className="body2 regular-weight">Everyone in your team</span>
                          </li>
                          {projectUsers.map((teamMember) => {
                            return (
                              <li
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  addTeamMember(teamMember);
                                }}
                                className={
                                  selectedTeamMembers.includes(teamMember)
                                    ? "d-flex align-items-center list-group-item list-group-item-action cp p-2 active"
                                    : "d-flex align-items-center list-group-item list-group-item-action cp border-bottom p-2"
                                }
                              >
                                <span className="avatar" style={{ marginRight: "0.5rem" }}>
                                  <img src={`${backendServerBaseURL}/${teamMember.avatar}`} width={24} height={24} />
                                </span>
                                <span className="body2 regular-weight flex-fill">
                                  {teamMember?.firstName} {teamMember?.lastName}
                                </span>

                                {selectedTeamMembers.includes(teamMember) && (
                                  <div className="ml-auto">
                                    <button
                                      type="button"
                                      class="btn-secondary btn-close"
                                      style={{ fontSize: "8px", marginRight: "12px" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        removeSelectedTeamMember(teamMember);
                                      }}
                                    ></button>
                                  </div>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>

                    {/* Action Buttons */}
                    <div className="d-flex align-items-center">
                      <div className="flex-fill">
                        <p
                          className="text-primary cp"
                          onClick={() => {
                            setselectedTeamMembers([]);
                            setselectedMenu("Key Metrics");
                          }}
                        >
                          Skip For Now
                        </p>
                      </div>
                      <div className="hstack gap-2">
                        <button
                          type="button"
                          class="btn btn-lg btn-outline-danger"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            // resetAllFields();
                          }}
                        >
                          Close
                        </button>

                        {selectedGoal ? (
                          <button
                            class={"btn btn-lg btn-primary"}
                            type="button"
                            onClick={() => {
                              submitNewGoalForm();
                            }}
                          >
                            Update Goal
                          </button>
                        ) : (
                          <button
                            type="button"
                            class={"btn btn-lg " + (selectedTeamMembers.length === 0 ? "btn-secondary" : "btn-primary")}
                            disabled={selectedTeamMembers.length === 0}
                            onClick={() => {
                              setselectedMenu("Key Metrics");
                            }}
                          >
                            Next
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {/* Key Metrics STEP */}
                <FormikProvider value={keyMetricsFormik}>
                  <Form autoComplete="off" noValidate onSubmit={keyMetricsFormik.handleSubmit}>
                    {selectedMenu === "Key Metrics" && (
                      <>
                        {/* Key Metrics */}
                        <div className="form-field customScrollBarLight" style={{ maxHeight: "20rem", overflowX: "hidden", overflowY: "scroll" }}>
                          <div className="mb-4">
                            <FieldArray
                              name="keyMetrics"
                              {...keyMetricsFormik.getFieldProps("keyMetrics")}
                              render={(arrayHelpers) => (
                                <div>
                                  {keyMetricsFormik.values.keyMetrics?.map((option, index) => (
                                    <div key={index} className="mb-4">
                                      <div className="row">
                                        <div className="col-12 mb-2">
                                          <label className="form-label">Key Metrics</label>

                                          <select
                                            class="form-select"
                                            name={`keyMetrics.${index}.keyMetric`}
                                             {...keyMetricsFormik.getFieldProps(`keyMetrics.${index}.keyMetric`)}
                                            onChange={(e) => {
                                              keyMetricsFormik.setFieldValue(`keyMetrics.${index}.keyMetric`, e.target.value)
                                              if (e.target.value === ""){
                                                keyMetricsFormik.setFieldValue(`keyMetrics.${index}.startValue`, "")
                                                keyMetricsFormik.setFieldValue(`keyMetrics.${index}.targetValue`, "")
                                                // `keyMetrics.${index}.startValue` === ""
                                              }}}
                                          >
                                            <option value="">Select a Key Metric</option>
                                            {allKeyMetrics.map((keyMetric) => {
                                              return <option value={keyMetric.name}>{keyMetric.name}</option>;
                                            })}
                                          </select>

                                          {/* <Field
                                            placeholder={`keyMetric`}
                                            name={`keyMetrics.${index}.keyMetric`}
                                            className="form-control form-control-lg"
                                          /> */}
                                          <span
                                            className="invalid-feedback"
                                            style={{
                                              display:
                                                Object.keys(keyMetricsFormik.errors).includes("keyMetrics") &&
                                                keyMetricsFormik.errors[`keyMetrics`]?.length != 0 &&
                                                keyMetricsFormik.errors[`keyMetrics`][index]?.keyMetric
                                                  ? "block"
                                                  : "none",
                                            }}
                                          >
                                            Key Metric is required
                                          </span>
                                        </div>

                                        <div className="col-6">
                                          <label className="form-label">Start Value</label>
                                          <Field
                                            placeholder={`startValue`}
                                            name={`keyMetrics.${index}.startValue`}
                                            className="form-control form-control-lg"
                                          />
                                          <span
                                            className="invalid-feedback"
                                            style={{
                                              display:
                                                Object.keys(keyMetricsFormik.errors).includes("keyMetrics") &&
                                                keyMetricsFormik.errors[`keyMetrics`]?.length != 0 &&
                                                keyMetricsFormik.errors[`keyMetrics`][index]?.startValue
                                                  ? "block"
                                                  : "none",
                                            }}
                                          >
                                            Start Value is required
                                          </span>
                                        </div>

                                        <div className="col-6">
                                          <label className="form-label">Target Value</label>
                                          <Field
                                            placeholder={`targetValue`}
                                            name={`keyMetrics.${index}.targetValue`}
                                            className="form-control form-control-lg"
                                          />
                                          <span
                                            className="invalid-feedback"
                                            // value={allKeyMetrics ? `keyMetrics.${index}.targetValue` : ""}
                                            style={{
                                              display:
                                                Object.keys(keyMetricsFormik.errors).includes("keyMetrics") &&
                                                keyMetricsFormik.errors[`keyMetrics`]?.length != 0 &&
                                                keyMetricsFormik.errors[`keyMetrics`][index]?.targetValue
                                                  ? "block"
                                                  : "none",
                                            }}
                                          >
                                            Target Value is required
                                          </span>
                                        </div>

                                        {/* <div className="col-2">
                                        <button className="btn btn-primary" onClick={() => arrayHelpers.remove(index)}>
                                          Remove
                                        </button>
                                      </div> */}
                                      </div>

                                      <hr style={{ marginTop: "2rem" }} />
                                    </div>
                                  ))}

                                  {/* Add New Key Metric */}
                                  <div>
                                    <label className="form-label">Key Metrics</label>
                                    <select
                                      class="form-select"
                                      onChange={(e) => {
                                        if (e.target.value !== "")
                                          arrayHelpers.push({ keyMetric: allKeyMetrics.filter((k) => k._id == e.target.value)[0].name });
                                          e.target.value = "";
                                      }}
                                    >
                                      <option value="">Select a Key Metric</option>
                                      {allKeyMetrics.map((keyMetric) => {
                                        return <option value={keyMetric._id}>{keyMetric.name}</option>;
                                      })}
                                    </select>
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                        </div>

                        {/* Action Buttons */}
                        <div className="d-flex align-items-center">
                          <div className="flex-fill">
                            <p
                              className="text-primary cp"
                              onClick={() => {
                                keyMetricsFormik.setFieldValue("keyMetrics", []);
                                setselectedMenu("Confidence");
                              }}
                            >
                              Skip For Now
                            </p>
                          </div>
                          <div className="hstack gap-2">
                            <button
                              type="button"
                              class="btn btn-lg btn-outline-danger"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                // resetAllFields();
                              }}
                            >
                              Close
                            </button>

                            {selectedGoal ? (
                              <button
                                class={"btn btn-lg btn-primary"}
                                type="button"
                                onClick={() => {
                                  submitNewGoalForm();
                                }}
                              >
                                Update Goal
                              </button>
                            ) : (
                              <button
                                type="button"
                                class={
                                  "btn btn-lg " +
                                  (!keyMetricsFormik.isValid || keyMetricsFormik.values.keyMetrics.length === 0 ? "btn-secondary" : "btn-primary")
                                }
                                disabled={!keyMetricsFormik.isValid || keyMetricsFormik.values.keyMetrics.length === 0}
                                onClick={() => {
                                  setselectedMenu("Confidence");
                                }}
                              >
                                Next
                              </button>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </Form>
                </FormikProvider>

                {/* Confidence STEP */}
                <FormikProvider value={confidenceFormik}>
                  <Form autoComplete="off" noValidate onSubmit={confidenceFormik.handleSubmit}>
                    {selectedMenu === "Confidence" && (
                      <>
                        {/* Confidence */}
                        <label className="form-label">Confidence</label>
                        <select {...confidenceFormik.getFieldProps("confidence")} class="form-select">
                          <option value="" className="text-secondary">
                            How confident are you about this goal
                          </option>
                          <option value="Very Confident">Very Confident</option>
                          <option value="Confident">Confident</option>
                          <option value="Realistic">Realistic</option>
                          <option value="Achievable">Achievable</option>
                          <option value="Ambitious">Ambitious</option>
                          <option value="Unsure">Unsure</option>
                        </select>

                        {/* Action Buttons */}
                        <div className="d-flex align-items-center mt-4">
                          <div className="flex-fill">
                            <p
                              className="text-primary cp"
                              onClick={() => {
                                confidenceFormik.setFieldValue("confidence", "");
                                submitNewGoalForm();
                              }}
                            >
                              Skip For Now
                            </p>
                          </div>
                          <div className="hstack gap-2">
                            <button
                              type="button"
                              class="btn btn-lg btn-outline-danger"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                // resetAllFields();
                              }}
                            >
                              Close
                            </button>

                            {selectedGoal ? (
                              <LoadingButton
                                loading={isSubmitting}
                                class={"btn btn-lg btn-primary"}
                                type="button"
                                onClick={async () => {
                                  setisSubmitting(true);
                                  await submitNewGoalForm();
                                  setisSubmitting(false);
                                }}
                              >
                                Update Goal
                              </LoadingButton>
                            ) : (
                              <LoadingButton
                                loading={isSubmitting}
                                type="button"
                                onClick={async () => {
                                  setisSubmitting(true);
                                  await submitNewGoalForm();
                                  setisSubmitting(false);
                                }}
                                class={"btn btn-lg " + (!confidenceFormik.isValid || !confidenceFormik.dirty ? "btn-secondary" : "btn-primary")}
                                disabled={!confidenceFormik.isValid || !confidenceFormik.dirty}
                              >
                                Create Goal
                              </LoadingButton>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </Form>
                </FormikProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateNewGoalDialog;
